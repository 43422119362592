import 'bootstrap/dist/css/bootstrap.min.css';

import React from 'react';
import ReactDOM from 'react-dom';

import App from './components/App';
import './index.css';
import { createRoot } from 'react-dom/client';
const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);

root.render(<React.StrictMode>
    <App />
  </React.StrictMode>);
