import gql from 'graphql-tag';

export default gql`
    query GetLoadSummary($jobId: Int) {
        getLoadSummary(jobId: $jobId) {
            proppantId
            proppantName
            loadSummaries {
                isActive
                jobSummaryId
                lofaId
                lofaName
                sandPo
                transportationPo
                loadsCreated
                loadsInProgress
                loadsCompleted
                loadsRemaining
                loadsTotal
                loadsCompletedVolume
                loadsCreatedVolume
                loadsInProgressVolume
                loadsRemainingVolume
                loadsTotalVolume
                totalVolume
                lofaVolume
                volumePerBox
                proppantId
                milesFromWell
            }
        }
    }
`;
