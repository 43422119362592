import { Bounce, ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
interface ToastOptions {
  position: any
  autoClose: any,
  hideProgressBar: any,
  closeOnClick: any,
  pauseOnHover: any,
  draggable: any,
  progress: any,
  theme: any,
  transition: any,
}
export const toastOpt:ToastOptions = {
  position: "top-center" || undefined,
  autoClose: 3000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "light",
  transition: Bounce,
}


