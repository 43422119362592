import gql from 'graphql-tag';

export default gql`
    query GetCarrier {
        getCarriers {
            id
            companyName
            companyType
        }
    }
`;
