import React from 'react';
import './SummaryHeader.css';

interface SummaryHeaderProps {
    wellName: string | null;
    stages: number | null;
    stageTime: number | null;
    totalBoxes: number | null;
    isLastItem: boolean;
}

const SummaryHeader = ({ wellName, stages, stageTime, totalBoxes, isLastItem }: SummaryHeaderProps): JSX.Element => {
    return (
        <div className={!isLastItem ? 'PumpSchedule-Row' : 'PumpSchedule-Last-Row'}>
            <div className="SummaryHeader-Container">
                <div className="Row">
                    <span className="SummaryHeader-Detail">{wellName}</span>
                </div>
                <div className="Row align-items-baseline">
                    <span className="SummaryHeader-Label">Stages:</span>
                    <span className="SummaryHeader-Detail">{stages}</span>
                </div>
                <div className="Row align-items-baseline">
                    <span className="SummaryHeader-Label">Stage Duration (min):</span>
                    <span className="SummaryHeader-Detail">{stageTime}</span>
                </div>
                <div className="Row align-items-baseline">
                    <span className="SummaryHeader-Label">Total Boxes:</span>
                    <span className="SummaryHeader-Detail">{totalBoxes}</span>
                </div>
            </div>
        </div>
    );
};

export default SummaryHeader;
