import gql from 'graphql-tag';

export default gql`
    query GetDriverFilters {
        getDriverFilters {
            statuses {
                id
                name
            }
            regions {
                id
                name
            }
            districts {
                id
                name
            }
        }
    }
`;
