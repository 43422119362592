import { Reducer } from 'react';
import { Actions } from '../../actions/messageList';
import { Action, MessageListState } from '../../types';
import moment from 'moment';
import FilterItem from '../../../components/Filter/FilterItem';

const initialMessageListState: MessageListState = {
    filters: {
        regionIds: [],
        carrierIds: [],
        fromDate: '',
        toDate: '',
        messageType: [],
    },
    searchPhrase: '',
    driverSearch: '',
    dateFilter: 'ONEWEEK',
    searchPhraseSubset: {
        regionSubset: { subset: [], searchPhrase: '' },
        carrierSubset: { subset: [], searchPhrase: '' },
    },
};

interface FilterObject {
    filterType: string;
    id: number;
    isSelected: boolean;
}

interface MessageTypeFilterObject {
    filterType: string;
    value: string;
    isSelected: boolean;
}

const setDateRangeFilters = (
    payload: { fromDate: string; toDate: string },
    state: MessageListState,
): MessageListState => {
    return {
        ...state,
        dateFilter: 'DATERANGE',
        filters: {
            ...state.filters,
            fromDate: payload.fromDate,
            toDate: payload.toDate,
        },
    };
};

const addOrRemoveFilters = (payload: FilterObject, filters: number[]): number[] => {
    let massagedFilter: number[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.id);
    } else {
        massagedFilter = filters.filter((id): any => {
            if (id !== payload.id) return id;

            return undefined;
        });
    }

    return massagedFilter;
};

const addOrRemoveMessageTypeFilters = (payload: MessageTypeFilterObject, filters: string[]): string[] => {
    let massagedFilter: string[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.value);
    } else {
        massagedFilter = filters.filter((value): any => {
            if (value !== payload.value) return value;
            return undefined;
        });
    }

    return massagedFilter;
};

const addOrRemoveSelectAllFilters = (itemIds: number[], filters: number[], selected: boolean): number[] => {
    const massagedFilter: number[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(itemIds);
    }
};

const addOrRemoveSelectAllMessageTypeFilters = (
    messageTypeValues: string[],
    filters: string[],
    selected: boolean,
): string[] => {
    const massagedFilter: string[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(messageTypeValues);
    }
};

const setSelectedFilters = (payload: FilterObject, state: MessageListState): MessageListState => {
    const { filters } = state;

    if (payload.filterType === 'REGIONS') {
        filters.regionIds = addOrRemoveFilters(payload, filters.regionIds);
    }

    if (payload.filterType === 'CARRIER') {
        filters.carrierIds = addOrRemoveFilters(payload, filters.carrierIds);
    }

    return {
        ...state,
        filters: { ...filters },
    };
};

const setSelectedMessageTypeFilters = (payload: MessageTypeFilterObject, state: MessageListState): MessageListState => {
    const { filters } = state;

    if (payload.filterType === 'MESSAGE_TYPE') {
        filters.messageType = addOrRemoveMessageTypeFilters(payload, filters.messageType);
    }
    return {
        ...state,
        filters: { ...filters },
    };
};

// TODO: Generisize these two methods to take in the action type and reduce use of chained if statements
const setSelectAllFilters = (
    payload: { itemIds: number[]; filterType: string },
    state: MessageListState,
): MessageListState => {
    const { filters } = state;

    if (payload.filterType === 'REGIONS') {
        filters.regionIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filters.regionIds,
            !(filters.regionIds.length > 0),
        );
    }

    if (payload.filterType === 'CARRIER') {
        filters.carrierIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filters.carrierIds,
            !(filters.carrierIds.length > 0),
        );
    }
    return {
        ...state,
        filters: { ...filters },
    };
};

const setSelectAllMessageTypeFilters = (
    payload: { messageTypeValues: string[]; filterType: string },
    state: MessageListState,
): MessageListState => {
    const { filters } = state;

    if (payload.filterType === 'MESSAGE_TYPE') {
        filters.messageType = addOrRemoveSelectAllMessageTypeFilters(
            payload.messageTypeValues,
            filters.messageType,
            !(filters.messageType.length > 0),
        );
    }
    return {
        ...state,
        filters: { ...filters },
    };
};

const setSearchPhrase = (search: string, state: MessageListState): MessageListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const setDriverSearchPhrase = (search: string, state: MessageListState): MessageListState => {
    return {
        ...state,
        driverSearch: search,
    };
};

const setDateFilter = (dateFilter: string, state: MessageListState): MessageListState => {
    return {
        ...state,
        dateFilter: dateFilter,
        filters: {
            ...state.filters,
            fromDate: '',
            toDate: '',
        },
    };
};

const updateFilterListForSearchPhrase = (items: { id: number; name: string }[], searchString: string): FilterItem[] => {
    return items.filter(item => item.name.toLowerCase().includes(searchString.toLowerCase()));
};

const setFilterSearchPhrase = (
    payload: { items: FilterItem[]; filterType: string; searchPhrase: string },
    state: MessageListState,
): MessageListState => {
    const { searchPhraseSubset } = state;

    if (payload.filterType === 'REGION') {
        searchPhraseSubset.regionSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }

    if (payload.filterType === 'CARRIER') {
        searchPhraseSubset.carrierSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }

    return {
        ...state,
        searchPhraseSubset: { ...searchPhraseSubset },
    };
};

const resetFilterState = (state: MessageListState) => {
    return {
        ...state,
        filters: {
            regionIds: [],
            carrierIds: [],
            fromDate: '',
            toDate: '',
            messageType: [],
        },
        searchPhrase: '',
        driverSearch: '',
        dateFilter: 'ONEWEEK',
        searchPhraseSubset: {
            regionSubset: { subset: [], searchPhrase: '' },
            carrierSubset: { subset: [], searchPhrase: '' },
        },
    };
};

const messageListReducer: Reducer<MessageListState, Action> = (state, action): MessageListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_FILTERS:
            newState = setSelectedFilters(action.payload, state);
            break;
        case Actions.UPDATE_MESSAGE_TYPE_FILTERS:
            newState = setSelectedMessageTypeFilters(action.payload, state);
            break;
        case Actions.UPDATE_FILTERS_SELECT_ALL:
            newState = setSelectAllFilters(action.payload, state);
            break;
        case Actions.UPDATE_MESSAGE_TYPE_FILTERS_SELECT_ALL:
            newState = setSelectAllMessageTypeFilters(action.payload, state);
            break;
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_DRIVER_SEARCH_PHRASE:
            newState = setDriverSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_DATE_FILTER:
            newState = setDateFilter(action.payload, state);
            break;
        case Actions.UPDATE_DATE_RANGE_FILTERS:
            newState = setDateRangeFilters(action.payload, state);
            break;
        case Actions.UPDATE_FILTER_SEARCH_PHRASE:
            newState = setFilterSearchPhrase(action.payload, state);
            break;
        case Actions.REFRESH_MESSAGE_FILTERS:
            newState = resetFilterState(state);
    }

    return newState;
};

export { initialMessageListState, messageListReducer };
