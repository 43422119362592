import { Reducer } from 'react';
import { Actions } from '../../actions/jobActivity';
import { Action, JobActivityState } from '../../types';
import loadIcon from '../../../assets/loadIcon.png';
import mapIcon from '../../../assets/googleEarth.png';

const initialJobActivityState: JobActivityState = {
    tabs: [
        {
            key: 0,
            displayName: 'Details',
            icon: loadIcon,
            isSelected: true,
        },
        {
            key: 1,
            displayName: 'Map',
            icon: mapIcon,
            isSelected: false,
        },
    ],
};

const setSelectedTab = (payload: number, state: JobActivityState): JobActivityState => {
    return {
        ...state,
        tabs: state.tabs.map((tab): any => {
            const isSelected = tab.key === payload;
            return { ...tab, isSelected: isSelected };
        }),
    };
};

const jobActivityReducer: Reducer<JobActivityState, Action> = (state, action): JobActivityState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.SET_SELECTED_TAB:
            newState = setSelectedTab(action.payload, state);
            break;
    }

    return newState;
};

export { initialJobActivityState, jobActivityReducer };
