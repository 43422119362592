import gql from 'graphql-tag';

export default gql`
    query GetDriversByFilter($input: GetAllDriversInput) {
        getDriversByFilter(input: $input) {
            driverId
            driverCompletedId
            driverRejectedId
            driverRequestedId
            driverName
            lastLoadDelivered
            lastLoadRequested
            lastLoadRejected
            onShift
            onShiftTime
            isActive
            loadId
            onLoad
            assigned
            waitingOnLoad
            notes {
                id
                loadId
                notes
                createDate
                name
            }
        }
    }
`;
