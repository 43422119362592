import gql from 'graphql-tag';

export default gql`
    query GetDriverDistance($input: DistanceDriverInput) {
        getDriverDistance(input: $input) {
            driverId
            driverLat
            driverLong
            wellLat
            wellLong
            lofaLat
            lofaLong
            distanceToLofa
            distanceToWellsite
            timeToDestinationInSeconds
            timeToDestinationInMinutes
        }
    }
`;
