import React, { FC, useEffect, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import {QueryResult} from '@apollo/client';
import './ChassisCheckout.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { GET_CHASSISCHECKOUT } from '../../graphql/client/queries';
import {
    GetChassisCheckedout_getChassisCheckedout as ChassisCheckout,
    GetChassisCheckedout,
} from '../../types/schemaTypes';
import { Error, Loading, NoResult } from '../_common';
import { JobFilters } from '../Filter';
import { Routes } from '../../utils';
import JobList from '../TableList/JobList';
import { useStoreContext } from '../App/StoreProvider';
import * as JobListActions from '../../store/actions/jobList';
import CarrierDashboardHeader from '../Header/CarrierDashboardHeader';
import { CarrierDashboardFilters } from '../Filter/CarrierDashboardFilters';
// import BootstrapTable from 'react-bootstrap-table-next';
import DriverLoadNotes from '../JobProfile/JobActivity/DriverLoadNotes/DriverLoadNotes';
import ForbiddenError from '../_common/Error/ForbiddenError';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import moment from 'moment';

interface SelectedJob {
    jobId: string;
    jobName: string;
}

// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

const CarrierDashboard: FC = (): JSX.Element => {
    const initialArray: SelectedJob[] = [];
    // TODO: Move local state to jobListReducer
    const [selectedJobs, setSelectedJobs] = useState(initialArray);
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [state, dispatch] = useStoreContext();
    const [refetched, setRefetched] = useState(false);
    const [isEndOfResults, setIsEndOfResults] = useState(false);
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCarrier = useAuthContext().userHasPermission(PermissionEnum.Carrier);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);
    const gridRef = React.useRef<AgGridReact<any>>(null);

    const carrierColumns = [
        {
            field: 'sbsId',
            headerName: 'Trailer Id',
           // headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
            /* eslint-disable react/display-name */
            cellRenderer: ({ value, data}) => {
                return <div>{data.sbsId}</div>;
            },
        },
        // {
        //     dataField: 'trailerId',
        //     text: 'Trailer Id',
        //     headerSortingClasses: 'SortingHeaderStyle',
        //     sort: true,
        //     formatter: (rowContent, row) => {
        //         return <div>{row.trailerId}</div>;
        //     },
        // },
        {
            field: 'checkedOutDate',
            headerName: 'Checked Out Date',
         //   headerSortingClasses: 'SortingHeaderStyle',
          //  sort: true,
          cellRenderer: ({ value, data}) => {
                return (
                    <div>
                        {data.checkedOutDate && data.checkedOutDate !== ''
                            ? moment(data.checkedOutDate).format('M/D/YYYY h:mm:ss A')
                            : ''}
                    </div>
                );
            },
        },
        {
            field: 'locationLatitude',
            headerName: 'Latitude',
          //  headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
           cellRenderer: ({ value, data})  => {
                return <div>{data.locationLatitude}</div>;
            },
        },
        {
            field: 'locationLongitude',
            headerName: 'Longitude',
           // headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
           cellRenderer: ({ value, data}) => {
                return <div>{data.locationLongitude}</div>;
            },
        },
        {
            field: 'lastLoadDeliveredDriver',
            headerName: 'Last Load Delivered Driver',
          //  headerSortingClasses: 'SortingHeaderStyle',
          //  sort: true,
          cellRenderer: ({ value, data}) => {
                return <div>{data.lastLoadDeliveredDriver}</div>;
            },
        },
        {
            field: 'currentLoadDriver',
            headerName: 'Current Load Driver',
           // headerSortingClasses: 'SortingHeaderStyle',
          //  sort: true,
          cellRenderer: ({ value, data})=> {
                return <div>{data.currentLoadDriver}</div>;
            },
        },
        {
            field: 'currentLoadAcceptedDateTime',
            headerName: 'Current Load Accepted Date',
         //   headerSortingClasses: 'SortingHeaderStyle',
         //   sort: true,
         cellRenderer: ({ value, data}) => {
                return (
                    <div>
                        {data.currentLoadAcceptedDateTime && data.currentLoadAcceptedDateTime !== ''
                            ? moment(data.currentLoadAcceptedDateTime).format('M/D/YYYY h:mm:ss A')
                            : ''}
                    </div>
                );
            },
        },
    ];

    const columns = [
        {
            field: 'sbsId',
            headerName: 'Trailer Id',
          //  headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
            /* eslint-disable react/display-name */
            cellRenderer: ({ value, data}) => {
                return <div>{data.sbsId}</div>;
            },
        },
        // {
        //     dataField: 'trailerId',
        //     text: 'Trailer Id',
        //     headerSortingClasses: 'SortingHeaderStyle',
        //     sort: true,
        //     formatter: (rowContent, row) => {
        //         return <div>{row.trailerId}</div>;
        //     },
        // },
        {
            field: 'checkedOutDate',
            headerName: 'Checked Out Date',
            //headerSortingClasses: 'SortingHeaderStyle',
           //  sort: true,
          cellRenderer: ({value, data }) => {
                return (
                    <div>
                        {data.checkedOutDate && data.checkedOutDate !== ''
                            ? moment(data.checkedOutDate).format('M/D/YYYY h:mm:ss A')
                            : ''}
                    </div>
                );
            },
        },
        {
            field: 'locationLatitude',
            headerName: 'Latitude',
           // headerSortingClasses: 'SortingHeaderStyle',
          //  sort: true,
          cellRenderer: ({value, data }) => {
                return <div>{data.locationLatitude}</div>;
            },
        },
        {
            field: 'locationLongitude',
            headerName: 'Longitude',
           // headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
           cellRenderer: ({value, data }) => {
                return <div>{data.locationLongitude}</div>;
            },
        },
        {
            field: 'lastDeliveredLoadID',
            headerName: 'Last Delivered Load ID',
           // headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
           cellRenderer: ({value, data }) => {
                return <div>{data.lastDeliveredLoadID}</div>;
            },
        },

        {
            field: 'lastLoadDeliveredDriver',
            headerName: 'Last Load Driver',
          //  headerSortingClasses: 'SortingHeaderStyle',
         //   sort: true,
         cellRenderer: ({value, data })  => {
                return <div>{data.lastLoadDeliveredDriver}</div>;
            },
        },
        {
            field: 'lastLoadDeliveredDateTime',
            headerName: 'Last Load Delivered Date',
           // headerSortingClasses: 'SortingHeaderStyle',
            //sort: true,
            cellRenderer: ({value, data })  => {
                return (
                    <div>
                        {data.lastLoadDeliveredDateTime && data.lastLoadDeliveredDateTime !== ''
                            ? moment(data.lastLoadDeliveredDateTime).format('M/D/YYYY h:mm:ss A')
                            : ''}
                    </div>
                );
            },
        },
        {
            field: 'currentLoadId',
            headerName: 'Current Load ID',
          //  headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
           cellRenderer: ({value, data }) => {
                return <div>{data.currentLoadId}</div>;
            },
        },
        {
            field: 'currentLoadDriver',
            headerName: 'Current Load Driver',
           // headerSortingClasses: 'SortingHeaderStyle',
          //  sort: true,
            cellRenderer: ({value, data })  => {
                return <div>{data.currentLoadDriver}</div>;
            },
        },
        {
            field: 'currentLoadAcceptedDateTime',
            headerName: 'Current Load Accepted Date',
           // headerSortingClasses: 'SortingHeaderStyle',
           // sort: true,
           cellRenderer: ({value, data })  => {
                return (
                    <div>
                        {data.currentLoadAcceptedDateTime && data.currentLoadAcceptedDateTime !== ''
                            ? moment(data.currentLoadAcceptedDateTime).format('M/D/YYYY h:mm:ss A')
                            : ''}
                    </div>
                );
            },
        },
    ];

    const defaultColDef = React.useMemo<any>(() => {
        return {
            flex: 1,
            resizable: false,
            filter: false,
            wrapText: true,
            autoHeight: true,
            suppressMovable: true,
            minWidth: 75,
            cellStyle: (params) => ({
                display: "flex",
                alignItems: "center",
                wordBreak: "normal"
              })
        };
    }, []);
    
    const abortController = new AbortController();
    const {
        jobList: { filters },
        carrierList: { carrierId, searchPhrase },
    } = state;

    useEffect(() => {
        dispatch(JobListActions.resetFilterState());
        dispatch(JobListActions.setSelectAllFilters([1], 'Status'));
    }, []);

    const openLoadProfile = (loadId: number | null, boxNumber: string | null): void => {
        const URL = `${window.location.origin}${Routes.LOAD_PROFILE.route}`;
        const newTab = window.open(URL + loadId, '_blank');
        setTimeout(function(): void {
            if (newTab) {
                newTab.document.title = boxNumber!;
            }
        }, 100);
    };

    const variables = {
        input: {
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
        },
    };

    const openSelectedJobs = (): void => {
        const URL = `${window.location.origin}${Routes.JOB_PROFILE.route}`;
        selectedJobs.forEach((el): void => {
            const newTab = window.open(URL + el.jobId, '_blank');
            setTimeout(function(): void {
                if (newTab) {
                    newTab.document.title = el.jobName;
                }
            }, 100);
        });
        const result: SelectedJob[] = [];
        setSelectedJobs(result);
    };

    const context = {
        fetchOptions: {
            signal: abortController.signal,
        },
    };

    const abortRequest = (): void => {
        abortController.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Chassis Checkout';

    if (!isAdmin && !isOperationUser && !isCarrier) {
        return <ForbiddenError />;
    }

    if (variables.input.driverCompanyId && variables.input.driverCompanyId !== 0) {
        return (
            <Query query={GET_CHASSISCHECKOUT} variables={variables} fetchPolicy="network-only" context={context}>
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetChassisCheckedout | undefined>): JSX.Element | null => {
                    if (error) {
                        return <Error error={error} />;
                    }
                    const chassisCheckoutList: ChassisCheckout[] = [];
                    if (data && data.getChassisCheckedout && data.getChassisCheckedout.length !== 0) {
                        data.getChassisCheckedout.forEach((element: ChassisCheckout | null): void => {
                            if (element !== null) {
                                chassisCheckoutList.push(element);
                            }
                        });
                    }
                    return (
                        <div className="JobManagement-Container">
                            <CarrierDashboardHeader />
                            <div className="JobManagement-Body">
                                <div
                                    id="jobsListContainer"
                                    className="JobManagement-List"
                                    style={{ height: listHeight }}
                                >
                                    <div className="Users-Bottom-Container table-div">
                                        {loading && (
                                            <div className="JobList-Loading">
                                                <Loading />
                                            </div>
                                        )}
                                         {!loading && chassisCheckoutList.length > 0 && (
                                            // <BootstrapTable
                                            //     keyField="id"
                                            //     data={chassisCheckoutList}
                                            //     columns={isAdmin ? columns : carrierColumns}
                                            //     striped
                                            //     bordered
                                            //     hover
                                            //     classes="DriverTable"
                                            //     bootstrap4
                                            //     table-responsive
                                            // />
                                            <div
                                            className={
                                                "ag-theme-quartz"
                                            }
                                            style={{ width: "98%", height: '100%', marginLeft: "10px" }}>
                                            <AgGridReact
                                            ref={gridRef}
                                            rowData={chassisCheckoutList}
                                            columnDefs={isAdmin ? columns : carrierColumns}
                                            suppressMenuHide={true}
                                            defaultColDef={defaultColDef}
                                            enableCellTextSelection={true}
                                            animateRows = {false}
                                          //  rowSelection={'single'}
                                            //animateRows = {false}
                                            //onSelectionChanged={onSelectionChanged}
                                        />
                                        </div>
                                        )} 


                                        {!loading && chassisCheckoutList.length === 0 && (
                                            <div
                                            className={
                                                "ag-theme-quartz"
                                            }
                                            style={{ width: "98%", height: '100%', marginLeft: "10px" }}>

                                            <AgGridReact
                                            ref={gridRef}
                                            rowData={[]}
                                            columnDefs={isAdmin ? columns : carrierColumns}
                                            suppressMenuHide={true}
                                            defaultColDef={defaultColDef}
                                            enableCellTextSelection={true}
                                            animateRows = {false}
                                            />
                                                {/* <BootstrapTable
                                                    keyField="id"
                                                    data={[]}
                                                    columns={isAdmin ? columns : carrierColumns}
                                                    striped
                                                    bordered
                                                    hover
                                                    classes="DriverTable"
                                                    bootstrap4
                                                    table-responsive
                                                /> */}
                                                <div style={{ paddingTop: '15%' }}>
                                                    <NoResult isModal={false} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="JobManagement-Container">
                <CarrierDashboardHeader />
                <div className="JobManagement-Body">
                    <div id="jobsListContainer" className="JobManagement-List" style={{ height: listHeight }}>
                        <div className="Users-Bottom-Container table-div">
                        <div
                                            className={
                                                "ag-theme-quartz"
                                            }
                                            style={{ width: "98%", height: '100%', marginLeft: "10px" }}>
                                    <AgGridReact
                                            ref={gridRef}
                                            rowData={[]}
                                            columnDefs={isAdmin ? columns : carrierColumns}
                                            suppressMenuHide={true}
                                            defaultColDef={defaultColDef}
                                            animateRows = {false}
                                            enableCellTextSelection={true}
                                            />
                                            </div>
                            {/* <BootstrapTable
                                keyField="id"
                                data={[]}
                                columns={isAdmin ? columns : carrierColumns}
                                striped
                                bordered
                                hover
                                classes="DriverTable"
                                bootstrap4
                                table-responsive
                            /> */}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default CarrierDashboard;
