/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './LoadSummary.scss';
import LoadSummaryItem from './LoadSummaryItem';
import {
    GetLoadSummary,
    GetLoadSummary_getLoadSummary_loadSummaries as LoadSummaryDetails,
} from '../../../types/schemaTypes';
import { useQuery } from '@apollo/client';
import { GET_LOAD_SUMMARY } from '../../../graphql/client/queries';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { Loading } from '../../_common';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';

interface LoadSummaryProps {
    jobId: number;
    jobName: string | null;
    customerId: number;
    region?: {
        regionName: string | null;
        id: number | null;
        extSystemName: string | null;
        extSystemReference: string | null;
        regionCode: string | null;
    } | null;
    isJobCompleted: boolean;
}

const LoadSummary = ({ region, jobId, jobName, customerId, isJobCompleted }: LoadSummaryProps): JSX.Element => {
    const { data, loading } = useQuery<GetLoadSummary>(GET_LOAD_SUMMARY, {
        variables: { jobId: jobId },
        fetchPolicy: 'network-only',
    });
    const [selectedTab, setSelectedTab] = useState('Loads');

    const isDisplayLoadsEnabled = true;
    // const isDisplayLoadsEnabled = useAuthContext().userHasPermission(PermissionEnum.CustomerService) ||
    // useAuthContext().userHasPermission(PermissionEnum.Customer) || useAuthContext().userHasPermission(PermissionEnum.Admin);

    if (loading) {
        return <Loading />;
    }

    const loadSummaryComponents =
        data &&
        data.getLoadSummary &&
        data.getLoadSummary.map((proppant, index: number) => {
            let totalCreated = 0;
            let totalInProgress = 0;
            let totalDelivered = 0;
            let totalRemaining = 0;
            let totalCreatedVolume = 0;
            let totalInProgressVolume = 0;
            let totalDeliveredVolume = 0;
            let totalRemainingVolume = 0;

            return proppant !== null && proppant.loadSummaries ? (
                <div key={index}>
                    {proppant.loadSummaries.map((item: LoadSummaryDetails | null, index: number):
                        | JSX.Element
                        | undefined => {
                        if (item !== null && item.lofaName !== '') {
                            totalCreated += item.loadsCreated || 0;
                            totalInProgress += item.loadsInProgress || 0;
                            totalDelivered += item.loadsCompleted || 0;
                            totalRemaining += item.loadsRemaining || 0;
                            totalCreatedVolume += item.loadsCreatedVolume || 0;
                            totalInProgressVolume += item.loadsInProgressVolume || 0;
                            totalDeliveredVolume += item.loadsCompletedVolume || 0;
                            totalRemainingVolume += item.loadsRemainingVolume || 0;
                            return (
                                <LoadSummaryItem
                                    item={item}
                                    key={index}
                                    index={index}
                                    proppantName={proppant.proppantName}
                                    jobId={jobId}
                                    jobName={jobName}
                                    customerId={customerId}
                                    region={region}
                                    selectedTab={selectedTab}
                                    isJobCompleted={isJobCompleted}
                                />
                            );
                        }
                        return undefined;
                    })}
                    {totalCreated == 0 && totalInProgress == 0 && totalDelivered == 0 && totalRemaining == 0 ? (
                        <div></div>
                    ) : (
                        <div className="Row LoadSummary-Detail-Row LoadSummary-TotalContainer">
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end"></div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end"></div>
                            {/* <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end">
                                {''}
                            </div> */}
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end"></div>

                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end"></div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end"></div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end">
                                Totals
                            </div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end">
                                <NumberFormat
                                    value={selectedTab === 'Loads' ? totalCreated || 0 : totalCreatedVolume || 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end">
                                <NumberFormat
                                    value={selectedTab === 'Loads' ? totalInProgress || 0 : totalInProgressVolume || 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end">
                                <NumberFormat
                                    value={selectedTab === 'Loads' ? totalDelivered || 0 : totalDeliveredVolume || 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end">
                                <NumberFormat
                                    value={selectedTab === 'Loads' ? totalRemaining || 0 : totalRemainingVolume || 0}
                                    displayType={'text'}
                                    thousandSeparator={true}
                                />
                            </div>
                            <div className="LoadSummary-Detail-Item LoadSummary-TotalTitle justify-content-end"></div>
                        </div>
                    )}
                </div>
            ) : (
                <div />
            );
        });

    return (
        <div className="LoadSummary-Container">
            {isDisplayLoadsEnabled && (
                <div>
                    <div className="LoadSummary-Link-Container Row justify-content-start">
                        <div
                            className={`LoadSummary-Tab ${selectedTab === 'Loads' && 'LoadSummary-TabSelected'}`}
                            onClick={(): void => {
                                setSelectedTab('Loads');
                            }}
                        >
                            Loads
                        </div>
                        <div
                            className={`LoadSummary-Tab LoadSummary-TabWithMargin ${selectedTab === 'Volume' &&
                                'LoadSummary-TabSelected'}`}
                            onClick={(): void => {
                                setSelectedTab('Volume');
                            }}
                        >
                            Volume
                        </div>
                    </div>
                    <div className="Row">
                        <div className="LoadSummary-Header-Item justify-content-start">Proppant</div>
                        <div className="LoadSummary-Header-Item justify-content-start" style={{ textAlign: 'right' }}>
                            Active
                        </div>
                        {/* <div className="LoadSummary-Header-Item justify-content-start"></div> */}
                        <div className="LoadSummary-Header-Item LoadSummary-Name justify-content-start">
                            Loading Facility
                        </div>
                        <div className="LoadSummary-Header-Item justify-content-start">Sand PO</div>
                        <div className="LoadSummary-Header-Item justify-content-end">Transportation PO</div>
                        <div className="LoadSummary-Header-Item justify-content-end">Created</div>
                        <div className="LoadSummary-Header-Item justify-content-end">InProgress</div>
                        <div className="LoadSummary-Header-Item justify-content-end">Delivered</div>
                        <div className="LoadSummary-Header-Item justify-content-end">Remaining</div>
                        <div className="LoadSummary-Header-Item justify-content-end"></div>
                    </div>
                    <div>{loadSummaryComponents}</div>{' '}
                </div>
            )}
        </div>
    );
};

export default LoadSummary;
