import React, { FC } from 'react';
import {  Routes as Switch, Route } from 'react-router-dom';
import Callback from '../Callback';
import Login from '../Login';
import { Routes } from '../../../utils';

const AuthContent: FC = (): JSX.Element => {

    if (!window.location.href.includes('callback')) {
        window.localStorage.setItem('url', window.location.href);
        
        return (
            <Switch>
                <Route  path= "*" element={<Login />} />
            </Switch>
          );
    }
    return (
        <Switch>
            <Route  path={Routes.CALLBACK.route} Component={Callback} />
            <Route index element={<Login />} />
        </Switch>
    );
};

export default AuthContent;
