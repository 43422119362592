import gql from 'graphql-tag';

export default gql`
    query GetAllLofasFiltered($input: String) {
        getAllLofasFiltered(input: $input) {
            id
            lofaName
            addressStreet
            city
            zip
            phoneNumber
            jdeNumber
            description
            stateId
            utm
            latitude
            longitude
            directions
        }
    }
`;
