import gql from 'graphql-tag';

export default gql`
    query GetJobNotes($jobId: Int) {
        getJobNotes(jobId: $jobId) {
            notesId
            notes
            createdAt
            modifiedAt
            createdUserId
            jobId
            isDeleted
            name
            userName
            email
        }
    }
`;
