import { Action } from '../../types';
import { User } from 'oidc-client';

export enum Actions {
    USER_LOADING = 'AUTH_USER_LOADING',
    USER_LOADED = 'AUTH_USER_LOADED',
    USER_UNLOADED = 'AUTH_USER_UNLOADED',
    USER_SIGNED_OUT = 'AUTH_USER_SIGNED_OUT',
    SILENT_RENEW_ERROR = 'AUTH_SILENT_RENEW_ERROR',
    ACCESS_TOKEN_EXPIRED = 'AUTH_ACCESS_TOKEN_EXPIRED',
}

export const userLoading = (isLoading: boolean): Action => {
    return {
        type: Actions.USER_LOADING,
        payload: isLoading,
        error: null,
    };
};

export const userLoaded = (user: User): Action => {
    return {
        type: Actions.USER_LOADED,
        payload: user,
        error: null,
    };
};

export const userUnloaded = (): Action => {
    return {
        type: Actions.USER_UNLOADED,
        payload: null,
        error: null,
    };
};

export const userSignedOut = (): Action => {
    return {
        type: Actions.USER_SIGNED_OUT,
        payload: null,
        error: null,
    };
};

export const silentRenewError = (error: string): Action => {
    return {
        type: Actions.SILENT_RENEW_ERROR,
        payload: null,
        error: error,
    };
};

export const accessTokenExpired = (): Action => {
    return {
        type: Actions.ACCESS_TOKEN_EXPIRED,
        payload: null,
        error: null,
    };
};
