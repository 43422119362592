import Nav from 'react-bootstrap/Nav';
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './NavBar.scss';
import NavBarItemWithOverlay from './NavBarItemWithOverlay';

interface NavBarItemProps {
    href: string;
    title: string;
    isActive: boolean;
    isDisabled?: boolean;
}

const NavBarItem = ({ href, title, isActive, isDisabled }: NavBarItemProps): JSX.Element => {
    const linkContainer = `NavBar-TabIndicator ${
        isActive ? 'NavBar-ActiveTabIndicator' : 'NavBar-HiddenTabIndicator'
    } ${isDisabled && 'pointerEvents: none'}`;
    return (
        <React.Fragment>
            {isDisabled ? (
                <NavBarItemWithOverlay>
                    <LinkContainer to={href}>
                        <Nav.Link disabled className={linkContainer}>
                            {title}
                        </Nav.Link>
                    </LinkContainer>
                </NavBarItemWithOverlay>
            ) : (
                <LinkContainer to={href}>
                    <Nav.Link onSelect={() => (document.title = 'SBS')} className={linkContainer}>
                        {title}
                    </Nav.Link>
                </LinkContainer>
            )}
        </React.Fragment>
    );
};

export default NavBarItem;
