import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import deburr from 'lodash/deburr';
import Autosuggest from 'react-autosuggest';
import React, { useState, useEffect } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Textfield from '../../_common/Textfield/Textfield';
import './SearchableDropdown.scss';

interface SearchableDropdownProps {
    autoCompleteEnabled?: boolean;
    dropdownOptions?: (DropdownOption | null)[];
    hasError?: boolean;
    errorMessage?: string;
    id: string;
    label: string;
    onSelect: (eventKey: string | null, e: React.SyntheticEvent<unknown>) => void;
    required?: boolean;
    disabled?: boolean;
    value?: string | number | null | undefined;
    endIcon?: JSX.Element;
}

export interface DropdownOption {
    id: number | string;
    name: string | number;
}

const renderAutoCompleteInputComponent = (inputProps: any): any => {
    const { placeholder, required, endIcon } = inputProps;
    return <Textfield label={placeholder} error={required} endIcon={endIcon} />;
};

export const SearchableDropdown = ({
    autoCompleteEnabled = false,
    disabled,
    dropdownOptions,
    errorMessage,
    hasError,
    id,
    label,
    onSelect,
    required,
    value,
    endIcon,
}: SearchableDropdownProps): JSX.Element => {
    const [selectedValue, setSelectedValue] = useState<string | number | null | undefined>(value);
    const [state, setState] = React.useState({ single: '' });
    const [suggestions, setSuggestions] = React.useState<DropdownOption[]>([]);

    useEffect(() => {
        setSelectedValue(value ? value : '');
    }, [value]);

    const handleOnSelect = (eventKey: string | null, e: React.SyntheticEvent<unknown>): void => {
        const target = e.target;
        if (target instanceof HTMLAnchorElement || target instanceof HTMLInputElement) {
            setSelectedValue(target.textContent);
        }
        onSelect(eventKey, e);
    };

    if (autoCompleteEnabled) {
        const getSuggestionValue = (suggestion: DropdownOption): string => {
            return `${suggestion.name}`;
        };

        const renderSuggestion = (
            suggestion: DropdownOption,
            { query, isHighlighted }: Autosuggest.RenderSuggestionParams,
        ): any => {
            const matches = match(`${suggestion.name}`, query);
            const parts = parse(`${suggestion.name}`, matches);

            return (
               // <Dropdown.Item selected={isHighlighted} component="div" key={suggestion.id}>
               <Dropdown.Item active={isHighlighted} key={suggestion.id} >
                    <div>
                        {parts.map((part, i) => (
                            <span key={i} style={{ fontWeight: part.highlight ? 500 : 400 }}>
                                {part.text}
                            </span>
                        ))}
                    </div>
                </Dropdown.Item>
            );
        };

        const getSuggestions = (value: string, suggestions: DropdownOption[]): DropdownOption[] => {
            const inputValue = deburr(value.trim()).toLowerCase();
            const inputLength = inputValue.length;

            const suggestionsList: DropdownOption[] = suggestions.filter(suggestion => {
                return (
                    suggestion.name
                        .toString()
                        .slice(0, inputLength)
                        .toLowerCase() === inputValue
                );
            });

            if (suggestionsList.length === 1 && suggestionsList[0].name === value) {
                return [];
            }

            return suggestionsList;
        };

        const getSuggestionsCollection = (dropdownOptions: (DropdownOption | null)[]): DropdownOption[] => {
            return dropdownOptions.length > 0 ? (dropdownOptions.filter(d => d !== null) as DropdownOption[]) : [];
        };

        const handleSuggestionsFetchRequested = ({ value }: { value: string }): void => {
            setSuggestions(
                getSuggestions(
                    value,
                    getSuggestionsCollection(dropdownOptions ? getSuggestionsCollection(dropdownOptions) : []),
                ),
            );
        };

        const handleSuggestionsClearRequested = (): void => {
            setSuggestions([]);
        };

        const handleChange = (name: keyof typeof state): any => (
            event: React.ChangeEvent<any>,
            { newValue }: Autosuggest.ChangeEvent,
        ) => {
            const selectedSuggestion =
                dropdownOptions && dropdownOptions.length > 0
                    ? dropdownOptions.filter((s: DropdownOption | null) =>
                          s !== null && s.name !== null ? s.name === newValue : false,
                      )[0]
                    : null;
            if (selectedSuggestion) {
                handleOnSelect(`${selectedSuggestion.id}`, event);
            } else {
                const defaultSuggestion: DropdownOption = {
                    id: 0,
                    name: newValue,
                };
                handleOnSelect(`${defaultSuggestion.id}`, event);
            }

            setState({
                ...state,
                [name]: newValue,
            });
        };

        const autosuggestProps = {
            renderAutoCompleteInputComponent,
            suggestions,
            onSuggestionsFetchRequested: handleSuggestionsFetchRequested,
            onSuggestionsClearRequested: handleSuggestionsClearRequested,
            getSuggestionValue,
            renderSuggestion,
        };

        return (
            <div className="Searchable-Dropdown-Container">
                <label className={`Searchable-Dropdown-Label ${hasError ? 'Searchable-Dropdown-Error' : ''}`}>
                    {required ? `*${label}` : label}
                </label>
                <div
                    className={`Searchable-Dropdown-InputContainer ${
                        hasError ? 'Searchable-Dropdown-Error-Border' : ''
                    }`}
                >
                    <Autosuggest
                        alwaysRenderSuggestions={true}
                        {...autosuggestProps}
                        inputProps={{
                            autoComplete: 'no',
                            value: `${selectedValue ? selectedValue : state.single}`,
                            onChange: handleChange('single'),
                            name: id,
                            disabled: disabled ? disabled : false,
                            required: hasError ? hasError : false,
                        }}
                        renderSuggestionsContainer={options => (
                            <div {...options.containerProps}>{options.children}</div>
                        )}
                        theme={{
                            container: {
                                flexGrow: 1,
                                position: 'relative',
                            },
                            suggestionsContainerOpen: {
                                backgroundColor: 'white',
                                border: '1px solid #0000008a',
                                maxHeight: '200px',
                                left: 0,
                                marginTop: '2px',
                                overflowY: 'auto',
                                position: 'absolute',
                                right: 0,
                                width: 'fit-content',
                                zIndex: 1,
                            },
                            suggestion: {
                                display: 'block',
                            },
                            suggestionsList: {
                                margin: 0,
                                padding: 0,
                                listStyleType: 'none',
                            },
                            input: {
                                flexWrap: 'wrap',
                                fontSize: '16px',
                                fontWeight: 'normal',
                                height: '35px',
                                width: '100%',
                            },
                        }}
                    />
                    {endIcon && <div className="SearchableDropdown-EndIcon">{endIcon}</div>}
                </div>
                {hasError && <label className="Searchable-Dropdown-Error">{errorMessage}</label>}
            </div>
        );
    }

    const dropdownMenuItems =
        dropdownOptions &&
        dropdownOptions.length > 0 &&
        dropdownOptions.map(option => {
            return option && option.id && option.name ? (
                <Dropdown.Item key={option.id} eventKey={`${option.id}`}>
                    {option.name}
                </Dropdown.Item>
            ) : (
                undefined
            );
        });

    return (
        <div className="Searchable-Dropdown-Container">
            <label className={`Searchable-Dropdown-Label ${hasError && 'Searchable-Dropdown-Error'}`}>
                {required ? `*${label}` : label}
            </label>
            <Dropdown onSelect={handleOnSelect} className={`${hasError && 'Searchable-Dropdown-Error-Border'}`}>
                <DropdownButton disabled={disabled} id={id} title={selectedValue ? selectedValue : ''}>
                    {dropdownMenuItems}
                </DropdownButton>
            </Dropdown>
            {hasError && <label className="Searchable-Dropdown-Error">{errorMessage}</label>}
        </div>
    );
};

export default SearchableDropdown;
