import React, { FC, useEffect, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import {QueryResult} from '@apollo/client';
import './Dashboard.css';
import 'material-design-icons/iconfont/material-icons.css';
import { GET_ALL_JOBS_WITH_FILTERS_AND_SEARCH } from '../../graphql/client/queries';
import {
    GetAllJobsWithFiltersAndSearch_getAllJobsWithFiltersAndSearch as Job,
    GetAllJobsWithFiltersAndSearch,
    SortOrder,
} from '../../types/schemaTypes';
import { Error } from '../_common';
import { JobFilters } from '../Filter';
import { Routes } from '../../utils';
import JobList from '../TableList/JobList';
import { useStoreContext } from '../App/StoreProvider';
import * as JobListActions from '../../store/actions/jobList';
import DashboardHeader from '../Header/DashboardHeader';
import { DashbordFilters } from '../Filter/DashboardFilters';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import ForbiddenError from '../_common/Error/ForbiddenError';

interface SelectedJob {
    jobId: string;
    jobName: string;
}

// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

const Dashboard: FC = (): JSX.Element => {
    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const initialArray: SelectedJob[] = [];
    // TODO: Move local state to jobListReducer
    const [selectedJobs, setSelectedJobs] = useState(initialArray);
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [state, dispatch] = useStoreContext();
    const [isSelectAll, setIsSelectAll] = useState(false);
    const abortController = React.useRef(new AbortController());
    const {
        jobList: { filters, searchPhrase },
    } = state;

    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCustomer = useAuthContext().userHasPermission(PermissionEnum.Customer);
    const isCustomerService = useAuthContext().userHasPermission(PermissionEnum.CustomerService);

    useEffect(() => {
        dispatch(JobListActions.resetFilterState());
    }, []);

    const variables = React.useMemo(()=>{
        
        return {
        input: {
            statuses: filters.statuses,
            regionIds: filters.regionIds,
            searchString: searchPhrase,
            sortOrder: sortOrder,
            // districtIds: filters.districtIds,
            // customerIds: filters.customerIds,
        },
    }},[filters.statuses,filters.regionIds,searchPhrase,sortOrder]);;

    useEffect(() => {
        setSelectedJobs([]);
        setIsSelectAll(false);
    }, [filters, searchPhrase]);

    const openSelectedJobs = (): void => {
        localStorage.setItem('redirectbackto', 'customer');
        const URL = `${window.location.origin}${Routes.JOB_PROFILE.route}`;
        selectedJobs.forEach((el): void => {
            const newTab = window.open(URL + el.jobId, '_blank');
            setTimeout(function(): void {
                if (newTab) {
                    newTab.document.title = el.jobName;
                }
            }, 100);
        });
        const result: SelectedJob[] = [];
        setSelectedJobs(result);
        setIsSelectAll(false);
    };

    const toggleSortOrder = (): void => {
        if (sortOrder === null) {
            setSortOrder(SortOrder.ASCENDING);
        } else if (sortOrder === SortOrder.ASCENDING) {
            setSortOrder(SortOrder.DESCENDING);
        } else {
            setSortOrder(null);
        }
    };

    const onCheckAll = (checkedStatus: boolean, jobsList: Job[]): void => {
        const result: SelectedJob[] = [];
        if (checkedStatus === true) {
            jobsList.forEach((el: Job): void => {
                result.push({ jobId: el.jobId, jobName: el.jobName });
            });
            setIsSelectAll(true);
        } else {
            setIsSelectAll(false);
        }
        setSelectedJobs(result);
    };

    const context = {
        fetchOptions: {
            signal: abortController.current.signal,
        },
    };

    const abortRequest = (): void => {
        abortController.current.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Job Management';

    if (!isAdmin && !isCustomer && !isCustomerService) {
        return <ForbiddenError />;
    }

    return (
        // TODO: Extract Query to useQuery to stop Filters section from reloading everytime
        <Query
            query={GET_ALL_JOBS_WITH_FILTERS_AND_SEARCH}
            variables={variables}
            fetchPolicy="network-only"
            context={context}
        >
            {({
                error,
                data,
                loading,
                fetchMore,
            }: QueryResult<GetAllJobsWithFiltersAndSearch | undefined>): JSX.Element | null => {
                if (error) {
                    return <Error error={error} />;
                }

                const jobsList: Job[] = [];

                if (data && data.getAllJobsWithFiltersAndSearch && data.getAllJobsWithFiltersAndSearch.length !== 0) {
                    data.getAllJobsWithFiltersAndSearch.forEach((element: Job | null): void => {
                        if (element !== null) {
                            jobsList.push(element);
                        }
                    });
                }

                const offset =
                    data && data.getAllJobsWithFiltersAndSearch
                        ? data.getAllJobsWithFiltersAndSearch.length / PAGE_SIZE
                        : 0;

                let isEndOfResults = false;
                if (
                    offset % 1 !== 0 ||
                    (!isEndOfResults &&
                        data &&
                        data.getAllJobsWithFiltersAndSearch &&
                        data.getAllJobsWithFiltersAndSearch.length < PAGE_SIZE)
                ) {
                    // If offset is not a whole number, the end of the results has been reached. (8/22/19) - BS
                    isEndOfResults = true;
                }

                return (
                    <div className="JobManagement-Container">
                        <DashboardHeader
                            onCheckAll={checked => onCheckAll(checked, jobsList)}
                            sortOrder={sortOrder}
                            toggleSortOrder={toggleSortOrder}
                            selectedJobs={selectedJobs}
                            openSelectedJobs={openSelectedJobs}
                            defaultChecked={isSelectAll}
                        />

                        <div className="JobManagement-Body">
                            <div id="jobsFilterContainer" className="JobManagement-Filter">
                                <DashbordFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                            </div>
                            <div id="jobsListContainer" className="JobManagement-List">
                                <JobList
                                    abortRequest={abortRequest}
                                    jobsList={jobsList}
                                    isLoading={loading}
                                    isEndOfResults={isEndOfResults}
                                    entries={(data && data.getAllJobsWithFiltersAndSearch) || []}
                                    selectedJobs={selectedJobs}
                                    setSelectedJobs={setSelectedJobs}
                                    detailsViewFrom="customer"
                                    onLoadMore={() =>
                                        fetchMore({
                                            variables: {
                                                offset,
                                            },
                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                if (
                                                    prev &&
                                                    fetchMoreResult &&
                                                    prev.getAllJobsWithFiltersAndSearch &&
                                                    fetchMoreResult.getAllJobsWithFiltersAndSearch
                                                ) {
                                                    return Object.assign({}, prev, {
                                                        getAllJobsWithFiltersAndSearch: [
                                                            ...prev.getAllJobsWithFiltersAndSearch,
                                                            ...fetchMoreResult.getAllJobsWithFiltersAndSearch,
                                                        ],
                                                    });
                                                } else {
                                                    return prev;
                                                }
                                            },
                                        })
                                    }
                                ></JobList>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default Dashboard;
