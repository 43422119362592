import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import { Modal } from 'react-bootstrap';
import { CustomButton } from '../../../_common/CustomButton/CustomButton';
import './CustomLoadInformationModal.scss';

interface CustomLoadInformationModalProps {
    show: boolean;
    onCancel: () => void;
    text: string;
}

const CustomLoadInformationModal = ({ show, onCancel, text }: CustomLoadInformationModalProps): JSX.Element => {
    return (
        <Modal show={show} onHide={onCancel} centered className="EditLoadProgressValidationModal-Container">
            <Modal.Header className="EditLoadProgressValidationModal-Header">
                <i className="material-icons EditLoadProgressValidationModal-CloseIcon" onClick={onCancel}>
                    clear
                </i>
            </Modal.Header>
            <Modal.Body>
                <div className="CustomLoadInformationModal-HeaderText">{text}</div>
            </Modal.Body>
            <Modal.Footer className="EditLoadProgressValidationModal-Footer">
                <div className="EditLoadProgressValidationModal-ButtonContainer">
                    <CustomButton title="OK" onClick={onCancel} isBordered />
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default CustomLoadInformationModal;
