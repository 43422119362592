import gql from 'graphql-tag';

export default gql`
    query GetAllDriversMasterData($input: DriverListInput, $offset: Int) {
        getAllDriversMasterData(input: $input, offset: $offset) {
            driverId
            name
            regionName
            districtName
            isActive
            cellPhone
            cdlNumber
            activeInactiveDate
            activeInactiveUser
            companyName
            kronosId
            driverType
            cdlExpiryDate
            driverCompanyId
            isAssigned
            isManual
            isAvailable
            isShiftStarted
            regionIds
            districtIds
            isLocked
            extSystemIdentifier
            extSystemName
            inactiveReason
            reactivationDate
            loadId
            status
        }
    }
`;
