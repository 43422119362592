import gql from 'graphql-tag';

export default gql`
    query GetB2CUserFiltered($input: GetB2cUserInput) {
        getB2CUserFiltered(input: $input) {
            userId
            firstName
            lastName
            emailAddress
            roleName
            roleId
            customerParentId
            isActive
            driverCompanyId
            driverCompanyName
        }
    }
`;
