import gql from 'graphql-tag';

export default gql`
    query GetSettingByName($name: String) {
        getSettingByName(name: $name) {
            id
            name
            value
        }
    }
`;
