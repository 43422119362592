import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { Routes } from '../../../utils';
import { useAuthContext } from '../../Auth/AuthProvider';
import '../../Header/JobLoadsManagementHeader/JobLoadsManagementHeader.scss';
import { Loading, Error, DateTimePicker } from '../../_common';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import { GET_LOAD_ALLOCATION } from '../../../graphql/client/queries';
import {
    GetLoadAllocation,
    GetLoadAllocation_getLoadAllocation as loadAllocationData,
} from '../../../types/schemaTypes';
import DailySandAllocationsFilter from '../../Filter/DailySandAllocationsFilter';
import DailySandAllocationsSummary from './DailySandAllocationsSummary';
import DailySandAllocationsTable from './DailySandAllocationsDetailsTable';
import './DailySandAllocations.scss';
import { Dropdown } from 'react-bootstrap';
import moment from 'moment';
import ForbiddenError from '../../_common/Error/ForbiddenError';
import NotFoundError from '../../_common/Error/NotFoundError';
import {useParams} from 'react-router-dom';

const DailySandAllocations = ({ match }: any): JSX.Element => {
    const authContext = useAuthContext();
    const isDisplayLoadsEnabled =
        authContext.userHasPermission(PermissionEnum.CustomerService) ||
        authContext.userHasPermission(PermissionEnum.Customer) ||
        authContext.userHasPermission(PermissionEnum.Admin);

    const [tabTitle, setTabTitle] = useState(document.title);
    const [jobName, setJobName] = useState('');

    const [showDownloadFilter, setShowDownloadFilter] = useState(false);
    const showDownloadFilterHandler = () => {
        setShowDownloadFilter(!showDownloadFilter);
    };

    const getUpdatedOn = () => {
        const now = new Date();
        const nowCst = new Date(now.toLocaleString('en-US', { timeZone: 'CST' }));
        let month: number | string = nowCst.getMonth() + 1;
        month = month < 10 ? `0${month}` : month;
        let date: number | string = nowCst.getDate();
        date = date < 10 ? `0${date}` : date;
        const year = nowCst.getFullYear();
        let hours = nowCst.getHours();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        let minutes: number | string = nowCst.getMinutes();
        minutes = minutes < 10 ? `0${minutes}` : minutes;
        let seconds: number | string = nowCst.getSeconds();
        seconds = seconds < 10 ? `0${seconds}` : seconds;
        return `${month}/${date}/${year} ${hours}:${minutes}:${seconds} ${ampm}`;
    };
    const [lastUpdatedOn, setLastUpdatedOn] = useState(getUpdatedOn());
    const [refreshed, setRefreshed] = useState(false);

    const refreshScreen = () => {
        setLastUpdatedOn(getUpdatedOn());
        setRefreshed(true);
    };

    const [selectedTab, setSelectedTab] = useState<'planned' | 'actual'>('planned');

    const today = new Date();
    const [fromDate, setFromDate] = useState(
        `${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()} 00:00:00`,
    );
    const fourteenDaysFromToday = new Date();
    fourteenDaysFromToday.setDate(today.getDate() + 13);
    const [toDate, setToDate] = useState(
        `${fourteenDaysFromToday.getMonth() +
            1}-${fourteenDaysFromToday.getDate()}-${fourteenDaysFromToday.getFullYear()} 00:00:00`,
    );

    const [sandFromDate, setSandFromDate] = useState<any>();

    const changeDate = (previous: boolean) => {
        const tempFromDate = new Date(fromDate);
        const tempToDate = new Date(toDate);
        if (previous) {
            tempFromDate.setDate(tempFromDate.getDate() - 14);
            tempToDate.setDate(tempToDate.getDate() - 14);
        } else {
            tempFromDate.setDate(tempFromDate.getDate() + 14);
            tempToDate.setDate(tempToDate.getDate() + 14);
        }
        setFromDate(`${tempFromDate.getMonth() + 1}-${tempFromDate.getDate()}-${tempFromDate.getFullYear()} 00:00:00`);
        setToDate(`${tempToDate.getMonth() + 1}-${tempToDate.getDate()}-${tempToDate.getFullYear()} 00:00:00`);
    };

    const changeDateFilter = (date: string) => {
        if (moment.isMoment(date)) {
            const tempFromDate = new Date(date.valueOf());
            tempFromDate.setHours(0, 0, 0);
            if (tempFromDate && tempFromDate.toString() !== 'Invalid Date' && tempFromDate.getFullYear() > 1900) {
                const tempToDate = new Date(date);
                tempToDate.setDate(tempToDate.getDate() + 13);
                tempToDate.setHours(0, 0, 0);

                setFromDate(
                    `${tempFromDate.getMonth() + 1}-${tempFromDate.getDate()}-${tempFromDate.getFullYear()} 00:00:00`,
                );
                setSandFromDate(`${tempFromDate.getMonth() + 1}-${tempFromDate.getDate()}-${tempFromDate.getFullYear()} 00:00:00`,)
                setToDate(`${tempToDate.getMonth() + 1}-${tempToDate.getDate()}-${tempToDate.getFullYear()} 00:00:00`);
                const containerElement = document.getElementById('DailySandAllocations-DateFilter-Container');
                if (containerElement) {
                    containerElement.click();
                }
            }
        }
    };

    const dateList: string[] = [];
    const dateItem = new Date(fromDate);
    for (let i = 0; i < 14; i++) {
        dateList.push(
            `${dateItem.getMonth() + 1 < 10 ? `0${dateItem.getMonth() + 1}` : dateItem.getMonth() + 1}/${
                dateItem.getDate() < 10 ? `0${dateItem.getDate()}` : dateItem.getDate()
            }${[6, 0].includes(dateItem.getDay()) ? '|' : ''}`,
        );
        dateItem.setDate(dateItem.getDate() + 1);
    }

    const {jobId} = useParams()
    const jobIdVal:any = jobId;

    const variables = {
        input: {
            jobId: parseInt(jobIdVal),
            fromDate: fromDate,
            toDate: toDate,
        },
    };

    return (
        <Query query={GET_LOAD_ALLOCATION} variables={variables} fetchPolicy="no-cache">
            {({ loading, error, data,previousData, refetch }: QueryResult<GetLoadAllocation | undefined>): JSX.Element | null => {
                if (error) {
                    if (error.message.includes('NoAccess')) {
                        return <ForbiddenError />;
                    }
                    if (error.message.includes('NoResults')) {
                        return <NotFoundError />;
                    }
                    return <Error error={error} />;
                }

                if (refreshed) {
                    refetch();
                }

                React.useEffect(()=>{
                    if (data && data.getLoadAllocation && data.getLoadAllocation.length !== 0) {
                    document.title = data.getLoadAllocation[0] ? data.getLoadAllocation[0].jobName : tabTitle;
                    localStorage.setItem('Jobdetails-TabName', document.title);
                    setTabTitle(document.title);
                    setJobName(document.title);
                    }

                },[data, data?.getLoadAllocation])

                if(data === undefined && previousData){
                    data = previousData
                }
                const plannedLoadAllocationDetails = {};
                const actualLoadAllocationDetails = {};
                if (data && data.getLoadAllocation && data.getLoadAllocation.length !== 0) {
                    document.title = data.getLoadAllocation[0] ? data.getLoadAllocation[0].jobName : tabTitle;
                    localStorage.setItem('Jobdetails-TabName', document.title);
                    //setTabTitle(document.title);
                  //  setJobName(document.title);

                    data.getLoadAllocation.forEach((element: loadAllocationData | null): void => {
                        if (element !== null) {
                            if (element.type === 'Planned') {
                                if (element.proppantName in plannedLoadAllocationDetails) {
                                    const temp: loadAllocationData[] =
                                        plannedLoadAllocationDetails[element.proppantName];
                                    temp.push(element);
                                    plannedLoadAllocationDetails[element.proppantName] = temp;
                                } else {
                                    const temp: loadAllocationData[] = [];
                                    temp.push(element);
                                    plannedLoadAllocationDetails[element.proppantName] = temp;
                                }
                            }
                            if (element.type === 'Actual') {
                                if (element.proppantName in actualLoadAllocationDetails) {
                                    const temp: loadAllocationData[] =
                                        actualLoadAllocationDetails[element.proppantName];
                                    temp.push(element);
                                    actualLoadAllocationDetails[element.proppantName] = temp;
                                } else {
                                    const temp: loadAllocationData[] = [];
                                    temp.push(element);
                                    actualLoadAllocationDetails[element.proppantName] = temp;
                                }
                            }
                        }
                    });
                }

                return (
                    <div className="DailySandAllocations-Container">
                        <div className="DailySandAllocations-BodyContainer">
                            <DailySandAllocationsFilter
                                jobId={jobIdVal}
                                showDownloadFilter={showDownloadFilter}
                                showDownloadFilterHandler={showDownloadFilterHandler}
                            />
                        </div>

                        <div className="DailySandAllocations-Header-Container">
                            <div className="DailySandAllocations-Headers">
                                <i
                                    className="material-icons DailySandAllocations-Download-Icon"
                                    onClick={showDownloadFilterHandler}
                                >
                                    get_app
                                </i>
                                <div className="JobProfile-HeaderTitle">Daily Sand Allocations: {jobName}</div>
                                <div className="Row JobProfile-ToggleButtonsContainer">
                                    <Link
                                        to={{
                                            pathname: `${Routes.JOB_PROFILE.route}${jobIdVal}`,
                                        }}
                                        className={`JobProfile-LeftToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                            'JobProfile-RightToggleButton-Disabled'}`}
                                    >
                                        Job Details
                                    </Link>
                                    <Link
                                        to={{
                                            pathname: `${Routes.JOB_ACTIVITY.route}${jobIdVal}`,
                                        }}
                                        className={`JobProfile-CenterToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                            'JobProfile-RightToggleButton-Disabled'}`}
                                    >
                                        Current Activity
                                    </Link>
                                    <div className="JobProfile-CenterToggleButton JobProfile-ActiveButton">
                                        <p className="JobProfile-ButtonText">Sand Allocations</p>
                                    </div>
                                    <Link
                                        to={{
                                            pathname: `${Routes.JOB_LOADS.route}${jobIdVal}`,
                                        }}
                                        className={`JobProfile-RightToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                            'JobProfile-RightToggleButton-Disabled'}`}
                                    >
                                        Loads
                                    </Link>
                                </div>
                            </div>

                            <div className="DailySandAllocation-Refresh-Container">
                                <button className="DailySandAllocations-Refresh-Button" onClick={refreshScreen}>
                                    <i className="material-icons DailySandAllocations-Refresh-Icon">refresh</i>
                                    Refresh
                                </button>
                                <div className="DailySandAllocations-Last-Update">Last Updated On:{lastUpdatedOn}</div>
                            </div>
                        </div>

                        <div className="DailySandAllocationHeader">Allocation Summary</div>
                        <div className="DailySandAllocationSummaryTableContainer">
                            <DailySandAllocationsSummary
                                jobId={parseInt(jobIdVal)}
                                date={`${today.getMonth() + 1}-${today.getDate()}-${today.getFullYear()}`}
                                refreshed={refreshed}
                                setRefreshed={setRefreshed}
                            />
                        </div>

                        <div className="DailySandAllocationHeader">Sand Allocation Details</div>
                        {Object.keys(plannedLoadAllocationDetails).length !== 0 && (
                            <div className="DailySandAllocations-Tab-Button-Container">
                                <div className="DailySandAllocations-Tab-Container">
                                    <div
                                        className={`DailySandAllocations-Tab ${selectedTab === 'planned' &&
                                            'DailySandAllocations-TabSelected'}`}
                                    >
                                        <span
                                            className="DailySandAllocations-Tab-Text"
                                            onClick={(): void => {
                                                setSelectedTab('planned');
                                            }}
                                        >
                                            Planned
                                        </span>
                                    </div>

                                    <div
                                        className={`DailySandAllocations-Tab DailySandAllocations-Tab-With-Margin ${selectedTab ===
                                            'actual' && 'DailySandAllocations-TabSelected'}`}
                                    >
                                        <span
                                            className="DailySandAllocations-Tab-Text"
                                            onClick={(): void => {
                                                setSelectedTab('actual');
                                            }}
                                        >
                                            Actual
                                        </span>
                                    </div>
                                </div>

                                <div className="DailySandAllocations-FilterPrevNext-Container">
                                    <Dropdown>
                                        <Dropdown.Toggle
                                            variant="outline-dark"
                                            id="DailySandAllocations-DateFilter-Container"
                                        >
                                            <span className="DailySandAllocations-DateFilter-Text">
                                                {`${fromDate.split(' ')[0].replaceAll('-', '/')} -
                                                     ${toDate.split(' ')[0].replaceAll('-', '/')}`}
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className="DailySandAllocations-DateFilter-Dropdown-Container">
                                            <div className="DailySandAllocations-DateFilter-Dropdown">
                                                <DateTimePicker
                                                    dateOnly
                                                    label="Choose FROM date"
                                                    onChange={value => changeDateFilter(value)}
                                                    error={''}
                                                    open={true}
                                                    value={sandFromDate}
                                                />
                                            </div>
                                        </Dropdown.Menu>
                                    </Dropdown>

                                    <div className="DailySandAllocations-PrevNext-Container">
                                        <div
                                            className="DailySandAllocations-Button DailySandAllocations-Prev-Button"
                                            onClick={() => {
                                                changeDate(true);
                                            }}
                                        >
                                            <span className="DailySandAllocations-Button-Text">
                                                <i className="material-icons">chevron_left</i>
                                                Previous
                                            </span>
                                        </div>

                                        <div
                                            className="DailySandAllocations-Button"
                                            onClick={() => {
                                                changeDate(false);
                                            }}
                                        >
                                            <span className="DailySandAllocations-Button-Text">
                                                Next
                                                <i className="material-icons">chevron_right</i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                        <div className="DailySandAllocationsTables-Container">
                            {loading && (
                                <div className="DailySandAllocations-Spinner-Container">
                                    <Loading />
                                </div>
                            )}

                            {selectedTab === 'planned' && Object.keys(plannedLoadAllocationDetails).length !== 0 && (
                                <DailySandAllocationsTable
                                    dateList={dateList}
                                    loadAllocationDetails={plannedLoadAllocationDetails}
                                    loading={loading}
                                />
                            )}

                            {selectedTab === 'actual' && Object.keys(actualLoadAllocationDetails).length !== 0 && (
                                <DailySandAllocationsTable
                                    dateList={dateList}
                                    loadAllocationDetails={actualLoadAllocationDetails}
                                    loading={loading}
                                />
                            )}

                            {Object.keys(plannedLoadAllocationDetails).length == 0 && !loading && (
                                <div className="NoResult-Second-Title-Primary">No Sand Allocations Details found.</div>
                            )}
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default DailySandAllocations;
