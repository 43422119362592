import { Reducer } from 'react';
import { Actions } from '../../actions/customerList';
import { Action, CustomerListState } from '../../types';

const initialCustomerListState: CustomerListState = {
    searchPhrase: '',
};

const setSearchPhrase = (search: string, state: CustomerListState): CustomerListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const customerListReducer: Reducer<CustomerListState, Action> = (state, action): CustomerListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
    }

    return newState;
};

export { initialCustomerListState, customerListReducer };
