import React from 'react';
import './ProppantItem.css';
import { GetFullJobProfile_getJobProfile_pumpScheduleSummary_proppants as ProppantData } from '../../../../types/schemaTypes';
import { NumericFormat as NumberFormat } from 'react-number-format';

interface ProppantItemProps {
    proppant: ProppantData;
    isLastItem: boolean;
}

const ProppantItem = ({ proppant, isLastItem }: ProppantItemProps): JSX.Element => {
    const { proppantName, totalVolume, volumePerBox, volumePerStage, boxesPerStage, boxesPerWell } = proppant;
    const totalVolumeTons = totalVolume ? '(' + (totalVolume / 2000).toFixed(2).toString() + 'T)' : undefined;
    const volumePerBoxTons = volumePerBox ? '(' + (volumePerBox / 2000).toFixed(2).toString() + 'T)' : undefined;
    const volumePerStageTons = volumePerStage ? '(' + (volumePerStage / 2000).toFixed(2).toString() + 'T)' : undefined;
    return (
        <div className={!isLastItem ? 'PumpSchedule-Row' : 'PumpSchedule-Last-Row'}>
            <div className="PumpSchedule-Info-Row justify-content-start">{proppantName}</div>
            <div className="PumpSchedule-Info-Row justify-content-end">
                <NumberFormat value={totalVolume || 0} displayType={'text'} thousandSeparator={true} />
                &nbsp;{totalVolumeTons || 0}
            </div>
            <div className="PumpSchedule-Info-Row justify-content-end">
                <NumberFormat value={volumePerBox || 0} displayType={'text'} thousandSeparator={true} />
                &nbsp;{volumePerBoxTons || 0}
            </div>
            <div className="PumpSchedule-Info-Row justify-content-end">
                <NumberFormat value={volumePerStage || 0} displayType={'text'} thousandSeparator={true} />
                &nbsp;{volumePerStageTons || 0}
            </div>
            <div className="PumpSchedule-Info-Row justify-content-end">
                <NumberFormat value={boxesPerStage || 0} displayType={'text'} thousandSeparator={true} />
            </div>
            <div className="PumpSchedule-Info-Row justify-content-end">
                <NumberFormat value={boxesPerWell || 0} displayType={'text'} thousandSeparator={true} />
            </div>
        </div>
    );
};

export default ProppantItem;
