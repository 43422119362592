import gql from 'graphql-tag';

export default gql`
    query GetTonnageExtract($input: GetLoadAllocationInput) {
        getTonnageExtract(input: $input) {
            id
            lofaId
            lofaName
            proppantId
            proppantName
            sandPO
            loadDate
            plannedWeight
            actualWeight
            updatedBy
            updatedDate
        }
    }
`;
