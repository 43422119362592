import gql from 'graphql-tag';

export default gql`
    query GetLoadAllocationSummary($input: GetLoadAllocationSummaryInput) {
        getLoadAllocationSummary(input: $input) {
            id
            proppantName
            lofaName
            sandPO
            loadDate
            plannedWeight
            actualWeight
            dispatchedWeight
            remainingWeight
        }
    }
`;
