/* eslint-disable @typescript-eslint/no-explicit-any */
import { Reducer } from 'react';
import { Actions } from '../../actions/pumpScheduleEdit';
import { Action, PumpScheduleEditState, WellheadInput, PumpScheduleSummaryObject } from '../../types';
import * as uuid from 'uuid';
import _ from 'lodash';

const wellheadList: WellheadInput[] = [];
const currentList: PumpScheduleSummaryObject[] = [];
const fieldErrorKey = (wellheadId: string, proppantId: string | null): string =>
    `${wellheadId}${!!proppantId ? ':' + proppantId : ''}`;

const initialPumpScheduleEditState: PumpScheduleEditState = {
    currentValues: currentList,
    toBePosted: wellheadList,
    fieldErrors: {},
};

// TODO: To remove duplicate logic for old/newValues collections,
// we need to consolidate the types used by GQL with the types
// used by PumpScheduleEditState - BS (9/12/19)
const addWellhead = (
    { wellhead }: { wellhead: WellheadInput },
    state: PumpScheduleEditState,
): PumpScheduleEditState => {
    const toBePosted = state.toBePosted.concat([wellhead]);
    const currentValues = state.currentValues.concat([
        {
            __typename: 'Wellhead',
            id: wellhead.id,
            wellName: '',
            stages: null,
            stageTime: null,
            totalBoxes: null,
            proppants: [
                {
                    __typename: 'PumpScheduleProppant',
                    id: wellhead.proppants[0].id,
                    pumpScheduleId: null,
                    proppantId: null,
                    proppantName: '',
                    totalVolume: null,
                    volumePerBox: null,
                    volumePerStage: null,
                    boxesPerStage: null,
                    boxesPerWell: null,
                },
            ],
        },
    ]);

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const addProppant = (
    { wellhead }: { wellhead: WellheadInput },
    state: PumpScheduleEditState,
): PumpScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);
    const newProppantId = uuid.v4();

    if (!toBePosted.find(e => e.id === wellhead.id)) {
        toBePosted = addWellhead({ wellhead }, state).toBePosted;
    }

    toBePosted = toBePosted.map(e => {
        if (e.id === wellhead.id && e.proppants) {
            return {
                ...e,
                proppants: e.proppants.concat([
                    {
                        id: newProppantId,
                        pumpScheduleId: null,
                        proppantId: null,
                        proppantName: '',
                        totalVolume: null,
                        volumePerBox: null,
                        isDeleted: false,
                    },
                ]),
            };
        } else {
            return e;
        }
    });

    if (currentValues.find(e => e.id === wellhead.id)) {
        currentValues = currentValues.map(e => {
            if (e.id === wellhead.id && e.proppants) {
                return {
                    ...e,
                    proppants: e.proppants.concat([
                        {
                            id: newProppantId,
                            __typename: 'PumpScheduleProppant',
                            pumpScheduleId: null,
                            proppantId: null,
                            proppantName: '',
                            totalVolume: null,
                            volumePerBox: null,
                            volumePerStage: null,
                            boxesPerStage: null,
                            boxesPerWell: null,
                        },
                    ]),
                };
            } else {
                return e;
            }
        });
    }

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const updateProppant = (
    { wellhead, proppant, inputTitle, value }: any,
    state: PumpScheduleEditState,
): PumpScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);

    if (!toBePosted.find(e => e.id === wellhead.id)) {
        toBePosted = addWellhead({ wellhead }, state).toBePosted;
    }
    toBePosted = toBePosted.map(e => {
        if (e.id === wellhead.id) {
            return {
                ...e,
                proppants: e.proppants.map((p: any) => {
                    if (p.id === proppant.id) {
                        p[inputTitle] = value;
                    }
                    return p;
                }),
            };
        }
        return e;
    });

    currentValues = currentValues.map(e => {
        if (e.id === wellhead.id && e.proppants) {
            return {
                ...e,
                proppants: e.proppants.map((p: any) => {
                    if (p.id === proppant.id) {
                        p[inputTitle] = value;
                    }
                    return p;
                }),
            };
        }
        return e;
    });

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const updateProppantValue = (
    { wellhead, proppant, proppantValue }: any,
    state: PumpScheduleEditState,
): PumpScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    const currentValues = _.cloneDeep(state.currentValues);

    if (!toBePosted.find(e => e.id === wellhead.id)) {
        toBePosted = addWellhead({ wellhead }, state).toBePosted;
    }

    return {
        ...state,
        toBePosted: toBePosted.map(e => {
            if (e.id === wellhead.id) {
                return {
                    ...e,
                    proppants: e.proppants.map((p: any) => {
                        if (p.id === proppant.id) {
                            p.proppantId = proppantValue.id;
                            p.proppantName = proppantValue.value;
                        }
                        return p;
                    }),
                };
            }
            return e;
        }),
        currentValues: currentValues.map(e => {
            if (e.id === wellhead.id && e.proppants) {
                return {
                    ...e,
                    proppants: e.proppants.map((p: any) => {
                        if (p.id === proppant.id) {
                            p.proppantId = proppantValue.id;
                            p.proppantName = proppantValue.value;
                        }
                        return p;
                    }),
                };
            }
            return e;
        }),
    };
};

const deleteProppant = ({ wellhead, proppant }: any, state: PumpScheduleEditState): PumpScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);

    if (!toBePosted.find(e => e.id === wellhead.id)) {
        toBePosted = addWellhead({ wellhead }, state).toBePosted;
    }

    const existingProppants = (well: any): any[] =>
        well.proppants.map((p: any) => {
            if (p.id === proppant.id) {
                p.isDeleted = true;
            }
            return p;
        });

    const filteredProppants = (well: any): any[] => well.proppants.filter((p: any) => p.id !== proppant.id);

    toBePosted = toBePosted.map(e => {
        if (e.id === wellhead.id) {
            return {
                ...e,
                proppants: !!proppant.pumpScheduleId ? existingProppants(e) : filteredProppants(e),
            };
        }
        return e;
    });

    currentValues = currentValues.map(e => {
        if (e.id === wellhead.id && e.proppants) {
            return {
                ...e,
                proppants: !!proppant.pumpScheduleId ? existingProppants(e) : filteredProppants(e),
            };
        }
        return e;
    });

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const updateWellhead = ({ wellhead, inputTitle, value }: any, state: PumpScheduleEditState): PumpScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);

    if (toBePosted.find(e => e.id === wellhead.id)) {
        toBePosted = toBePosted.map((e: any) => {
            if (e.id === wellhead.id) {
                e[inputTitle] = value;
            }
            return e;
        });
    } else {
        wellhead[inputTitle] = value;
        toBePosted.push(wellhead);
    }

    currentValues = currentValues.map((e: any) => {
        if (e.id === wellhead.id) {
            e[inputTitle] = value;
        }
        return e;
    });

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const deleteWellhead = ({ wellhead }: any, state: PumpScheduleEditState): PumpScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);

    if (toBePosted.find(e => e.id === wellhead.id)) {
        toBePosted = toBePosted.map((e: any) => {
            if (e.id === wellhead.id) {
                e.isDeleted = true;
                e.proppants.forEach((item: any) => {
                    item.isDeleted = true;
                });
            }
            return e;
        });
    } else {
        wellhead.proppants.forEach((item: any) => {
            item.isDeleted = true;
        });
        toBePosted.push(wellhead);
    }

    currentValues = currentValues.map((e: any) => {
        if (e.id === wellhead.id) {
            e.isDeleted = true;
            e.proppants.forEach((item: any) => {
                item.isDeleted = true;
            });
        }
        return e;
    });

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const setInitialData = ({ data, newValues }: any, state: PumpScheduleEditState): PumpScheduleEditState => {
    return {
        ...state,
        currentValues: _.cloneDeep(data),
        toBePosted: _.cloneDeep(newValues),
    };
};

const setPumpScheduleToInitialState = (payload: [], state: PumpScheduleEditState): PumpScheduleEditState => {
    return {
        ...state,
        toBePosted: payload,
        currentValues: payload,
    };
};

const setFieldError = (payload: any, state: PumpScheduleEditState): PumpScheduleEditState => {
    const { fieldName, value, wellheadId, proppantId } = payload;
    const key = fieldErrorKey(wellheadId, proppantId);
    const fieldErrors = {
        ...state.fieldErrors,
        [key]: { ...state.fieldErrors[key], [fieldName]: value },
    };

    return {
        ...state,
        fieldErrors,
    };
};

const resetFieldErrors = (payload: any, state: PumpScheduleEditState): PumpScheduleEditState => {
    return {
        ...state,
        fieldErrors: {},
    };
};

const pumpScheduleEditReducer: Reducer<PumpScheduleEditState, Action> = (state, action): PumpScheduleEditState => {
    let newState = { ...state };
    switch (action.type) {
        case Actions.ADD_PROPPANT:
            newState = addProppant(action.payload, state);
            break;
        case Actions.ADD_WELLHEAD:
            newState = addWellhead(action.payload, state);
            break;
        case Actions.UPDATE_PROPPANT:
            newState = updateProppant(action.payload, state);
            break;
        case Actions.UPDATE_PROPPANT_VALUE:
            newState = updateProppantValue(action.payload, state);
            break;
        case Actions.UPDATE_WELLHEAD:
            newState = updateWellhead(action.payload, state);
            break;
        case Actions.SET_INITIAL_DATA:
            newState = setInitialData(action.payload, state);
            break;
        case Actions.DELETE_PROPPANT:
            newState = deleteProppant(action.payload, state);
            break;
        case Actions.DELETE_WELLHEAD:
            newState = deleteWellhead(action.payload, state);
            break;
        case Actions.RE_INITIALIZE:
            newState = setPumpScheduleToInitialState(action.payload, state);
            break;
        case Actions.SET_FIELD_ERROR:
            newState = setFieldError(action.payload, state);
            break;
        case Actions.RESET_FIELD_ERRORS:
            newState = resetFieldErrors(action.payload, state);
            break;
    }
    return newState;
};

export { initialPumpScheduleEditState, pumpScheduleEditReducer };
