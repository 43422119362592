import React, { useState } from 'react';
import './DriverDetail.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { useStoreContext } from '../../App/StoreProvider';

interface DriverDetailProps {
    driver?: string | null;
    carrier?: string | null;
    box?: string | null;
    truck?: string | null;
    chassis?: string | null;
    driverStatus?: string;
    driverId?: number;
    secondaryDriverId?: number;
}

const DriverDetail = ({
    driver,
    carrier,
    box,
    truck,
    chassis,
    driverStatus,
    driverId,
    secondaryDriverId,
}: DriverDetailProps): JSX.Element => {
    const [state] = useStoreContext();
    return (
        <Col className='custom-column-p'>
            <Row className="DriverDetail-Title">Driver Details</Row>
            <Row>
                <TextContent hasTopMargin title={'Driver: '} value={driver || ''}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'Carrier: '} value={carrier || ''}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'Box: '} value={box || ''}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'Truck: '} value={truck || ''}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'Chassis: '} value={chassis || ''}></TextContent>
            </Row>
            {secondaryDriverId !== driverId && (
                <Row>
                    {driverStatus === 'Accepted' ? (
                        <div className="DriverDetail-StatusLabel">
                            <i className="material-icons complete-icon">check_circle_outline</i>
                            <span className="status-label">Accepted</span>
                        </div>
                    ) : (
                        ''
                    )}
                    {driverStatus === 'Rejected' ? (
                        <div className="DriverDetail-StatusLabel">
                            <i className="material-icons rejected-icon">highlight_off</i>
                            <span className="status-label">Rejected</span>
                        </div>
                    ) : (
                        ''
                    )}
                    {driverStatus === 'Pending' ? (
                        <div className="DriverDetail-StatusLabel">
                            <i className="material-icons pending-icon">restore</i>
                            <span className="status-label">Pending</span>
                        </div>
                    ) : (
                        ''
                    )}
                </Row>
            )}
        </Col>
    );
};

export default DriverDetail;
