import { combineReducers } from './combineReducers';
import { authReducer, initialAuthState } from '../../store/reducers/auth';
import { jobListReducer, initialJobListState } from '../../store/reducers/jobList';
import { jobProfileReducer, initialJobProfileState } from '../../store/reducers/jobProfile';
import { pumpScheduleEditReducer, initialPumpScheduleEditState } from '../../store/reducers/pumpScheduleEdit';
import { useReducer } from 'react';
import { State } from '../../store/types';
import { loadListReducer, initialLoadListState } from '../../store/reducers/loadList/loadListReducer';
import { lofaListReducer, initialLofaListState } from '../../store/reducers/lofaList/lofaListReducer';
import { addLoadModalReducer, initialAddLoadModalState } from '../../store/reducers/addLoadModal/addLoadModalReducer';
import { lofaScheduleEditReducer, initialLofaScheduleEditState } from '../../store/reducers/lofaScheduleEdit';
import { loadProfileReducer, initialLoadProfileState } from '../../store/reducers/loadProfile';
import { userListReducer, initialUserListState } from '../../store/reducers/userList/userListReducer';
import { customerListReducer, initialCustomerListState } from '../../store/reducers/customerList/customerListReducer';
import {
    customerParentListReducer,
    initialCustomerParentListState,
} from '../../store/reducers/customerParentList/customerParentListReducer';
import { crewListReducer, initialCrewListState } from '../../store/reducers/crewList/crewListReducer';
import { carrierListReducer, initialCarrierListState } from '../../store/reducers/carrierList/carrierListReducer';
import {
    loadManagementListReducer,
    initialLoadManagementListState,
} from '../../store/reducers/loadManagementList/loadManagementListReducer';
import { jobActivityReducer, initialJobActivityState } from '../../store/reducers/jobActivity';
import { messageListReducer, initialMessageListState } from '../../store/reducers/messageList/messageListReducer';
import {
    currentActivityReducer,
    initialCurrentActivityState,
} from '../../store/reducers/currentActivity/currentActivityReducer';
import { driversListReducer, initialDriveristState } from '../../store/reducers/driversList/driversListReducer';

const initialState: State = {
    addLoadModal: initialAddLoadModalState,
    auth: initialAuthState,
    carrierList: initialCarrierListState,
    crewList: initialCrewListState,
    customerList: initialCustomerListState,
    customerParentList: initialCustomerParentListState,
    jobActivity: initialJobActivityState,
    jobProfile: initialJobProfileState,
    jobList: initialJobListState,
    pumpScheduleEdit: initialPumpScheduleEditState,
    loadList: initialLoadListState,
    lofaList: initialLofaListState,
    lofaScheduleEdit: initialLofaScheduleEditState,
    loadProfile: initialLoadProfileState,
    userList: initialUserListState,
    loadManagementList: initialLoadManagementListState,
    messageList: initialMessageListState,
    currentActivity: initialCurrentActivityState,
    driverList: initialDriveristState,
};

const rootReducer = combineReducers({
    addLoadModal: addLoadModalReducer,
    auth: authReducer,
    carrierList: carrierListReducer,
    crewList: crewListReducer,
    customerList: customerListReducer,
    customerParentList: customerParentListReducer,
    jobActivity: jobActivityReducer,
    jobProfile: jobProfileReducer,
    jobList: jobListReducer,
    pumpScheduleEdit: pumpScheduleEditReducer,
    loadList: loadListReducer,
    lofaList: lofaListReducer,
    lofaScheduleEdit: lofaScheduleEditReducer,
    loadProfile: loadProfileReducer,
    userList: userListReducer,
    loadManagementList: loadManagementListReducer,
    messageList: messageListReducer,
    currentActivity: currentActivityReducer,
    driverList: driversListReducer,
});

// Custom hook to apply Store to top-level component.
// Used by StoreContext to inject context to all components (8/4/19) - BS
const useStore = (): [State, React.Dispatch<unknown>] => {
    const store = useReducer(rootReducer, initialState) as [State, React.Dispatch<unknown>];

    return store;
};

export { useStore, initialState };
