import { Reducer } from 'react';
import { Actions } from '../../actions/loadList';
import { Action, LoadListState } from '../../types';
import FilterItem from '../../../components/Filter/FilterItem';
import { LoadFilterTypeEnum } from '../../../types/LoadFilterTypeEnum';

const initialLoadListState: LoadListState = {
    filters: {
        statuses: [],
        proppantIds: [],
        lofaIds: [],
        driverIds: [],
        carrierIds: [],
        fromDate: '',
        toDate: '',
    },
    searchPhraseSubset: {
        statusSubset: { subset: [], searchPhrase: '' },
        driverSubset: { subset: [], searchPhrase: '' },
        proppantSubset: { subset: [], searchPhrase: '' },
        lofaSubset: { subset: [], searchPhrase: '' },
        carrierSubset: { subset: [], searchPhrase: '' },
    },
    searchPhrase: '',
};

interface FilterObject {
    filterType: string;
    id: number;
    isSelected: boolean;
}

const setDateRangeFilters = (payload: { fromDate: string; toDate: string }, state: LoadListState): LoadListState => {
    return {
        ...state,
        filters: {
            ...state.filters,
            fromDate: payload.fromDate,
            toDate: payload.toDate,
        },
    };
};

const addOrRemoveFilters = (payload: FilterObject, filters: number[]): number[] => {
    let massagedFilter: number[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.id);
    } else {
        massagedFilter = filters.filter((id): any => {
            if (id !== payload.id) return id;

            return undefined;
        });
    }

    return massagedFilter;
};

const addOrRemoveSelectAllFilters = (itemIds: number[], filters: number[], selected: boolean): number[] => {
    const massagedFilter: number[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(itemIds);
    }
};

const updateFilterListForSearchPhrase = (items: { id: number; name: string }[], searchString: string): FilterItem[] => {
    return items.filter(item => item.name.toLowerCase().includes(searchString.toLowerCase()));
};

const setSelectedFilters = (payload: FilterObject, state: LoadListState): LoadListState => {
    const { filters } = state;

    const filterCopy = {...filters}

    if (payload.filterType === LoadFilterTypeEnum.PROPPANT) {
        filterCopy.proppantIds = addOrRemoveFilters(payload, filterCopy.proppantIds);
    }
    if (payload.filterType === LoadFilterTypeEnum.LOADING_FACILITY) {
        filterCopy.lofaIds = addOrRemoveFilters(payload, filterCopy.lofaIds);
    }
    if (payload.filterType === LoadFilterTypeEnum.DRIVER) {
        filterCopy.driverIds = addOrRemoveFilters(payload, filterCopy.driverIds);
    }
    if (payload.filterType === LoadFilterTypeEnum.CARRIER) {
        filterCopy.carrierIds = addOrRemoveFilters(payload, filterCopy.carrierIds);
    }
    if (payload.filterType === LoadFilterTypeEnum.STATUS) {
        filterCopy.statuses = addOrRemoveFilters(payload, filterCopy.statuses);
    }

    return {
        ...state,
        filters: { ...filterCopy },
    };
};

// TODO: Generisize these two methods to take in the action type and reduce use of chained if statements
const setSelectAllFilters = (
    payload: { itemIds: number[]; filterType: string },
    state: LoadListState,
): LoadListState => {
    const { filters } = state;

    const filterCopy = {...filters}
    if (payload.filterType === LoadFilterTypeEnum.PROPPANT) {
        filterCopy.proppantIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filterCopy.proppantIds,
            !(filterCopy.proppantIds.length > 0),
        );
    }
    if (payload.filterType === LoadFilterTypeEnum.LOADING_FACILITY) {
        filterCopy.lofaIds = addOrRemoveSelectAllFilters(payload.itemIds, filterCopy.lofaIds, !(filterCopy.lofaIds.length > 0));
    }
    if (payload.filterType === LoadFilterTypeEnum.DRIVER) {
        filterCopy.driverIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filterCopy.driverIds,
            !(filterCopy.driverIds.length > 0),
        );
    }
    if (payload.filterType === LoadFilterTypeEnum.CARRIER) {
        filterCopy.carrierIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filterCopy.carrierIds,
            !(filterCopy.carrierIds.length > 0),
        );
    }
    if (payload.filterType === LoadFilterTypeEnum.STATUS) {
        filterCopy.statuses = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filterCopy.statuses,
            !(filterCopy.statuses.length > 0),
        );
    }
    return {
        ...state,
        filters: { ...filterCopy },
    };
};

const setFilterSearchPhrase = (
    payload: { items: FilterItem[]; filterType: string; searchPhrase: string },
    state: LoadListState,
): LoadListState => {
    const { searchPhraseSubset } = state;
    if (payload.filterType === LoadFilterTypeEnum.PROPPANT) {
        searchPhraseSubset.proppantSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadFilterTypeEnum.LOADING_FACILITY) {
        searchPhraseSubset.lofaSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadFilterTypeEnum.DRIVER) {
        searchPhraseSubset.driverSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadFilterTypeEnum.CARRIER) {
        searchPhraseSubset.carrierSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadFilterTypeEnum.STATUS) {
        searchPhraseSubset.statusSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }

    return {
        ...state,
        searchPhraseSubset: { ...searchPhraseSubset },
    };
};

const setSearchPhrase = (search: string, state: LoadListState): LoadListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const loadListReducer: Reducer<LoadListState, Action> = (state, action): LoadListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_FILTERS:
            newState = setSelectedFilters(action.payload, state);
            break;
        case Actions.UPDATE_FILTER_SEARCH_PHRASE:
            newState = setFilterSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_FILTERS_SELECT_ALL:
            newState = setSelectAllFilters(action.payload, state);
            break;
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_DATE_RANGE_FILTERS:
            newState = setDateRangeFilters(action.payload, state);
            break;
    }

    return newState;
};

export { initialLoadListState, loadListReducer };
