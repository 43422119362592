import gql from 'graphql-tag';

export default gql`
    query GetChassisCheckedout($input: GetChasssisCheckedoutInput) {
        getChassisCheckedout(input: $input) {
            sbsId
            trailerId
            checkedOutToDriverCompanyID
            checkedOutDate
            lastDeliveredLoadID
            currentLoadId
            locationLatitude
            locationLongitude
            lastLoadDeliveredDateTime
            lastLoadDeliveredDriverCompanyId
            lastLoadDeliveredDriver
            currentLoadAcceptedDateTime
            currentLoadDriverCompanyId
            currentLoadDriver
        }
    }
`;
