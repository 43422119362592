import gql from 'graphql-tag';

export default gql`
    query GetRoles {
        getRoles {
            roleId
            roleName
        }
    }
`;
