import gql from 'graphql-tag';

export default gql`
    query GetAllCarriers($input: String) {
        getAllCarriers(input: $input) {
            id
            companyName
            type
            stateId
            isActive
            extSystemIdentifier
            extSystemName
            activeInactiveDate
            activeInactiveUser
            name
            addressLine1
            addressLine2
            notes
            city
            zip
            phoneNumber
            email
        }
    }
`;
