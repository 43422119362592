import moment from 'moment';

export const formatDateUtcToLocal = (unformattedDate: string | null | undefined): string => {
    return unformattedDate
        ? moment
              .utc(unformattedDate)
              .local()
              .format('M/D/YYYY h:mm:ss A')
        : '';
};

export const formatDateToUtc = (unformattedDate: string | null | undefined): string => {
    const clientDate = new Date();
    const clientOffset = clientDate.getTimezoneOffset();
    const date = unformattedDate;
    const targetTime = new Date(date ? date : new Date(''));
    const returnTime = new Date(targetTime.getTime() - clientOffset * 60 * 1000);
    return unformattedDate ? returnTime.toUTCString() : '';
};
