import gql from 'graphql-tag';

export default gql`
    query GetLofaSchedule($jobId: Int) {
        getLofaSchedule(jobId: $jobId) {
            proppantId
            proppantName
            jobSummaries {
                id
                jobSummaryId
                lofaName
                lofaId
                lofaVolume
                volumePerBox
                proppantId
                totalVolume
                milesFromWell
                sandPo
                transportationPo
            }
        }
    }
`;
