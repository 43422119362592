import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CustomToggle from '../CustomToggle';
import { useStoreContext } from '../../App/StoreProvider';
import './DatePickerFilterItem.scss';
import { DateTimePicker } from '../../_common';
import { isValidDate } from '../../../utils';
import * as LoadListActions from '../../../store/actions/loadList';
import * as MessageListActions from '../../../store/actions/messageList';
import * as LoadManagementListActions from '../../../store/actions/loadManagementList';

import Button from 'react-bootstrap/Button';

interface DatePickerFilterItemProps {
    abortRequest: () => void;
    title: string;
    activeKey: string;
    fromDate: string;
    toDate: string;
    filterType: string | undefined;
    closeDateRange: () => void;
}

const FROM_DATE_ERROR_MESSAGE = 'From date can not be greater than to date.';
const TO_DATE_ERROR_MESSAGE = 'To date can not be lower than from date.';

const DatePickerFilterItem = ({
    abortRequest,
    title,
    activeKey,
    fromDate,
    toDate,
    filterType,
    closeDateRange,
}: DatePickerFilterItemProps): JSX.Element => {
    const [defActiveKey, setDefActiveKey] = useState(activeKey);
    const [fromDateError, setFromDateError] = useState('');
    const [toDateError, setToDateError] = useState('');
    const [fromDateState, setFromDateState] = useState(fromDate);
    const [toDateState, setToDateState] = useState(toDate);
    const [openToDateTimePicker, setOpenToDateTimePicker] = useState(false);
    const [openFromDateTimePicker, setOpenFromDateTimePicker] = useState(true);

    const [state, dispatch] = useStoreContext();
    useEffect((): void => {
        setDefActiveKey(activeKey);
    }, [activeKey]); // this line will tell react only trigger if count was changed

    const updateFromDateFilter = (value: string): void => {
        const fromDate = !!value ? new Date(value) : null;
        if (fromDate && isValidDate(fromDate)) {
            let errorMessage = '';
            if (fromDate.getTime() > new Date(state.loadList.filters.toDate).getTime()) {
                errorMessage = FROM_DATE_ERROR_MESSAGE;
            } else {
              //  abortRequest();

                if (filterType === 'Messages') {
                    setFromDateState(new Date(fromDate.setUTCHours(0, 0, 0)).toISOString());
                    setOpenFromDateTimePicker(false);
                    setOpenToDateTimePicker(true);
                } else {
                    dispatch(
                        LoadListActions.setDateRangeFilters(
                            new Date(fromDate.setUTCHours(0, 0, 0)).toISOString(),
                            state.loadList.filters.toDate,
                        ),
                    );

                    dispatch(
                        LoadManagementListActions.setDateRangeFilters(
                            new Date(fromDate.setUTCHours(0, 0, 0)).toISOString(),
                            state.loadList.filters.toDate,
                        ),
                    );
                }
            }

            setFromDateError(errorMessage);
        } else {
            setFromDateError('Please enter a valid date.');
        }
    };

    const updateToDateFilter = (value: string): void => {
        const toDate = !!value ? new Date(value) : null;
        if (toDate && isValidDate(toDate)) {
            let errorMessage = '';
             if (toDate.getTime() < new Date(state.loadList.filters.fromDate).getTime()) {
                errorMessage = TO_DATE_ERROR_MESSAGE;
             }
           // } else {
            // if (toDate.getTime() < new Date(state.loadList.filters.fromDate).getTime()) {
            //     errorMessage = TO_DATE_ERROR_MESSAGE;
            // } else {
              //  abortRequest();
                if (filterType === 'Messages') {
                    if (toDate.getTime() < new Date(fromDateState).getTime()) {
                        errorMessage = TO_DATE_ERROR_MESSAGE;
                        setOpenFromDateTimePicker(false);
                        setOpenToDateTimePicker(false);
                    } else {
                        setToDateState(new Date(toDate.setUTCHours(23, 59, 59)).toISOString());
                        closeDateRange();
                        dispatch(
                            MessageListActions.setDateRangeFilters(
                                fromDateState,
                                new Date(toDate.setUTCHours(23, 59, 59)).toISOString(),
                            ),
                        );
                        setOpenFromDateTimePicker(true);
                        setOpenToDateTimePicker(false);
                    }
                } else {
                    dispatch(
                        LoadListActions.setDateRangeFilters(
                            state.loadList.filters.fromDate,
                            new Date(toDate.setUTCHours(23, 59, 59)).toISOString(),
                        ),
                    );

                    dispatch(
                        LoadManagementListActions.setDateRangeFilters(
                            state.loadList.filters.fromDate,
                            new Date(toDate.setUTCHours(23, 59, 59)).toISOString(),
                        ),
                    );
                }
         //   }
            setToDateError(errorMessage);
        } else {
            setToDateError('Please enter a valid date.');
        }
    };

    const clearDateRangeFilters = (): void => {
      //  abortRequest();
       // console.log("here")
      //  console.log("fromDate",fromDate)
        dispatch(LoadListActions.setDateRangeFilters('', ''));
        dispatch(LoadManagementListActions.setDateRangeFilters('', ''));
        setFromDateError('');
        setToDateError('');
    };

    const closeDateRangeFilters = (): void => {
      //  abortRequest();
        closeDateRange();
    };

    const submitDateRangeFilters = (): void => {
        if (toDateState && fromDateState) {
            closeDateRange();
            dispatch(MessageListActions.setDateRangeFilters(fromDateState, toDateState));
        } else {
            if (fromDateState) {
                setToDateError('Please enter a valid date.');
            } else {
                setFromDateError('Please enter a valid date.');
            }
        }
    };

    const handleFromInputClick = (): void => {
        setOpenFromDateTimePicker(true);
        setOpenToDateTimePicker(false);
    };

    const handleToInputClick = (): void => {
        setOpenFromDateTimePicker(false);
        setOpenToDateTimePicker(true);
    };

    return (
        <div className={filterType === 'Messages' ? 'Message-Date-Range' : ''}>
            <Accordion activeKey={defActiveKey}>
                <Card className="FilterItem-Card" style={{ overflow: 'visible' }}>
                    {filterType !== 'Messages' && (
                        <CustomToggle
                            eventKey={defActiveKey}
                            title={title}
                            onClick={(): void => setDefActiveKey(defActiveKey === '0' ? '1' : '0')}
                            tags={[]}
                        ></CustomToggle>
                    )}
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className="DatePickerFilterItem-Body">
                            {filterType !== 'Messages' ? (
                                <Button
                                    className="DatePickerFilterItem-SelectAll"
                                    variant="link"
                                    onClick={clearDateRangeFilters}
                                >
                                    Clear Dates
                                </Button>
                            ) : (
                                <Button
                                    className="DatePickerFilterItem-SelectAll"
                                    variant="link"
                                    onClick={closeDateRangeFilters}
                                >
                                    Close
                                </Button>
                            )}

                            <div className="DatePickerFilterItem-Date-Container">
                                {filterType !== 'Messages' ? (
                                    <>
                                        <DateTimePicker
                                            dateOnly
                                            label="FROM"
                                            value={fromDate}
                                            onChange={updateFromDateFilter}
                                            error={fromDateError || ''}
                                        />
                                        <span className="DatePickerFilterItem-Divider">-</span>
                                        <DateTimePicker
                                            dateOnly
                                            label="TO"
                                            value={toDate}
                                            onChange={updateToDateFilter}
                                            error={toDateError || ''}
                                        />
                                    </>
                                ) : (
                                    <>
                                        <DateTimePicker
                                            dateOnly
                                            label="FROM"
                                            value={fromDate}
                                            onChange={updateFromDateFilter}
                                            error={fromDateError || ''}
                                            open={openFromDateTimePicker}
                                            inputProps={{ onClick: handleFromInputClick }}
                                        />
                                        <span className="DatePickerFilterItem-Divider">-</span>
                                        <DateTimePicker
                                            dateOnly
                                            label="TO"
                                            value={toDate}
                                            onChange={updateToDateFilter}
                                            error={toDateError || ''}
                                            open={openToDateTimePicker}
                                            inputProps={{ onClick: handleToInputClick }}
                                        />
                                    </>
                                )}
                            </div>
                            {/* {filterType === 'Messages' && (
                                <Button className="greenbtn" variant="link" onClick={submitDateRangeFilters}>
                                    Submit
                                </Button>
                            )} */}
                        </Card.Body>
                    </Accordion.Collapse>{' '}
                </Card>
            </Accordion>
        </div>
    );
};

export default DatePickerFilterItem;
