import gql from 'graphql-tag';

export default gql`
    query GetActivitySymmary($input: ActivitySummaryInput) {
        getActivitySummary(input: $input) {
            proppantName
            lofaName
            wellName
            created
            accepted
            assigned
            loading
            loaded
            unloading
            totalActiveTrucks
            proppantId
            lofaId
            regionId
            sandPO
        }
    }
`;
