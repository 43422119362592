import React from 'react';
import './DispatchModal.scss';
import Modal from 'react-bootstrap/Modal';
import { CustomButton } from '../../_common';
import dispatchIcon from '../../../assets/dispatchSuccess.png';
import 'material-design-icons/iconfont/material-icons.css';
import { Row } from 'react-bootstrap';

interface ConfirmDispatchModalProps {
    onClickHide: () => void;
    show: boolean;
    isResetPassword: boolean;
}

const ConfirmDispatchModal = ({ show, onClickHide, isResetPassword }: ConfirmDispatchModalProps): JSX.Element => {
    const resetPwdMessage = 'Reset password link successfully sent to your email.';

    return (
        <Modal show={show} onHide={onClickHide} backdrop="static">
            <Modal.Header className="ConfirmDispatchModal-Header">
                <Modal.Title className="ConfirmDispatchModal-Header-Container">
                    <i className="material-icons ConfirmDispatchModal-X-Button" onClick={onClickHide}>
                        close
                    </i>
                    <img className="ConfirmDispatchModal-Icon" src={dispatchIcon} alt="dispatch icon" />
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="ConfirmDispatchModal-Body">
                {isResetPassword ? (
                    <div className="ConfirmDispatchModal-Body-Header">{resetPwdMessage}</div>
                ) : (
                    <div></div>
                )}
            </Modal.Body>
            <Modal.Footer className="ConfirmDispatchModal-Footer">
                <CustomButton title="OK" isDisabled={false} onClick={onClickHide}></CustomButton>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmDispatchModal;
