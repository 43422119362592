import gql from 'graphql-tag';

export default gql`
    query GetAllStates {
        getAllStates {
            id
            stateAcronym
            stateName
        }
    }
`;
