import gql from 'graphql-tag';

export default gql`
    query GetLoadFilterLoadFacilityList($input: GetLoadFilterLoadFacilityListInput) {
        getLoadFilterLoadFacilityList(input: $input) {
            lofaId
            lofaName
        }
    }
`;
