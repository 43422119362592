export enum PermissionEnum {
    Admin = 'Admin',
    Customer = 'Customer',
    CustomerService = 'Customer Service',
    Carrier = 'Carrier',
    Operation = 'SBX Operations',
    EditJob = 'EditJob',
    EditPumpSchedule = 'EditPumpSchedule',
    EditLOFASchedule = 'EditLOFASchedule',
    ViewJobPlan = 'ViewJobPlan',
    EditJobPlan = 'EditJobPlan',
    ViewLoads = 'ViewLoads',
    AddLoads = 'AddLoads',
    ManageLoads = 'ManageLoads',
    EditLoadDetails = 'EditLoadDetails',
    ViewDriverLoads = 'ViewDriverLoads',
    ViewDriverCompany = 'ViewDriverCompany',
    EditDriverCompany = 'EditDriverCompany',
    ViewDriver = 'ViewDriver',
    EditDriver = 'EditDriver',
    ViewDriverWellAssignment = 'ViewDriverWellAssignment',
    EditDriverWellAssignment = 'EditDriverWellAssignment',
    ViewSettings = 'ViewSettings',
    EditSettings = 'EditSettings',
    EditCustomer = 'EditCustomer',
    ViewCrew = 'ViewCrew',
    EditCrew = 'EditCrew',
    ViewProppant = 'ViewProppant',
    EditProppant = 'EditProppant',
    DeleteProppant = 'DeleteProppant',
    ViewLOFA = 'ViewLOFA',
    EditLOFA = 'EditLofa',
    ViewUsers = 'ViewUsers',
    EditUsers = 'EditUsers',
    EditPermissions = 'EditPermissions',
    LoadReport = 'LoadReport',
    DriverScheduleReport = 'DriverScheduleReport',
    ViewMessages = 'ViewMessages',
    SendMessages = 'SendMessages',
    ViewLogs = 'ViewLogs',
    UserRolesReport = 'UserRolesReport',
    RolesPermissionsReport = 'RolesPermissionsReport',
    ViewAudits = 'ViewAudits',
    EditCustomerParent = 'EditCustomerParent',
    ViewJob = 'ViewJob',
    ViewCustomer = 'ViewCustomer',
    ViewCustomerParent = 'ViewCustomerParent',
    ViewPermissions = 'ViewPermissions',
    SplitLoads = 'SplitLoads',
    UploadImages = 'UploadImages',
    DriverSchedule = 'DriverSchedule',
    Edit3rdPartyCarrier = 'Edit3rdPartyCarrier',
    EditKronosID = 'EditKronosID',
    EditDriverRegion = 'EditDriverRegion',
    EditDriverDistrict = 'EditDriverDistrict',
    EditDriverCellNumber = 'EditDriverCell#',
    ViewJobsForLoading = 'ViewJobsForLoading',
    EditCompletedLoads = 'EditCompletedLoads',
    DeleteJob = 'DeleteJob',
    RunScripts = 'RunScripts',
    // TODO: Verify that these two permissions should be in QA and PROD - (9/26/19) BS
    UnlockDrivers = 'Unlockdrivers',
    ResetUserPasswords = 'Resetuserpasswords',
    // TODO: Verify that these permissions exist on backend - GB
    AddJobNotes = 'AddJobNotes',
    EditJobNotes = 'EditJobNotes',
    ViewJobNotes = 'ViewJobNotes',
    AddLoadNotes = 'AddLoadNotes',
    EditLoadNotes = 'EditLoadNotes',
    ViewLoadNotes = 'ViewLoadNotes',
    DeletePumpSchedule = 'DeletePumpSchedule',
    DeleteLOFASchedule = 'DeleteLOFASchedule',
    EditLoadProgress = 'EditLoadProgress',
    MarkLoadComplete = 'MarkLoadComplete',
    EditLoadTimestamps = 'EditLoadTimestamps',
    DeleteLoad = 'DeleteLoad',
}
