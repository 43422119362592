import React, { FC, useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ItemDetails from '../ItemDetails';
import { GetFilteredLoadManagements_getFilteredLoadManagements as Load } from '../../../types/schemaTypes';
import './LoadItem.scss';
import { LoadStatusEnum } from '../../../types/LoadStatusEnum';
import { DriverLoadStatusEnum } from '../../../types/DriverLoadStatusEnum';
import { Routes } from '../../../utils';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { useStoreContext } from '../../App/StoreProvider';
import { formatDateUtcToLocal } from '../../../utils/helpers/datetimeHelper';
import moment from 'moment';
interface LoadItemProps {
    abortRequest: () => void;
    load: Load;
    // isChecked: boolean;
    // onChange: (checkedStatus: boolean) => void;
    refetch: () => void;
}

interface DetailOption {
    displayAssignDriver: boolean;
    statusLabel: string;
    statusDetails: string;
    displayMore: boolean;
    loadStatusLabel: string;
}

const getLoadDetails = (
    status: string,
    driverLoadStatus: string,
    secondaryDriverLoadStatus: string,
    secondaryDriverId: number,
    isSplit: boolean,
    rejectReason: string,
    rejectedDateTime: string,
    userName: string,
    rejectedDriverName: string,
): DetailOption => {
    const details: DetailOption = {
        displayAssignDriver: false,
        statusLabel: '',
        statusDetails: '',
        displayMore: false,
        loadStatusLabel: '',
    };
    if (status === LoadStatusEnum.CREATED) {
        if (rejectedDateTime != '') {
            if (rejectReason === 'Timeout') {
                details.statusLabel = DriverLoadStatusEnum.REJECTED;
                details.statusDetails = 'Load ' + rejectReason + ' on ' + formatDateUtcToLocal(rejectedDateTime);
                details.displayAssignDriver = true;
            } else {
                details.statusLabel = DriverLoadStatusEnum.REJECTED;
                details.statusDetails =
                    'Rejected by ' +
                    (rejectReason === 'Rejected by Dispatch'
                        ? 'dispatch ' + userName
                        : 'driver ' + rejectedDriverName) +
                    ' on ' +
                    formatDateUtcToLocal(rejectedDateTime);
                details.displayAssignDriver = true;
            }
        }
        details.displayAssignDriver = true;
    } else if (status === LoadStatusEnum.ASSIGNED) {
        if (driverLoadStatus === DriverLoadStatusEnum.ACCEPTED) {
            details.displayMore = true;
        } else {
            details.statusLabel = DriverLoadStatusEnum.PENDING;
        }
    } else if (status === LoadStatusEnum.LOADED) {
        if (secondaryDriverId === 0 && isSplit && rejectedDateTime != '') {
            if (rejectReason === 'Timeout') {
                details.statusLabel = DriverLoadStatusEnum.REJECTED;
                details.statusDetails = 'Load ' + rejectReason + ' on ' + formatDateUtcToLocal(rejectedDateTime);
            } else {
                details.loadStatusLabel = LoadStatusEnum.LOADED;
                details.statusDetails =
                    'Rejected by ' +
                    (rejectReason === 'Rejected by Dispatch'
                        ? 'dispatch ' + userName
                        : 'driver ' + rejectedDriverName) +
                    ' on ' +
                    formatDateUtcToLocal(rejectedDateTime);
                details.displayAssignDriver = true;
                details.statusLabel = DriverLoadStatusEnum.REJECTED;
            }
            details.displayMore = true;
            details.displayAssignDriver = true;
        } else if (secondaryDriverLoadStatus === DriverLoadStatusEnum.PENDING) {
            details.loadStatusLabel = LoadStatusEnum.LOADED;
            details.displayMore = true;
            details.statusLabel = DriverLoadStatusEnum.PENDING;
        } else {
            details.loadStatusLabel = LoadStatusEnum.LOADED;
            details.displayMore = true;
        }
    } else if (status === LoadStatusEnum.ON_SITE) {
        if (secondaryDriverId === 0 && isSplit && rejectedDateTime != '') {
            if (rejectReason === 'Timeout') {
                details.statusLabel = DriverLoadStatusEnum.REJECTED;
                details.statusDetails = 'Load ' + rejectReason + ' on ' + formatDateUtcToLocal(rejectedDateTime);
            } else {
                details.loadStatusLabel = LoadStatusEnum.LOADED;
                details.statusDetails =
                    'Rejected by ' +
                    (rejectReason === 'Rejected by Dispatch'
                        ? 'dispatch ' + userName
                        : 'driver ' + rejectedDriverName) +
                    ' on ' +
                    formatDateUtcToLocal(rejectedDateTime);
                details.displayAssignDriver = true;
                details.statusLabel = DriverLoadStatusEnum.REJECTED;
            }
            details.displayMore = true;
            details.displayAssignDriver = true;
        } else if (secondaryDriverLoadStatus === DriverLoadStatusEnum.PENDING) {
            details.loadStatusLabel = LoadStatusEnum.ON_SITE;
            details.displayMore = true;
            details.statusLabel = DriverLoadStatusEnum.PENDING;
        } else {
            details.loadStatusLabel = LoadStatusEnum.ON_SITE;
            details.displayMore = true;
        }
    } else {
        details.displayMore = true;
    }

    return details;
};

const LoadItem: FC<LoadItemProps> = ({
    abortRequest,
    load,
    // isChecked,
    // onChange,
    refetch,
}: LoadItemProps): JSX.Element => {
    const canAddDriver = useAuthContext().userHasPermission(PermissionEnum.ManageLoads);
    const [isDetailsOpen, setIsDetailsOpen] = useState(false);
    // const [showModal, setShowModal] = useState(false);
    const [state] = useStoreContext();

    const {
        loadList: { filters, searchPhrase },
    } = state;
    const {
        loadId,
        boxNumber,
        carrierName,
        driverLoadStatus,
        driverName,
        proppantName,
        loadingFacility,
        sandPO,
        status,
        weight,
        atLofaIn,
        atLofaOut,
        atDestinationIn,
        atDestinationOut,
        truckNumber,
        chassisNumber,
        sandTicket,
        transportationPo,
        regionId,
        secondaryDriverId,
        secondaryDriverLoadStatus,
        rejectReason,
        assignedDateTime,
        rejectedDateTime,
        userName,
        rejectedDriverName,
    } = load;
    const { displayAssignDriver, statusLabel, statusDetails, displayMore, loadStatusLabel } = getLoadDetails(
        status,
        driverLoadStatus || '',
        secondaryDriverLoadStatus || '',
        secondaryDriverId || 0,
        load.isSplit || false,
        rejectReason || '',
        rejectedDateTime || '',
        userName || '',
        rejectedDriverName || '',
    );
    const canSplitLoad = useAuthContext().userHasPermission(PermissionEnum.SplitLoads);
    // const handleOnClickHide = (): void => {
    //     setShowModal(false);
    // };
    const UNAVAILABLE_DATE = 'Not available';

    const openLoadProfile = (loadId: number, boxNumber: string): void => {
        const URL = `${window.location.origin}${Routes.LOAD_PROFILE.route}`;
        const newTab = window.open(URL + loadId, '_blank');
        // localStorage.setItem('redirectbackto', 'carrier');
        setTimeout(function(): void {
            if (newTab) {
                newTab.document.title = boxNumber;
            }
        }, 100);
    };

    // const checkForStatus = (status: string): boolean => {
    //     if (status === LoadStatusEnum.CREATED || status === LoadStatusEnum.CREATED_NOT_SAVED) {
    //         return true;
    //     }
    //     return false;
    // };

    const formatDateTime = (dateTime: string): string => {
        return dateTime ? moment(dateTime).format('M/D/YYYY h:mm:ss A') : '';
    };

    return (
        <div>
            <div className="LoadItem-Container">
                <Col md={3} className="ml-2 d-flex flex-column custom-column-p justify-content-between my-flex-container">
                    <Row>
                        <span className="LoadItem-Name" onClick={(): void => openLoadProfile(loadId, boxNumber)}>
                            {`Load #: ${loadId}`}
                        </span>
                    </Row>
                    {/* <ItemDetails label="Carrier:" info={carrierName || ''} /> */}
                    <ItemDetails label="Driver:" info={driverName} />
                </Col>
                <Col md={4} className="d-flex flex-column custom-column-p justify-content-between my-flex-container">
                    <ItemDetails label="Proppant:" info={proppantName} />
                    <ItemDetails label="Loading Facility:" info={loadingFacility} />
                    <ItemDetails label="Sand PO:" info={sandPO} />
                </Col>
                <Col md={2} className="d-flex flex-column justify-content-between custom-column-p my-flex-container">
                    <ItemDetails label="Status:" info={status} />
                    <ItemDetails label="Box #:" info={boxNumber} />
                    <ItemDetails
                        label="Weight:"
                        info={weight ? weight.toLocaleString() + ' (' + (weight / 2000).toFixed(2) + 'T)' : weight}
                    />
                </Col>
                <Col md={3} className="d-flex flex-column justify-content-between custom-column-p my-flex-container">
                    {displayMore ? (
                        <div>
                            <Row>
                                {load.isSplit && (
                                    <Row
                                        className={` Split-Load-Info-Green ${
                                            statusLabel !== DriverLoadStatusEnum.PENDING ? 'Split-Load-Info-Green2' : ''
                                        }`}
                                    >
                                        <i className="material-icons Split-Load-IsSplit-Icon">call_split</i>
                                        <p>Split Load</p>
                                    </Row>
                                )}
                            </Row>
                            <Row
                                className={` LoadItem-LinkContainer ${
                                    statusLabel === DriverLoadStatusEnum.REJECTED ||
                                    ((secondaryDriverId === null || secondaryDriverId === 0) &&
                                        (status === LoadStatusEnum.LOADED || status === LoadStatusEnum.ON_SITE)) ||
                                    secondaryDriverLoadStatus === DriverLoadStatusEnum.PENDING ||
                                    (load.isSplit && load.status === LoadStatusEnum.DELIVERED)
                                        ? load.isSplit && secondaryDriverId === 0
                                            ? 'LoadItem-LinkContainer-More3'
                                            : 'LoadItem-LinkContainer-More3'
                                        : 'LoadItem-LinkContainer-More2'
                                }`}
                                onClick={(): void => setIsDetailsOpen(!isDetailsOpen)}
                            >
                                <div className="LoadItem-MoreInfo">
                                    <p className="LoadItem-LinkText">{isDetailsOpen ? 'Less' : 'More'}</p>
                                    <i className="material-icons LoadItem-Icon">
                                        {isDetailsOpen ? 'expand_less' : 'expand_more'}
                                    </i>
                                </div>
                            </Row>
                        </div>
                    ) : (
                        <div
                            className={`${
                                status === LoadStatusEnum.CREATED
                                    ? canAddDriver
                                        ? 'LoadItem-LinkContainer'
                                        : 'LoadItem-AddDriver-Disable'
                                    : ''
                            }`}
                            id={statusLabel ? 'LoadItem-AddDriver-Enable' : ''}
                        ></div>
                    )}
                </Col>
            </div>
            {isDetailsOpen && (
                <div className="LoadItem-DetailsContainer">
                    <div className="LoadItem-DetailsSection Load-Row-Section">
                        <ItemDetails label="Chassis #:" info={chassisNumber} />
                        <ItemDetails label="Truck #:" info={truckNumber} />
                    </div>
                    <div className="LoadItem-Divider" />
                    <div className="LoadItem-DetailsSection Load-Row-Section">
                        <ItemDetails label="LoFa In:" info={atLofaIn ? formatDateTime(atLofaIn) : UNAVAILABLE_DATE} />
                        <ItemDetails
                            label="LoFa Out:"
                            info={atLofaOut ? formatDateTime(atLofaOut) : UNAVAILABLE_DATE}
                        />
                    </div>
                    <div className="LoadItem-Divider" />
                    <div className="LoadItem-DetailsSection Load-Row-Section">
                        <ItemDetails
                            label="Dest In:"
                            info={atDestinationIn ? formatDateTime(atDestinationIn) : UNAVAILABLE_DATE}
                        />
                        <ItemDetails
                            label="Dest Out:"
                            info={atDestinationOut ? formatDateTime(atDestinationOut) : UNAVAILABLE_DATE}
                        />
                    </div>
                    <div className="LoadItem-Divider" />
                    <div className="LoadItem-DetailsSection Load-Row-Section">
                        <ItemDetails label="Sand Ticket:" info={sandTicket || sandPO} />
                        <ItemDetails label="Transportation PO" info={transportationPo} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default LoadItem;
