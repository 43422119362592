import React from 'react';
import './AccessorialForLoadDetail.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent';
import GET_ACCESSORIAL_FOR_LOAD_DETAIL from '../../../graphql/client/queries/getAccessorialForLoadDetail';
import {
    GetAccessorialForLoadDetail_getAccessorialForLoadDetail as AccessorialForLoadDetailData,
    GetAccessorialForLoadDetail,
    GetLoadAccessorial,
    GetLoadAccessorial_getLoadAccessorial as LoadAccessorialData,
} from '../../../types/schemaTypes';
import { useQuery } from '@apollo/client';
import { Form } from 'react-bootstrap';
import { GET_LOAD_ACCESSORIAL } from '../../../graphql/client/queries';
import { LoadAccessorialEnum } from '../../../types/LoadAccessorialEnum';

interface AccessorialForLoadDetailProps {
    loadId: number;
}

const AccessorialForLoadDetail = ({ loadId }: AccessorialForLoadDetailProps): JSX.Element => {
    const { data } = useQuery<GetAccessorialForLoadDetail>(GET_ACCESSORIAL_FOR_LOAD_DETAIL);

    let accessorialForLoadDetailData: (AccessorialForLoadDetailData | null)[] | null;
    const { data: loadAccessorialData } = useQuery<GetLoadAccessorial>(GET_LOAD_ACCESSORIAL, {
        variables: {
            input: {
                loadId: loadId,
                accessorialName: [LoadAccessorialEnum.DEAD_HEAD, LoadAccessorialEnum.CHAINUP_TOWUP],
            },
        },
    });
    let loadAccessorials: (LoadAccessorialData | null)[] | null;
    if (loadAccessorialData && loadAccessorialData.getLoadAccessorial) {
        loadAccessorials = loadAccessorialData.getLoadAccessorial;
    }
    const checkForLoad = (accessorialName: string): boolean => {
        if (accessorialName != '') {
            const loadAccessorial =
                loadAccessorials && loadAccessorials.filter(it => it && it.accessorialName === accessorialName)[0];
            if (loadAccessorial != null && loadAccessorial != undefined) {
                return true;
            }
        }
        return false;
    };

    const getMilesForDeadHead = (accessorialName: string): string => {
        if (accessorialName != '') {
            const loadAccessorial =
                loadAccessorials &&
                loadAccessorials
                    .filter(it => it && it.accessorialName === accessorialName)
                    .map(it => it && it.value)[0];
            if (loadAccessorial != null && loadAccessorial != undefined) {
                return loadAccessorial;
            }
        }
        return '';
    };

    if (data && data.getAccessorialForLoadDetail) {
        accessorialForLoadDetailData = data.getAccessorialForLoadDetail;
        return (
            <Col>
                <Row className="AccessorialForLoadDetail-Title">Accessorials</Row>
                {accessorialForLoadDetailData &&
                    accessorialForLoadDetailData.map((item, index) => (
                        <Form key={index}>
                            <div key={index} className="AccessorialForLoadDetail-Body">
                                <Form.Check
                                    type={'checkbox'}
                                    id={`filled-in checked`}
                                    disabled
                                    checked={checkForLoad((item && item.accessorialName) || '')}
                                    label={(item && item.accessorialName) || ''}
                                />
                                {getMilesForDeadHead((item && item.accessorialName) || '') != '' &&
                                getMilesForDeadHead((item && item.accessorialName) || '') != '0' ? (
                                    <div className="Accessorial-Miles">
                                        <TextContent
                                            hasTopMargin
                                            title={'MILES: '}
                                            value={getMilesForDeadHead((item && item.accessorialName) || '') || ''}
                                        ></TextContent>
                                    </div>
                                ) : (
                                    <div></div>
                                )}
                            </div>
                        </Form>
                    ))}
            </Col>
        );
    } else {
        return <div></div>;
    }
};

export default AccessorialForLoadDetail;
