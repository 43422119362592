import gql from 'graphql-tag';

export default gql`
    query GetAllDriversFilter {
        getAllDriversFilter {
            id
            name
        }
    }
`;
