import gql from 'graphql-tag';

export default gql`
    query GetLoadFilterDriverList($input: GetLoadFilterDriverListInput) {
        getLoadFilterDriverList(input: $input) {
            driverId
            driverName
        }
    }
`;
