import gql from 'graphql-tag';

export default gql`
    query GetDriverActivity($input: DriverActivityInput) {
        getDriverActivity(input: $input) {
            driverName
            lofaName
            status
            acceptTime
            etaLoading
            etaLoaded
            etaOnsite
            etaDelivered
            loadId
            overdueAtLofaIn
            overdueAtLofaOut
            overdueAtDestinationIn
            overdueAtDestinationOut
            isOverdue
            proppantName
            notes {
                id
                loadId
                notes
                createDate
                name
            }
        }
    }
`;
