import React, { FC, useEffect, useState } from 'react';
import { Query  } from '@apollo/client/react/components';
import {QueryResult, useQuery} from '@apollo/client'
import './CarrierDashboard.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { GET_DRIVER_BY_FILTERS } from '../../graphql/client/queries';
import { GetDriversByFilter_getDriversByFilter as Driver, GetDriversByFilter } from '../../types/schemaTypes';
import { Error, Loading, NoResult } from '../_common';
import { JobFilters } from '../Filter';
import { Routes } from '../../utils';
import JobList from '../TableList/JobList';
import { useStoreContext } from '../App/StoreProvider';
import * as JobListActions from '../../store/actions/jobList';
import CarrierDashboardHeader from '../Header/CarrierDashboardHeader';
import { CarrierDashboardFilters } from '../Filter/CarrierDashboardFilters';
import DriverList from './DriverList';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import DriverLoadNotes from '../JobProfile/JobActivity/DriverLoadNotes/DriverLoadNotes';
import ForbiddenError from '../_common/Error/ForbiddenError';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import DriverCompanyGrade from './DriverCompanyGrade/DriverCompanyGrade';
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ToastContainer, toast } from 'react-toastify';
import { toastOpt } from '../../utils/helpers/toastHelper'

interface SelectedJob {
    jobId: string;
    jobName: string;
}

// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

const CarrierDashboard: FC = (): JSX.Element => {
    const initialArray: SelectedJob[] = [];
    // TODO: Move local state to jobListReducer
    const [selectedJobs, setSelectedJobs] = useState(initialArray);
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [state, dispatch] = useStoreContext();
    const [refetched, setRefetched] = useState(false);
    const [isEndOfResults, setIsEndOfResults] = useState(false);
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCarrier = useAuthContext().userHasPermission(PermissionEnum.Carrier);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);
    const gridRef = React.useRef<AgGridReact<any>>(null);
    const defaultColDef = React.useMemo<any>(() => {
        return {
            flex: 1,
            resizable: false,
            filter: false,
            wrapText: true,
            autoHeight: true,
            suppressMovable: true,
            minWidth: 75,
            cellStyle: (params) => ({
                display: "flex",
                alignItems: "center",
                wordBreak: "normal"
              })
        };
    }, []);

    
    const columns = [
        {
            field: 'driverName',
            headerName: 'Driver Name',
            minWidth: 200,

            cellRenderer: ({ value, data }) => {
                return ( <div style={{ display: 'flex' }}>
                            <div>{data.driverName}</div>
                            {data.notes && data.notes.length > 0 && <DriverLoadNotes data={data}></DriverLoadNotes>}
                     </div>)
            }
        },
        {
            field: 'loadId',
            headerName: 'Load#',
            maxWidth: 100,
            minWidth: 98,
            cellRenderer: ({value, data}) => {
                const openLoad = () => {
                    if (data.loadId !== 0) {
                        openLoadProfile(data['loadId'], '');
                    }
                }


                if (data.loadId !== 0) {
                    return <div>{data.loadId !== 0 && <a className="Driver-LoadId" onClick={openLoad}>{data.loadId}</a>}</div>;
                } else {
                    if (data.onLoad) {
                        return <span className="invalid-loadid">0</span>;
                    } else {
                        return <span></span>;
                    }
                }
            },
        },
        {
            field: 'lastLoadDelivered',
            headerName: 'Last Load Delivered',
            maxWidth: 180,
            minWidth: 160,

            cellRenderer: ({ value, data }) => {
                return <div>{data.lastLoadDelivered !== 0 ? data.lastLoadDelivered + ' mins' : ''}</div>
            }
            
        },
        {
            field: 'lastLoadRequested',
            headerName: 'Last Load Requested',
            maxWidth: 200,
            minWidth: 180,

            cellRenderer: ({ value, data }) => {
                return <div>{data.lastLoadRequested !== 0 ? data.lastLoadRequested + ' mins' : ''}</div>;
                },
        },
        {
            field: 'lastLoadRejected',
            headerName: 'Last Load Rejected',
            maxWidth: 180,
            minWidth: 160,

            cellRenderer: ({ value, data }) => {
                return <div>{data.lastLoadRejected !== 0 ? data.lastLoadRejected + ' mins' : ''}</div>;
            },
        },
        {
            field: 'onShiftTime',
            headerName: 'On Shift',

            cellRenderer: ({ value, data }) => {
                return <div>{data.onShiftTime !== 0 ? data.onShiftTime + ' mins' : ''}</div>;
            },
        },
        {
            field: 'isActive',
            headerName: 'Status',

          cellRenderer: ({ value, data }) => {
                return <div>{data.isActive ? 'Active' : 'Inactive'}</div>;
            },
        },
    ];

    const abortController = new AbortController();
    const {
        jobList: { filters },
        carrierList: { carrierId, searchPhrase },
    } = state;

    useEffect(() => {
        dispatch(JobListActions.resetFilterState());
        dispatch(JobListActions.setSelectAllFilters([1], 'Status'));
    }, []);

    const openLoadProfile = (loadId: number | null, boxNumber: string | null): void => {
        const URL = `${window.location.origin}${Routes.LOAD_PROFILE.route}`;
        const newTab = window.open(URL + loadId, '_blank');
        setTimeout(function(): void {
            if (newTab) {
                newTab.document.title = boxNumber!;
            }
        }, 100);
    };

    const variables = {
        input: {
            statuses: filters.statuses,
            regionIds: filters.regionIds,
            districtIds: filters.districtIds,
            searchString: searchPhrase,
            sortOrder: 'asc',
            offset: 0,
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
        },
    };

    const openSelectedJobs = (): void => {
        const URL = `${window.location.origin}${Routes.JOB_PROFILE.route}`;
        selectedJobs.forEach((el): void => {
            const newTab = window.open(URL + el.jobId, '_blank');
            setTimeout(function(): void {
                if (newTab) {
                    newTab.document.title = el.jobName;
                }
            }, 100);
        });
        const result: SelectedJob[] = [];
        setSelectedJobs(result);
    };

    const context = {
        fetchOptions: {
            signal: abortController.signal,
        },
    };

    const abortRequest = (): void => {
        abortController.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Carrier Dashboard';

    if (!isAdmin && !isOperationUser && !isCarrier) {
        return <ForbiddenError />;
    }
  

   
    const { loading: getDriversLoading,error, data: getDriversByFiltersData } = useQuery(GET_DRIVER_BY_FILTERS, {
        variables,
        fetchPolicy: 'network-only',
        skip:(carrierId > 0  ) ? false:true,

    });
        
    if (error) {
        return <Error error={error} />;
    }

    const generateDynamicHtml = () => {

      //  const table = copyToClipboardMap.get(id);
        let html = "";
        
        console.log("getDriversByFiltersData",getDriversByFiltersData.getDriversByFilter)
        if (getDriversByFiltersData?.getDriversByFilter
            .length > 0) {
            html += `<div style="font-family: Avenir-Book; font-size: 15px">Carrier Driver Details</div><table style="border: 1px solid black;
    border-collapse: collapse;">
    <thead>
        <tr>
            <!-- Add more columns as needed -->
                                <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">Driver Name</th>
                                <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">Load#</th>
                                <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">Last Load Delivered</th>
                                <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">Last Load Requested</th>
                                <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">Last Load Rejected</th>
                                 <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">On sift</th>
                                <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                border-collapse: collapse;">Status</th>

        </tr>
    </thead>
    <tbody>
        `

        getDriversByFiltersData?.getDriversByFilter
        .forEach((item) => {
                html += `<tr><td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['driverName']}</td>
            <td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['loadId']!== 0 ? item['loadId'] : 0 }</td>
            <td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['lastLoadDelivered']} mins</td>
            <td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['lastLoadRequested']} mins</td>
            <td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['lastLoadRejected']} mins</td>
            <td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['onShiftTime']} mins</td>
            <td style="font-size: 11px; border: 1px solid black;
            border-collapse: collapse;">${item['isActive'] ? 'Active' : 'Inactive' }</td>

            </tr>`
            });

            html += `
        </tbody>
        </table>`

        } else {
            html += `<div style="font-family: Avenir-Book; font-size: 11px">No Carrier Driver details available.</div><br><br>`
        }
        const parser = new DOMParser();
        const htmlDoc = parser.parseFromString(html, 'text/html');
        return htmlDoc.documentElement.outerHTML;
        
    }

    const copyToClipboard = async (id) => {

        const html = generateDynamicHtml();
        console.log("html",html)
        const clipboardItem = new
            ClipboardItem({
                'text/html': new Blob([html],
                    { type: 'text/html' }),
            });

        try {
            await navigator.clipboard.write([clipboardItem]);
            toast.success(`Carrier driver table data copied successfully`, toastOpt);

        } catch (error) {
            console.log("error -", error);
            toast.error('Something went wrong!', toastOpt);
        }

    }

    if (variables.input.driverCompanyId && variables.input.driverCompanyId !== 0) {
        return (
            <Query query={GET_DRIVER_BY_FILTERS} variables={variables} fetchPolicy="network-only" context={context}>
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetDriversByFilter | undefined>): JSX.Element | null => {
                    if (error) {
                        return <Error error={error} />;
                    }
                    const driverList: Driver[] = [];
                    if (data && data.getDriversByFilter && data.getDriversByFilter.length !== 0) {
                        data.getDriversByFilter.forEach((element: Driver | null): void => {
                            if (element !== null) {
                                driverList.push(element);
                            }
                        });
                    }
                  

                    return (
                        <div className="JobManagement-Container">
                            <ToastContainer/>
                            <CarrierDashboardHeader />
                            <DriverCompanyGrade />
                            <div className="JobManagement-Body">
                                <div id="jobsFilterContainer" className="JobManagement-Filter">
                                    <CarrierDashboardFilters
                                        abortRequest={abortRequest}
                                        onFilterToggle={setListContainerHeight}
                                    />
                                </div>
                                <div
                                    id="jobsListContainer"
                                    className="JobManagement-List"
                                    style={{ height: listHeight }}
                                >
                                    <div className="Users-Bottom-Container table-div">
                                        {loading && (
                                            <div className="JobList-Loading">
                                                <Loading />
                                            </div>
                                        )}
                                        {!loading && driverList.length > 0 && (
                                            

                                            <>
                                            <div className='carrierCopyToclipboard' onClick={() => copyToClipboard(1)}>
                                            <ContentCopyRoundedIcon
                                                    className='JobActivity-Copy'
                                                    
                                                />
                                            <span className='JobActivity-Copy'
                                                   
                                                    >Copy contents</span>
                                                    
                                               
                                            </div>
                                            <div
                                            className={
                                                "ag-theme-quartz"
                                            }
                                            style={{ width: "98%", marginLeft: "10px" }}
                                        >
                                            <AgGridReact
                                            ref={gridRef}
                                            rowData={driverList}
                                            columnDefs={columns}
                                            suppressMenuHide={true}
                                            defaultColDef={defaultColDef}
                                            enableCellTextSelection={true}
                                            copyHeadersToClipboard={true}
                                          //  rowSelection={'single'}
                                            animateRows = {false}
                                            //onSelectionChanged={onSelectionChanged}
                                        />
                                        </div>
                                        </>
                                        )}
                                        {!loading && driverList.length === 0 && (
                                            <div>
         
                                               {/* <div
                                            className={
                                                "ag-theme-quartz"
                                            }
                                            style={{ width: "98%", height: '100%', marginLeft: "10px" }}
                                        >
                                                
                                                <AgGridReact
                                                ref={gridRef}
                                                rowData={[]}
                                                columnDefs={columns}
                                                suppressMenuHide={true}
                                                defaultColDef={defaultColDef}
                                                animateRows = {false}
                                                />
                                                </div> */}
                                                <div style={{ paddingTop: '15%' }}>
                                                    <NoResult isModal={false} />
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="JobManagement-Container">
                <CarrierDashboardHeader />
                <div className="JobManagement-Body">
                    <div id="jobsFilterContainer" className="JobManagement-Filter">
                        <CarrierDashboardFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                    </div>
                    <div id="jobsListContainer" className="JobManagement-List" style={{ height: listHeight }}>
                        <div className="Users-Bottom-Container table-div">
                        {/* <div
                            className={
                                "ag-theme-quartz"
                            }
                            style={{ width: "98%", height: '100%', marginLeft: "10px" }}
                        >   
                        <AgGridReact
                        ref={gridRef}
                        rowData={[]}
                        columnDefs={columns}
                        suppressMenuHide={true}
                        defaultColDef={defaultColDef}
                        animateRows = {false}
                        />
                        </div> */}
                        <div style={{ paddingTop: '15%' }}>
                            <NoResult isModal={false} />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

};

export default CarrierDashboard;
