import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as JobListActions from '../../../store/actions/jobList';
import * as DriverListActions from '../../../store/actions/driversList';
import { useStoreContext } from '../../App/StoreProvider';
import FilterItem from '../FilterItem';
import './FilterList.css';
import 'material-design-icons/iconfont/material-icons.css';

interface ListProps {
    abortRequest: () => void;
    filterType: string;
    items: FilterItem[];
    filterFrom?: string | null;
}

const Collection = ({ abortRequest, filterType, items, filterFrom }: ListProps): JSX.Element => {
    const halfLength = Math.ceil(items.length / 2);
    const leftSideItems = items.slice(0, halfLength);
    const rightSideItems = items.slice(halfLength, items.length);

    const leftSideRows: JSX.Element[] = [];
    for (let i = 0; i < leftSideItems.length; i++) {
        leftSideRows.push(
            <FilterCheckBox
                abortRequest={abortRequest}
                filterType={filterType}
                id={leftSideItems[i].id}
                key={i}
                title={leftSideItems[i].name}
                defaultChecked={leftSideItems[i].defaultChecked}
                filterFrom={filterFrom}
            />,
        );
    }

    const rightSideRows: JSX.Element[] = [];
    for (let i = 0; i < rightSideItems.length; i++) {
        rightSideRows.push(
            <FilterCheckBox
                abortRequest={abortRequest}
                filterType={filterType}
                id={rightSideItems[i].id}
                key={i}
                title={rightSideItems[i].name}
                filterFrom={filterFrom}
            />,
        );
    }

    return (
        <Container className="FilterList-Container">
            <Row className="FilterList-Row">
                <Col>{leftSideRows}</Col>
                <Col>{rightSideRows}</Col>
            </Row>
        </Container>
    );
};

const List = ({ abortRequest, filterType, items, filterFrom }: ListProps): JSX.Element => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < items.length; i++) {
        rows.push(
            <FilterCheckBox
                abortRequest={abortRequest}
                filterType={filterType}
                id={items[i].id}
                key={i}
                title={items[i].name}
                filterFrom={filterFrom}
            />,
        );
    }

    return (
        <Container className="FilterList-Container">
            <Col className="FilterList-Col">{rows}</Col>
        </Container>
    );
};

interface FilterCheckBoxProp {
    abortRequest: () => void;
    id: number;
    title: string;
    filterType: string;
    defaultChecked?: boolean;
    filterFrom?: string | null;
}

const FilterCheckBox = ({
    abortRequest,
    id,
    title,
    filterType,
    defaultChecked,
    filterFrom,
}: FilterCheckBoxProp): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const checkbox: React.Ref<HTMLInputElement> = React.createRef();

    
    // TODO: Extract this method's logic as not to violate DRY (8/6/19) - BS
    const isFilterChecked = (): boolean => {
        const {
            jobList: { filters },
            driverList: { regionIds, districtIds },
        } = state;

      //  console.log("onclick")
        if (filterType === 'Regions') {
            if (filterFrom == 'DriverList') {
                const found = regionIds.find((regionId): number | undefined => {
                    if (regionId === id) return regionId;

                    return undefined;
                });

                return !!found;
            } else {
                const found = filters.regionIds.find((regionId): number | undefined => {
                    if (regionId === id) return regionId;

                    return undefined;
                });

                return !!found;
            }
        }
        if (filterType === 'Districts') {
            if (filterFrom == 'DriverList') {
                const found = districtIds.find((districtId): number | undefined => {
                    if (districtId === id) return districtId;

                    return undefined;
                });

                return !!found;
            } else {
                const found = filters.districtIds.find((districtId): number | undefined => {
                    if (districtId === id) return districtId;

                    return undefined;
                });

                return !!found;
            }
        }
        if (filterType === 'Customers') {
            const found = filters.customerIds.find((customerId): number | undefined => {
                if (customerId === id) return customerId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Status') {
            const found = filters.statuses.find((status): number | undefined => {
                if (status === id) return status;

                return undefined;
            });

            return !!found;
        }
        // defaultChecked = false;
        const checkStatus = defaultChecked ? defaultChecked : false;
        return checkStatus;
    };

    const updateFilterFromCheckbox = (): void => {
        defaultChecked = false;
        const isSelected = checkbox && checkbox.current ? checkbox.current.checked : false;
        if (filterFrom == 'DriverList') {
            dispatch(DriverListActions.setSelectedFilters(filterType, id, isSelected));
        } else {
            dispatch(JobListActions.setFiltersForJobs(filterType, id, isSelected));
        }
    };

    return (
        <Row key={id} className="FilterCheckBox-Container">
            <input
                key={filterType + '_' + id}
                value={id}
                type="checkbox"
                className="FilterCheckBox-Checkbox"
                ref={checkbox}
                checked={isFilterChecked()}
                // defaultChecked={defaultChecked}
                onChange={(): void => {
                   // abortRequest();
                    updateFilterFromCheckbox();
                }}
            />
            <span className="FilterCheckBox-Title">{title}</span>
        </Row>
    );
};

export { Collection, List };
