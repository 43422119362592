import { Action } from '../../types';
import {
    GetFullJobProfile_getJobProfile_pumpScheduleSummary as PumpScheduleSummaryData,
    GetFullJobProfile_getJobProfile_pumpScheduleSummary_proppants as ProppantData,
} from '../../../types/schemaTypes';

export enum Actions {
    ADD_PROPPANT = 'PUMP_SCHEDULE_EDIT_ADD_PROPPANT',
    ADD_WELLHEAD = 'PUMP_SCHEDULE_EDIT_ADD_WELLHEAD',
    UPDATE_PROPPANT = 'PUMP_SCHEDULE_EDIT_UPDATE_PROPPANT',
    UPDATE_PROPPANT_VALUE = 'PUMP_SCHEDULE_EDIT_UPDATE_PROPPANT_VALUE',
    UPDATE_WELLHEAD = 'PUMP_SCHEDULE_EDIT_UPDATE_WELLHEAD',
    SET_INITIAL_DATA = 'PUMP_SCHEDULE_EDIT_SET_INITIAL_DATA',
    DELETE_PROPPANT = 'PUMP_SCHEDULE_EDIT_DELETE_PROPPANT',
    DELETE_WELLHEAD = 'PUMP_SCHEDULE_EDIT_DELETE_WELLHEAD',
    RE_INITIALIZE = 'PUMP_SCHEDULE_RE_INITALIZE',
    SET_FIELD_ERROR = 'PUMP_SCHEDULE_SET_FIELD_ERROR',
    RESET_FIELD_ERRORS = 'PUMP_SCHEDULE_RESET_FIELD_ERRORS',
}

export const addProppant = (wellhead: PumpScheduleSummaryData): Action => ({
    type: Actions.ADD_PROPPANT,
    payload: { wellhead },
    error: null,
});

export const addWellhead = (wellhead: PumpScheduleSummaryData): Action => ({
    type: Actions.ADD_WELLHEAD,
    payload: { wellhead },
    error: null,
});

export const updateProppant = (
    wellhead: PumpScheduleSummaryData,
    proppant: ProppantData,
    inputTitle: string,
    value: string | number | null | undefined,
): Action => ({
    type: Actions.UPDATE_PROPPANT,
    payload: { wellhead, proppant, inputTitle, value },
    error: null,
});

export const updateProppantValue = (
    wellhead: PumpScheduleSummaryData,
    proppant: ProppantData,
    proppantValue: { id: number | null; value: string | number | undefined | null },
): Action => ({
    type: Actions.UPDATE_PROPPANT_VALUE,
    payload: { wellhead, proppant, proppantValue },
    error: null,
});

export const deleteProppant = (wellhead: PumpScheduleSummaryData, proppant: ProppantData): Action => ({
    type: Actions.DELETE_PROPPANT,
    payload: { wellhead, proppant },
    error: null,
});

export const updateWellhead = (
    wellhead: PumpScheduleSummaryData,
    inputTitle: string,
    value: string | number | null | undefined,
): Action => ({
    type: Actions.UPDATE_WELLHEAD,
    payload: { wellhead, inputTitle, value },
    error: null,
});

export const deleteWellhead = (wellhead: PumpScheduleSummaryData): Action => ({
    type: Actions.DELETE_WELLHEAD,
    payload: { wellhead },
    error: null,
});

export const setInitialData = (data: PumpScheduleSummaryData[], newValues: any): Action => ({
    type: Actions.SET_INITIAL_DATA,
    payload: { data, newValues },
    error: null,
});

export const setPumpScheduleToInitialState = (): Action => ({
    type: Actions.RE_INITIALIZE,
    payload: [],
    error: null,
});

export const setFieldError = (
    fieldName: string,
    value: any,
    wellheadId: string,
    proppantId: string | null,
): Action => ({
    type: Actions.SET_FIELD_ERROR,
    payload: {
        fieldName,
        value,
        wellheadId,
        proppantId,
    },
    error: null,
});

export const resetFieldErrors = (): Action => ({
    type: Actions.RESET_FIELD_ERRORS,
    payload: {},
    error: null,
});
