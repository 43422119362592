import { Action } from '../../types';

export enum Actions {
    SET_SELECTED_TAB = 'JOB_PROFILE_SET_SELECTED_TAB',
}

export const setSelectedTab = (index: number): Action => {
    return {
        type: Actions.SET_SELECTED_TAB,
        payload: index,
        error: null,
    };
};
