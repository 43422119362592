import React from 'react';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import { Table } from 'react-bootstrap';
import { GET_LOAD_ALLOCATION_SUMMARY } from '../../../graphql/client/queries';
import {
    GetLoadAllocationSummary,
    GetLoadAllocationSummary_getLoadAllocationSummary as LoadAllocationSummary,
} from '../../../types/schemaTypes';
import { Loading, Error } from '../../_common';
import './DailySandAllocations.scss';

interface DailySandAllocationsSummaryProps {
    jobId: number;
    date: string;
    refreshed: boolean;
    setRefreshed: React.Dispatch<React.SetStateAction<boolean>>;
}

const DailySandAllocationsSummary = ({
    jobId,
    date,
    refreshed,
    setRefreshed,
}: DailySandAllocationsSummaryProps): JSX.Element => {
    const variables = {
        input: {
            jobId: jobId,
            date: date,
        },
    };

    return (
        <Query query={GET_LOAD_ALLOCATION_SUMMARY} variables={variables} fetchPolicy="no-cache">
            {({
                loading,
                error,
                data,
                refetch,
            }: QueryResult<GetLoadAllocationSummary | undefined>): JSX.Element | null => {
                if (loading) {
                    return (
                        <div className="DailySandAllocations-Spinner-Container">
                            <Loading />
                        </div>
                    );
                }

                if (refreshed) {
                    refetch();
                    setRefreshed(false);
                }

                if (error) {
                    return <Error error={error} />;
                }

                const loadAllocationSummaryObj = {};
                if (data && data.getLoadAllocationSummary && data.getLoadAllocationSummary.length !== 0) {
                    data.getLoadAllocationSummary.forEach((element: LoadAllocationSummary | null): void => {
                        if (element !== null && element.proppantName !== null) {
                            if (element.proppantName in loadAllocationSummaryObj) {
                                const temp: LoadAllocationSummary[] = loadAllocationSummaryObj[element.proppantName];
                                temp.push(element);
                                loadAllocationSummaryObj[element.proppantName] = temp;
                            } else {
                                const temp: LoadAllocationSummary[] = [];
                                temp.push(element);
                                loadAllocationSummaryObj[element.proppantName] = temp;
                            }
                        }
                    });
                }

                return (
                    <div>
                        {Object.keys(loadAllocationSummaryObj).length !== 0 ? (
                            <Table bordered className="DailySandAllocationSummaryTable">
                                <thead>
                                    <tr className="DailySandAllocationSummaryTable-Header">
                                        <th>Proppant</th>
                                        <th>Loading Facility</th>
                                        <th>Sand PO</th>
                                        <th>Planned Weight</th>
                                        <th>Dispatched Weight</th>
                                        <th>Remaining Weight</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {Object.keys(loadAllocationSummaryObj).map(proppant => {
                                        const loadAllocationSummaries: LoadAllocationSummary[] =
                                            loadAllocationSummaryObj[proppant];
                                        let plannedWeightTotal = 0;
                                        let dispatchWeightTotal = 0;
                                        let actualWeightTotal = 0;
                                        let remainingWeightTotal = 0;
                                        return (
                                            <>
                                                {loadAllocationSummaries.map((loadAllocationSummary, index) => {
                                                    loadAllocationSummary.plannedWeight &&
                                                        (plannedWeightTotal =
                                                            plannedWeightTotal + loadAllocationSummary.plannedWeight);
                                                    loadAllocationSummary.dispatchedWeight &&
                                                        (dispatchWeightTotal =
                                                            dispatchWeightTotal +
                                                            loadAllocationSummary.dispatchedWeight);
                                                    loadAllocationSummary.actualWeight &&
                                                        (actualWeightTotal =
                                                            actualWeightTotal + loadAllocationSummary.actualWeight);
                                                    loadAllocationSummary.remainingWeight &&
                                                        (remainingWeightTotal =
                                                            remainingWeightTotal +
                                                            loadAllocationSummary.remainingWeight);
                                                    return (
                                                        <tr key={index}>
                                                            {index === 0 && (
                                                                <td
                                                                    className="DailySandAllocationsSummary-Proppant"
                                                                    rowSpan={loadAllocationSummaries.length}
                                                                >
                                                                    {proppant}
                                                                </td>
                                                            )}
                                                            <td>{loadAllocationSummary.lofaName}</td>
                                                            <td>{loadAllocationSummary.sandPO}</td>
                                                            <td>{loadAllocationSummary.plannedWeight?.toFixed(2)}</td>
                                                            <td>
                                                                {loadAllocationSummary.dispatchedWeight?.toFixed(2)}
                                                            </td>
                                                            <td>{loadAllocationSummary.remainingWeight?.toFixed(2)}</td>
                                                        </tr>
                                                    );
                                                })}

                                                <tr className="DailySandAllocations-Footer">
                                                    <td className="DailySandAllocationsSummary-Total">{`Total ${proppant}`}</td>
                                                    <td></td>
                                                    <td></td>
                                                    <td className="DailySandAllocationsSummary-Total">
                                                        {plannedWeightTotal?.toFixed(2)}
                                                    </td>
                                                    <td className="DailySandAllocationsSummary-Total">
                                                        {dispatchWeightTotal?.toFixed(2)}
                                                    </td>
                                                    <td className="DailySandAllocationsSummary-Total">
                                                        {actualWeightTotal?.toFixed(2)}
                                                    </td>
                                                    <td className="DailySandAllocationsSummary-Total">
                                                        {remainingWeightTotal?.toFixed(2)}
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        ) : (
                            <div className="NoResult-Second-Title-Primary">No Sand Allocation Summary found.</div>
                        )}
                    </div>
                );
            }}
        </Query>
    );
};

export default DailySandAllocationsSummary;
