import React, { useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './PumpScheduleSummary.css';
import PumpScheduleDisplayMode from './PumpScheduleDisplayMode';
import { GetFullJobProfile_getJobProfile_pumpScheduleSummary as PumpScheduleSummaryData } from '../../../types/schemaTypes';

interface PumpScheduleSummaryProps {
    pumpScheduleSummaryList: PumpScheduleSummaryData[];
    isJobCompleted: boolean;
}

const PumpScheduleSummary = ({ pumpScheduleSummaryList, isJobCompleted }: PumpScheduleSummaryProps): JSX.Element => {
    return (
        <div className="PumpScheduleSummary-Container">
            <PumpScheduleDisplayMode
                pumpScheduleSummaryList={pumpScheduleSummaryList}
                isJobCompleted={isJobCompleted}
            />
        </div>
    );
};

export default PumpScheduleSummary;
