/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: DeleteB2CUser
// ====================================================

export interface DeleteB2CUser_deleteB2CUser {
  __typename: "DeleteB2CUserResponse";
  errors: (string | null)[] | null;
}

export interface DeleteB2CUser {
  deleteB2CUser: DeleteB2CUser_deleteB2CUser | null;
}

export interface DeleteB2CUserVariables {
  input?: DeleteB2CUserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: SaveB2CUser
// ====================================================

export interface SaveB2CUser_saveB2CUser {
  __typename: "SaveB2CUserResponse";
  errors: (string | null)[] | null;
}

export interface SaveB2CUser {
  saveB2CUser: SaveB2CUser_saveB2CUser | null;
}

export interface SaveB2CUserVariables {
  input?: SaveB2CUserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL mutation operation: updateDriver
// ====================================================

export interface updateDriver_updateDriver {
  __typename: "UpdateDriverResponse";
  errors: (string | null)[] | null;
}

export interface updateDriver {
  updateDriver: updateDriver_updateDriver | null;
}

export interface updateDriverVariables {
  input?: UpdateDriverInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckIsJobAccessible
// ====================================================

export interface CheckIsJobAccessible_checkIsJobAccessible {
  __typename: "CheckIsJobAccessible";
  isAccessible: boolean | null;
}

export interface CheckIsJobAccessible {
  checkIsJobAccessible: CheckIsJobAccessible_checkIsJobAccessible | null;
}

export interface CheckIsJobAccessibleVariables {
  input?: CheckIsJobAccessibleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CheckIsLoadAccessible
// ====================================================

export interface CheckIsLoadAccessible_checkIsLoadAccessible {
  __typename: "CheckIsLoadAccessible";
  isAccessible: boolean | null;
}

export interface CheckIsLoadAccessible {
  checkIsLoadAccessible: CheckIsLoadAccessible_checkIsLoadAccessible | null;
}

export interface CheckIsLoadAccessibleVariables {
  input?: CheckIsLoadAccessibleInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountLoadsByJobProppant
// ====================================================

export interface CountLoadsByJobProppant_countLoadsByJobProppant {
  __typename: "Count";
  count: number;
}

export interface CountLoadsByJobProppant {
  countLoadsByJobProppant: CountLoadsByJobProppant_countLoadsByJobProppant;
}

export interface CountLoadsByJobProppantVariables {
  input?: CountLoadsByJobProppantInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountLoadsByJobSummaryIdsAndStatus
// ====================================================

export interface CountLoadsByJobSummaryIdsAndStatus_countLoadsByJobSummaryIdsAndStatus {
  __typename: "Count";
  count: number;
}

export interface CountLoadsByJobSummaryIdsAndStatus {
  countLoadsByJobSummaryIdsAndStatus: CountLoadsByJobSummaryIdsAndStatus_countLoadsByJobSummaryIdsAndStatus;
}

export interface CountLoadsByJobSummaryIdsAndStatusVariables {
  input?: CountLoadsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: CountLoadsByJobWellhead
// ====================================================

export interface CountLoadsByJobWellhead_countLoadsByJobWellhead {
  __typename: "Count";
  count: number;
}

export interface CountLoadsByJobWellhead {
  countLoadsByJobWellhead: CountLoadsByJobWellhead_countLoadsByJobWellhead;
}

export interface CountLoadsByJobWellheadVariables {
  input?: CountLoadsByJobWellheadInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAccessorialForLoadDetail
// ====================================================

export interface GetAccessorialForLoadDetail_getAccessorialForLoadDetail {
  __typename: "ViewAccessorial";
  accessorialId: number;
  accessorialName: string | null;
  isDeleted: boolean | null;
  extSystemName: string | null;
  extSystemReference: string | null;
}

export interface GetAccessorialForLoadDetail {
  getAccessorialForLoadDetail: (GetAccessorialForLoadDetail_getAccessorialForLoadDetail | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetActivitySymmary
// ====================================================

export interface GetActivitySymmary_getActivitySummary {
  __typename: "ActivitySummaryResponse";
  proppantName: string | null;
  lofaName: string | null;
  wellName: string | null;
  created: number | null;
  accepted: number | null;
  assigned: number | null;
  loading: number | null;
  loaded: number | null;
  unloading: number | null;
  totalActiveTrucks: number | null;
  proppantId: number | null;
  lofaId: number | null;
  regionId: number | null;
  sandPO: string | null;
}

export interface GetActivitySymmary {
  getActivitySummary: (GetActivitySymmary_getActivitySummary | null)[] | null;
}

export interface GetActivitySymmaryVariables {
  input?: ActivitySummaryInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllAccessorials
// ====================================================

export interface GetAllAccessorials_getAllAccessorials {
  __typename: "Accessorial";
  accessorialId: number;
  accessorialName: string | null;
  isDeleted: boolean | null;
}

export interface GetAllAccessorials {
  getAllAccessorials: (GetAllAccessorials_getAllAccessorials | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCarriers
// ====================================================

export interface GetAllCarriers_getAllCarriers {
  __typename: "CarrierMasterData";
  id: number | null;
  companyName: string | null;
  type: string | null;
  stateId: number | null;
  isActive: boolean | null;
  extSystemIdentifier: string | null;
  extSystemName: string | null;
  activeInactiveDate: string | null;
  activeInactiveUser: string | null;
  name: string | null;
  addressLine1: string | null;
  addressLine2: string | null;
  notes: string | null;
  city: string | null;
  zip: string | null;
  phoneNumber: string | null;
  email: string | null;
}

export interface GetAllCarriers {
  getAllCarriers: (GetAllCarriers_getAllCarriers | null)[] | null;
}

export interface GetAllCarriersVariables {
  input?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCarriersFilter
// ====================================================

export interface GetAllCarriersFilter_getAllCarriersFilter {
  __typename: "FilterObjectModel_Carrier";
  id: number;
  name: string;
}

export interface GetAllCarriersFilter {
  getAllCarriersFilter: (GetAllCarriersFilter_getAllCarriersFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCrewFiltered
// ====================================================

export interface GetAllCrewFiltered_getAllCrewFiltered {
  __typename: "Crew";
  id: number;
  crewNumber: string | null;
  customerId: number;
  customerName: string | null;
  isDeleted: boolean | null;
}

export interface GetAllCrewFiltered {
  getAllCrewFiltered: (GetAllCrewFiltered_getAllCrewFiltered | null)[] | null;
}

export interface GetAllCrewFilteredVariables {
  input?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCrews
// ====================================================

export interface GetAllCrews_getAllCrews {
  __typename: "Crew";
  id: number;
  crewNumber: string | null;
  customerId: number;
  isDeleted: boolean | null;
}

export interface GetAllCrews {
  getAllCrews: (GetAllCrews_getAllCrews | null)[] | null;
}

export interface GetAllCrewsVariables {
  customerId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCustomerParents
// ====================================================

export interface GetAllCustomerParents_getAllCustomerParents {
  __typename: "CustomerParent";
  id: number | null;
  customerName: string | null;
  enabled: boolean | null;
  isDeleted: boolean | null;
  extSystemName: string | null;
  extSystemIdentifier: string | null;
}

export interface GetAllCustomerParents {
  getAllCustomerParents: (GetAllCustomerParents_getAllCustomerParents | null)[] | null;
}

export interface GetAllCustomerParentsVariables {
  input?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCustomers
// ====================================================

export interface GetAllCustomers_getAllCustomers {
  __typename: "Customer";
  id: number | null;
  customerName: string | null;
  description: string | null;
  enabled: boolean | null;
  isDeleted: boolean | null;
  extSystemName: string | null;
  extSystemIdentifier: string | null;
  industryClassificationCode: string | null;
  customerParentId: number | null;
}

export interface GetAllCustomers {
  getAllCustomers: (GetAllCustomers_getAllCustomers | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllCustomersFiltered
// ====================================================

export interface GetAllCustomersFiltered_getAllCustomersFiltered {
  __typename: "Customer";
  id: number | null;
  customerName: string | null;
  description: string | null;
  enabled: boolean | null;
  isDeleted: boolean | null;
  extSystemName: string | null;
  extSystemIdentifier: string | null;
  industryClassificationCode: string | null;
  customerParentId: number | null;
}

export interface GetAllCustomersFiltered {
  getAllCustomersFiltered: (GetAllCustomersFiltered_getAllCustomersFiltered | null)[] | null;
}

export interface GetAllCustomersFilteredVariables {
  input?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverCompanies
// ====================================================

export interface GetDriverCompanies_getDriverCompanies {
  __typename: "DriverCompany";
  id: number | null;
  companyName: string | null;
}

export interface GetDriverCompanies {
  getDriverCompanies: (GetDriverCompanies_getDriverCompanies | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDriversByCarriers
// ====================================================

export interface GetAllDriversByCarriers_getAllDriversByCarriers {
  __typename: "FilterObjectModel_Driver";
  id: number;
  name: string;
}

export interface GetAllDriversByCarriers {
  getAllDriversByCarriers: (GetAllDriversByCarriers_getAllDriversByCarriers | null)[] | null;
}

export interface GetAllDriversByCarriersVariables {
  input?: CarrierListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDrivers
// ====================================================

export interface GetAllDrivers_getAllDrivers {
  __typename: "DriverListItem";
  driverId: number;
  driverName: string;
  driverCompany: string | null;
  regionId: number;
}

export interface GetAllDrivers {
  getAllDrivers: (GetAllDrivers_getAllDrivers | null)[] | null;
}

export interface GetAllDriversVariables {
  regionId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDriversFilter
// ====================================================

export interface GetAllDriversFilter_getAllDriversFilter {
  __typename: "FilterObjectModel_Driver";
  id: number;
  name: string;
}

export interface GetAllDriversFilter {
  getAllDriversFilter: (GetAllDriversFilter_getAllDriversFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDriversListByFilter
// ====================================================

export interface GetAllDriversListByFilter_getAllDriversListByFilter {
  __typename: "DriverDetails";
  id: number | null;
  name: string | null;
  regionName: string | null;
  districtName: string | null;
  isnetId: string | null;
  isCompliant: string | null;
  driverActiveDate: string | null;
  activeInactiveUser: string | null;
  active: boolean | null;
  companyName: string | null;
  cellPhone: string | null;
  status: boolean | null;
  cdlNumber: string | null;
  regionIds: string | null;
  districtIds: string | null;
}

export interface GetAllDriversListByFilter {
  getAllDriversListByFilter: (GetAllDriversListByFilter_getAllDriversListByFilter | null)[] | null;
}

export interface GetAllDriversListByFilterVariables {
  input?: GetAllDriversListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllDriversMasterData
// ====================================================

export interface GetAllDriversMasterData_getAllDriversMasterData {
  __typename: "DriverMasterData";
  driverId: number;
  name: string;
  regionName: string | null;
  districtName: string | null;
  isActive: boolean | null;
  cellPhone: string | null;
  cdlNumber: string | null;
  activeInactiveDate: string | null;
  activeInactiveUser: string | null;
  companyName: string | null;
  kronosId: string | null;
  driverType: string | null;
  cdlExpiryDate: string | null;
  driverCompanyId: number | null;
  isAssigned: boolean | null;
  isManual: boolean | null;
  isAvailable: boolean | null;
  isShiftStarted: boolean | null;
  regionIds: string | null;
  districtIds: string | null;
  isLocked: boolean | null;
  extSystemIdentifier: string | null;
  extSystemName: string | null;
  inactiveReason: string | null;
  reactivationDate: string | null;
  loadId: string | null;
  status: string | null;
}

export interface GetAllDriversMasterData {
  getAllDriversMasterData: (GetAllDriversMasterData_getAllDriversMasterData | null)[] | null;
}

export interface GetAllDriversMasterDataVariables {
  input?: DriverListInput | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllEpCompanies
// ====================================================

export interface GetAllEpCompanies_getAllEpCompanies {
  __typename: "EpCompany";
  id: number;
  companyName: string | null;
}

export interface GetAllEpCompanies {
  getAllEpCompanies: (GetAllEpCompanies_getAllEpCompanies | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllJobsFilter
// ====================================================

export interface GetAllJobsFilter_getAllJobsFilter {
  __typename: "FilterObjectModel_Job";
  id: number;
  name: string;
}

export interface GetAllJobsFilter {
  getAllJobsFilter: (GetAllJobsFilter_getAllJobsFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllJobsWithFiltersAndSearch
// ====================================================

export interface GetAllJobsWithFiltersAndSearch_getAllJobsWithFiltersAndSearch {
  __typename: "JobSummary";
  jobId: string;
  jobName: string;
  jdeNumber: string;
  region: string;
  district: string;
  customerName: string;
  startDate: string;
  endDate: string;
  estimatedEndDate: string;
  status: Status;
}

export interface GetAllJobsWithFiltersAndSearch {
  getAllJobsWithFiltersAndSearch: (GetAllJobsWithFiltersAndSearch_getAllJobsWithFiltersAndSearch | null)[] | null;
}

export interface GetAllJobsWithFiltersAndSearchVariables {
  input?: GetAllJobsInput | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllLofas
// ====================================================

export interface GetAllLofas_getAllLofas {
  __typename: "Lofa";
  lofaId: number | null;
  lofaName: string | null;
}

export interface GetAllLofas {
  getAllLofas: (GetAllLofas_getAllLofas | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllLofasByJob
// ====================================================

export interface GetAllLofasByJob_getAllLofasByJob {
  __typename: "Lofa";
  lofaId: number | null;
  lofaName: string | null;
}

export interface GetAllLofasByJob {
  getAllLofasByJob: (GetAllLofasByJob_getAllLofasByJob | null)[] | null;
}

export interface GetAllLofasByJobVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllLofasFiltered
// ====================================================

export interface GetAllLofasFiltered_getAllLofasFiltered {
  __typename: "LofaFiltered";
  id: number | null;
  lofaName: string | null;
  addressStreet: string | null;
  city: string | null;
  zip: string | null;
  phoneNumber: string | null;
  jdeNumber: string | null;
  description: string | null;
  stateId: number | null;
  utm: string | null;
  latitude: number | null;
  longitude: number | null;
  directions: string | null;
}

export interface GetAllLofasFiltered {
  getAllLofasFiltered: (GetAllLofasFiltered_getAllLofasFiltered | null)[] | null;
}

export interface GetAllLofasFilteredVariables {
  input?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllProppants
// ====================================================

export interface GetAllProppants_getAllProppants {
  __typename: "Proppant";
  proppantId: number | null;
  proppantName: string | null;
  extSystemName: string | null;
  extSystemReference: string | null;
}

export interface GetAllProppants {
  getAllProppants: (GetAllProppants_getAllProppants | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllProppantsByJob
// ====================================================

export interface GetAllProppantsByJob_getAllProppantsByJob {
  __typename: "Proppant";
  proppantId: number | null;
  proppantName: string | null;
}

export interface GetAllProppantsByJob {
  getAllProppantsByJob: (GetAllProppantsByJob_getAllProppantsByJob | null)[] | null;
}

export interface GetAllProppantsByJobVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllRegions
// ====================================================

export interface GetAllRegions_getAllRegions_districts {
  __typename: "District";
  id: number;
  districtName: string | null;
  regionId: number;
}

export interface GetAllRegions_getAllRegions {
  __typename: "Region";
  id: number;
  regionName: string | null;
  regionCode: number | null;
  districts: (GetAllRegions_getAllRegions_districts | null)[] | null;
}

export interface GetAllRegions {
  getAllRegions: (GetAllRegions_getAllRegions | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllStates
// ====================================================

export interface GetAllStates_getAllStates {
  __typename: "StateDetails";
  id: number | null;
  stateAcronym: string | null;
  stateName: string | null;
}

export interface GetAllStates {
  getAllStates: (GetAllStates_getAllStates | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetAllStatusFilter
// ====================================================

export interface GetAllStatusFilter_getAllStatusFilter {
  __typename: "FilterObjectModel_Statuses";
  id: number;
  name: string;
}

export interface GetAllStatusFilter {
  getAllStatusFilter: (GetAllStatusFilter_getAllStatusFilter | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserCustomerList
// ====================================================

export interface GetUserCustomerList_getUserCustomerList {
  __typename: "UserCustomer";
  customerId: number | null;
  customerName: string | null;
}

export interface GetUserCustomerList {
  getUserCustomerList: (GetUserCustomerList_getUserCustomerList | null)[] | null;
}

export interface GetUserCustomerListVariables {
  input?: GetUserCustomerListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserDriverCompanyList
// ====================================================

export interface GetUserDriverCompanyList_getUserDriverCompanyList {
  __typename: "UserDriverCompany";
  driverCompanyId: number | null;
  driverCompanyName: string | null;
}

export interface GetUserDriverCompanyList {
  getUserDriverCompanyList: (GetUserDriverCompanyList_getUserDriverCompanyList | null)[] | null;
}

export interface GetUserDriverCompanyListVariables {
  input?: GetUserDriverCompanyListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUserRoleList
// ====================================================

export interface GetUserRoleList_getUserRoleList {
  __typename: "UserRole";
  roleId: number | null;
  roleName: string | null;
}

export interface GetUserRoleList {
  getUserRoleList: (GetUserRoleList_getUserRoleList | null)[] | null;
}

export interface GetUserRoleListVariables {
  input?: GetUserRoleListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetB2CUserFiltered
// ====================================================

export interface GetB2CUserFiltered_getB2CUserFiltered {
  __typename: "B2CUser";
  userId: number | null;
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  roleName: string | null;
  roleId: number | null;
  customerParentId: number | null;
  isActive: boolean | null;
  driverCompanyId: number | null;
  driverCompanyName: string | null;
}

export interface GetB2CUserFiltered {
  getB2CUserFiltered: (GetB2CUserFiltered_getB2CUserFiltered | null)[] | null;
}

export interface GetB2CUserFilteredVariables {
  input?: GetB2cUserInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetB2CUsers
// ====================================================

export interface GetB2CUsers_getB2CUsers {
  __typename: "B2CUser";
  userId: number | null;
  firstName: string | null;
  lastName: string | null;
  emailAddress: string | null;
  roleName: string | null;
  roleId: number | null;
  customerParentId: number | null;
  isActive: boolean | null;
  driverCompanyId: number | null;
  driverCompanyName: string | null;
}

export interface GetB2CUsers {
  getB2CUsers: (GetB2CUsers_getB2CUsers | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetCarrier
// ====================================================

export interface GetCarrier_getCarriers {
  __typename: "CarrierDetail";
  id: number;
  companyName: string;
  companyType: string | null;
}

export interface GetCarrier {
  getCarriers: (GetCarrier_getCarriers | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverActivity
// ====================================================

export interface GetDriverActivity_getDriverActivity_notes {
  __typename: "DriverLoadNote";
  id: number | null;
  loadId: number | null;
  notes: string | null;
  createDate: string | null;
  name: string | null;
}

export interface GetDriverActivity_getDriverActivity {
  __typename: "DriverActivityResponse";
  driverName: string | null;
  lofaName: string | null;
  status: string | null;
  acceptTime: string | null;
  etaLoading: string | null;
  etaLoaded: string | null;
  etaOnsite: string | null;
  etaDelivered: string | null;
  loadId: number | null;
  overdueAtLofaIn: string | null;
  overdueAtLofaOut: string | null;
  overdueAtDestinationIn: string | null;
  overdueAtDestinationOut: string | null;
  isOverdue: string | null;
  proppantName: string | null;
  notes: (GetDriverActivity_getDriverActivity_notes | null)[] | null;
}

export interface GetDriverActivity {
  getDriverActivity: (GetDriverActivity_getDriverActivity | null)[] | null;
}

export interface GetDriverActivityVariables {
  input?: DriverActivityInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverByCellPhone
// ====================================================

export interface GetDriverByCellPhone_getDriverByCellPhone {
  __typename: "DriverByCellPhoneOutput";
  driverId: number;
  driverName: string | null;
}

export interface GetDriverByCellPhone {
  getDriverByCellPhone: (GetDriverByCellPhone_getDriverByCellPhone | null)[] | null;
}

export interface GetDriverByCellPhoneVariables {
  input?: CellPhoneInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: getDriverCanBeSetInactive
// ====================================================

export interface getDriverCanBeSetInactive_getDriverCanBeSetInactive {
  __typename: "DriverInactive";
  canBeSetInactive: boolean | null;
}

export interface getDriverCanBeSetInactive {
  getDriverCanBeSetInactive: getDriverCanBeSetInactive_getDriverCanBeSetInactive;
}

export interface getDriverCanBeSetInactiveVariables {
  carrierId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverCompanyGrade
// ====================================================

export interface GetDriverCompanyGrade_getDriverCompanyGrade {
  __typename: "DriverCompanyGrade";
  grade: string | null;
}

export interface GetDriverCompanyGrade {
  getDriverCompanyGrade: GetDriverCompanyGrade_getDriverCompanyGrade | null;
}

export interface GetDriverCompanyGradeVariables {
  id?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverCompanyReferenceByDriver
// ====================================================

export interface GetDriverCompanyReferenceByDriver_getDriverCompanyReferenceByDriver {
  __typename: "DriverCompanyReference";
  id: number | null;
  driverId: number | null;
  driverCompanyId: number | null;
  updatedDate: string | null;
  updatedBy: string | null;
  companyName: string | null;
}

export interface GetDriverCompanyReferenceByDriver {
  getDriverCompanyReferenceByDriver: (GetDriverCompanyReferenceByDriver_getDriverCompanyReferenceByDriver | null)[] | null;
}

export interface GetDriverCompanyReferenceByDriverVariables {
  driverId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverDistance
// ====================================================

export interface GetDriverDistance_getDriverDistance {
  __typename: "DistanceDriverResponse";
  driverId: number | null;
  driverLat: number | null;
  driverLong: number | null;
  wellLat: number | null;
  wellLong: number | null;
  lofaLat: number | null;
  lofaLong: number | null;
  distanceToLofa: number | null;
  distanceToWellsite: number | null;
  timeToDestinationInSeconds: number | null;
  timeToDestinationInMinutes: number | null;
}

export interface GetDriverDistance {
  getDriverDistance: GetDriverDistance_getDriverDistance | null;
}

export interface GetDriverDistanceVariables {
  input?: DistanceDriverInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverLocations
// ====================================================

export interface GetDriverLocations_getDriverLocations {
  __typename: "DriverLocationsResponse";
  latitude: number | null;
  longitude: number | null;
  locationTimestamp: string | null;
}

export interface GetDriverLocations {
  getDriverLocations: (GetDriverLocations_getDriverLocations | null)[] | null;
}

export interface GetDriverLocationsVariables {
  input?: DriverLocationsInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriversByFilter
// ====================================================

export interface GetDriversByFilter_getDriversByFilter_notes {
  __typename: "DriverNote";
  id: number | null;
  loadId: number | null;
  notes: string | null;
  createDate: string | null;
  name: string | null;
}

export interface GetDriversByFilter_getDriversByFilter {
  __typename: "Driver";
  driverId: number;
  driverCompletedId: number;
  driverRejectedId: number;
  driverRequestedId: number;
  driverName: string;
  lastLoadDelivered: number;
  lastLoadRequested: number;
  lastLoadRejected: number;
  onShift: boolean;
  onShiftTime: number;
  isActive: boolean | null;
  loadId: number;
  onLoad: boolean;
  assigned: boolean;
  waitingOnLoad: boolean;
  notes: (GetDriversByFilter_getDriversByFilter_notes | null)[] | null;
}

export interface GetDriversByFilter {
  getDriversByFilter: (GetDriversByFilter_getDriversByFilter | null)[] | null;
}

export interface GetDriversByFilterVariables {
  input?: GetAllDriversInput | null;
}


/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetChassisCheckedout
// ====================================================


export interface GetChassisCheckedout_getChassisCheckedout {
  __typename: "ChassisCheckout";
  sbsId: string | null;
  trailerId: number | null;
  checkedOutToDriverCompanyID: string | null;
  checkedOutDate: string | null;
  lastDeliveredLoadID: string | null;
  currentLoadId: string | null;
  locationLatitude: number | null;
  locationLongitude: number | null;
  lastLoadDeliveredDateTime: string | null;
  lastLoadDeliveredDriverCompanyId: number | null;
  lastLoadDeliveredDriver: string | null;
  currentLoadAcceptedDateTime: string | null;
  currentLoadDriverCompanyId: number | null;
  currentLoadDriver: string | null;
}

export interface GetChassisCheckedout {
  getChassisCheckedout: (GetChassisCheckedout_getChassisCheckedout | null)[] | null;
}

export interface GetChassisCheckedoutVariables {
  input?: GetChasssisCheckedoutInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFilteredLoadManagements
// ====================================================

export interface GetFilteredLoadManagements_getFilteredLoadManagements {
  __typename: "LoadManagementLookup";
  atLofaIn: string | null;
  atLofaOut: string | null;
  atDestinationIn: string | null;
  atDestinationOut: string | null;
  truckNumber: string | null;
  chassisNumber: string | null;
  sandTicket: string;
  transportationPo: string | null;
  jobSummaryId: number;
  loadId: number;
  boxNumber: string;
  carrierName: string | null;
  driverCompanyId: number | null;
  driverId: number | null;
  driverName: string | null;
  driverLoadStatus: string | null;
  loadingFacility: string | null;
  loadNumber: number;
  proppantName: string | null;
  status: string;
  weight: number | null;
  regionName: string | null;
  isSplit: boolean | null;
  jobId: number | null;
  districtName: string | null;
  customerName: string | null;
  regionId: number | null;
  sandPO: string | null;
  secondaryDriverId: number | null;
  secondaryDriverLoadStatus: string | null;
  jobName: string | null;
  rejectReason: string | null;
  assignedDateTime: string | null;
  rejectedDateTime: string | null;
  userName: string | null;
  rejectedDriverName: string | null;
}

export interface GetFilteredLoadManagements {
  getFilteredLoadManagements: (GetFilteredLoadManagements_getFilteredLoadManagements | null)[] | null;
}

export interface GetFilteredLoadManagementsVariables {
  input?: GetFilteredLoadListInput | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetDriverFilters
// ====================================================

export interface GetDriverFilters_getDriverFilters_statuses {
  __typename: "DriverFilterObjectModel_Status";
  id: number;
  name: string;
}

export interface GetDriverFilters_getDriverFilters_regions {
  __typename: "DriverFilterObjectModel_Region";
  id: number;
  name: string;
}

export interface GetDriverFilters_getDriverFilters_districts {
  __typename: "DriverFilterObjectModel_District";
  id: number;
  name: string;
}

export interface GetDriverFilters_getDriverFilters {
  __typename: "DriverFilterList";
  statuses: GetDriverFilters_getDriverFilters_statuses[];
  regions: GetDriverFilters_getDriverFilters_regions[];
  districts: GetDriverFilters_getDriverFilters_districts[];
}

export interface GetDriverFilters {
  getDriverFilters: GetDriverFilters_getDriverFilters | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobFiltersList
// ====================================================

export interface GetJobFiltersList_getJobFiltersList_statuses {
  __typename: "FilterObjectModel_Status";
  id: number;
  name: string;
}

export interface GetJobFiltersList_getJobFiltersList_regions {
  __typename: "FilterObjectModel_Region";
  id: number;
  name: string;
}

export interface GetJobFiltersList_getJobFiltersList {
  __typename: "JobFilterList";
  statuses: GetJobFiltersList_getJobFiltersList_statuses[];
  regions: GetJobFiltersList_getJobFiltersList_regions[];
}

export interface GetJobFiltersList {
  getJobFiltersList: GetJobFiltersList_getJobFiltersList;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobImages
// ====================================================

export interface GetJobImages_getJobImages {
  __typename: "jobImages";
  id: number | null;
  name: string | null;
  imageName: string | null;
  uploadedBy: string | null;
  uploadDate: string | null;
  downloadLink: string | null;
}

export interface GetJobImages {
  getJobImages: (GetJobImages_getJobImages | null)[] | null;
}

export interface GetJobImagesVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobNotes
// ====================================================

export interface GetJobNotes_getJobNotes {
  __typename: "JobNotesUserDetails";
  notesId: number | null;
  notes: string | null;
  createdAt: string | null;
  modifiedAt: string | null;
  createdUserId: number | null;
  jobId: number | null;
  isDeleted: boolean | null;
  name: string | null;
  userName: string | null;
  email: string | null;
}

export interface GetJobNotes {
  getJobNotes: (GetJobNotes_getJobNotes | null)[] | null;
}

export interface GetJobNotesVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetFullJobProfile
// ====================================================

export interface GetFullJobProfile_getJobProfile_customerDetails {
  __typename: "CustomerDetails";
  customerId: number | null;
  customerName: string | null;
  customerPO: string | null;
  epCompany: string | null;
  crew: string | null;
  contact: string | null;
  phoneNumber: string | null;
}

export interface GetFullJobProfile_getJobProfile_jobDetails_district {
  __typename: "DistrictDetails";
  districtName: string | null;
  id: number | null;
  regionId: number | null;
  extSystemName: string | null;
  extSystemReference: string | null;
  districtAbbreviation: string | null;
}

export interface GetFullJobProfile_getJobProfile_jobDetails_region {
  __typename: "RegionDetails";
  regionName: string | null;
  id: number | null;
  extSystemName: string | null;
  extSystemReference: string | null;
  regionCode: string | null;
}

export interface GetFullJobProfile_getJobProfile_jobDetails_state {
  __typename: "StateDetails";
  id: number | null;
  stateName: string | null;
  stateAcronym: string | null;
}

export interface GetFullJobProfile_getJobProfile_jobDetails_status {
  __typename: "StatusDetails";
  statusId: number | null;
  status: string | null;
}

export interface GetFullJobProfile_getJobProfile_jobDetails {
  __typename: "JobDetails";
  addressLine1: string | null;
  addressLine2: string | null;
  city: string | null;
  contact: string | null;
  directions: string | null;
  district: GetFullJobProfile_getJobProfile_jobDetails_district | null;
  jdeNumber: string | null;
  jobId: number | null;
  jobName: string | null;
  latitude: number | null;
  longitude: number | null;
  mobDate: string | null;
  region: GetFullJobProfile_getJobProfile_jobDetails_region | null;
  state: GetFullJobProfile_getJobProfile_jobDetails_state | null;
  status: GetFullJobProfile_getJobProfile_jobDetails_status | null;
  wellEndDate: string | null;
  wellStartDate: string | null;
  zip: string | null;
  crewId: number | null;
  epCompanyId: number | null;
  accessorialId: number | null;
  miles: string | null;
  accessorialName: string | null;
}

export interface GetFullJobProfile_getJobProfile_pumpScheduleSummary_proppants {
  __typename: "PumpScheduleProppant";
  id: string;
  pumpScheduleId: number | null;
  proppantId: number | null;
  proppantName: string | null;
  totalVolume: number | null;
  volumePerBox: number | null;
  volumePerStage: number | null;
  boxesPerStage: number | null;
  boxesPerWell: number | null;
}

export interface GetFullJobProfile_getJobProfile_pumpScheduleSummary {
  __typename: "Wellhead";
  id: string;
  stages: number | null;
  stageTime: number | null;
  totalBoxes: number | null;
  wellName: string | null;
  proppants: (GetFullJobProfile_getJobProfile_pumpScheduleSummary_proppants | null)[] | null;
}

export interface GetFullJobProfile_getJobProfile {
  __typename: "JobProfile";
  totalBoxesForWells: number | null;
  customerDetails: GetFullJobProfile_getJobProfile_customerDetails | null;
  jobDetails: GetFullJobProfile_getJobProfile_jobDetails | null;
  pumpScheduleSummary: (GetFullJobProfile_getJobProfile_pumpScheduleSummary | null)[] | null;
}

export interface GetFullJobProfile {
  getJobProfile: GetFullJobProfile_getJobProfile | null;
}

export interface GetFullJobProfileVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetJobProfileSummary
// ====================================================

export interface GetJobProfileSummary_getJobProfileSummary {
  __typename: "JobProfileSummary";
  totalBoxesRemaining: number | null;
  totalBoxesLoaded: number | null;
  averageLoadPerBox: number | null;
}

export interface GetJobProfileSummary {
  getJobProfileSummary: GetJobProfileSummary_getJobProfileSummary | null;
}

export interface GetJobProfileSummaryVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadAccessorial
// ====================================================

export interface GetLoadAccessorial_getLoadAccessorial {
  __typename: "LoadAccessorialType";
  id: number | null;
  accessorialId: number | null;
  accessorialName: string | null;
  value: string | null;
  checked: string | null;
}

export interface GetLoadAccessorial {
  getLoadAccessorial: (GetLoadAccessorial_getLoadAccessorial | null)[] | null;
}

export interface GetLoadAccessorialVariables {
  input?: LoadAcessorialInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadAllocation
// ====================================================

export interface GetLoadAllocation_getLoadAllocation {
  __typename: "AllocationDetails";
  id: number;
  jobName: string;
  lofaId: number;
  lofaName: string;
  proppantId: number;
  proppantName: string;
  sandPO: string;
  type: string;
  weightonDate1: number | null;
  weightonDate2: number | null;
  weightonDate3: number | null;
  weightonDate4: number | null;
  weightonDate5: number | null;
  weightonDate6: number | null;
  weightonDate7: number | null;
  weightonDate8: number | null;
  weightonDate9: number | null;
  weightonDate10: number | null;
  weightonDate11: number | null;
  weightonDate12: number | null;
  weightonDate13: number | null;
  weightonDate14: number | null;
}

export interface GetLoadAllocation {
  getLoadAllocation: (GetLoadAllocation_getLoadAllocation | null)[] | null;
}

export interface GetLoadAllocationVariables {
  input?: GetLoadAllocationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadAllocationSummary
// ====================================================

export interface GetLoadAllocationSummary_getLoadAllocationSummary {
  __typename: "AllocationSummary";
  id: number | null;
  proppantName: string | null;
  lofaName: string | null;
  sandPO: string | null;
  loadDate: string | null;
  plannedWeight: number | null;
  actualWeight: number | null;
  dispatchedWeight: number | null;
  remainingWeight: number | null;
}

export interface GetLoadAllocationSummary {
  getLoadAllocationSummary: (GetLoadAllocationSummary_getLoadAllocationSummary | null)[] | null;
}

export interface GetLoadAllocationSummaryVariables {
  input?: GetLoadAllocationSummaryInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadDetail
// ====================================================

export interface GetLoadDetail_getLoadDetail {
  __typename: "LoadDetail";
  boxNumber: string;
  carrierName: string | null;
  driverName: string | null;
  jobSummaryId: number;
  jobName: string | null;
  loadId: number;
  loadingFacility: string | null;
  loadNumber: number;
  lofaId: number;
  truckNumber: string;
  chassisNumber: string;
  transportationPo: string;
  sandPO: string;
  status: string;
  customerName: string;
  jdeNumber: string | null;
  secondaryDriverId: number | null;
  secondaryDriverName: string | null;
  secondaryCarrierName: string | null;
  secondaryTruckNumber: string | null;
  secondaryChassisNumber: string | null;
  regionId: number | null;
  proppantName: string | null;
  isSplit: boolean | null;
  secondaryDriverLoadStatus: string | null;
  tmsId: string | null;
  driverLoadStatus: string | null;
  driverId: number | null;
  customerId: number;
}

export interface GetLoadDetail {
  getLoadDetail: GetLoadDetail_getLoadDetail | null;
}

export interface GetLoadDetailVariables {
  loadId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadFilterDriverList
// ====================================================

export interface GetLoadFilterDriverList_getLoadFilterDriverList {
  __typename: "LoadFilterDriver";
  driverId: number;
  driverName: string;
}

export interface GetLoadFilterDriverList {
  getLoadFilterDriverList: (GetLoadFilterDriverList_getLoadFilterDriverList | null)[] | null;
}

export interface GetLoadFilterDriverListVariables {
  input?: GetLoadFilterDriverListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadFilterLoadFacilityList
// ====================================================

export interface GetLoadFilterLoadFacilityList_getLoadFilterLoadFacilityList {
  __typename: "LoadFilterLoadFacility";
  lofaId: number;
  lofaName: string;
}

export interface GetLoadFilterLoadFacilityList {
  getLoadFilterLoadFacilityList: (GetLoadFilterLoadFacilityList_getLoadFilterLoadFacilityList | null)[] | null;
}

export interface GetLoadFilterLoadFacilityListVariables {
  input?: GetLoadFilterLoadFacilityListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadIdByDriverId
// ====================================================

export interface GetLoadIdByDriverId_getLoadIdByDriverId {
  __typename: "DriverCurrentLoadId";
  loadId: number | null;
}

export interface GetLoadIdByDriverId {
  getLoadIdByDriverId: GetLoadIdByDriverId_getLoadIdByDriverId;
}

export interface GetLoadIdByDriverIdVariables {
  driverId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadList
// ====================================================

export interface GetLoadList_getLoadListByJobId {
  __typename: "LoadManagement";
  atLofaIn: string;
  atLofaOut: string;
  atDestinationIn: string;
  atDestinationOut: string;
  truckNumber: string;
  chassisNumber: string;
  sandTicket: string;
  transportationPo: string;
  jobSummaryId: number;
  loadId: number;
  boxNumber: string | null;
  carrierName: string | null;
  driverCompanyId: number | null;
  driverId: number | null;
  driverName: string | null;
  driverLoadStatus: string | null;
  loadingFacility: string;
  loadNumber: number;
  lofaId: number;
  proppantName: string;
  proppantId: number;
  sandPO: string;
  status: string;
  weight: number;
  regionId: number;
  isSplit: boolean | null;
  secondaryDriverId: number | null;
  secondaryDriverLoadStatus: string | null;
  jobName: string;
  rejectReason: string | null;
  assignedDateTime: string | null;
  rejectedDateTime: string | null;
  userName: string | null;
  rejectedDriverName: string | null;
}

export interface GetLoadList {
  getLoadListByJobId: (GetLoadList_getLoadListByJobId | null)[] | null;
}

export interface GetLoadListVariables {
  input?: GetLoadsInput | null;
  offset?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadListCSV
// ====================================================

export interface GetLoadListCSV_getLoadListCSV {
  __typename: "LoadManagementCSV";
  csv: string | null;
}

export interface GetLoadListCSV {
  getLoadListCSV: GetLoadListCSV_getLoadListCSV | null;
}

export interface GetLoadListCSVVariables {
  input?: GetLoadsCSVInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadLoggers
// ====================================================

export interface GetLoadLoggers_getLoadLoggers {
  __typename: "Logger";
  id: number;
  message: string;
  creationDate: string;
  createdBy: number | null;
  creatorName: string | null;
  loadId: number;
  systemType: string | null;
}

export interface GetLoadLoggers {
  getLoadLoggers: (GetLoadLoggers_getLoadLoggers | null)[] | null;
}

export interface GetLoadLoggersVariables {
  input?: LoggerSearchInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadManagementLoadListCSV
// ====================================================

export interface GetLoadManagementLoadListCSV_getLoadManagementLoadListCSV {
  __typename: "LoadManagementCSV";
  csv: string | null;
}

export interface GetLoadManagementLoadListCSV {
  getLoadManagementLoadListCSV: GetLoadManagementLoadListCSV_getLoadManagementLoadListCSV | null;
}

export interface GetLoadManagementLoadListCSVVariables {
  input?: GetFilteredLoadListInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadNotes
// ====================================================

export interface GetLoadNotes_getLoadNotes {
  __typename: "LoadNotesUserDetails";
  id: number | null;
  notes: string | null;
  createdAt: string | null;
  modifiedAt: string | null;
  createdUserId: number | null;
  loadId: number | null;
  isDeleted: boolean | null;
  name: string | null;
  userName: string | null;
  email: string | null;
}

export interface GetLoadNotes {
  getLoadNotes: (GetLoadNotes_getLoadNotes | null)[] | null;
}

export interface GetLoadNotesVariables {
  loadId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadProgress
// ====================================================

export interface GetLoadProgress_getLoadProgress {
  __typename: "LoadProgress";
  isAssigned: boolean;
  assignedDateTime: string | null;
  acceptedDateTime: string | null;
  isLoading: boolean;
  loadingDateTime: string | null;
  isLoaded: boolean;
  loadedDateTime: string | null;
  loadingElapsedTime: string | null;
  milesToWell: number | null;
  isOnSite: boolean;
  onSiteDateTime: string | null;
  isDelivered: boolean;
  deliveredDateTime: string | null;
  deliveryElapsedTime: string | null;
  sandTicketNumber: string | null;
  bolNumber: string | null;
  additionalTicketNumber: string | null;
  weight: number | null;
  grossWeight: number | null;
  tareWeight: number | null;
  sandTicketImage: string | null;
  bolTicketImage: string | null;
  additionalTicketImage: string | null;
  sandTicketUploadedBy: string | null;
  bolTicketUploadedBy: string | null;
  additionalTicketUploadedBy: string | null;
  sandTicketUploadedAt: string | null;
  bolTicketUploadedAt: string | null;
  additionalTicketUploadedAt: string | null;
  emptyBox1: string | null;
  emptyBox2: string | null;
}

export interface GetLoadProgress {
  getLoadProgress: GetLoadProgress_getLoadProgress | null;
}

export interface GetLoadProgressVariables {
  loadId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLoadSummary
// ====================================================

export interface GetLoadSummary_getLoadSummary_loadSummaries {
  __typename: "LoadSummaryDetails";
  isActive: boolean | null;
  jobSummaryId: number | null;
  lofaId: number | null;
  lofaName: string | null;
  sandPo: string | null;
  transportationPo: string | null;
  loadsCreated: number | null;
  loadsInProgress: number | null;
  loadsCompleted: number | null;
  loadsRemaining: number | null;
  loadsTotal: number | null;
  loadsCompletedVolume: number | null;
  loadsCreatedVolume: number | null;
  loadsInProgressVolume: number | null;
  loadsRemainingVolume: number | null;
  loadsTotalVolume: number | null;
  totalVolume: number | null;
  lofaVolume: number | null;
  volumePerBox: number | null;
  proppantId: number | null;
  milesFromWell: number | null;
}

export interface GetLoadSummary_getLoadSummary {
  __typename: "LoadSummaryByProppant";
  proppantId: number | null;
  proppantName: string | null;
  loadSummaries: (GetLoadSummary_getLoadSummary_loadSummaries | null)[] | null;
}

export interface GetLoadSummary {
  getLoadSummary: (GetLoadSummary_getLoadSummary | null)[] | null;
}

export interface GetLoadSummaryVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetLofaSchedule
// ====================================================

export interface GetLofaSchedule_getLofaSchedule_jobSummaries {
  __typename: "LoadFacilityDetail";
  id: string | null;
  jobSummaryId: number | null;
  lofaName: string | null;
  lofaId: number | null;
  lofaVolume: number | null;
  volumePerBox: number | null;
  proppantId: number | null;
  totalVolume: number | null;
  milesFromWell: number | null;
  sandPo: string | null;
  transportationPo: string | null;
}

export interface GetLofaSchedule_getLofaSchedule {
  __typename: "LofaDetailByProppant";
  proppantId: number | null;
  proppantName: string | null;
  jobSummaries: (GetLofaSchedule_getLofaSchedule_jobSummaries | null)[] | null;
}

export interface GetLofaSchedule {
  getLofaSchedule: (GetLofaSchedule_getLofaSchedule | null)[] | null;
}

export interface GetLofaScheduleVariables {
  jobId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetMapActiveData
// ====================================================

export interface GetMapActiveData_getMapActiveData_maps {
  __typename: "mapActiveLoads";
  wellName: string | null;
  loadId: number | null;
  wellLat: number | null;
  wellLong: number | null;
  lofaLat: number | null;
  lofaLong: number | null;
  loadStatus: string | null;
  regionName: string | null;
  milesFromWell: number | null;
  jobId: number | null;
  driverLat: number | null;
  driverLong: number | null;
  lofaName: string | null;
  proppantName: string | null;
  driverName: string | null;
  companyName: string | null;
  lofaId: number | null;
  driverId: number | null;
  driverLastUpdated: string | null;
  averageLoadTime: number | null;
  regionId: number | null;
  carrierId: number | null;
  proppantId: number | null;
  averageUnloadTime: number | null;
  cellPhone: string | null;
  customerId: number | null;
  atLofaIn: string | null;
  atLofaOut: string | null;
  avgDriveTimeLofaToWell: number | null;
  driveTimeToWellSoFar: number | null;
}

export interface GetMapActiveData_getMapActiveData_onshiftdrivers {
  __typename: "onShiftDrivers";
  driverId: number | null;
  driverName: string | null;
  companyName: string | null;
  cellPhone: string | null;
  driverLat: number | null;
  driverLong: number | null;
  driverLastUpdated: string | null;
  carrierId: number | null;
}

export interface GetMapActiveData_getMapActiveData {
  __typename: "ActiveMapData";
  maps: (GetMapActiveData_getMapActiveData_maps | null)[] | null;
  onshiftdrivers: (GetMapActiveData_getMapActiveData_onshiftdrivers | null)[] | null;
}

export interface GetMapActiveData {
  getMapActiveData: GetMapActiveData_getMapActiveData | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetRoles
// ====================================================

export interface GetRoles_getRoles {
  __typename: "Role";
  roleId: number | null;
  roleName: string | null;
}

export interface GetRoles {
  getRoles: (GetRoles_getRoles | null)[] | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetSettingByName
// ====================================================

export interface GetSettingByName_getSettingByName {
  __typename: "Setting";
  id: number | null;
  name: string | null;
  value: string | null;
}

export interface GetSettingByName {
  getSettingByName: GetSettingByName_getSettingByName | null;
}

export interface GetSettingByNameVariables {
  name?: string | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetStandbyDrivers
// ====================================================

export interface GetStandbyDrivers_getStandbyDrivers {
  __typename: "StandByDriversResponse";
  driverId: number | null;
  driverName: string | null;
  carrierName: string | null;
  driverPoolNotes: string | null;
  loadRequested: number | null;
  loadRejected: number | null;
  loadDelivered: number | null;
  onShift: number | null;
  assignedTime: string | null;
}

export interface GetStandbyDrivers {
  getStandbyDrivers: (GetStandbyDrivers_getStandbyDrivers | null)[] | null;
}

export interface GetStandbyDriversVariables {
  input?: StandByDriverInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTmsDuplicates
// ====================================================

export interface GetTmsDuplicates_getTmsDuplicates {
  __typename: "TmsDuplicateCheckResponse";
  duplicateTmsIds: (number | null)[] | null;
}

export interface GetTmsDuplicates {
  getTmsDuplicates: GetTmsDuplicates_getTmsDuplicates | null;
}

export interface GetTmsDuplicatesVariables {
  input?: TmsDuplicateCheckInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetTonnageExtract
// ====================================================

export interface GetTonnageExtract_getTonnageExtract {
  __typename: "TonnageDetails";
  id: number | null;
  lofaId: number | null;
  lofaName: string | null;
  proppantId: number | null;
  proppantName: string | null;
  sandPO: string | null;
  loadDate: string | null;
  plannedWeight: number | null;
  actualWeight: number | null;
  updatedBy: string | null;
  updatedDate: string | null;
}

export interface GetTonnageExtract {
  getTonnageExtract: (GetTonnageExtract_getTonnageExtract | null)[] | null;
}

export interface GetTonnageExtractVariables {
  input?: GetLoadAllocationInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersAudit
// ====================================================

export interface GetUsersAudit_getUsersAudit_valuesBefore {
  __typename: "UserAuditModel";
  name: string | null;
  emailAddress: string | null;
  isActive: boolean | null;
  isDeleted: boolean | null;
  roleId: number | null;
}

export interface GetUsersAudit_getUsersAudit_valuesAfter {
  __typename: "UserAuditModel";
  name: string | null;
  emailAddress: string | null;
  isActive: boolean | null;
  isDeleted: boolean | null;
  roleId: number | null;
}

export interface GetUsersAudit_getUsersAudit {
  __typename: "UserAudit";
  id: number;
  userId: number | null;
  auditDate: string | null;
  auditUserName: string | null;
  valuesBefore: GetUsersAudit_getUsersAudit_valuesBefore | null;
  valuesAfter: GetUsersAudit_getUsersAudit_valuesAfter | null;
}

export interface GetUsersAudit {
  getUsersAudit: (GetUsersAudit_getUsersAudit | null)[] | null;
}

export interface GetUsersAuditVariables {
  userId?: number | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

// ====================================================
// GraphQL query operation: GetUsersCheckEmail
// ====================================================

export interface GetUsersCheckEmail_getUsersCheckEmail {
  __typename: "CheckEmailResponse";
  emailExists: boolean | null;
}

export interface GetUsersCheckEmail {
  getUsersCheckEmail: GetUsersCheckEmail_getUsersCheckEmail | null;
}

export interface GetUsersCheckEmailVariables {
  input?: CheckEmailInput | null;
}

/* tslint:disable */
/* eslint-disable */
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum SortOrder {
  ASCENDING = "ASCENDING",
  DESCENDING = "DESCENDING",
}

export enum Status {
  CLOSED = "CLOSED",
  COMPLETE = "COMPLETE",
  CREATED = "CREATED",
  INPROGRESS = "INPROGRESS",
  UNKNOWN = "UNKNOWN",
}

export interface ActivitySummaryInput {
  jobId?: number | null;
  carrierIds?: (number | null)[] | null;
}

export interface CarrierListInput {
  carrierIds?: (number | null)[] | null;
}

export interface CellPhoneInput {
  cellPhone?: string | null;
}

export interface CheckEmailInput {
  emailAddress?: string | null;
}

export interface CheckIsJobAccessibleInput {
  driverCompanyId?: number | null;
  jobId?: number | null;
}

export interface CheckIsLoadAccessibleInput {
  driverCompanyId?: number | null;
  loadId?: number | null;
}

export interface CountLoadsByJobProppantInput {
  jobId: number;
  proppantId: number;
}

export interface CountLoadsByJobWellheadInput {
  jobId: number;
  wellhead: string;
}

export interface CountLoadsInput {
  jobSummaryIds: number[];
  statuses: (number | null)[];
}

export interface DeleteB2CUserInput {
  userId?: number | null;
}

export interface DistanceDriverDetail {
  driverId?: number | null;
  driverLat?: number | null;
  driverLong?: number | null;
  wellLat?: number | null;
  wellLong?: number | null;
  lofaLat?: number | null;
  lofaLong?: number | null;
  distanceToLofa?: number | null;
  distanceToWellsite?: number | null;
  timeToDestinationInSeconds?: number | null;
  timeToDestinationInMinutes?: number | null;
}

export interface DistanceDriverInput {
  driver?: DistanceDriverDetail | null;
  destination?: string | null;
}

export interface DriverActivityInput {
  jobId?: number | null;
  carrierIds?: (number | null)[] | null;
}

export interface DriverListInput {
  regionIds?: (number | null)[] | null;
  districtIds?: (number | null)[] | null;
  statuses?: (number | null)[] | null;
  carrierIds?: (number | null)[] | null;
  search?: string | null;
}

export interface DriverLocationsInput {
  loadId?: number | null;
}

export interface GetAllDriversInput {
  districtIds?: (number | null)[] | null;
  searchString?: string | null;
  regionIds?: (number | null)[] | null;
  statuses?: (number | null)[] | null;
  customerIds?: (number | null)[] | null;
  sortOrder?: string | null;
  offset: number;
  driverCompanyId: number;
}

export interface GetChasssisCheckedoutInput {
  driverCompanyId: number;
}

export interface GetAllDriversListInput {
  searchString?: string | null;
  regionIds?: (number | null)[] | null;
  districtIds?: (number | null)[] | null;
  status?: string | null;
  complaint?: string | null;
  sortOrder?: string | null;
  offset: number;
  driverCompanyId: number;
}

export interface GetAllJobsInput {
  driverCompanyId?: number | null;
  statuses?: (number | null)[] | null;
  regionIds?: (number | null)[] | null;
  searchString?: string | null;
  sortOrder?: SortOrder | null;
}

export interface GetB2cUserInput {
  searchString?: string | null;
  isOperationUserView?: boolean | null;
  customer?: number | null;
  role?: number | null;
  carrier?: number | null;
  status?: string | null;
}

export interface GetFilteredLoadListInput {
  jobIds?: (number | null)[] | null;
  regionIds?: (number | null)[] | null;
  districtIds?: (number | null)[] | null;
  customerIds?: (number | null)[] | null;
  jobStatuses?: (number | null)[] | null;
  proppants?: (number | null)[] | null;
  statuses?: (number | null)[] | null;
  carriers?: (number | null)[] | null;
  drivers?: (number | null)[] | null;
  lofas?: (number | null)[] | null;
  atDestinationOutFrom?: string | null;
  atDestinationOutTo?: string | null;
  isSearchOnLoadId?: boolean | null;
  search?: string | null;
  sortOrder?: string | null;
  driverCompanyId?: number | null;
}

export interface GetLoadAllocationInput {
  fromDate?: string | null;
  jobId?: number | null;
  toDate?: string | null;
}

export interface GetLoadAllocationSummaryInput {
  date?: string | null;
  jobId?: number | null;
}

export interface GetLoadFilterDriverListInput {
  isSearchOnLoadId?: boolean | null;
  driverCompanyId?: number | null;
  search?: string | null;
  proppants?: (number | null)[] | null;
  statuses?: (number | null)[] | null;
  atDestinationOutFrom?: string | null;
  atDestinationOutTo?: string | null;
}

export interface GetLoadFilterLoadFacilityListInput {
  isSearchOnLoadId?: boolean | null;
  driverCompanyId?: number | null;
  search?: string | null;
  proppants?: (number | null)[] | null;
  statuses?: (number | null)[] | null;
  atDestinationOutFrom?: string | null;
  atDestinationOutTo?: string | null;
}

export interface GetLoadsCSVInput {
  jobId?: number | null;
  search?: string | null;
  statuses?: (number | null)[] | null;
  proppants?: (number | null)[] | null;
  lofas?: (number | null)[] | null;
  atDestinationOutFrom?: string | null;
  atDestinationOutTo?: string | null;
  carriers?: (number | null)[] | null;
}

export interface GetLoadsInput {
  jobId?: number | null;
  isSearchOnLoadId?: boolean | null;
  search?: string | null;
  statuses?: (number | null)[] | null;
  lofas?: (number | null)[] | null;
  proppants?: (number | null)[] | null;
  atDestinationOutFrom?: string | null;
  atDestinationOutTo?: string | null;
  carriers?: (number | null)[] | null;
  drivers?: (number | null)[] | null;
}

export interface GetUserCustomerListInput {
  searchString?: string | null;
  isOperationUserView?: boolean | null;
  status?: string | null;
}

export interface GetUserDriverCompanyListInput {
  searchString?: string | null;
  isOperationUserView?: boolean | null;
  status?: string | null;
}

export interface GetUserRoleListInput {
  searchString?: string | null;
  isOperationUserView?: boolean | null;
  status?: string | null;
}

export interface LoadAcessorialInput {
  loadId?: number | null;
  accessorialName?: (string | null)[] | null;
}

export interface LoggerSearchInput {
  loadId: number;
  search?: string | null;
}

export interface SaveB2CUserInput {
  userId?: number | null;
  firstName?: string | null;
  lastName?: string | null;
  emailAddress?: string | null;
  roleId?: number | null;
  customerParentId?: number | null;
  driverCompanyId?: number | null;
  isActive?: boolean | null;
  resetPassword?: boolean | null;
}

export interface StandByDriverInput {
  jobId?: number | null;
  carrierIds?: (number | null)[] | null;
}

export interface TmsDuplicateCheckInput {
  tmsIds?: (number | null)[] | null;
}

export interface UpdateDriverInput {
  id?: number | null;
  cellPhone?: string | null;
  cdlNumber?: string | null;
  regionIds?: string | null;
  districtIds?: string | null;
  isComplaint?: boolean | null;
  extSystemIdentifier?: string | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
