import gql from 'graphql-tag';

export default gql`
    query GetLoadNotes($loadId: Int) {
        getLoadNotes(loadId: $loadId) {
            id
            notes
            createdAt
            modifiedAt
            createdUserId
            loadId
            isDeleted
            name
            userName
            email
        }
    }
`;
