import React from 'react';
import './LoadDetail.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent/TextContent';

interface LoadDetailProps {
    jobName?: string | null;
    customerName?: string | null;
    jdeNumber?: string | null;
    sandPo?: string | null;
    loadingFacility?: string | null;
    proppantType?: string | null;
    transportationPO?: string | null;
    tmsId?: string | null;
}

const LoadDetail = ({
    jobName,
    customerName,
    jdeNumber,
    sandPo,
    loadingFacility,
    proppantType,
    transportationPO,
    tmsId,
}: LoadDetailProps): JSX.Element => {
    return (
        <Col>
            <Row className="LoadDetail-Title">Load Details</Row>
            <Row className="custom-row">
                <TextContent hasTopMargin title={'Job Name: '} value={jobName || ''}></TextContent>
            </Row>
            <Row className="custom-row">
                <TextContent hasTopMargin title={'Customer Name: '} value={customerName || ''}></TextContent>
            </Row>
            {/* <Row>
                <TextContent hasTopMargin title={'JDE#: '} value={jdeNumber || ''}></TextContent>
            </Row> */}
            <Row className="custom-row">{tmsId ? <TextContent hasTopMargin title={'Tms Id: '} value={tmsId || ''}></TextContent> : ''}</Row>
            <Row className="custom-row">
                <TextContent hasTopMargin title={'Transportation PO: '} value={transportationPO || ''}></TextContent>
            </Row>
            <Row className="custom-row">
                <TextContent hasTopMargin title={'Sand PO: '} value={sandPo || ''}></TextContent>
            </Row>
            <Row className="custom-row">
                <TextContent hasTopMargin title={'Loading Facility: '} value={loadingFacility || ''}></TextContent>
            </Row>
            <Row className="custom-row">
                <TextContent hasTopMargin title={'Proppant Type: '} value={proppantType || ''}></TextContent>
            </Row>
        </Col>
    );
};

export default LoadDetail;
