import { Reducer } from 'react';
import { Actions } from '../../actions/lofaList';
import { Action, LofaListState } from '../../types';

const initialLofaListState: LofaListState = {
    searchPhrase: '',
};

const setSearchPhrase = (search: string, state: LofaListState): LofaListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const lofaListReducer: Reducer<LofaListState, Action> = (state, action): LofaListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
    }

    return newState;
};

export { initialLofaListState, lofaListReducer };
