import Nav from 'react-bootstrap/Nav';
import React from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import './NavBar.scss';
import NavBarItemWithOverlay from './NavBarItemWithOverlay';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { RoutesObject } from '../../../../utils/Routes';
import { CustomButton } from '../../../_common';
import { useAuthContext } from '../../../Auth/AuthProvider';
import { PermissionEnum } from '../../../../types/PermissionEnum';
import { Routes } from '../../../../utils';

interface NavBarDropdownProps {
    header?: JSX.Element;
    title?: string;
    isActive: boolean;
    isDisabled?: boolean;
    routes: RoutesObject[];
}

const NavBarDropdown = ({ header, title, isActive, isDisabled, routes }: NavBarDropdownProps): JSX.Element => {
    const linkContainer = `NavBar-TabIndicator ${
        isActive ? 'NavBar-ActiveTabIndicator' : 'NavBar-HiddenTabIndicator'
    } ${isDisabled && 'pointerEvents: none'}`;
    const authContext = useAuthContext();
    const opsAdmin = authContext.userHasPermission(PermissionEnum.Admin);
    const opsCustomer = authContext.userHasPermission(PermissionEnum.Customer);
    const opsCustomerService = authContext.userHasPermission(PermissionEnum.CustomerService);

    // const canViewUsers = authContext.userHasPermission(PermissionEnum.ViewUsers);
    // const canViewPermissions = authContext.userHasPermission(PermissionEnum.ViewPermissions);
    // const canViewLofas = authContext.userHasPermission(PermissionEnum.ViewLOFA);
    // const canViewCustomers = authContext.userHasPermission(PermissionEnum.ViewCustomer);
    // const canViewCustomerParents = authContext.userHasPermission(PermissionEnum.ViewCustomerParent);
    // const canViewDrivers = authContext.userHasPermission(PermissionEnum.ViewDriver);
    // const canViewCrews = authContext.userHasPermission(PermissionEnum.ViewCrew);
    // const canViewProppants = authContext.userHasPermission(PermissionEnum.ViewProppant);
    // const canViewCarriers = authContext.userHasPermission(PermissionEnum.ViewDriverCompany);

    // TODO: This is a temporary fix, need to add the isDisable in the Routes object - GB
    // return isDisabled || (!canViewUsers && !canViewPermissions && title === Routes.SYSTEM_ADMIN.title) ? (
    //     <NavBarItemWithOverlay>
    //         <Nav.Link disabled className={linkContainer}>
    //             {title}
    //         </Nav.Link>
    //     </NavBarItemWithOverlay>
    // ) :
    return (
        <NavDropdown title={header || title} id={`collapsible-nav-dropdown`} className={linkContainer}>
            {routes.map((route: RoutesObject, index: number) => {
                if (route.type === 'external') {
                    return (
                        <a
                            key={index}
                            href={route.route}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="NavBar-Dropdown-Item dropdown-item"
                        >
                            <div className="div-dropdown">{route.title}</div>
                        </a>
                    );
                } else {
                    // const isTabDisabled = route.title === Routes.SYSTEM_ADMIN_USERS.title && (opsCustomer || opsCustomerService);
                    return (
                        <LinkContainer key={index} to={route.route}>
                            <NavDropdown.Item className={'NavBar-Dropdown-Item'} disabled={false}>
                                <div className={'div-dropdown'}>
                                    {route.title === Routes.HOME.title ? (
                                        <CustomButton
                                            title="Logout"
                                            onClick={() => {
                                                authContext.userManager.signoutRedirect();
                                            }}
                                        />
                                    ) : (
                                        route.title
                                    )}
                                </div>
                                {/* Code to put divider under dropdown menu item ....*/}
                                {index < routes.length - 1 && <NavDropdown.Divider></NavDropdown.Divider>}
                            </NavDropdown.Item>
                        </LinkContainer>
                    );
                }
            })}
        </NavDropdown>
    );
};

export default NavBarDropdown;
