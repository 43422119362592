import gql from 'graphql-tag';

export default gql`
    query GetUserDriverCompanyList($input: GetUserDriverCompanyListInput) {
        getUserDriverCompanyList(input: $input) {
            driverCompanyId
            driverCompanyName
        }
    }
`;
