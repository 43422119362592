import React, { FC } from 'react';
import Content from './Content';
import './Frame.css';
import Navbar from './NavBar/NavBar';
import AuthContent from '../../Auth/AuthContent';
import { Loading } from '../../_common';
import { useStoreContext } from '../StoreProvider';
import moment from 'moment-timezone';

const Frame: FC = (): JSX.Element => {
    const [state] = useStoreContext();
    const { auth } = state;
    moment.tz.setDefault('America/Chicago');

    if (auth.isUserLoading) {
        return (
            <span className="Frame-Loading">
                <Loading />
            </span>
        );
    }

    if (auth.user && !auth.user.expired) {
        return (
            <div className="Frame-Container">
                <div className="Frame-Navigation">
                    <Navbar />
                </div>
                <div className="Frame-Content">
                    <Content />
                </div>
            </div>
        );
    } else {
        return <AuthContent />;
    }
};

export default Frame;
