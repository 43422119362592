import React from 'react';
import './FacilityItem.scss';
import { GetLofaSchedule_getLofaSchedule_jobSummaries as FacilityData } from '../../../../types/schemaTypes';
import { NumericFormat as NumberFormat } from 'react-number-format';

interface FacilityItemProps {
    facility: FacilityData;
    isLastItem: boolean;
}

const FacilityItem = ({ facility, isLastItem }: FacilityItemProps): JSX.Element => {
    const { lofaName, lofaVolume, milesFromWell, volumePerBox, sandPo, transportationPo } = facility;
    const newLofaVolume = lofaVolume ? lofaVolume : undefined;
    const newLofaVolumeTons = lofaVolume ? '(' + (lofaVolume / 2000).toFixed(2).toString() + 'T)' : undefined;
    const newMilesFromWell = milesFromWell ? milesFromWell : undefined;
    const newVolumePerBox = volumePerBox ? volumePerBox : undefined;
    const newVolumePerBoxTons = volumePerBox ? '(' + (volumePerBox / 2000).toFixed(2).toString() + 'T)' : undefined;
    return (
        <div className={!isLastItem ? 'FacilityItem-Row' : 'FacilityItem-Last-Row'}>
            <div className="FacilityItem-Info-Row justify-content-start">{lofaName}</div>
            <div className="FacilityItem-Info-Row justify-content-end">
                {/* <NumberFormat value={newLofaVolume} mask={''} displayType={'text'} thousandSeparator={true} /> */}
                <NumberFormat value={newLofaVolume}  displayType={'text'} thousandSeparator={true} />
                &nbsp;{newLofaVolumeTons} 
               
            </div>
            <div className="FacilityItem-Info-Row justify-content-end">
                <NumberFormat value={newMilesFromWell} displayType={'text'} thousandSeparator={true} />
            </div>
            <div className="FacilityItem-Info-Row justify-content-end">
                <NumberFormat value={newVolumePerBox} displayType={'text'} thousandSeparator={true} />
                &nbsp;{newVolumePerBoxTons}
            </div>
            <div className="FacilityItem-Info-Row justify-content-end">{sandPo}</div>
            <div className="FacilityItem-Info-Row justify-content-end">{transportationPo}</div>
        </div>
    );
};

export default FacilityItem;
