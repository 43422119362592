import React from 'react';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Tags from '../Tags';
import './CustomToggle.scss';
import 'material-design-icons/iconfont/material-icons.css';

interface CustomToggleProps {
    title: string;
    eventKey: string;
    onClick: () => void;
    tags: string[];
}

const CustomToggle = ({ eventKey, title, onClick, tags }: CustomToggleProps): JSX.Element => {
    const decoratedOnClick = useAccordionButton(eventKey, onClick);

    return (
        <React.Fragment>
            <div className="CustomToggle-Container" onClick={decoratedOnClick}>
                <div className="CustomToggle-Header">
                    <p className="CustomToggle-Title">{title}</p>
                    <i className="material-icons CustomToggle-Arrow">keyboard_arrow_down</i>
                </div>
            </div>
            {eventKey === '1' && (
                <React.Fragment>
                    {tags.length > 0 && <Tags items={tags}></Tags>}
                    <div className="CustomToggle-Divider"></div>
                </React.Fragment>
            )}
        </React.Fragment>
    );
};

export default CustomToggle;
