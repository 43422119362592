import { Action } from '../../types';

interface FilterItem {
    id: number;
    name: string;
}

export enum Actions {
    UPDATE_FILTERS = 'CURRENT_ACTIVITY_UPDATE_FILTERS',
    UPDATE_FILTERS_SELECT_ALL = 'CURRENT_ACTIVITY_UPDATE_FILTERS_TO_SELECT_ALL',
    UPDATE_FILTER_SEARCH_PHRASE = 'CURRENT_ACTIVITY_FILTERS_UPDATE_SEARCH_PHRASE',
    UPDATE_SEARCH_PHRASE = 'CURRENT_ACTIVITY_UPDATE_SEARCH_PHRASE',
}

export const setFiltersForMessages = (filterType: string, id: number, isSelected: boolean): Action => {
    return {
        type: Actions.UPDATE_FILTERS,
        payload: {
            filterType,
            id,
            isSelected,
        },
        error: null,
    };
};

export const setSelectAllFilters = (itemIds: number[], filterType: string): Action => {
    return {
        type: Actions.UPDATE_FILTERS_SELECT_ALL,
        payload: {
            filterType,
            itemIds,
        },
        error: null,
    };
};

export const setFilterSearchPhrase = (items: FilterItem[], filterType: string, searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_FILTER_SEARCH_PHRASE,
        payload: {
            filterType,
            items,
            searchPhrase,
        },
        error: null,
    };
};

export const setSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};
