import './SearchBar.css';
import 'material-design-icons/iconfont/material-icons.css';
import React from 'react';
import { useStoreContext } from '../App/StoreProvider';
import * as JobListActions from '../../store/actions/jobList';
import * as LoadListActions from '../../store/actions/loadList';
import * as UserListActions from '../../store/actions/userList';
import * as CustomerListActions from '../../store/actions/customerList';
import * as CustomerParentListActions from '../../store/actions/customerParentList';
import * as LofaListActions from '../../store/actions/lofaList';
import * as CrewListActions from '../../store/actions/crewList';
import * as MessageListActions from '../../store/actions/messageList';
import * as CarrierListActions from '../../store/actions/carrierList';
import * as LoadManagementListActions from '../../store/actions/loadManagementList';
import * as DriverListActions from '../../store/actions/driversList';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';
import { Actions } from '../../store/actions/userList';

interface SearchBarProps {
    type?: SearchTypeEnum;
    onChange?: (text: string) => void;
    placeHolderText?: string;
}

const SearchBar = ({
    type = SearchTypeEnum.Unknown,
    onChange,
    placeHolderText = 'Search',
}: SearchBarProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const search: React.Ref<HTMLInputElement> = React.createRef();

    const updateSearchPhrase = (e: React.KeyboardEvent<HTMLInputElement>): any => {
        if (e.key === 'Enter') {
            e.preventDefault();
            const searchPhrase = search && search.current ? search.current.value : '';

            if (type == SearchTypeEnum.Job) dispatch(JobListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.Load) dispatch(LoadListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.User)
                dispatch(UserListActions.setFilterValue(searchPhrase, Actions.FILTER_SEARCHSTRING));
            if (type == SearchTypeEnum.Customer) dispatch(CustomerListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.CustomerParent)
                dispatch(CustomerParentListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.Crew) dispatch(CrewListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.Carrier) dispatch(CarrierListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.LoadManagement)
                dispatch(LoadManagementListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.Lofa) dispatch(LofaListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.MessagePreview) dispatch(MessageListActions.setSearchPhrase(searchPhrase));
            if (type == SearchTypeEnum.Driver)
                dispatch(
                    DriverListActions.setFilterValue(searchPhrase, DriverListActions.Actions.FILTER_BY_SEARCHSTRING),
                );
        }
    };

    let searchValue = '';

    if (type == SearchTypeEnum.Job) searchValue = state.jobList.searchPhrase;
    if (type == SearchTypeEnum.Load) searchValue = state.loadList.searchPhrase;
    if (type == SearchTypeEnum.User) searchValue = state.userList.searchString;
    if (type == SearchTypeEnum.LoadManagement) searchValue = state.loadManagementList.searchPhrase;
    if (type == SearchTypeEnum.Customer) searchValue = state.customerList.searchPhrase;
    if (type == SearchTypeEnum.CustomerParent) searchValue = state.customerParentList.searchPhrase;
    if (type == SearchTypeEnum.Crew) searchValue = state.crewList.searchPhrase;
    if (type == SearchTypeEnum.Carrier) searchValue = state.carrierList.searchPhrase;
    if (type == SearchTypeEnum.Lofa) searchValue = state.lofaList.searchPhrase;
    if (type == SearchTypeEnum.MessagePreview) searchValue = state.messageList.searchPhrase;
    if (type == SearchTypeEnum.Driver) searchValue = state.driverList.searchString;
    return (
        <div className="SearchBar-Container">
            <i className="material-icons SearchBar-Icon">search</i>
            <input
                id="textSearch"
                type="text"
                placeholder={placeHolderText}
                onChange={e => onChange && onChange(e.target.value)}
                className="SearchBar-Input"
                ref={search}
                defaultValue={state.messageList.searchPhrase}
                // value={state.messageList.searchPhrase}
                onKeyDown={(e): void => updateSearchPhrase(e)}
                autoComplete="off"
            />
        </div>
    );
};

export default SearchBar;
