import { Reducer } from 'react';
import { Actions } from '../../actions/customerParentList';
import { Action, CustomerParentListState } from '../../types';

const initialCustomerParentListState: CustomerParentListState = {
    searchPhrase: '',
};

const setSearchPhrase = (search: string, state: CustomerParentListState): CustomerParentListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const customerParentListReducer: Reducer<CustomerParentListState, Action> = (
    state,
    action,
): CustomerParentListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
    }

    return newState;
};

export { initialCustomerParentListState, customerParentListReducer };
