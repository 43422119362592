import gql from 'graphql-tag';

export default gql`
    query GetLoadList($input: GetLoadsInput, $offset: Int) {
        getLoadListByJobId(input: $input, offset: $offset) {
            atLofaIn
            atLofaOut
            atDestinationIn
            atDestinationOut
            truckNumber
            chassisNumber
            sandTicket
            transportationPo
            jobSummaryId
            loadId
            boxNumber
            carrierName
            driverCompanyId
            driverId
            driverName
            driverLoadStatus
            loadingFacility
            loadNumber
            lofaId
            proppantName
            proppantId
            sandPO
            status
            weight
            regionId
            isSplit
            secondaryDriverId
            secondaryDriverLoadStatus
            jobName
            rejectReason
            assignedDateTime
            rejectedDateTime
            userName
            rejectedDriverName
        }
    }
`;
