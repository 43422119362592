import React, { FC, useEffect, useState } from 'react';
import { AuthConsumer } from '../AuthProvider';
import SandBoxLogo from '../../../assets/SandBoxSystem@3x.png';
import Footer from '../../../assets/footer.jpg';
import 'material-design-icons/iconfont/material-icons.css';
import './Login.scss';
import { GetUsersCheckEmail } from '../../../types/schemaTypes';
import { GET_USERS_CHECK_EMAIL } from '../../../graphql/client/queries';
import { useQuery } from '@apollo/client';

const Login: FC = (): JSX.Element => {
    localStorage.removeItem('carrierId');
    localStorage.removeItem('redirectbackto');
    const [email, setEmail] = useState('');
    const [searchEmail, setSearchEmail] = useState('');
    const [isEmailExists, setIsEmailExists] = useState(true);
    const [isEmailCheckCompleted, setIsEmailCheckCompleted] = useState(false);

    useQuery<GetUsersCheckEmail>(GET_USERS_CHECK_EMAIL, {
        variables: { input: { emailAddress: searchEmail } },
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            if (email !== '') {
                setIsEmailCheckCompleted(true);
            } else {
                setIsEmailCheckCompleted(false);
            }

            const emailExists =
                data!.getUsersCheckEmail!.emailExists == null ? false : data!.getUsersCheckEmail!.emailExists;
            setIsEmailExists(emailExists);
        },
    });

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            setIsEmailCheckCompleted(false);
            setSearchEmail(email);
        }, 300);
        return () => clearTimeout(delayDebounceFn);
    }, [email]);

    const onsubmitLogin = (userManager, e) => {
        e.preventDefault();
        if (email !== '' && isEmailCheckCompleted && isEmailExists) {
            userManager.signinRedirect({
                login_hint: email,
                prompt: 'login',
            });
        }
    };

    return (
        <AuthConsumer>
            {({ userManager }): JSX.Element => {
                return (
                    <div className="Login">
                        <div className="Login-Container">
                            <img
                                className="Login-Logo"
                                src={SandBoxLogo}
                                alt="Operations Portal Logo"
                                width="407"
                                height="78"
                            />
                            <form className="Login-Form" onSubmit={(event): void => onsubmitLogin(userManager, event)}>
                                <input
                                    autoFocus
                                    className="Login-Form-Email"
                                    type="email"
                                    placeholder="EMAIL"
                                    required
                                    value={email}
                                    onChange={e => setEmail(e.target.value)}
                                />

                                <div className="email-not-exist-div">
                                    {email != '' && isEmailCheckCompleted && !isEmailExists && (
                                        <label className="email-not-exist-lable">
                                            User account does not exist. Please contact customer service for assistance.
                                        </label>
                                    )}
                                </div>

                                <button type="submit" className="Login-Form-Button" disabled={email === ''}>
                                    Login
                                </button>
                            </form>
                        </div>

                        <footer className="Login-Footer">
                            <img className="Login-Footer-Asset" src={Footer} alt="Workflow process" />
                            <div className="Login-Footer-Top" />
                            <div className="Login-Footer-Bottom" />
                        </footer>
                    </div>
                );
            }}
        </AuthConsumer>
    );
};

export default Login;
