import React, { useCallback, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import {QueryResult} from '@apollo/client';
import SearchBar from '../SearchBar';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';
import { ModalForm, Option, CPOption, CarrierOption } from './interface';
import { Alert } from 'react-bootstrap';
import {
    GET_B2C_USER_FILTERED,
    GET_ROLES,
    GET_ALL_CUSTOMER_PARENTS,
    GET_ALL_DRIVER_COMPANY,
    GETALL_DRIVERSLIST_BYFILTER,
} from '../../graphql/client/queries';

import {
    GetRoles_getRoles as Role,
    GetAllCustomerParents_getAllCustomerParents as CustomerParent,
    GetAllDriversListByFilter_getAllDriversListByFilter as Driver,
    GetAllDriversListByFilter,
    GetDriverCompanies_getDriverCompanies as DriverCompany,
    updateDriver,
} from '../../types/schemaTypes';
import AddDriverModal from './AddEditDriverModal/AddEditDriverModal';

import DriverList from './DriverList';
import { Error, Loading } from '../_common';
// import AddUserModal from './AddEditUserModal/AddEditUserModal';
import './Drivers.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { useQuery,useMutation } from '@apollo/client';
import { useStoreContext } from '../App/StoreProvider';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';

import { DELETE_B2C_USER, UPDATE_DRIVER_COMMAND } from '../../graphql/client/mutations';
import {  ToastContainer, toast } from 'react-toastify';
import {toastOpt} from '../../utils/helpers/toastHelper'
// import ConfirmDispatchModal from './ComfirmModal/DispatchModal';
import ForbiddenError from '../_common/Error/ForbiddenError';
import { UserFilters } from '../Filter';
import { DriverFilters } from '../Filter/DriverFilters';
import CarrierDropDown from '../CarrierDropdown/CarrierDropdown';

const toasterOptions = { duration: 2500 };
const Messages = {
    delete: 'Driver deleted successfully',
    added: 'Driver added successfully',
    edited: 'Driver details saved successfully',
};
const ErrorMessages = {
    delete: 'Failed to delete User',
};
let selectedDriver: Driver | undefined;

const PAGE_SIZE = 50;

const Drivers = (): JSX.Element => {
    const [showEditDriverModal, setShowEditDriverModal] = useState(false);
    const [modalForm, setModalForm] = useState(ModalForm.EMPTY);
    const [isEndOfResults, setIsEndOfResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tabTitle, setTabTitle] = useState(document.title);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [showDispatchModal, setDispatchModal] = useState(false);
    document.title = 'SBX OPS Portal';
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCustomerService = useAuthContext().userHasPermission(PermissionEnum.CustomerService);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);
    const isCarrierUser = useAuthContext().userHasPermission(PermissionEnum.Carrier);
    const canEditUsers = isAdmin || isCarrierUser || isOperationUser;
    const [refetched, setRefetched] = useState(false);
    const [state] = useStoreContext();
    const abortController = new AbortController();
    const [listHeight, setListHeight] = useState(window.screen.height);

    const {
        driverList: { searchString, districtIds, isComplaint, status, regionIds },
        carrierList: { carrierId },
    } = state;

    const variables = {
        input: {
            searchString,
            regionIds,
            districtIds,
            status,
            complaint: isComplaint,
            sortOrder: '',
            offset: 0,
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
        },
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    const onCompleted = action => {
     //   toaster.notify(renderToast(Messages[action], 'success'), toasterOptions);
        toast.success(Messages[action],toastOpt);
    };

    const onActionError = action => {
        toast.error(Messages[action],toastOpt);
     //   toaster.notify(renderToast(ErrorMessages[action], 'danger'), toasterOptions);
    };

    const handleOnHide = (): void => {
        selectedDriver = undefined;
        setErrorMessage('');
        setShowEditDriverModal(false);
    };

    const { data: customerParentData } = useQuery(GET_ALL_CUSTOMER_PARENTS);
    const cpOptions: CPOption[] = [];
    if (customerParentData && customerParentData.getAllCustomerParents) {
        customerParentData.getAllCustomerParents.forEach((element: CustomerParent) => {
            if (element.id !== null) {
                const id = element.id;
                const displayName = element && element.customerName ? element.customerName : '<None>';
                const jdeId = element && element.extSystemIdentifier ? element.extSystemIdentifier : '<None>';
                cpOptions.push({
                    id: id,
                    name: displayName,
                    jdeId: jdeId,
                });
            }
        });
    }

    const { data: driverComapniesData } = useQuery(GET_ALL_DRIVER_COMPANY);
    const carrierOptions: CarrierOption[] = [];
    if (driverComapniesData && driverComapniesData.getDriverCompanies) {
        driverComapniesData.getDriverCompanies.forEach((element: DriverCompany) => {
            if (element.id !== null) {
                const id = element.id;
                const displayName = element && element.companyName ? element.companyName : '<None>';
                carrierOptions.push({
                    id: id,
                    name: displayName,
                });
            }
        });
    }

    const { data: rolesData } = useQuery(GET_ROLES);
    const options: Option[] = [];
    if (rolesData && rolesData.getRoles) {
        rolesData.getRoles.forEach((element: Role) => {
            if (element.roleId !== null) {
                const id = element.roleId;
                const displayName = element && element.roleName ? element.roleName : '<None>';

                options.push({
                    id: id,
                    name: displayName,
                });
            }
        });
    }
    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };

    document.title = 'Drivers';

    const handleOnSave = async (
        id: number,
        driverName: string,
        cellPhone: string,
        cdlNumber: string,
        isActive: boolean,
        extSystemName: string,
        extSystemIdentifier: string,
        activeInactiveDate: Date,
        inactiveReason: string,
        reactivationDate: Date,
        regionIds: string,
        districtIds: string,
        isComplaint: boolean,
    ): Promise<void> => {
        console.log(
            id,
            driverName,
            cellPhone,
            cdlNumber,
            isActive,
            extSystemName,
            extSystemIdentifier,
            activeInactiveDate,
            inactiveReason,
            reactivationDate,
            regionIds,
            districtIds,
        );
        const variables = {
            input: {
                id,
                cellPhone,
                cdlNumber,
                regionIds,
                districtIds,
                isComplaint,
                extSystemIdentifier,
            },
        };
        await saveDriver({ variables });
    };

    const onCompletedDriver = (data: updateDriver): void => {
        if (data.updateDriver === null) return;
        const saveDriverErrors = data.updateDriver.errors;
        if (saveDriverErrors && saveDriverErrors.length > 0) {
            setErrorMessage(saveDriverErrors[0] || '');
        } else {
            toast.success(Messages['edited'],toastOpt);
            //toaster.notify(renderToast(Messages['edited'], 'success'), toasterOptions);
            selectedDriver = undefined;
            setShowEditDriverModal(false);
        }
    };

    const [saveDriver] = useMutation(UPDATE_DRIVER_COMMAND, {
        onCompleted: onCompletedDriver,
        refetchQueries: () => {
            return [
                {
                    query: GETALL_DRIVERSLIST_BYFILTER,
                    variables,
                },
            ];
        },
    });

    const abortRequest = (): void => {
        abortController.abort();
    };

    if (!isAdmin && !isOperationUser && !isCarrierUser) {
        <ToastContainer/>
        return <ForbiddenError />;
    }
    if (variables.input.driverCompanyId && variables.input.driverCompanyId !== 0) {
        return (
            <Query query={GETALL_DRIVERSLIST_BYFILTER} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetAllDriversListByFilter | undefined>): JSX.Element | null => {
                    if (error) {
                        return <Error error={error} />;
                    }

                    const drivers: Driver[] = [];

                    if (data && data.getAllDriversListByFilter && data.getAllDriversListByFilter.length !== 0) {
                        data.getAllDriversListByFilter.forEach((element: Driver | null): void => {
                            if (element !== null) {
                                drivers.push(element);
                            }
                        });
                    }
                   

                    return (
                        <div className="Users-Container">
                             <ToastContainer/>
                            <div className="Header-Container">
                                <div className="Header-SearchBar">
                                    <CarrierDropDown></CarrierDropDown>
                                </div>
                                <div className="Header-ListHeader">
                                    {/* {canEditUsers && (
                                        <div
                                            className="Users-Create-New-User Row"
                                            onClick={() => {
                                                setShowAddUserModal(true);
                                                errorMessage && setErrorMessage('');
                                                setModalForm(ModalForm.ADD_USER);
                                            }}
                                        >
                                            <i className="material-icons Users-Icon">add_circle</i>
                                            <span className="Users-Link">Create New User</span>
                                        </div>
                                    )} */}
                                </div>
                            </div>
                            <div className="JobManagement-Body">
                                <div id="jobsFilterContainer" className="JobManagement-Filter">
                                    <DriverFilters
                                        abortRequest={abortRequest}
                                        onFilterToggle={setListContainerHeight}
                                        carrierOptions={carrierOptions}
                                        cpOptions={cpOptions}
                                        roles={options}
                                    />
                                </div>
                                <div id="jobsListContainer" className="JobManagement-List">
                                    <div className="Users-Bottom-Container">
                                        <div className="DriversItem-Bottom-Container DriversItem-Header-Container">
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-2">
                                                <div className="Drivers-Header-Title">Name</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">Region Name</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">Distrinct Name</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">ISNET ID</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">IsCompliant</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">Active/Inactive Date</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">Active/Inactive User</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-2">
                                                <div className="Drivers-Header-Title">Company Name</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">CellPhone</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">Status</div>
                                            </div>
                                            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                                <div className="Drivers-Header-Title">Actions</div>
                                            </div>
                                        </div>
                                        {loading && (
                                            <div className="SpinnerContainer">
                                                <Loading />
                                            </div>
                                        )}

                                        {drivers.length > 0 && (
                                            <DriverList
                                                drivers={drivers}
                                                options={options}
                                                cpOptions={cpOptions}
                                                carrierOptions={carrierOptions}
                                                isLoading={loading}
                                                canEditUsers={canEditUsers}
                                                isOperationUser={isOperationUser}
                                                refetched={refetched}
                                                setRefetched={setRefetched}
                                                isEndOfResults={isEndOfResults}
                                                onEditClick={driver => {
                                                    setShowEditDriverModal(true);
                                                    errorMessage && setErrorMessage('');
                                                    setModalForm(ModalForm.EDIT_DRIVER);
                                                    selectedDriver = driver;
                                                }}
                                            />
                                        )}

                                        <AddDriverModal
                                            show={showEditDriverModal}
                                            selectedDriver={selectedDriver}
                                            modalForm={modalForm}
                                            options={options}
                                            driverId={selectedDriver?.id ?? 0}
                                            onClickHide={handleOnHide}
                                            onClickSave={handleOnSave}
                                            errorMessage={errorMessage}
                                        ></AddDriverModal>
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
           
            <div className="Users-Container">
                 <ToastContainer/>
                <div className="Header-Container">
                    <div className="Header-SearchBar">
                        <CarrierDropDown></CarrierDropDown>
                    </div>
                    <div className="Header-ListHeader">
                        {/* {canEditUsers && (
                            <div
                                className="Users-Create-New-User Row"
                                onClick={() => {
                                    setShowAddUserModal(true);
                                    errorMessage && setErrorMessage('');
                                    setModalForm(ModalForm.ADD_USER);
                                }}
                            >
                                <i className="material-icons Users-Icon">add_circle</i>
                                <span className="Users-Link">Create New User</span>
                            </div>
                        )} */}
                    </div>
                </div>
                <div className="JobManagement-Body">
                    <div id="jobsFilterContainer" className="JobManagement-Filter">
                        <DriverFilters
                            abortRequest={abortRequest}
                            onFilterToggle={setListContainerHeight}
                            carrierOptions={carrierOptions}
                            cpOptions={cpOptions}
                            roles={options}
                        />
                    </div>
                    <div id="jobsListContainer" className="JobManagement-List">
                        <div className="Users-Bottom-Container">
                            <div className="DriversItem-Bottom-Container DriversItem-Header-Container">
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-2">
                                    <div className="Drivers-Header-Title">Name</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">Region Name</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">Distrinct Name</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">ISNET ID</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">IsCompliant</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">Active/Inactive Date</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">Active/Inactive User</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-2">
                                    <div className="Drivers-Header-Title">Company Name</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">CellPhone</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">Status</div>
                                </div>
                                <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                                    <div className="Drivers-Header-Title">Actions</div>
                                </div>
                            </div>

                            <DriverList
                                drivers={[]}
                                options={options}
                                cpOptions={cpOptions}
                                carrierOptions={carrierOptions}
                                isLoading={false}
                                canEditUsers={canEditUsers}
                                isOperationUser={isOperationUser}
                                refetched={refetched}
                                setRefetched={setRefetched}
                                isEndOfResults={isEndOfResults}
                                onEditClick={user => {
                                    setShowEditDriverModal(true);
                                    errorMessage && setErrorMessage('');
                                    setModalForm(ModalForm.EDIT_USER);
                                    selectedDriver = user;
                                }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
};

export default Drivers;
