import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TextContent from '../_common/TextContent';
import JobDetail from './JobDetail';
import { GET_JOB_PROFILE, CHECK_ISJOBACCESSIBLE } from '../../graphql/client/queries';
import {
    GetFullJobProfile_getJobProfile as JobProfileData,
    GetFullJobProfile,
    GetFullJobProfile_getJobProfile_pumpScheduleSummary as PumpScheduleSummaryData,
    CheckIsJobAccessible,
} from '../../types/schemaTypes';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import { Error, Loading } from '../_common';
import JobProfileNav from './JobProfileNav';
import './JobProfile.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Routes } from '../../utils';
import JobSummary from './JobSummary';
import JobTotals from './JobTotals';
import CustomerDetail from './CustomerDetail';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import { Link } from 'react-router-dom';
import { JobStatusEnum } from '../../types/JobStatusEnum';
import ForbiddenError from '../_common/Error/ForbiddenError';
import NotFoundError from '../_common/Error/NotFoundError';
import { useStoreContext } from '../App/StoreProvider';
import * as CarrierListActions from '../../store/actions/carrierList';
import {useParams} from 'react-router-dom';

const JobProfile = ({ match }: any): JSX.Element => {
    const authContext = useAuthContext();
    const [state, dispatch] = useStoreContext();
    const [isCustomerView, setIsCustomerView] = useState(true);
    const {
        auth: { user },
    } = state;
    const userProfile = user.profile;
    const opsCarrierUser = authContext.userHasPermission(PermissionEnum.Carrier);
    const {jobId} = useParams()
    const jobIdVal:any = jobId;
    const variables = {
     
        jobId: parseInt(jobIdVal),
    };
    const [tabTitle, setTabTitle] = useState(document.title);

    const formatDate = (text: string | null): string => (text ? moment(text).format('MMM DD, YYYY') : '');

    const isDisplayLoadsEnabled =
        authContext.userHasPermission(PermissionEnum.CustomerService) ||
        authContext.userHasPermission(PermissionEnum.Customer) ||
        authContext.userHasPermission(PermissionEnum.Admin) ||
        authContext.userHasPermission(PermissionEnum.Carrier) ||
        authContext.userHasPermission(PermissionEnum.Operation);

    const checkIsJobAccessvariables = {
        input: {
            jobId: parseInt(jobIdVal),
            driverCompanyId: opsCarrierUser ? Number(userProfile?.CarrierId) : 0,
        },
    };

    useEffect(() => {
        if (opsCarrierUser) {
            localStorage.setItem('carrierId', userProfile?.CarrierId);
            setIsCustomerView(false);
            if (userProfile.CarrierId) {
                dispatch(CarrierListActions.setCarrierIdSelection(Number(userProfile?.CarrierId)));
            }
        } else {
            const isRedirectToCustomer =
                localStorage.getItem('redirectbackto') && localStorage.getItem('redirectbackto') === 'carrier'
                    ? false
                    : true;
            setIsCustomerView(isRedirectToCustomer);
        }
    }, [opsCarrierUser]);

    if (opsCarrierUser) {
        return (
            <Query query={CHECK_ISJOBACCESSIBLE} variables={checkIsJobAccessvariables} fetchPolicy="cache-and-network">
                {({ loading, error, data }: QueryResult<CheckIsJobAccessible>): JSX.Element | null => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (data && data?.checkIsJobAccessible?.isAccessible) {
                        return (
                            <Query query={GET_JOB_PROFILE} variables={variables} fetchPolicy="cache-and-network">
                                {({ loading, error, data }: QueryResult<GetFullJobProfile>): JSX.Element | null => {
                                    if (loading) {
                                        return <Loading />;
                                    }

                                    if (error) {
                                        if (error.message.includes('NoAccess')) {
                                            return <ForbiddenError />;
                                        }
                                        if (error.message.includes('NoResults')) {
                                            return <NotFoundError />;
                                        }
                                        return <Error error={error} />;
                                    }

                                    let jobProfileData: JobProfileData | null;

                                    if (data && data.getJobProfile) {
                                        jobProfileData = data.getJobProfile;

                                        const {
                                            customerDetails,
                                            jobDetails,
                                            pumpScheduleSummary,
                                            totalBoxesForWells,
                                        } = jobProfileData;

                                        document.title =
                                            jobDetails && jobDetails.jobName ? jobDetails.jobName : tabTitle;
                                        localStorage.setItem('Jobdetails-TabName', document.title);
                                        setTabTitle(document.title);

                                        const customerId =
                                            customerDetails && customerDetails.customerId
                                                ? customerDetails.customerId
                                                : 0;

                                        const pumpScheduleSummaryList: PumpScheduleSummaryData[] = [];
                                        if (pumpScheduleSummary !== null && pumpScheduleSummary.length !== 0) {
                                            pumpScheduleSummary.forEach(
                                                (item: PumpScheduleSummaryData | null): void => {
                                                    if (item !== null) {
                                                        pumpScheduleSummaryList.push(item);
                                                    }
                                                },
                                            );
                                        }

                                        const jobDetailComponent = jobDetails ? (
                                            <JobDetail
                                                addressLine1={jobDetails.addressLine1}
                                                addressLine2={jobDetails.addressLine2}
                                                city={jobDetails.city}
                                                district={jobDetails!.district}
                                                latitude={jobDetails.latitude}
                                                longitude={jobDetails.longitude}
                                                region={jobDetails!.region}
                                                state={jobDetails.state}
                                                zip={jobDetails.zip}
                                                directions={jobDetails.directions}
                                            />
                                        ) : (
                                            <JobDetail />
                                        );

                                        const customerDetailComponent =
                                            customerDetails !== null ? (
                                                <CustomerDetail
                                                    customerName={customerDetails.customerName}
                                                    customerPo={customerDetails.customerPO}
                                                    epCompany={customerDetails.epCompany}
                                                    crew={customerDetails.crew}
                                                    contact={customerDetails.contact}
                                                    phoneNumber={customerDetails.phoneNumber}
                                                />
                                            ) : (
                                                <CustomerDetail />
                                            );

                                        return (
                                            <div className="JobProfile-Container">
                                                <div className="JobProfile-Header">
                                                    <div className="JobProfile-HeaderTitle">Job Profile</div>
                                                    <div className="Row JobProfile-ToggleButtonsContainer">
                                                        <div className="JobProfile-LeftToggleButton JobProfile-ActiveButton">
                                                            <p className="JobProfile-ButtonText">Job Details</p>
                                                        </div>
                                                        <Link
                                                            to={{
                                                                pathname: `${Routes.JOB_ACTIVITY.route}${jobIdVal}`,
                                                            }}
                                                            className={`JobProfile-CenterToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                                                'JobProfile-RightToggleButton-Disabled'}`}
                                                        >
                                                            Current Activity
                                                        </Link>
                                                        <Link
                                                            to={{
                                                                pathname: `${Routes.JOB_LOADS.route}${jobIdVal}`,
                                                            }}
                                                            className={`JobProfile-RightToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                                                'JobProfile-RightToggleButton-Disabled'}`}
                                                        >
                                                            Loads
                                                        </Link>
                                                    </div>
                                                </div>
                                                <div className="JobProfile-JobDetailsContainer">
                                                    <div className="JobProfile-JobNameContainer">
                                                        <div className="JobProfile-NameEditContainer">
                                                            <div className="JobProfile-JobName">
                                                                {jobDetails!.jobName || ''}
                                                            </div>
                                                        </div>
                                                        <div className="JobProfile-NameEditContainer">
                                                            <TextContent
                                                                title="MOB:"
                                                                value={formatDate(jobDetails!.mobDate)}
                                                            />
                                                            <TextContent
                                                                title="Start:"
                                                                value={formatDate(jobDetails!.wellStartDate)}
                                                            />
                                                            <TextContent
                                                                title="End:"
                                                                value={formatDate(jobDetails!.wellEndDate)}
                                                            />

                                                            <div className="JobProfile-StatusContainer">
                                                                <i className="material-icons">cached</i>
                                                                <div className="JobProfile-StatusTitle">
                                                                    {jobDetails!.status!.status}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <TextContent title="JDE #:" value={jobDetails!.jdeNumber || ''} /> */}
                                                    <div className="JobProfile-JobDetails">
                                                        {jobDetailComponent}
                                                        {customerDetailComponent}
                                                        <JobSummary
                                                            jobId={variables.jobId}
                                                            wellTotalBoxes={totalBoxesForWells || 0}
                                                        />
                                                        <JobTotals
                                                            loadsByWell={
                                                                pumpScheduleSummary
                                                                    ? pumpScheduleSummary.map(ps => ({
                                                                          wellName: ps ? ps.wellName : '',
                                                                          loadCount: ps ? ps.totalBoxes : 0,
                                                                      }))
                                                                    : []
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <div className="JobProfile-Nav">
                                                    <JobProfileNav
                                                        pumpScheduleSummary={pumpScheduleSummaryList}
                                                        jobId={parseInt(jobIdVal)}
                                                        region={jobDetails!.region}
                                                        customerId={customerId}
                                                        jobName={jobDetails!.jobName}
                                                        isJobCompleted={
                                                            jobDetails!.status!.status ===
                                                            JobStatusEnum.COMPLETED.toString()
                                                        }
                                                    />
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return <div></div>; // TODO: Show empty data view
                                    }
                                }}
                            </Query>
                        );
                    } else {
                        return <ForbiddenError />;
                    }
                }}
            </Query>
        );
    } else {
        return (
            <Query query={GET_JOB_PROFILE} variables={variables} fetchPolicy="cache-and-network">
                {({ loading, error, data }: QueryResult<GetFullJobProfile>): JSX.Element | null => {
                    if (loading) {
                        return <Loading />;
                    }

                    if (error) {
                        if (error.message.includes('NoAccess')) {
                            return <ForbiddenError />;
                        }
                        if (error.message.includes('NoResults')) {
                            return <NotFoundError />;
                        }
                        return <Error error={error} />;
                    }

                    let jobProfileData: JobProfileData | null;

                    if (data && data.getJobProfile) {
                        jobProfileData = data.getJobProfile;

                        const { customerDetails, jobDetails, pumpScheduleSummary, totalBoxesForWells } = jobProfileData;

                        document.title = jobDetails && jobDetails.jobName ? jobDetails.jobName : tabTitle;
                        localStorage.setItem('Jobdetails-TabName', document.title);
                        setTabTitle(document.title);

                        const customerId =
                            customerDetails && customerDetails.customerId ? customerDetails.customerId : 0;

                        const pumpScheduleSummaryList: PumpScheduleSummaryData[] = [];
                        if (pumpScheduleSummary !== null && pumpScheduleSummary.length !== 0) {
                            pumpScheduleSummary.forEach((item: PumpScheduleSummaryData | null): void => {
                                if (item !== null) {
                                    pumpScheduleSummaryList.push(item);
                                }
                            });
                        }

                        const jobDetailComponent = jobDetails ? (
                            <JobDetail
                                addressLine1={jobDetails.addressLine1}
                                addressLine2={jobDetails.addressLine2}
                                city={jobDetails.city}
                                district={jobDetails!.district}
                                latitude={jobDetails.latitude}
                                longitude={jobDetails.longitude}
                                region={jobDetails!.region}
                                state={jobDetails.state}
                                zip={jobDetails.zip}
                                directions={jobDetails.directions}
                            />
                        ) : (
                            <JobDetail />
                        );

                        const customerDetailComponent =
                            customerDetails !== null ? (
                                <CustomerDetail
                                    customerName={customerDetails.customerName}
                                    customerPo={customerDetails.customerPO}
                                    epCompany={customerDetails.epCompany}
                                    crew={customerDetails.crew}
                                    contact={customerDetails.contact}
                                    phoneNumber={customerDetails.phoneNumber}
                                />
                            ) : (
                                <CustomerDetail />
                            );

                        return (
                            <div className="JobProfile-Container">
                                <div className="JobProfile-Header">
                                    <div className="JobProfile-HeaderTitle">Job Profile</div>
                                    <div className="Row JobProfile-ToggleButtonsContainer">
                                        <div className="JobProfile-LeftToggleButton JobProfile-ActiveButton">
                                            <p className="JobProfile-ButtonText">Job Details</p>
                                        </div>
                                        <Link
                                            to={{
                                                pathname: `${Routes.JOB_ACTIVITY.route}${jobIdVal}`,
                                            }}
                                            className={`JobProfile-CenterToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                                'JobProfile-RightToggleButton-Disabled'}`}
                                        >
                                            Current Activity
                                        </Link>
                                        {isCustomerView && (
                                            <Link
                                                to={{
                                                    pathname: `${Routes.DAILY_SAND_ALLOCATIONS.route}${jobIdVal}`,
                                                }}
                                                className={`JobProfile-CenterToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                                    'JobProfile-RightToggleButton-Disabled'}`}
                                            >
                                                Sand Allocations
                                            </Link>
                                        )}
                                        <Link
                                            to={{
                                                pathname: `${Routes.JOB_LOADS.route}${jobIdVal}`,
                                            }}
                                            className={`JobProfile-RightToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                                'JobProfile-RightToggleButton-Disabled'}`}
                                        >
                                            Loads
                                        </Link>
                                    </div>
                                </div>
                                <div className="JobProfile-JobDetailsContainer">
                                    <div className="JobProfile-JobNameContainer">
                                        <div className="JobProfile-NameEditContainer">
                                            <div className="JobProfile-JobName">{jobDetails!.jobName || ''}</div>
                                        </div>
                                        <div className="JobProfile-NameEditContainer">
                                            <TextContent title="MOB:" value={formatDate(jobDetails!.mobDate)} />
                                            <TextContent title="Start:" value={formatDate(jobDetails!.wellStartDate)} />
                                            <TextContent title="End:" value={formatDate(jobDetails!.wellEndDate)} />

                                            <div className="JobProfile-StatusContainer">
                                                <i className="material-icons">cached</i>
                                                <div className="JobProfile-StatusTitle">
                                                    {jobDetails!.status!.status}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <TextContent title="JDE #:" value={jobDetails!.jdeNumber || ''} /> */}
                                    <div className="JobProfile-JobDetails">
                                        {jobDetailComponent}
                                        {customerDetailComponent}
                                        <JobSummary jobId={variables.jobId} wellTotalBoxes={totalBoxesForWells || 0} />
                                        <JobTotals
                                            loadsByWell={
                                                pumpScheduleSummary
                                                    ? pumpScheduleSummary.map(ps => ({
                                                          wellName: ps ? ps.wellName : '',
                                                          loadCount: ps ? ps.totalBoxes : 0,
                                                      }))
                                                    : []
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="JobProfile-Nav">
                                    <JobProfileNav
                                        pumpScheduleSummary={pumpScheduleSummaryList}
                                        jobId={parseInt(jobIdVal)}
                                        region={jobDetails!.region}
                                        customerId={customerId}
                                        jobName={jobDetails!.jobName}
                                        isJobCompleted={
                                            jobDetails!.status!.status === JobStatusEnum.COMPLETED.toString()
                                        }
                                    />
                                </div>
                            </div>
                        );
                    } else {
                        return <div></div>; // TODO: Show empty data view
                    }
                }}
            </Query>
        );
    }
};

export default JobProfile;
