import React, { useEffect, useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './LoadManagementHeader.scss';
import sortAsc from '../../../assets/sort_az.svg';
import sortDesc from '../../../assets/sort_za.svg';
import sortDefault from '../../../assets/sort_default.svg';
import { OverlayTrigger } from 'react-bootstrap';
import Image from 'react-bootstrap/Image';
import CarrierDropDown from '../../CarrierDropdown/CarrierDropdown';

interface SelectedLoad {
    loadId: number;
    loadNumber: number;
}

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

interface HeaderProps {
    onCheckAll: (checkedStatus: boolean) => void;
    selectedJobs: SelectedLoad[];
    toggleSortOrder: () => void;
    sortOrder: SortOrder | null;
    isSearchOnLoadId: boolean;
    setIsSearchOnLoadId: React.Dispatch<React.SetStateAction<boolean>>;
}

const sortIconSrc = (sortOrder: SortOrder | null): string => {
    if (sortOrder === null) return sortDefault;
    else return sortOrder === SortOrder.ASCENDING ? sortAsc : sortDesc;
};

const LoadManagementHeader = ({
    onCheckAll,
    selectedJobs,
    toggleSortOrder,
    sortOrder,
    isSearchOnLoadId,
    setIsSearchOnLoadId,
}: HeaderProps): JSX.Element => {
    return (
        <div className="Header-Container">
            <div className="Header-SearchBar">
                <CarrierDropDown></CarrierDropDown>
            </div>
            <div className="Header-ListHeader">
                <div className="JobLoadManagementHeader-SearchBar">
                    {/* // eslint-disable-next-line prettier/prettier */}
                    {/* <div className="row">
                        <div className="col-md-12">
                            <div className="JobLoadManagementHeader-Search-Filter">
                                <input
                                    type="radio"
                                    id="loadId"
                                    name="isLoadId"
                                    checked={isSearchOnLoadId}
                                    onChange={() => setIsSearchOnLoadId(true)}
                                />
                                <label htmlFor="loadId">Load Id</label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="JobLoadManagementHeader-Search-Filter">
                                <input
                                    type="radio"
                                    id="searchAll"
                                    name="isLoadId"
                                    checked={!isSearchOnLoadId}
                                    onChange={() => setIsSearchOnLoadId(false)}
                                />
                                <label htmlFor="searchAll">Search All</label>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>
            <div className="Header-RightOptions">
                <div className="Row Header-GroupLink">
                    {/* // eslint-disable-next-line prettier/prettier */}
                    {/* <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<div className="Header-Tooltip">Sort by Job Name</div>}
                    >
                        <div className="Row Header-GroupLink" onClick={(): void => toggleSortOrder()}>
                            <Image src={sortIconSrc(sortOrder)} className="Header-Sorting-Icon" />
                            <span className="TableListHeader-Link">Sort</span>
                        </div>
                    </OverlayTrigger> */}
                </div>
            </div>
        </div>
    );
};

export default LoadManagementHeader;
