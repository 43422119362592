import React, { FC } from 'react';
import { CarrierOption, Option, CPOption } from '../Users/interface';
import { GetAllDriversListByFilter_getAllDriversListByFilter as Driver } from '../../types/schemaTypes';
import { useLazyLoading } from '../_common';
import DriverItem from './DriverItem';

interface DriverListProps {
    drivers: Driver[];
    cpOptions: CPOption[];
    options: Option[];
    carrierOptions: CarrierOption[];
    isLoading: boolean;
    canEditUsers: boolean;
    isOperationUser: boolean;
    isEndOfResults: boolean;
    refetched: boolean;
    setRefetched: React.Dispatch<React.SetStateAction<boolean>>;
    onEditClick: (driver: Driver) => void;
}

const UserList: FC<DriverListProps> = ({
    drivers,
    cpOptions,
    options,
    isLoading,
    isEndOfResults,
    refetched,
    setRefetched,
    onEditClick,
    canEditUsers,
    isOperationUser,
    carrierOptions,
}: DriverListProps): JSX.Element => {
    return (
        <>
            {drivers.map((driver, index) => (
                <DriverItem
                    key={driver.id ? driver.id.toString() : '0'}
                    index={index}
                    canEditUsers={canEditUsers}
                    isOperationUser={isOperationUser}
                    driver={driver}
                    options={options}
                    cpOptions={cpOptions}
                    carrierOptions={carrierOptions}
                    onEditClick={onEditClick}
                ></DriverItem>
            ))}
        </>
    );
};

export default UserList;
