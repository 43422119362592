import React, { useCallback, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import SearchBar from '../SearchBar';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';
import { ModalForm, Option, CPOption, CarrierOption } from './interface';
import { Alert } from 'react-bootstrap';
import {
    GET_B2C_USER_FILTERED,
    GET_ROLES,
    GET_ALL_CUSTOMER_PARENTS,
    GET_ALL_DRIVER_COMPANY,
} from '../../graphql/client/queries';
import {
    GetRoles_getRoles as Role,
    GetAllCustomerParents_getAllCustomerParents as CustomerParent,
    GetB2CUserFiltered_getB2CUserFiltered as B2CUser,
    GetB2CUserFiltered,
    GetDriverCompanies_getDriverCompanies as DriverCompany,
} from '../../types/schemaTypes';
import UserList from './UserList';
import { Error, Loading } from '../_common';
import AddUserModal from './AddEditUserModal/AddEditUserModal';
import './Users.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { useQuery } from '@apollo/client';
import { useStoreContext } from '../App/StoreProvider';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import { useMutation } from '@apollo/client';
import { DELETE_B2C_USER } from '../../graphql/client/mutations';

import ConfirmDispatchModal from './ComfirmModal/DispatchModal';
import ForbiddenError from '../_common/Error/ForbiddenError';
import { UserFilters } from '../Filter';
import {  ToastContainer, toast } from 'react-toastify';
import {toastOpt} from '../../utils/helpers/toastHelper'

const toasterOptions = { duration: 2500 };
const Messages = {
    delete: 'User deleted successfully',
    userAdded: 'User added successfully',
    userEdited: 'User details saved successfully',
};
const ErrorMessages = {
    delete: 'Failed to delete User',
};
let selectedUser: B2CUser | undefined;

const PAGE_SIZE = 50;

const Users = (): JSX.Element => {
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [modalForm, setModalForm] = useState(ModalForm.EMPTY);
    const [isEndOfResults, setIsEndOfResults] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [tabTitle, setTabTitle] = useState(document.title);
    const [isResetPassword, setIsResetPassword] = useState(false);
    const [showDispatchModal, setDispatchModal] = useState(false);
    document.title = 'SBX OPS Portal';
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCustomerService = useAuthContext().userHasPermission(PermissionEnum.CustomerService);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);
    const canEditUsers = isAdmin || isCustomerService || isOperationUser;
    const [refetched, setRefetched] = useState(false);
    const [state] = useStoreContext();
    const abortController = new AbortController();
    const [listHeight, setListHeight] = useState(window.screen.height);

    const {
        userList: { searchString, customer, carrier, role, status },
    } = state;

    const variables = {
        input: {
            searchString,
            isOperationUserView: isOperationUser ? true : false,
            customer,
            carrier,
            role,
            status,
        },
    };

    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose}>
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

    const onCompleted = action => {
        toast.success(Messages[action],toastOpt);
    //    toaster.notify(renderToast(Messages[action], 'success'), toasterOptions);
    };

    const onActionError = action => {
        toast.error(ErrorMessages[action],toastOpt);
       // toaster.notify(renderToast(ErrorMessages[action], 'danger'), toasterOptions);
    };

    const checkIfPassword = () => {
        if (isResetPassword) {
            setDispatchModal(true);
        }
    };

    const handleClickHide = (): void => {
        setDispatchModal(false);
        setIsResetPassword(false);
    };

    const handleOnHide = (): void => {
        selectedUser = undefined;
        setErrorMessage('');
        setShowAddUserModal(false);
    };

    const onUserSaved = () => {
        !isResetPassword && onCompleted(selectedUser ? 'userEdited' : 'userAdded');
        selectedUser = undefined;
        setErrorMessage('');
        setShowAddUserModal(false);
        setRefetched(true);
    };

    const [deleteUser] = useMutation(DELETE_B2C_USER, {
        onCompleted: () => {
            onCompleted('delete');
            setRefetched(true);
        },
        onError: () => onActionError('delete'),
        refetchQueries: () => {
            return [
                {
                    query: GET_B2C_USER_FILTERED,
                    variables: { input: { variables } },
                },
            ];
        },
    });

    const onDelete = useCallback(async (record: any) => {
        const variables = { input: { userId: record.userId } };
        await deleteUser({ variables });
    }, []);

    const { data: customerParentData } = useQuery(GET_ALL_CUSTOMER_PARENTS);
    const cpOptions: CPOption[] = [];
    if (customerParentData && customerParentData.getAllCustomerParents) {
        customerParentData.getAllCustomerParents.forEach((element: CustomerParent) => {
            if (element.id !== null) {
                const id = element.id;
                const displayName = element && element.customerName ? element.customerName : '<None>';
                const jdeId = element && element.extSystemIdentifier ? element.extSystemIdentifier : '<None>';
                cpOptions.push({
                    id: id,
                    name: displayName,
                    jdeId: jdeId,
                });
            }
        });
    }

    const { data: driverComapniesData } = useQuery(GET_ALL_DRIVER_COMPANY);
    const carrierOptions: CarrierOption[] = [];
    if (driverComapniesData && driverComapniesData.getDriverCompanies) {
        driverComapniesData.getDriverCompanies.forEach((element: DriverCompany) => {
            if (element.id !== null) {
                const id = element.id;
                const displayName = element && element.companyName ? element.companyName : '<None>';
                carrierOptions.push({
                    id: id,
                    name: displayName,
                });
            }
        });
    }

    const { data: rolesData } = useQuery(GET_ROLES);
    const options: Option[] = [];
    if (rolesData && rolesData.getRoles) {
        rolesData.getRoles.forEach((element: Role) => {
            if (element.roleId !== null) {
                const id = element.roleId;
                const displayName = element && element.roleName ? element.roleName : '<None>';

                options.push({
                    id: id,
                    name: displayName,
                });
            }
        });
    }
    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };

    document.title = 'Users';

    const abortRequest = (): void => {
        abortController.abort();
    };

    if (!canEditUsers && !isOperationUser) {

        return <ForbiddenError />;
    }
    return (
        <Query query={GET_B2C_USER_FILTERED} variables={variables} fetchPolicy="network-only">
            {({
                error,
                data,
                loading,
                refetch,
                fetchMore,
            }: QueryResult<GetB2CUserFiltered | undefined>): JSX.Element | null => {
                if (error) {
                    return <Error error={error} />;
                }

                const users: B2CUser[] = [];

                if (data && data.getB2CUserFiltered && data.getB2CUserFiltered.length !== 0) {
                    data.getB2CUserFiltered.forEach((element: B2CUser | null): void => {
                        if (element !== null) {
                            users.push(element);
                        }
                    });
                }

                const offset = data && data.getB2CUserFiltered ? data.getB2CUserFiltered.length / PAGE_SIZE : 0;

                if (
                    offset % 1 !== 0 ||
                    (!isEndOfResults && data && data.getB2CUserFiltered && data.getB2CUserFiltered.length < PAGE_SIZE)
                ) {
                    // If offset is not a whole number, the end of the results has been reached. (8/22/19) - BS
                    setIsEndOfResults(true);
                //    React.useEffect(() => {
                //     if (
                //         offset % 1 !== 0 ||
                //         (!isEndOfResults && data && data.getB2CUserFiltered && data.getB2CUserFiltered.length < PAGE_SIZE)
                //     ) {
                //         setIsEndOfResults(true);
                //     }
                // }, [offset]);
                }

                return (
                    <div className="Users-Container">
                        <ToastContainer/>
                        <div className="Header-Container">
                            <div className="Header-SearchBar">
                                <SearchBar type={SearchTypeEnum.User}></SearchBar>
                            </div>
                            <div className="Header-ListHeader">
                                {canEditUsers && (
                                    <div
                                        className="Users-Create-New-User Row"
                                        onClick={() => {
                                            setShowAddUserModal(true);
                                            errorMessage && setErrorMessage('');
                                            setModalForm(ModalForm.ADD_USER);
                                        }}
                                    >
                                        <i className="material-icons Users-Icon">add_circle</i>
                                        <span className="Users-Link">Create New User</span>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="JobManagement-Body">
                            <div id="jobsFilterContainer" className="JobManagement-Filter">
                                <UserFilters
                                    abortRequest={abortRequest}
                                    onFilterToggle={setListContainerHeight}
                                    carrierOptions={carrierOptions}
                                    cpOptions={cpOptions}
                                    roles={options}
                                />
                            </div>
                            <div id="jobsListContainer" className="JobManagement-List">
                                <div className="Users-Bottom-Container">
                                    {isOperationUser && (
                                        <div className="Users-Bottom-Header-Container">
                                            <div className="Users-Header-Title Users-Header-Title-Lg">Name</div>
                                            <div className="Users-Header-Title Users-Header-Title-Xl">Email</div>
                                            <div className="Users-Header-Title Users-Header-Title-Lg">Carrier Name</div>
                                            <div className="Users-Header-Title Users-Header-Title">Role</div>
                                            <div className="Users-Header-Title Users-Header-Title">Status</div>
                                            <div className="Users-Header-Title Users-Header-Title">Actions</div>
                                        </div>
                                    )}
                                    {!isOperationUser && (
                                        <div className="Users-Bottom-Header-Container">
                                            <div className="Users-Header-Title Users-Header-Title-Lg">Name</div>
                                            <div className="Users-Header-Title Users-Header-Title-Xl">Email</div>
                                            <div className="Users-Header-Title Users-Header-Title-Lg">
                                                Customer Name
                                            </div>
                                            <div className="Users-Header-Title Users-Header-Title-Lg">JDE Number</div>
                                            <div className="Users-Header-Title Users-Header-Title-Lg">Carrier Name</div>
                                            <div className="Users-Header-Title">Role</div>
                                            <div className="Users-Header-Title">Status</div>
                                            <div className="Users-Header-Title">Actions</div>
                                        </div>
                                    )}

                                    {loading && (
                                        <div className="SpinnerContainer">
                                            <Loading />
                                        </div>
                                    )}

                                    {users.length > 0 && (
                                        <UserList
                                            users={users}
                                            options={options}
                                            cpOptions={cpOptions}
                                            carrierOptions={carrierOptions}
                                            isLoading={loading}
                                            canEditUsers={canEditUsers}
                                            isOperationUser={isOperationUser}
                                            refetch={refetch}
                                            refetched={refetched}
                                            setRefetched={setRefetched}
                                            isEndOfResults={isEndOfResults}
                                            onEditClick={user => {
                                                setShowAddUserModal(true);
                                                errorMessage && setErrorMessage('');
                                                setModalForm(ModalForm.EDIT_USER);
                                                selectedUser = user;
                                            }}
                                            onDelete={onDelete}
                                            onLoadMore={() =>
                                                fetchMore({
                                                    variables: {
                                                        offset,
                                                    },
                                                    updateQuery: (prev, { fetchMoreResult }) => {
                                                        if (
                                                            prev &&
                                                            fetchMoreResult &&
                                                            prev.getB2CUserFiltered &&
                                                            fetchMoreResult.getB2CUserFiltered
                                                        ) {
                                                            return Object.assign({}, prev, {
                                                                getB2CUserFiltered: [
                                                                    ...prev.getB2CUserFiltered,
                                                                    ...fetchMoreResult.getB2CUserFiltered,
                                                                ],
                                                            });
                                                        } else {
                                                            return prev;
                                                        }
                                                    },
                                                })
                                            }
                                        />
                                    )}
                                    <AddUserModal
                                        show={showAddUserModal}
                                        modalForm={modalForm}
                                        options={options}
                                        cpOptions={cpOptions}
                                        carrierOptions={carrierOptions}
                                        selectedUser={selectedUser}
                                        isOperationUser={isOperationUser}
                                        onClickHide={handleOnHide}
                                        onUserSaved={onUserSaved}
                                        checkIfPassword={checkIfPassword}
                                        setIsResetPassword={setIsResetPassword}
                                        errorMessage={errorMessage}
                                        setErrorMessage={setErrorMessage}
                                        userId={selectedUser?.userId == null ? 0 : selectedUser.userId}
                                    />
                                    <ConfirmDispatchModal
                                        isResetPassword={true}
                                        show={showDispatchModal}
                                        onClickHide={handleClickHide}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default Users;
