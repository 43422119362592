import { Reducer } from 'react';
import { Actions } from '../../actions/addLoadModal';
import { Action, AddLoadModalState } from '../../types';
import { cloneDeep } from 'lodash';

const initialAddLoadModalState: AddLoadModalState = {
    currentLoadId: 0,
    loadIds: [],
    tmsIds: [],
    loadsWithDrivers: [],
    numberOfLoadsToCreate: 0,
    generalErrorMessage: '',
    failedLoadIds: [],
    currentDriverIds: [],
};

const setNumberOfLoadsToCreate = (payload: number, state: AddLoadModalState): AddLoadModalState => {
    return {
        ...state,
        numberOfLoadsToCreate: payload,
    };
};

const removeLoadIds = (payload: number[], state: AddLoadModalState): AddLoadModalState => {
    const currentDriverIds = state.loadsWithDrivers.map(load => {
        return load.driverId;
    });
    const newDriverIds = state.currentDriverIds.filter(id => {
        return !currentDriverIds.includes(id);
    });
    const newLoadsWithDrivers = state.loadsWithDrivers.filter(load => {
        return !payload.includes(load.id);
    });
    return {
        ...state,
        loadsWithDrivers: newLoadsWithDrivers,
        currentDriverIds: newDriverIds,
    };
};

const setTmsIds = (payload: number[], state: AddLoadModalState): AddLoadModalState => {
    return {
        ...state,
        tmsIds: payload,
    };
};

const setCreatedLoadIds = (payload: number[], state: AddLoadModalState): AddLoadModalState => {
    const toBeAdded = cloneDeep(state.tmsIds);
    const loadAndDriverList = payload.map(id => {
        if (toBeAdded.length > 0) {
            let i = -1;
            i++;
            return {
                id,
                driverName: '',
                driverId: 0,
                secondaryDriverName: '',
                secondaryDriverId: 0,
                tmsId: toBeAdded[i],
                errorMessage: '',
            };
        } else {
            return {
                id,
                driverName: '',
                driverId: 0,
                secondaryDriverName: '',
                secondaryDriverId: 0,
                tmsId: 0,
                errorMessage: '',
            };
        }
    });

    return {
        ...state,
        loadIds: payload,
        loadsWithDrivers: loadAndDriverList,
    };
};

const setCurrentLoadId = (payload: number, state: AddLoadModalState): AddLoadModalState => {
    return {
        ...state,
        currentLoadId: payload,
    };
};

const assignDriverToLoad = (
    payload: {
        loadId: number;
        driverName: string;
        driverId: number;
        isPrimaryDriver: boolean;
        primaryDriverName: string;
    },
    state: AddLoadModalState,
): AddLoadModalState => {
    const newLoadsWithDrivers = state.loadsWithDrivers.map(load => {
        if (load.id === payload.loadId) {
            if (payload.isPrimaryDriver) {
                load.driverName = payload.driverName;
                load.driverId = payload.driverId;
            } else if (!payload.isPrimaryDriver) {
                load.secondaryDriverName = payload.driverName;
                load.secondaryDriverId = payload.driverId;
                load.driverName = payload.primaryDriverName;
            }
        }
        return load;
    });
    return {
        ...state,
        loadsWithDrivers: newLoadsWithDrivers,
    };
};

const removeDriverFromLoad = (
    payload: {
        loadId: number;
    },
    state: AddLoadModalState,
): AddLoadModalState => {
    const currentDriverId = state.loadsWithDrivers.filter(load => load.id === payload.loadId)[0];
    const newDriverIds = state.currentDriverIds.filter(id => currentDriverId.driverId !== id);
    const newLoadsWithDrivers = state.loadsWithDrivers.map(load => {
        if (load.id === payload.loadId) {
            load.driverName = '';
            load.driverId = 0;
            load.driverName = '';
        }
        return load;
    });
    return {
        ...state,
        loadsWithDrivers: newLoadsWithDrivers,
        currentDriverIds: newDriverIds,
    };
};

const addFailedLoadId = (
    payload: {
        loadId: number;
    },
    state: AddLoadModalState,
): AddLoadModalState => {
    const toBeAdded = cloneDeep(state.failedLoadIds);
    toBeAdded.push(payload.loadId);
    return {
        ...state,
        failedLoadIds: toBeAdded,
    };
};

const setErrorMessageForLoad = (
    payload: {
        loadId: number;
        errorMessage: string;
    },
    state: AddLoadModalState,
): AddLoadModalState => {
    const newLoadsWithDrivers = state.loadsWithDrivers.map(load => {
        if (load.id === payload.loadId) {
            load.errorMessage = payload.errorMessage;
        }
        return load;
    });
    return {
        ...state,
        loadsWithDrivers: newLoadsWithDrivers,
    };
};

const removeSuccessfulLoadsFromList = (state: AddLoadModalState): AddLoadModalState => {
    const failedLoads = cloneDeep(state.failedLoadIds);
    const newLoadsWithDrivers = state.loadsWithDrivers.filter(load => {
        return failedLoads.includes(load.id);
    });
    const newLoadIds = state.loadIds.filter(it => {
        return failedLoads.includes(it);
    });
    return {
        ...state,
        loadsWithDrivers: newLoadsWithDrivers,
        loadIds: newLoadIds,
    };
};

const addDriverIdToDriverList = (
    payload: {
        driverId: number;
    },
    state: AddLoadModalState,
): AddLoadModalState => {
    const toBeAdded = cloneDeep(state.currentDriverIds);
    toBeAdded.push(payload.driverId);
    return {
        ...state,
        currentDriverIds: toBeAdded,
    };
};

const setDriverIds = (payload: number[], state: AddLoadModalState): AddLoadModalState => {
    return {
        ...state,
        currentDriverIds: payload,
    };
};

const setFailedLoadIds = (payload: number[], state: AddLoadModalState): AddLoadModalState => {
    return {
        ...state,
        failedLoadIds: payload,
    };
};

const addLoadModalReducer: Reducer<AddLoadModalState, Action> = (state, action): AddLoadModalState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.SET_NUMBER_OF_LOADS_TO_CREATE:
            newState = setNumberOfLoadsToCreate(action.payload, state);
            break;
        case Actions.SET_TMS_IDS:
            newState = setTmsIds(action.payload, state);
            break;
        case Actions.SET_CREATED_IDS_LIST:
            newState = setCreatedLoadIds(action.payload, state);
            break;
        case Actions.SET_CURRENT_LOAD_ID:
            newState = setCurrentLoadId(action.payload, state);
            break;
        case Actions.ASSIGN_DRIVER_TO_LOAD:
            newState = assignDriverToLoad(action.payload, state);
            break;
        case Actions.REMOVE_LOAD_IDS:
            newState = removeLoadIds(action.payload, state);
            break;
        case Actions.REMOVE_DRIVER_FROM_LOAD:
            newState = removeDriverFromLoad(action.payload, state);
            break;
        case Actions.ADD_FAILED_LOAD_ID:
            newState = addFailedLoadId(action.payload, state);
            break;
        case Actions.SET_ERROR_MESSAGE_FOR_A_LOAD:
            newState = setErrorMessageForLoad(action.payload, state);
            break;
        case Actions.REMOVE_SUCCESSFULE_LOADS_FROM_LIST:
            newState = removeSuccessfulLoadsFromList(state);
            break;
        case Actions.ADD_DRIVER_ID_TO_DRIVER_LIST:
            newState = addDriverIdToDriverList(action.payload, state);
            break;
        case Actions.SET_DRIVER_IDS:
            newState = setDriverIds(action.payload, state);
            break;
        case Actions.SET_FAILED_LOAD_IDS:
            newState = setFailedLoadIds(action.payload, state);
            break;
    }

    return newState;
};

export { initialAddLoadModalState, addLoadModalReducer };
