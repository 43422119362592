import gql from 'graphql-tag';

export default gql`
    query GetAllCustomerParents($input: String) {
        getAllCustomerParents(input: $input) {
            id
            customerName
            enabled
            isDeleted
            extSystemName
            extSystemIdentifier
        }
    }
`;
