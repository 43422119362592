import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Routes } from '../../../../utils';
import NavBarItem from './NavBarItem';
import NavBarItemWithOverlay from './NavBarItemWithOverlay';
import NavBarDropdown from './NavBarDropdown';
import SandboxLogo from '../../../../assets/SandBoxSystem.png';
import { useAuthContext } from '../../../Auth/AuthProvider';
import { PermissionEnum } from '../../../../types/PermissionEnum';
import 'material-design-icons/iconfont/material-icons.css';
import './NavBar.scss';
import { useNavigate  } from 'react-router';
import { useLocation } from 'react-router-dom';

export const NavBar = (): JSX.Element => {
    const [selectedTab, setSelectedTab] = useState<any>(window.location.pathname);
    const isSystemAdminActive: boolean = selectedTab === `${Routes.SYSTEM_ADMIN_USERS.route}`;
    const isJobProfileActive: boolean =
        selectedTab.indexOf(Routes.JOB_PROFILE.route) !== -1 ||
        selectedTab.indexOf(Routes.JOB_ACTIVITY.route) !== -1 ||
        selectedTab.indexOf(Routes.JOB_LOADS.route) !== -1 ||
        selectedTab.indexOf(Routes.DAILY_SAND_ALLOCATIONS.route) !== -1;
    const isLoadProfileActive: boolean = selectedTab.indexOf(Routes.LOAD_PROFILE.route) !== -1;
    const isReportingActive: boolean = selectedTab === `${Routes.DRIVER_REPORT.route}`;
    const isProfileActive: boolean =
        selectedTab === `${Routes.PROFILE.route}` || selectedTab === `${Routes.CHANGE_PASSWORD.route}`;
    const profileHeader = (
        <div className="NavBar-ProfileIconContainer">
            <i className="material-icons NavBar-ProfileIcon">person</i>
        </div>
    );
    const authContext = useAuthContext();
    const opsAdmin = authContext.userHasPermission(PermissionEnum.Admin);
    const opsCustomer = authContext.userHasPermission(PermissionEnum.Customer);
    const opsCustomerService = authContext.userHasPermission(PermissionEnum.CustomerService);
    const opsOperation = authContext.userHasPermission(PermissionEnum.Operation);
    const opsCarrier = authContext.userHasPermission(PermissionEnum.Carrier);
    const [menuDropDownValue, setMenuDropDownValue] = useState('customer');

    const history = useNavigate ();
    const location = useLocation();
    // const canViewUsers = authContext.userHasPermission(PermissionEnum.ViewUsers);
    // const canViewPermissions = authContext.userHasPermission(PermissionEnum.ViewPermissions);
    // const canViewLofas = authContext.userHasPermission(PermissionEnum.ViewLOFA);
    // const canViewCustomers = authContext.userHasPermission(PermissionEnum.ViewCustomer);
    // const canViewCrews = authContext.userHasPermission(PermissionEnum.ViewCrew);
    // const canViewProppants = authContext.userHasPermission(PermissionEnum.ViewProppant);
    // const canViewDrivers = authContext.userHasPermission(PermissionEnum.ViewDriver);
    // const canViewCustomerParent = authContext.userHasPermission(PermissionEnum.ViewCustomerParent);
    // const canViewCarriers = authContext.userHasPermission(PermissionEnum.ViewDriverCompany);
    // const canViewSetting = authContext.userHasPermission(PermissionEnum.ViewSettings);

    const isAdmin = opsAdmin;
    const isCustomerService = opsCustomerService;
    const isOperation = opsOperation;
    const isCarrier = opsCarrier;
    const isCustomer = opsCustomer;
    const showSubMenus = !isSystemAdminActive && !isJobProfileActive && !isLoadProfileActive;
    // const canViewJob = authContext.userHasPermission(PermissionEnum.ViewJob);
    // const canEditJob = authContext.userHasPermission(PermissionEnum.EditJob);
    // const canViewMessages = authContext.userHasPermission(PermissionEnum.ViewMessages);

    const onSelectMenuDropDown = value => {
        setMenuDropDownValue(value);
        if (value === 'customer') {
            resetCarrierIdSelection();
            setSelectedTab('/dashboard');
             history('/dashboard');
              //  history.push('/dashboard');
        } else {
            setSelectedTab('/carrier-dashboard');
            history('/carrier-dashboard');
         //   history.push('/carrier-dashboard');
        }
    };

    const resetCarrierIdSelection = () => {
        localStorage.removeItem('carrierId');
    };

    useEffect(() => {
        if (location.pathname === '/') {
            if (isAdmin || isCustomer) {
                onSelectMenuDropDown('customer');
            } else if (isCustomerService || isOperation || isCarrier) {
                onSelectMenuDropDown('carrier');
            }
        } else {
            setSelectedTab(location.pathname);
            if (location.pathname === '/dashboard') {
                resetCarrierIdSelection();
                setMenuDropDownValue('customer');
            } else {
                setMenuDropDownValue('carrier');
            }
        }
    }, [isAdmin, isCustomerService, isOperation, isCarrier, location]);

    const redirectFromProfile =
        localStorage.getItem('redirectbackto') && localStorage.getItem('redirectbackto') === 'carrier' ? 'carrier' : '';

    return (
        <Navbar
            expand="lg"
            className="NavBar-Container"
            onSelect={(eventKey: string | null): void => {
                setSelectedTab(eventKey);
            }}
        >
            <Navbar.Brand
                href={
                    isCustomer
                        ? Routes.DASHBOARD.route
                        : isAdmin && redirectFromProfile === ''
                        ? Routes.DASHBOARD.route
                        : isAdmin && redirectFromProfile === 'carrier'
                        ? Routes.CARRIERDASHBOARD.route
                        : Routes.CARRIERDASHBOARD.route
                }
            >
                <img alt="" src={SandboxLogo} className="d-inline-block align-top" width="305" height="58" />
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
                {/* <p>{menuDropDownValue}</p> */}
                <Nav className="mr-auto NavBar-linksContainer">
                    {showSubMenus && isAdmin && (
                        <select
                            className="form-control NavBar-Dropdown"
                            onChange={e => onSelectMenuDropDown(e.target.value)}
                            value={menuDropDownValue}
                        >
                            <option value={'customer'}>Customer</option>
                            <option value={'carrier'}>Carrier</option>
                        </select>
                    )}
                    {showSubMenus &&
                        (isAdmin || isCustomer || isCustomerService) &&
                        menuDropDownValue === 'customer' && (
                            <NavBarItem
                                href={Routes.DASHBOARD.route}
                                title="Dashboard"
                                isActive={selectedTab === Routes.DASHBOARD.route}
                                isDisabled={false}
                            />
                        )}
                    {showSubMenus && (isAdmin || isOperation || isCarrier) && menuDropDownValue === 'carrier' && (
                        <NavBarItem
                            href={Routes.CARRIERDASHBOARD.route}
                            title="Carrier"
                            isActive={selectedTab === Routes.CARRIERDASHBOARD.route}
                            isDisabled={false}
                        />
                    )}
                    {showSubMenus && (isAdmin || isOperation || isCarrier) && menuDropDownValue === 'carrier' && (
                        <NavBarItem
                            href={Routes.JOB_MANAGEMENT.route}
                            title="Jobs"
                            isActive={
                                selectedTab === Routes.JOB_MANAGEMENT.route ||
                                selectedTab.startsWith(Routes.JOB_PROFILE.route) ||
                                selectedTab.startsWith(Routes.JOB_LOADS.route)
                            }
                        />
                    )}
                    {showSubMenus && (isAdmin || isOperation || isCarrier) && menuDropDownValue === 'carrier' && (
                        <NavBarItem
                            href={Routes.LOAD_MANAGEMENT.route}
                            title="Loads"
                            isActive={
                                selectedTab === Routes.LOAD_MANAGEMENT.route ||
                                selectedTab.startsWith(Routes.LOAD_PROFILE.route) ||
                                selectedTab.startsWith(Routes.LOAD_HISTORY.route)
                            }
                            // isDisabled={!authContext.userHasPermission(PermissionEnum.ViewLoads)}
                        />
                    )}

                    {showSubMenus && (isAdmin || isOperation || isCarrier) && menuDropDownValue === 'carrier' && (
                        <NavBarItem
                            href={Routes.CHASSIS_CHECKOUT.route}
                            title="Chassis Checkout"
                            isActive={selectedTab === Routes.CHASSIS_CHECKOUT.route}
                            // isDisabled={!authContext.userHasPermission(PermissionEnum.ViewLoads)}
                        />
                    )}

                    {(isAdmin || isOperation) && !isCustomerService && (
                        <NavBarDropdown
                            title={Routes.SYSTEM_ADMIN.title}
                            isActive={isSystemAdminActive}
                            routes={[Routes.SYSTEM_ADMIN_USERS, Routes.DRIVERS]}
                        />
                    )}

                    {isCarrier && !isCustomerService && (
                        <NavBarDropdown
                            title={Routes.SYSTEM_ADMIN.title}
                            isActive={isSystemAdminActive}
                            routes={[Routes.DRIVERS]}
                        />
                    )}

                    {isCustomerService && (
                        <NavBarDropdown
                            title={Routes.SYSTEM_ADMIN.title}
                            isActive={isSystemAdminActive}
                            routes={[Routes.SYSTEM_ADMIN_USERS]}
                        />
                    )}
                </Nav>
                <Nav>
                    {/* <NavBarItemWithOverlay>
                        <Nav.Link href={Routes.EMAIL.route} disabled>
                            <i className="material-icons NavBar-NotificationIcon">email</i>
                        </Nav.Link>
                    </NavBarItemWithOverlay>*/}
                    <NavBarDropdown header={profileHeader} isActive={isProfileActive} routes={[Routes.HOME]} />
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    );
};

export default NavBar;
