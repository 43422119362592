import React, { useEffect, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { useLazyQuery ,QueryResult, useMutation, useQuery} from '@apollo/client';
import JobLoadsManagementHeader from '../../Header/JobLoadsManagementHeader';
import { GET_LOADS_BY_JOB_ID, GET_LOADS_CSV } from '../../../graphql/client/queries';
import { GetLoadList, GetLoadList_getLoadListByJobId as Load, SortOrder } from '../../../types/schemaTypes';
import LoadList from '../../TableList/LoadList';
import './LoadManagement.scss';
import { Error } from '../../_common';
import { JobLoadsFilters } from '../../Filter';
import { useStoreContext } from '../../App/StoreProvider';
import { Base64 } from 'js-base64';

import { LoadStatusEnum } from '../../../types/LoadStatusEnum';
import ForbiddenError from '../../_common/Error/ForbiddenError';
import NotFoundError from '../../_common/Error/NotFoundError';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { useAuthContext } from '../../Auth/AuthProvider';
import { useParams } from 'react-router-dom';

// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

const LoadManagement = ({ match }: any): JSX.Element => {
    const loadIds: any = [];
    const [state] = useStoreContext();
    const [loadsJobName, setLoadsJobName] = useState('');
    const [tabTitle, setTabTitle] = useState(document.title);
    const abortController = new AbortController();
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [isSearchOnLoadId, setIsSearchOnLoadId] = useState(true);
    const [csvCalled, setCsvCalled] = useState(false);
    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const authContext = useAuthContext();
    const opsCarrierUser = authContext.userHasPermission(PermissionEnum.Carrier);
    const {
        auth: { user },
        loadList: { filters, searchPhrase },
    } = state;

    const {jobId} = useParams()
    const jobIdVal:any = jobId;
    const userProfile = user.profile;
    const selectedCarrierId = opsCarrierUser
        ? Number(userProfile?.CarrierId)
        : parseInt(localStorage.getItem('carrierId') || '0');

    const variables = {
        input: {
            jobId: parseInt(jobIdVal),
            carriers: selectedCarrierId && selectedCarrierId !== 0 ? [selectedCarrierId] : [],
            drivers: filters.driverIds,
            lofas: filters.lofaIds,
            proppants: filters.proppantIds,
            statuses: filters.statuses,
            atDestinationOutFrom: filters.fromDate,
            atDestinationOutTo: filters.toDate,
            isSearchOnLoadId: searchPhrase ? isSearchOnLoadId : true,
            search: searchPhrase,
        },
    };

    const context = {
        fetchOptions: {
            signal: abortController.signal,
        },
    };

    const abortRequest = (): void => {
        abortController.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('loadsFilterContainer');
        const listDiv = document.getElementById('loadsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };

    const downloadCsv = (csvExport): Blob => {
        const result = csvExport.getLoadListCSV.csv;
        const decodedCsv = Base64.decode(result);
        const file = new Blob([decodedCsv], { type: 'application/csv' });
        return file;
    };

    const getCurrentDate = (): string => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const strDay = day < 10 ? `0${day}` : `${day}`;
        const strMonth = month < 10 ? `0${month}` : `${month}`;
        return `${year}${strMonth}${strDay}`;
    };

    const toggleSortOrder = (): void => {
        if (sortOrder === null) {
            setSortOrder(SortOrder.ASCENDING);
        } else if (sortOrder === SortOrder.ASCENDING) {
            setSortOrder(SortOrder.DESCENDING);
        } else {
            setSortOrder(null);
        }
    };

    const { loading: csvLoading, data: csvExport } = useQuery(GET_LOADS_CSV, {
        onCompleted: () => {
            if (csvCalled && csvExport) {
                const file: Blob = downloadCsv(csvExport);
                const url = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = url;
                a.download = `List of Loads for Job ${jobIdVal}_${getCurrentDate()}.csv`;
                a.click();
                setCsvCalled(false);
            }
        },
        variables: {
            input: {
                jobId: parseInt(jobIdVal),
                search: searchPhrase ?? '',
                statuses: filters.statuses ?? '',
                proppants: filters.proppantIds ?? '',
                lofas: filters.lofaIds ?? '',
                atDestinationOutFrom: filters.fromDate ?? '',
                atDestinationOutTo: filters.toDate ?? '',
                carriers: selectedCarrierId && selectedCarrierId !== 0 ? [selectedCarrierId] : [],
            },
        },
        skip: !csvCalled,
        fetchPolicy: 'no-cache',
    });

    return (
        <Query
            query={GET_LOADS_BY_JOB_ID}
            variables={variables}
            pollInterval={60000}
            context={context}
            fetchPolicy="network-only"
        >
            {({
                error,
                data,
                loading,
                fetchMore,
                refetch,
            }: QueryResult<GetLoadList | undefined>): JSX.Element | null => {
                if (error) {
                    if (error.message.includes('NoAccess')) {
                        return <ForbiddenError />;
                    }
                    if (error.message.includes('NoResults')) {
                        return <NotFoundError />;
                    }
                    return <Error error={error} />;
                }

                const loadsList: Load[] = [];

                if (data && data.getLoadListByJobId && data.getLoadListByJobId.length !== 0) {
                    data.getLoadListByJobId.forEach((element: Load | null): void => {
                        if (element !== null) {
                            loadsList.push(element);
                        }
                    });
                }

                if (loadsList.length > 0) {
                    setLoadsJobName(loadsList[0].jobName);
                    document.title = loadsList[0].jobName ? loadsList[0].jobName : tabTitle;
                    setTabTitle(document.title);
                }

                const offset = data && data.getLoadListByJobId ? data.getLoadListByJobId.length / PAGE_SIZE : 0;

                let isEndOfResults = false;
                if (
                    offset % 1 !== 0 ||
                    (!isEndOfResults && data && data.getLoadListByJobId && data.getLoadListByJobId.length < PAGE_SIZE)
                ) {
                    // If offset is not a whole number, the end of the results has been reached. (8/22/19) - BS
                    isEndOfResults = true;
                }

                return (
                    <div className="LoadManagement-Container">
                        <JobLoadsManagementHeader
                            jobId={jobIdVal}
                            jobName={loadsJobName}
                            isSearchOnLoadId={isSearchOnLoadId}
                            // toggleSortOrder={toggleSortOrder}
                            setIsSearchOnLoadId={setIsSearchOnLoadId}
                        />
                        <div className="LoadManagement-Body">
                            <div id="loadsFilterContainer" className="LoadManagement-Filter">
                                <JobLoadsFilters
                                    abortRequest={abortRequest}
                                    jobId={parseInt(jobIdVal)}
                                    onFilterToggle={setListContainerHeight}
                                    exportCsv={() => {
                                        setCsvCalled(true);
                                    }}
                                    csvLoading={csvLoading}
                                />
                            </div>
                            <div id="loadsListContainer" className="LoadManagement-List" style={{ height: listHeight }}>
                                <LoadList
                                    abortRequest={abortRequest}
                                    loadList={loadsList}
                                    isLoading={loading}
                                    isEndOfResults={isEndOfResults}
                                    entries={(data && data.getLoadListByJobId) || []}
                                    // selectedLoads={selectedLoads}
                                    // setSelectedLoads={setSelectedLoads}
                                    refetch={refetch}
                                    onLoadMore={() =>
                                        fetchMore({
                                            variables: {
                                                offset,
                                            },
                                            updateQuery: (prev, { fetchMoreResult }) => {
                                                if (
                                                    prev &&
                                                    fetchMoreResult &&
                                                    prev.getLoadListByJobId &&
                                                    fetchMoreResult.getLoadListByJobId
                                                ) {
                                                    return Object.assign({}, prev, {
                                                        getLoadListByJobId: [
                                                            ...prev.getLoadListByJobId,
                                                            ...fetchMoreResult.getLoadListByJobId,
                                                        ],
                                                    });
                                                } else {
                                                    return prev;
                                                }
                                            },
                                        })
                                    }
                                />
                            </div>
                        </div>
                    </div>
                );
            }}
        </Query>
    );
};

export default LoadManagement;
