import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CustomToggle from '../CustomToggle';
import * as JobListActions from '../../../store/actions/jobList';
import * as DriverListActions from '../../../store/actions/driversList';
import { useStoreContext } from '../../App/StoreProvider';
import './FilterItem.css';
import 'material-design-icons/iconfont/material-icons.css';
import { Collection, List } from '../FilterList';
import SearchableDropdown from '../../_common/SearchableDropdown/SearchableDropdown';

interface FilterItem {
    id: number;
    name: string;
    defaultChecked?: boolean;
}

interface FilterItemProps {
    abortRequest: () => void;
    title: string;
    isCollection: boolean;
    items: any[];
    isSearchable: boolean;
    activeKey: string;
    type?: string | null;
    placeHolder?: string | null;
    onFilterToggle?: () => void;
    onSelection?;
    filterFrom?: string | null;
}

const FilterItem = ({
    abortRequest,
    title,
    isCollection,
    items,
    isSearchable,
    activeKey,
    type = 'checkBox',
    placeHolder,
    onFilterToggle,
    onSelection,
    filterFrom,
}: FilterItemProps): JSX.Element => {
    const [defActiveKey, setDefActiveKey] = useState(activeKey);
    const [searchPhrase, setSearchPhrase] = useState('');
    const [, dispatch] = useStoreContext();

    useEffect((): void => {
        setDefActiveKey(activeKey);
    }, [activeKey]); // this line will tell react only trigger if count was changed

    useEffect((): void => {
        if (onFilterToggle) {
            onFilterToggle();
        }
    });
    // useEffect((): void => {
    //     if (onSelection) {
    //         onSelection();
    //     }
    // });

    let filteredItems: FilterItem[] = items;
    if (searchPhrase && searchPhrase.length > 0) {
        filteredItems = items.filter(item => {
            return item && item.name.toLowerCase().includes(searchPhrase.toLowerCase());
        });
    }

    const selectAllFilters = (): void => {
        const itemIds = items.map(item => item.id);
        if (filterFrom == 'DriverList') {
            dispatch(DriverListActions.setSelectAllFilters(itemIds, title));
        } else {
            dispatch(JobListActions.setSelectAllFilters(itemIds, title));
        }
    };

    const onToggle = (): void => {
        setDefActiveKey(defActiveKey === '0' ? '1' : '0');
    };

    const onSelectSelection = (e: any): void => {
        const id = parseInt(e.target.value);
        onSelection(id);
    };

    return (
        <div>
            <Accordion activeKey={defActiveKey}>
                <Card className="FilterItem-Card">
                    <CustomToggle eventKey={defActiveKey} title={title} onClick={onToggle} tags={[]}></CustomToggle>
                    {type === 'checkBox' && (
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className={`FilterItem-Body ${isSearchable && 'FilterItem-BodyPadding'}`}>
                                {isSearchable && (
                                    <div className="FilterItem-InputContainer">
                                        <input
                                            type="text"
                                            placeholder="Search"
                                            className="FilterItem-Input"
                                            onChange={event => {
                                               // abortRequest();
                                                setSearchPhrase(event.target.value);
                                            }}
                                        />
                                    </div>
                                )}
                                <Button className="FilterItem-SelectAll" variant="link" onClick={selectAllFilters}>
                                    Select All
                                </Button>
                                {/* {items.forEach(item => item.id)} */}
                                {isCollection ? (
                                    <Collection
                                        abortRequest={abortRequest}
                                        filterType={title}
                                        items={filteredItems}
                                        filterFrom={filterFrom}
                                    />
                                ) : (
                                    <List
                                        abortRequest={abortRequest}
                                        filterType={title}
                                        items={filteredItems}
                                        filterFrom={filterFrom}
                                    />
                                )}
                            </Card.Body>
                        </Accordion.Collapse>
                    )}
                    {type === 'selectDropDown' && (
                        <Accordion.Collapse eventKey="0">
                            <Card.Body className={`FilterItem-Body FilterItem-BodyPadding card-body`}>
                                <select className="form-control mt-2" onChange={e => onSelectSelection(e)}>
                                    <option value={0}>{placeHolder}</option>
                                    {items.map((item: any) => (
                                        <option key={item.id} value={item.id}>
                                            {item.name}
                                        </option>
                                    ))}
                                </select>
                            </Card.Body>
                        </Accordion.Collapse>
                    )}
                </Card>
            </Accordion>
        </div>
    );
};

export default FilterItem;
