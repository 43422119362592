import React, { CSSProperties, useState } from 'react';
import Select, { MultiValue }from 'react-select';
import { InputActionMeta} from 'react-select';
import '../SearchableDropdown/SearchableDropdown.scss';

interface OptionType {
    label: string;
    value: string;
}

interface MultiSelectDropdownProps {
    dropdownOptions: OptionType[];
    onInputChange?: (inputValue: string, actionMeta: InputActionMeta) => string;
    onChange?: (value: OptionType) => void;
    value?: OptionType | OptionType[];
    label: string;
    error?: boolean;
    helperText?: string;
}

export const MultiSelectDropdownV2 = ({
    dropdownOptions,
    onInputChange,
    onChange,
    value,
    label,
    error,
    helperText,
}: MultiSelectDropdownProps): JSX.Element => {
    const handleChangeSingle = (newvalue: MultiValue<OptionType>): void => {
        const value = newvalue as unknown as OptionType;
        if (onChange) onChange(value);
    };

    const handleInputChange = (inputValue: string, actionMeta: InputActionMeta): string => {
        if (onInputChange) onInputChange(inputValue, actionMeta);
        return inputValue;
    };
    const selectStyles = {
        menu: (base) => ({
            ...base,
           
                fontSize: 17,
            
        }),
    };

    const suggestions: OptionType[] =
        dropdownOptions &&
        dropdownOptions.map(it => ({
            value: it && it.value ? it.value : '',
            label: it && it.label ? it.label : '',
        }));

    return (
        <div className="SearchableDropdown">
            <label className={`Searchable-Dropdown-Label`}>{label}</label>
            <div>
                <Select
                    isMulti={true}
                    styles={selectStyles}
                    // TextFieldProps={{
                    //     label: label,
                    //     error: error,
                    //     helperText: helperText,
                    //     InputLabelProps: {
                    //         htmlFor: 'react-select-single',
                    //         shrink: true,
                    //     },
                    // }}
                    placeholder=''
                    options={suggestions}
                    key={`my_unique_select_key__${JSON.stringify(value)}`}
                    value={value}
                    onChange={handleChangeSingle}
                    // onInputChange={handleInputChange}
                />
            </div>
        </div>
    );
};

export default MultiSelectDropdownV2;
