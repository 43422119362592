import gql from 'graphql-tag';

export default gql`
    query GetLoadAccessorial($input: LoadAcessorialInput) {
        getLoadAccessorial(input: $input) {
            id
            accessorialId
            accessorialName
            value
            checked
        }
    }
`;
