import gql from 'graphql-tag';

export default gql`
    query GetAllCustomersFiltered($input: String) {
        getAllCustomersFiltered(input: $input) {
            id
            customerName
            description
            enabled
            isDeleted
            extSystemName
            extSystemIdentifier
            industryClassificationCode
            customerParentId
        }
    }
`;
