import { Reducer } from 'react';
import { Actions } from '../../actions/driversList';
import { Action } from '../../types';
import { DriverListState } from '../../types/DriverListState';

const initialDriveristState: DriverListState = {
    searchString: '',
    status: '',
    districtIds: [],
    isComplaint: '',
    regionIds: [],
};

interface FilterObject {
    filterType: string;
    id: number;
    isSelected: boolean;
}

const driversListReducer: Reducer<DriverListState, Action> = (state, action): DriverListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.FILTER_BY_SEARCHSTRING:
            newState = { ...state, searchString: action.payload };
            break;
        case Actions.FILTER_BY_STATUS:
            newState = { ...state, status: action.payload };
            break;
        case Actions.FILTER_BY_COMPLAINT:
            newState = { ...state, isComplaint: action.payload };
            break;
        case Actions.FILTER_BY_DISTRICT:
            newState = { ...state, districtIds: action.payload };
            break;
        case Actions.FILTER_BY_REGION:
            newState = { ...state, regionIds: action.payload };
            break;
        case Actions.UPDATE_FILTERS_SELECT_ALL:
            newState = setSelectAllFilters(action.payload, state);
            break;
        case Actions.UPDATE_FILTERS:
            newState = setSelectedFilters(action.payload, state);
            break;
    }

    return newState;
};

const setSelectAllFilters = (
    payload: { itemIds: number[]; filterType: string; isSelected: boolean },
    state: DriverListState,
): DriverListState => {
    const { isComplaint, searchString, status } = state;
    let { regionIds, districtIds } = state;

    if (payload.filterType === 'Regions') {
        const selectAll = regionIds.length > 0 ? false : true;
        regionIds = addOrRemoveSelectAllFilters(payload.itemIds, regionIds, selectAll);
    }
    if (payload.filterType === 'Districts') {
        const selectAll = districtIds.length > 0 ? false : true;
        districtIds = addOrRemoveSelectAllFilters(payload.itemIds, districtIds, selectAll);
    }
    return {
        ...state,
        regionIds,
        districtIds,
        status,
        isComplaint,
        searchString,
    };
};

const addOrRemoveSelectAllFilters = (itemIds: number[], filters: number[], selected: boolean): number[] => {
    const massagedFilter: number[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(itemIds);
    }
};

const setSelectedFilters = (payload: FilterObject, state: DriverListState): DriverListState => {
    let { districtIds, regionIds } = state;
    const { status, isComplaint } = state;

    if (payload.filterType === 'Regions') {
        regionIds = addOrRemoveFilters(payload, regionIds);
    }
    if (payload.filterType === 'Districts') {
        districtIds = addOrRemoveFilters(payload, districtIds);
    }
    return {
        ...state,
        regionIds,
        districtIds,
        status,
        isComplaint,
    };
};

const addOrRemoveFilters = (payload: FilterObject, filters: number[]): number[] => {
    let massagedFilter: number[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.id);
    } else {
        massagedFilter = filters.filter((id): any => {
            if (id !== payload.id) return id;

            return undefined;
        });
    }

    return massagedFilter;
};

export { initialDriveristState, driversListReducer };
