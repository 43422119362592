import React, { useState, useEffect } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import CustomToggle from '../CustomToggle';
import * as LoadListActions from '../../../store/actions/loadList';
import * as LoadManagementListActions from '../../../store/actions/loadManagementList';
import { useStoreContext } from '../../App/StoreProvider';
import './FilterItem.css';
import '../../SearchBar/SearchBar.css';
import 'material-design-icons/iconfont/material-icons.css';
import { Collection, List } from '../FilterList/LoadFilterList';
import FilterItem from './FilterItem';

interface FilterItemProps {
    abortRequest: () => void;
    title: string;
    isCollection: boolean;
    items: FilterItem[];
    itemsSubset: FilterItem[];
    searchPhrase: string;
    isSearchable: boolean;
    activeKey: string;
    loadManagementMode?: boolean;
    onFilterToggle?: () => void;
}

const LoadFilterItem = ({
    abortRequest,
    title,
    isCollection,
    items,
    itemsSubset,
    searchPhrase,
    isSearchable,
    activeKey,
    loadManagementMode = false,
    onFilterToggle,
}: FilterItemProps): JSX.Element => {
    const [defActiveKey, setDefActiveKey] = useState(activeKey);
    const [, dispatch] = useStoreContext();
    useEffect((): void => {
        setDefActiveKey(activeKey);
    }, [activeKey]); // this line will tell react only trigger if count was changed

    useEffect((): void => {
        if (onFilterToggle) {
            onFilterToggle();
        }
    });

    const updateFilterListForSearch = (searchString: string): void => {
        if (loadManagementMode) {
            dispatch(LoadManagementListActions.setFilterSearchPhrase(items, title, searchString));
        } else {
            dispatch(LoadListActions.setFilterSearchPhrase(items, title, searchString));
        }
    };

    const selectAllFilters = (): void => {
        const itemIds = items.map(item => item.id);
      //  alert("test"+itemIds)
        if (loadManagementMode) {
            dispatch(LoadManagementListActions.setSelectAllFilters(itemIds, title));
        } else {
            dispatch(LoadListActions.setSelectAllFilters(itemIds, title));
        }
    };

    const displayItems = searchPhrase && searchPhrase.length > 0 ? itemsSubset : items;

    return (
        <div>
            <Accordion activeKey={defActiveKey}>
                <Card className="FilterItem-Card">
                    <CustomToggle
                        eventKey={defActiveKey}
                        title={title}
                        onClick={(): void => setDefActiveKey(defActiveKey === '0' ? '1' : '0')}
                        tags={[]}
                    />
                    <Accordion.Collapse eventKey="0">
                        <Card.Body className={`FilterItem-Body ${isSearchable && 'FilterItem-BodyPadding'}`}>
                            {isSearchable && (
                                <div className="SearchBar-Container">
                                    <input
                                        type="text"
                                        placeholder="Search"
                                        className="SearchBar-Input"
                                        onChange={event => {
                                           // abortRequest();
                                            updateFilterListForSearch(event.target.value);
                                        }}
                                    />
                                </div>
                            )}
                            <Button className="FilterItem-SelectAll" variant="link" onClick={selectAllFilters}>
                                Select All
                            </Button>
                            {/* {displayItems.forEach(item => item.id)} */}
                            {isCollection ? (
                                <Collection
                                    abortRequest={abortRequest}
                                    filterType={title}
                                    items={displayItems}
                                    loadManagementMode={loadManagementMode}
                                />
                            ) : (
                                <List
                                    abortRequest={abortRequest}
                                    filterType={title}
                                    items={displayItems}
                                    loadManagementMode={loadManagementMode}
                                />
                            )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            </Accordion>
        </div>
    );
};

export default LoadFilterItem;
