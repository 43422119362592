import gql from 'graphql-tag';

export default gql`
    query GetUserCustomerList($input: GetUserCustomerListInput) {
        getUserCustomerList(input: $input) {
            customerId
            customerName
        }
    }
`;
