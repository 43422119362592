export enum EditActions {
    MAX_VOL_BOX_ERROR = 'The value must be greater than 1,000 and less than 56,000',
    MILES_FROM_WELL_ERROR = 'The value must be greater than 0',
    PROPPANT_LOADS_COUNT_ERROR_MESSAGE = 'This proppant has already created loads.',
    PROPPANT_TOTAL_VOLUME_DELETE_ERROR_MESSAGE = 'Deleting this proppant will cause the LOFA Schedule to go negative.',
    PROPPANT_TOTAL_VOLUME_EXCEED_ERROR_MESSAGE = 'This Proppant edit will cause the LOFA Schedule to go negative.',
    STAGE_TIME_ERROR = 'The value must be greater than 0 and less than 999',
    TOTAL_VOLUME_ERROR = 'The value must be greater than 0 and less than 999999999',
    VOLUME_PER_BOX_ERROR = 'The value must be greater than 1,000 and less than 56000',
    WELLHEAD_DELETE_ERROR_MESSAGE = 'Deleting this well will cause the LOFA Schedule to go negative.',
    WELLHEAD_DUPLICATE_ERROR_MESSAGE = 'This wellhead name you have selected is already in use for this job.',
    WELLHEAD_PROPPANT_LOADS_COUNT_ERROR_MESSAGE = 'This wellhead includes one or more proppants that already have created loads.',
    WELLHEAD_PROPPANT_LOFA_COUNT_ERROR_MESSAGE = 'This wellhead includes one or more proppants that already have created lofas.',
}
