import './Filter.scss';
import React, { useState } from 'react';
import FilterItem from './FilterItem';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import {  QueryResult, useQuery } from '@apollo/client';
import {Query} from '@apollo/client/react/components';
import { GET_JOB_FILTERS_LIST } from '../../graphql/client/queries';
import SearchableDropdown from '../_common/SearchableDropdown/SearchableDropdown';
import { useStoreContext } from '../App/StoreProvider';
import * as UserListActions from '../../store/actions/userList';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import {
    GET_ALL_CUSTOMERS_FOR_USERS,
    GET_ALL_DRIVERCOMPANY_FOR_USERS,
    GET_ALL_ROLES_FOR_USERS,
} from '../../graphql/client/queries';
import {
    GetUserCustomerList_getUserCustomerList as UserCustomer,
    GetUserDriverCompanyList_getUserDriverCompanyList as DriverCompany,
    GetUserRoleList_getUserRoleList as Roles,
    GetUserDriverCompanyList,
    GetUserCustomerList,
    GetUserRoleList,
} from '../../types/schemaTypes';

interface UserFiltersProps {
    cpOptions: any[];
    carrierOptions: any[];
    roles: any[];
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const UserFilters = ({
    abortRequest,
    onFilterToggle,
    cpOptions,
    carrierOptions,
    roles,
}: UserFiltersProps): JSX.Element => {
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);
    const rolesList = isOperationUser ? roles.filter(t => t.id === 5 || t.id === 6) : roles;
    const [state, dispatch] = useStoreContext();
    const {
        userList: { searchString, status, role, customer, carrier },
    } = state;
    const [activeKey, setActiveKey] = useState('0');
    const statusFilters = [
        { id: 1, name: 'Active' },
        { id: 2, name: 'Inactive' },
    ];

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    const onSelectStatus = value => {
        const statusValue = value === 1 ? 'Active' : value === 2 ? 'Inactive' : '';
        dispatch(UserListActions.setFilterValue(statusValue, UserListActions.Actions.FILTER_STATUS_SELECT));
    };

    const onSelectRole = value => {
        dispatch(UserListActions.setFilterValue(value, UserListActions.Actions.FILTER_ROLE_SELECT));
    };

    const onSelectCustomer = value => {
        dispatch(UserListActions.setFilterValue(value, UserListActions.Actions.FILTER_CUSTOMER_SELECT));
    };

    const onSelectCarrier = value => {
        dispatch(UserListActions.setFilterValue(value, UserListActions.Actions.FILTER_CARRIER_SELECT));
    };

    const variables = {
        input: {
            searchString,
            isOperationUserView: isOperationUser ? true : false,
            status,
        },
    };

    return (
        <div className="Filter-Container userList">
            <div className="Filter-Header">
                <div className="Filter-HeaderIconContainer">
                    <i className="material-icons">filter_list</i>
                    <span className="Filter-HeaderTitle">Filters</span>
                </div>
                <Button className="Filter-SelectAll" variant="link" onClick={onSelectAll}>
                    {activeKey === '0' ? 'Collapse All' : 'Expand All'}
                </Button>
            </div>

            <FilterItem
                abortRequest={abortRequest}
                title="Status"
                items={statusFilters}
                activeKey={activeKey}
                isCollection={true}
                isSearchable={false}
                type="selectDropDown"
                onSelection={onSelectStatus}
                placeHolder="Filter By Status"
            />
            <Query query={GET_ALL_ROLES_FOR_USERS} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetUserRoleList | undefined>): JSX.Element | null => {
                    const userRoles: any[] = [];
                    // if (error) {
                    //     return <Error error={error} />;
                    // } else {

                    if (data && data.getUserRoleList && data.getUserRoleList.length !== 0) {
                        data.getUserRoleList.forEach((element: any | null): void => {
                            if (element !== null) {
                                userRoles.push({
                                    id: element.roleId,
                                    name: element.roleName,
                                });
                            }
                        });
                    }
                    // }
                    return (
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Roles"
                            items={userRoles}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable={false}
                            type="selectDropDown"
                            onSelection={onSelectRole}
                            placeHolder="Filter By Roles"
                        />
                    );
                }}
            </Query>

            {!isOperationUser && (
                <Query query={GET_ALL_CUSTOMERS_FOR_USERS} variables={variables} fetchPolicy="network-only">
                    {({
                        error,
                        data,
                        loading,
                        refetch,
                        fetchMore,
                    }: QueryResult<GetUserCustomerList | undefined>): JSX.Element | null => {
                        const userCustomers: any[] = [];
                        // if (error) {
                        //     return <Error error={error} />;
                        // } else {

                        if (data && data.getUserCustomerList && data.getUserCustomerList.length !== 0) {
                            data.getUserCustomerList.forEach((element: UserCustomer | null): void => {
                                if (element !== null) {
                                    userCustomers.push({ id: element.customerId, name: element.customerName });
                                }
                            });
                        }
                        // }
                        return (
                            <FilterItem
                                abortRequest={abortRequest}
                                title="Customer"
                                items={userCustomers}
                                activeKey={activeKey}
                                isCollection={false}
                                isSearchable={false}
                                type="selectDropDown"
                                onSelection={onSelectCustomer}
                                placeHolder="Filter By Customer"
                            />
                        );
                    }}
                </Query>
            )}

            <Query query={GET_ALL_DRIVERCOMPANY_FOR_USERS} variables={variables} fetchPolicy="network-only">
                {({
                    error,
                    data,
                    loading,
                    refetch,
                    fetchMore,
                }: QueryResult<GetUserDriverCompanyList | undefined>): JSX.Element | null => {
                    const userDriverCompany: any[] = [];
                    // if (error) {
                    //     return <Error error={error} />;
                    // } else {

                    if (data && data.getUserDriverCompanyList && data.getUserDriverCompanyList.length !== 0) {
                        data.getUserDriverCompanyList.forEach((element: DriverCompany | null): void => {
                            if (element !== null) {
                                userDriverCompany.push({
                                    id: element.driverCompanyId,
                                    name: element.driverCompanyName,
                                });
                            }
                        });
                    }
                    // }
                    return (
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Carrier"
                            items={userDriverCompany}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable={false}
                            type="selectDropDown"
                            onSelection={onSelectCarrier}
                            placeHolder="Filter By Carrier"
                        />
                    );
                }}
            </Query>
        </div>
    );
};
