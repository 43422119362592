import gql from 'graphql-tag';

export default gql`
    query GetAllProppants {
        getAllProppants {
            proppantId
            proppantName
            extSystemName
            extSystemReference
        }
    }
`;
