/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FC } from 'react';
import { useStoreContext } from '../../App/StoreProvider';
import * as JobProfileActions from '../../../store/actions/jobProfile';
import PumpScheduleSummary from '../PumpScheduleSummary';
import LoadSummary from '../LoadSummary';
import { GetFullJobProfile_getJobProfile_pumpScheduleSummary as PumpScheduleSummaryData } from '../../../types/schemaTypes';
import './JobProfileNav.scss';
import LOFAScheduleSummary from '../LOFASchedule';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
// import JobNotes from '../JobNotes';
// import MileageConfirmation from '../MileageConfirmation';

interface JobProfileNavProps {
    pumpScheduleSummary: PumpScheduleSummaryData[];
    jobId: number;
    jobName: string | null;
    customerId: number;
    isJobCompleted: boolean;
    region?: {
        regionName: string | null;
        id: number | null;
        extSystemName: string | null;
        extSystemReference: string | null;
        regionCode: string | null;
    } | null;
}

const getNavigationComponent = (
    tabs: any,
    pumpScheduleSummary: PumpScheduleSummaryData[],
    jobId: number,
    jobName: string | null,
    customerId: number,
    isJobCompleted: boolean,
    region?: {
        regionName: string | null;
        id: number | null;
        extSystemName: string | null;
        extSystemReference: string | null;
        regionCode: string | null;
    } | null,
): JSX.Element => {
    const selectedTab = tabs.find((tab: any): boolean => {
        return tab.isSelected;
    });

    if (!selectedTab) {
        return <div>No tab selected</div>;
    }

    switch (selectedTab.displayName) {
        case 'Load Summary':
            return (
                <LoadSummary
                    jobId={jobId}
                    region={region}
                    jobName={jobName}
                    customerId={customerId}
                    isJobCompleted={isJobCompleted}
                />
            );
        case 'LOFA Schedule':
            return <LOFAScheduleSummary jobId={jobId} isJobCompleted={isJobCompleted} />;
        case 'Pump Schedule Summary':
            return (
                <PumpScheduleSummary pumpScheduleSummaryList={pumpScheduleSummary} isJobCompleted={isJobCompleted} />
            );
        // case 'Notes':
        //     return <JobNotes jobId={jobId} />;
        // case 'Mileage Confirmation':
        //     return <MileageConfirmation jobId={jobId} />;
        default:
            return <div>No content provided for {selectedTab.displayName}</div>;
    }
};

const JobProfileNav: FC<JobProfileNavProps> = ({
    pumpScheduleSummary,
    jobId,
    region,
    jobName,
    customerId,
    isJobCompleted,
}: JobProfileNavProps): JSX.Element => {
    const authContext = useAuthContext();
    const [state, dispatch] = useStoreContext();
    const { jobProfile } = state;
    const carrierTabs = jobProfile.tabs.filter(t => t.displayName === 'LOFA Schedule');
    const isRedirectToCustomer =
        localStorage.getItem('redirectbackto') && localStorage.getItem('redirectbackto') === 'carrier' ? false : true;
    const jobProfileTabs =
        authContext.userHasPermission(PermissionEnum.Carrier) || !isRedirectToCustomer ? carrierTabs : jobProfile.tabs;
    if (jobProfileTabs.length === 1) {
        jobProfileTabs[0].isSelected = true;
    }

    return (
        <React.Fragment>
            <span className="JobProfileNav-TabContainer">
                {jobProfileTabs.map(
                    (tab: any): JSX.Element => {
                        const tabClass = `JobProfileNav-Tab ${
                            tab.isSelected ? 'JobProfileNav-Selected' : 'JobProfileNav-Unselected'
                        }`;

                        return (
                            <div
                                key={tab.key}
                                className={tabClass}
                                onClick={(): void => dispatch(JobProfileActions.setSelectedTab(tab.key))}
                            >
                                <span>
                                    <img
                                        className="JobProfileNav-TabIcon"
                                        src={tab.icon}
                                        alt={`${tab.displayName} Icon`}
                                    />
                                    {tab.displayName}
                                </span>
                            </div>
                        );
                    },
                )}
            </span>
            <div className="JobProfileNav-Content">
                {getNavigationComponent(
                    jobProfileTabs,
                    pumpScheduleSummary,
                    jobId,
                    jobName,
                    customerId,
                    isJobCompleted,
                    region,
                )}
            </div>
        </React.Fragment>
    );
};

export default JobProfileNav;
