import React from 'react';

const ForbiddenError = (): JSX.Element => {
    return (
        <div className="NoResult-Container">
            <div className="NoResult-First-Title-Secondary">Unauthorized</div>
            <div className="NoResult-First-Title-Primary">You do not have permission to view this screen</div>
        </div>
    );
};

export default ForbiddenError;
