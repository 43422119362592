import React from 'react';
import './WellheadItem.css';
import {
    GetFullJobProfile_getJobProfile_pumpScheduleSummary as PumpScheduleSummaryData,
    GetFullJobProfile_getJobProfile_pumpScheduleSummary_proppants as ProppantData,
} from '../../../../types/schemaTypes';
import SummaryHeader from '../SummaryHeader';
import ProppantItem from '../ProppantItem/ProppantItem';

interface WellheadItemProps {
    pumpScheduleSummaryList: PumpScheduleSummaryData[];
}

const WellheadItem = ({ pumpScheduleSummaryList }: WellheadItemProps): JSX.Element => {
    return (
        <div className="PumpSchedule-ListContainer">
            <div className="PumpSchedule-Header Row">
                <div className="PumpSchedule-Header-Item PumpSchedule-Name justify-content-start">Well</div>
                <div className="PumpSchedule-Items-Container Row">
                    <div className="PumpSchedule-Header-Item justify-content-start">Proppant</div>
                    <div className="PumpSchedule-Header-Item justify-content-end">Total Volume</div>
                    <div className="PumpSchedule-Header-Item justify-content-end">Planned Vol/Box</div>
                    <div className="PumpSchedule-Header-Item justify-content-end">Vol/Stage</div>
                    <div className="PumpSchedule-Header-Item justify-content-end">Boxes/Stage</div>
                    <div className="PumpSchedule-Header-Item justify-content-end">Boxes/Well</div>
                </div>
            </div>
            <div className="Column">
                {pumpScheduleSummaryList.map((wellhead: PumpScheduleSummaryData | null, index: number):
                    | JSX.Element
                    | undefined => {
                    if (wellhead !== null) {
                        return (
                            <div
                                key={index}
                                className={
                                    index % 2 === 0
                                        ? 'PumpSchedule-Detail-Even-Row '
                                        : 'PumpSchedule-Detail-Odd-Row ' +
                                          'PumpSchedule-Detail-Row PumpSchedule-Name justify-content-start'
                                }
                            >
                                <SummaryHeader
                                    wellName={wellhead.wellName}
                                    stages={wellhead.stages}
                                    stageTime={wellhead.stageTime}
                                    totalBoxes={wellhead.totalBoxes}
                                    key={index}
                                    isLastItem={wellhead ? index === pumpScheduleSummaryList.length - 1 : false}
                                />
                                <div className="PumpSchedule-Row-Container">
                                    {wellhead &&
                                        wellhead.proppants &&
                                        wellhead.proppants.length !== 0 &&
                                        wellhead.proppants.map((proppantItem: ProppantData | null, index: number):
                                            | JSX.Element
                                            | undefined => {
                                            if (proppantItem !== null) {
                                                return (
                                                    <ProppantItem
                                                        proppant={proppantItem}
                                                        key={index}
                                                        isLastItem={
                                                            wellhead && wellhead.proppants
                                                                ? index === wellhead.proppants.length - 1
                                                                : false
                                                        }
                                                    />
                                                );
                                            }

                                            return undefined;
                                        })}
                                </div>
                            </div>
                        );
                    }

                    return undefined;
                })}
            </div>
        </div>
    );
};

export default WellheadItem;
