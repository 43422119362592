import './Filter.scss';
import React, { useState } from 'react';
import FilterItem from './FilterItem';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import { Query } from '@apollo/client/react/components';
import {QueryResult} from '@apollo/client';
import { GET_JOB_FILTERS_LIST } from '../../graphql/client/queries';
import {
    // GetJobFiltersList_getJobFiltersList_customers as CustomerFilter,
    GetJobFiltersList_getJobFiltersList_regions as RegionFilter,
    // GetJobFiltersList_getJobFiltersList_districts as DistrictsFilter,
    GetJobFiltersList_getJobFiltersList_statuses as StatusFilter,
} from '../../types/schemaTypes';
import { GetJobFiltersList } from '../../types/schemaTypes';

interface DashbordFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const DashbordFilters = ({ abortRequest, onFilterToggle }: DashbordFiltersProps): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    let statusFilters: StatusFilter[] = [];
    let regionFilters: RegionFilter[] = [];
    // const districtFilters: DistrictsFilter[] = [];
    // var customerFilters: CustomerFilter[] = [];

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    return (
        <Query query={GET_JOB_FILTERS_LIST}>
            {({ loading, error, data }: QueryResult<GetJobFiltersList>): JSX.Element | null => {
                if (loading) {
                    return (
                        <div className="Filter-Loading">
                            <Loading />
                        </div>
                    );
                }
                if (error) {
                    return <Error error={error} />;
                }

                if (data && data.getJobFiltersList) {
                    statusFilters = data.getJobFiltersList.statuses.filter(stat => stat.name !== 'Unknown');
                    regionFilters = data.getJobFiltersList.regions;
                    // districtFilters = data.getJobFiltersList.districts;
                    // customerFilters = data.getJobFiltersList.customers;
                }

                return (
                    <div className="Filter-Container Customer-Dashboard">
                        <div className="Filter-Header">
                            <div className="Filter-HeaderIconContainer">
                                <i className="material-icons">filter_list</i>
                                <span className="Filter-HeaderTitle">Filters</span>
                            </div>
                            <Button className="Filter-SelectAll" variant="link" onClick={onSelectAll}>
                                {activeKey === '0' ? 'Collapse All' : 'Expand All'}
                            </Button>
                        </div>
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Status"
                            items={statusFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable={false}
                            onFilterToggle={onFilterToggle}
                        />
                        {/* <FilterItem
                            abortRequest={abortRequest}
                            title="Districts"
                            items={districtFilters}
                            activeKey={activeKey}
                            isCollection={false}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                        /> */}
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Regions"
                            items={regionFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                        />
                        {/*
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Customers"
                            items={customerFilters}
                            activeKey={activeKey}
                            isCollection={false}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                        /> */}
                    </div>
                );
            }}
        </Query>
    );
};
