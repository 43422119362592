import React, { FC, useEffect, useRef, useState } from 'react';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import './JobManagement.css';
import 'material-design-icons/iconfont/material-icons.css';
import { GET_ALL_JOBS_WITH_FILTERS_AND_SEARCH } from '../../graphql/client/queries';
import {
    GetAllJobsWithFiltersAndSearch_getAllJobsWithFiltersAndSearch as Job,
    GetAllJobsWithFiltersAndSearch,
    SortOrder,
} from '../../types/schemaTypes';
import { Error } from '../_common';
import JobManagementHeader from '../Header/JobManagementHeader';
import { JobFilters } from '../Filter';
import { Routes } from '../../utils';
import JobList from '../TableList/JobList';
import { useStoreContext } from '../App/StoreProvider';
import * as JobListActions from '../../store/actions/jobList';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import ForbiddenError from '../_common/Error/ForbiddenError';

interface SelectedJob {
    jobId: string;
    jobName: string;
}

// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

const JobManagement: FC = (): JSX.Element => {
    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const initialArray: SelectedJob[] = [];
    // TODO: Move local state to jobListReducer
    const [selectedJobs, setSelectedJobs] = useState(initialArray);
    const headerCheckSelected = useRef(false);
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [state, dispatch] = useStoreContext();
    const [isSelectAll, setIsSelectAll] = useState(false);
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCarrier = useAuthContext().userHasPermission(PermissionEnum.Carrier);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);

    const abortController = React.useRef(new AbortController());
    const {
        jobList: { filters, searchPhrase },
        carrierList: { carrierId },
    } = state;

    useEffect(() => {
        dispatch(JobListActions.resetFilterState());
    }, []);

    useEffect(() => {
        setSelectedJobs([]);
        setIsSelectAll(false);
    }, [filters, searchPhrase, carrierId]);

    const variables = React.useMemo(()=>{
        
        return {
        input: {
            statuses: filters.statuses,
            regionIds: filters.regionIds,
            searchString: searchPhrase,
            sortOrder: sortOrder,
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
            // districtIds: filters.districtIds,
            // customerIds: filters.customerIds,
        },
    }},[filters.statuses,filters.regionIds,searchPhrase,sortOrder,carrierId]);

    const openSelectedJobs = (): void => {
        localStorage.setItem('redirectbackto', 'carrier');
        const URL = `${window.location.origin}${Routes.JOB_PROFILE.route}`;
        selectedJobs.forEach((el): void => {
            const newTab = window.open(URL + el.jobId, '_blank');
            setTimeout(function(): void {
                if (newTab) {
                    newTab.document.title = el.jobName;
                }
            }, 100);
        });

        const result: SelectedJob[] = [];
        setSelectedJobs(result);
        setIsSelectAll(false);
    };

    const toggleSortOrder = (): void => {
        if (sortOrder === null) {
            setSortOrder(SortOrder.ASCENDING);
        } else if (sortOrder === SortOrder.ASCENDING) {
            setSortOrder(SortOrder.DESCENDING);
        } else {
            setSortOrder(null);
        }
    };

    const onCheckAll = (checkedStatus: boolean, jobsList: Job[]): void => {
        const result: SelectedJob[] = [];
        if (checkedStatus === true) {
            headerCheckSelected.current = true;
            jobsList.forEach((el: Job): void => {
                result.push({ jobId: el.jobId, jobName: el.jobName });
            });
            setIsSelectAll(true);
        } else {
            setIsSelectAll(false);
        }
        setSelectedJobs(result);
    };

    const context = {
        fetchOptions: {
            signal: abortController.current.signal,
        },
    };

    const abortRequest = (): void => {
        abortController.current.abort();
    };

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('jobsFilterContainer');
        const listDiv = document.getElementById('jobsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };
    document.title = 'Job Management';

    if (!isAdmin && !isOperationUser && !isCarrier) {
        return <ForbiddenError />;
    }

    if (variables.input.driverCompanyId && variables.input.driverCompanyId !== 0) {
        return (
            // TODO: Extract Query to useQuery to stop Filters section from reloading everytime
            <Query
                query={GET_ALL_JOBS_WITH_FILTERS_AND_SEARCH}
                variables={variables}
                fetchPolicy="network-only"
                context={context}
            >
                {({
                    error,
                    data,
                    loading,
                    fetchMore,
                }: QueryResult<GetAllJobsWithFiltersAndSearch | undefined>): JSX.Element | null => {
                    if (error) {
                        return <Error error={error} />;
                    }

                    const jobsList: Job[] = [];

                    if (
                        data &&
                        data.getAllJobsWithFiltersAndSearch &&
                        data.getAllJobsWithFiltersAndSearch.length !== 0
                    ) {
                        data.getAllJobsWithFiltersAndSearch.forEach((element: Job | null): void => {
                            if (element !== null) {
                                jobsList.push(element);
                            }
                        });
                    }

                    const offset =
                        data && data.getAllJobsWithFiltersAndSearch
                            ? data.getAllJobsWithFiltersAndSearch.length / PAGE_SIZE
                            : 0;

                    let isEndOfResults = false;
                    if (
                        offset % 1 !== 0 ||
                        (!isEndOfResults &&
                            data &&
                            data.getAllJobsWithFiltersAndSearch &&
                            data.getAllJobsWithFiltersAndSearch.length < PAGE_SIZE)
                    ) {
                        // If offset is not a whole number, the end of the results has been reached. (8/22/19) - BS
                        isEndOfResults = true;
                    }

                    return (
                        <div className="JobManagement-Container">
                            <JobManagementHeader
                                onCheckAll={checked => onCheckAll(checked, jobsList)}
                                sortOrder={sortOrder}
                                toggleSortOrder={toggleSortOrder}
                                selectedJobs={selectedJobs}
                                openSelectedJobs={openSelectedJobs}
                                defaultChecked={isSelectAll}
                            />
                            <div className="JobManagement-Body">
                                <div id="jobsFilterContainer" className="JobManagement-Filter">
                                    <JobFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                                </div>
                                <div id="jobsListContainer" className="JobManagement-List">
                                    <JobList
                                        abortRequest={abortRequest}
                                        jobsList={jobsList}
                                        isLoading={loading}
                                        isEndOfResults={isEndOfResults}
                                        entries={(data && data.getAllJobsWithFiltersAndSearch) || []}
                                        selectedJobs={selectedJobs}
                                        setSelectedJobs={setSelectedJobs}
                                        detailsViewFrom="carrier"
                                        onLoadMore={() =>
                                            fetchMore({
                                                variables: {
                                                    offset,
                                                },
                                                updateQuery: (prev, { fetchMoreResult }) => {
                                                    if (
                                                        prev &&
                                                        fetchMoreResult &&
                                                        prev.getAllJobsWithFiltersAndSearch &&
                                                        fetchMoreResult.getAllJobsWithFiltersAndSearch
                                                    ) {
                                                        return Object.assign({}, prev, {
                                                            getAllJobsWithFiltersAndSearch: [
                                                                ...prev.getAllJobsWithFiltersAndSearch,
                                                                ...fetchMoreResult.getAllJobsWithFiltersAndSearch,
                                                            ],
                                                        });
                                                    } else {
                                                        return prev;
                                                    }
                                                },
                                            })
                                        }
                                    ></JobList>
                                </div>
                            </div>
                        </div>
                    );
                }}
            </Query>
        );
    } else {
        return (
            <div className="JobManagement-Container">
                <JobManagementHeader
                    onCheckAll={checked => onCheckAll(checked, [])}
                    sortOrder={sortOrder}
                    toggleSortOrder={toggleSortOrder}
                    selectedJobs={selectedJobs}
                    openSelectedJobs={openSelectedJobs}
                    defaultChecked={isSelectAll}
                />
                <div className="JobManagement-Body">
                    <div id="jobsFilterContainer" className="JobManagement-Filter">
                        <JobFilters abortRequest={abortRequest} onFilterToggle={setListContainerHeight} />
                    </div>
                    <div id="jobsListContainer" className="JobManagement-List">
                        <JobList
                            abortRequest={abortRequest}
                            jobsList={[]}
                            isLoading={false}
                            isEndOfResults={true}
                            entries={[]}
                            selectedJobs={selectedJobs}
                            setSelectedJobs={setSelectedJobs}
                            detailsViewFrom="carrier"
                            onLoadMore={() => null}
                        ></JobList>
                    </div>
                </div>
            </div>
        );
    }
};

export default JobManagement;
