import gql from 'graphql-tag';

export default gql`
    query GetAllLofas {
        getAllLofas {
            lofaId
            lofaName
        }
    }
`;
