import React, { useState } from 'react';
import DateTimeField from 'react-datetime';
import moment from 'moment-timezone';
import './DateTimePicker.scss';

// All library props (react-datetime) can be found at: https://github.com/YouCanBookMe/react-datetime
export const DateTimePicker = (props: any): JSX.Element => {
    const { label, error, value, onChange, dateOnly } = props;
    moment.tz.setDefault();
    console.log("value21",value)
    const [dateTime, setDateTime] = useState(moment(value));


  
    const updateStateOnChange = (value: any): void => {
        const tempDate = moment(value, 'M/D/YYYY', true);
        let momentDate:any="";
        if(tempDate.isValid()){
            momentDate = moment(value);
        }else{
            momentDate = value
        }
        setDateTime(momentDate.valueOf());
        onChange(momentDate);
    };
    const DateRender:any = () =>{
        if(value == '') {
           
        return (    <DateTimeField
            {...props}
            
            onChange={updateStateOnChange}
            dateFormat="MM/DD/YYYY"
            timeFormat={dateOnly ? false : 'h:mm A'}
            inputProps={{placeholder:'MM/DD/YYYY'}}
            renderInput={(props) => {
                return <input {...props} value={(value) ? props.value : ''} />
            }}
        /> )
        } else {
            
            return (   
            <DateTimeField
            {...props}
            value={!!value ? dateTime : ''}
            onChange={updateStateOnChange}
            dateFormat="MM/DD/YYYY"
            timeFormat={dateOnly ? false : 'h:mm A'}
            inputProps={{placeholder:'MM/DD/YYYY'}}
            renderInput={(props) => {
                return <input {...props}   />
            }}
        /> )
        }
    }

    return (
        <span className={`${error ? 'DateTimePicker-Container-Error' : 'DateTimePicker-Container'}`}>
            {label && (
                <span className={`${error ? 'DateTimePicker-Label-Error' : 'DateTimePicker-Label'}`}>{label}</span>
            )}
          {DateRender()}
            <span className="DateTimePicker-Error">{error}</span>
        </span>
    );
};

export default DateTimePicker;
