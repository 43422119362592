import gql from 'graphql-tag';

export default gql`
    query GetUserRoleList($input: GetUserRoleListInput) {
        getUserRoleList(input: $input) {
            roleId
            roleName
        }
    }
`;
