import React, { useContext } from 'react';
import { State } from '../../../store/types';

interface StoreProviderProps {
    store: [State, React.Dispatch<unknown>];
    children: JSX.Element;
}

const StoreContext: React.Context<any> = React.createContext({});

const StoreProvider = (props: StoreProviderProps): JSX.Element => {
    return <StoreContext.Provider value={props.store}>{props.children}</StoreContext.Provider>;
};

const useStoreContext = (): [State, React.Dispatch<unknown>] => {
    const context = useContext(StoreContext);

    return context;
};

export { useStoreContext, StoreProvider };
