import gql from 'graphql-tag';

export default gql`
    query GetAllCrews($customerId: Int) {
        getAllCrews(customerId: $customerId) {
            id
            crewNumber
            customerId
            isDeleted
        }
    }
`;
