import gql from 'graphql-tag';

export default gql`
    query GetAllProppantsByJob($jobId: Int) {
        getAllProppantsByJob(jobId: $jobId) {
            proppantId
            proppantName
        }
    }
`;
