import { Reducer } from 'react';
import { Actions } from '../../actions/lofaScheduleEdit';
import { Action, LofaScheduleEditState, LofaDetailByProppantEdit } from '../../types';
import { GetLofaSchedule_getLofaSchedule as LofaScheduleSummaryData } from '../../../types/schemaTypes';
import _ from 'lodash';
import * as uuid from 'uuid';

const lofaDetailList: LofaDetailByProppantEdit[] = [];
const oldList: LofaScheduleSummaryData[] = [];

const initialLofaScheduleEditState: LofaScheduleEditState = {
    currentValues: oldList,
    isFormDisabled: true,
    toBePosted: lofaDetailList,
};

const updateLofaDetail = (
    { lofaDetail, inputTitle, value }: any,
    state: LofaScheduleEditState,
): LofaScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    const currentValues = _.cloneDeep(state.currentValues);

    if (toBePosted.find(e => e.proppantId === lofaDetail.proppantId)) {
        toBePosted = toBePosted.map((e: any) => {
            if (e.proppantId === lofaDetail.proppantId) {
                e[inputTitle] = value;
            }

            return e;
        });
    } else {
        lofaDetail[inputTitle] = value;
        toBePosted.push(lofaDetail);
    }
    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const addLofaDetail = (
    { lofaDetail }: { lofaDetail: LofaDetailByProppantEdit },
    state: LofaScheduleEditState,
): LofaScheduleEditState => {
    const toBePosted = state.toBePosted.concat([lofaDetail]);
    return {
        ...state,
        toBePosted: toBePosted,
    };
};

const updateFacilityValue = (
    { lofaDetail, lofaFacility, lofaFacilityValue }: any,
    state: LofaScheduleEditState,
): LofaScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    const currentValues = _.cloneDeep(state.currentValues);
    if (!toBePosted.find(e => e.proppantId === lofaDetail.proppantId)) {
        toBePosted = addLofaDetail({ lofaDetail }, state).toBePosted;
    }

    toBePosted = toBePosted.map(j => {
        if (j.proppantId === lofaDetail.proppantId) {
            return {
                ...j,
                jobSummaries: j.jobSummaries.map((j: any) => {
                    if (j.id === lofaFacility.id) {
                        j.lofaName = lofaFacilityValue.value;
                        j.lofaId = lofaFacilityValue.id;
                    }
                    return j;
                }),
            };
        }
        return j;
    });

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const updateFacility = (
    { lofaDetail, lofaFacility, inputTitle, value }: any,
    state: LofaScheduleEditState,
): LofaScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    const currentValues = _.cloneDeep(state.currentValues);

    if (!toBePosted.find(e => e.proppantId === lofaDetail.proppantId)) {
        toBePosted = addLofaDetail({ lofaDetail }, state).toBePosted;
    }

    toBePosted = toBePosted.map(j => {
        if (j.proppantId === lofaDetail.proppantId) {
            return {
                ...j,
                jobSummaries: j.jobSummaries.map((js: any) => {
                    if (js.id === lofaFacility.id) {
                        js[inputTitle] = value;
                    }
                    return js;
                }),
            };
        }
        return j;
    });

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const setInitialData = ({ data, newValues }: any, state: LofaScheduleEditState): LofaScheduleEditState => {
    const copiedOldValues = _.cloneDeep(data);
    return {
        ...state,
        currentValues: copiedOldValues,
        toBePosted: _.cloneDeep(newValues),
    };
};

const addFacility = (
    {
        lofaDetail,
        lofaSummaryDetail,
    }: { lofaDetail: LofaDetailByProppantEdit; lofaSummaryDetail: LofaScheduleSummaryData },
    state: LofaScheduleEditState,
): LofaScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);
    const id = uuid.v4();
    if (!toBePosted.find(e => e.proppantId === lofaDetail.proppantId)) {
        toBePosted = addLofaDetail({ lofaDetail }, state).toBePosted;
    }
    let totalVolume = 0;
    if (
        lofaSummaryDetail !== undefined &&
        lofaSummaryDetail !== null &&
        lofaSummaryDetail.proppantId === lofaDetail.proppantId
    ) {
        if (
            lofaSummaryDetail.jobSummaries !== null &&
            lofaSummaryDetail.jobSummaries![0] !== null &&
            lofaSummaryDetail!.jobSummaries![0]!.totalVolume
        ) {
            totalVolume = lofaSummaryDetail!.jobSummaries![0]!.totalVolume;
        }
    }
    toBePosted = toBePosted.map(e => {
        if (e.proppantId === lofaDetail.proppantId && e.jobSummaries) {
            return {
                ...e,
                jobSummaries: e.jobSummaries.concat([
                    {
                        id: id,
                        jobSummaryId: 0,
                        jobId: 0,
                        lofaId: 0,
                        lofaName: '',
                        lofaVolume: undefined,
                        totalVolume: totalVolume || 0,
                        volumePerBox: undefined,
                        proppantId: 0,
                        milesFromWell: undefined,
                        sandPo: '',
                        transportationPo: '',
                        isDeleted: false,
                    },
                ]),
            };
        } else {
            return e;
        }
    });

    if (currentValues.find(e => e.proppantId === lofaDetail.proppantId)) {
        currentValues = currentValues.map(e => {
            if (e.proppantId === lofaDetail.proppantId && e.jobSummaries) {
                return {
                    ...e,
                    jobSummaries: e.jobSummaries.concat([
                        {
                            id: id,
                            __typename: 'LoadFacilityDetail',
                            jobSummaryId: 0,
                            lofaId: 0,
                            lofaName: '',
                            totalVolume: totalVolume || 0,
                            lofaVolume: null,
                            volumePerBox: null,
                            proppantId: 0,
                            milesFromWell: null,
                            sandPo: '',
                            transportationPo: '',
                        },
                    ]),
                };
            } else {
                return e;
            }
        });
    }

    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const deleteFacility = ({ lofaDetail, lofaFacility }: any, state: LofaScheduleEditState): LofaScheduleEditState => {
    let toBePosted = _.cloneDeep(state.toBePosted);
    let currentValues = _.cloneDeep(state.currentValues);

    const existingFacilities = (proppant: any): any[] =>
        proppant.jobSummaries.map((j: any) => {
            if (j.id === lofaFacility.id) {
                j.isDeleted = true;
            }
            return j;
        });

    const filteredFacilities = (proppant: any): any[] =>
        proppant.jobSummaries.filter((j: any) => j.id !== lofaFacility.id);

    if (!toBePosted.find(e => e.proppantId === lofaDetail.proppantId)) {
        toBePosted = addLofaDetail({ lofaDetail }, state).toBePosted;
    }
    toBePosted = toBePosted.map(j => {
        if (j.proppantId === lofaDetail.proppantId) {
            return {
                ...j,
                jobSummaries: !!lofaFacility.lofaId ? existingFacilities(j) : filteredFacilities(j),
            };
        }
        return j;
    });

    currentValues = currentValues.map(j => {
        if (j.proppantId === lofaDetail.proppantId && j.jobSummaries) {
            return {
                ...j,
                jobSummaries: !!lofaDetail.lofaId ? existingFacilities(j) : filteredFacilities(j),
            };
        }
        return j;
    });
    return {
        ...state,
        toBePosted: toBePosted,
        currentValues: currentValues,
    };
};

const setLofaScheduleToInitialState = (payload: [], state: LofaScheduleEditState): LofaScheduleEditState => {
    return {
        ...state,
        toBePosted: payload,
        currentValues: payload,
    };
};

const setFormEnabledDisabled = (
    payload: { isDisabled: boolean },
    state: LofaScheduleEditState,
): LofaScheduleEditState => {
    return {
        ...state,
        isFormDisabled: payload.isDisabled,
    };
};

const lofaScheduleEditReducer: Reducer<LofaScheduleEditState, Action> = (state, action): LofaScheduleEditState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.ADD_FACILITY:
            newState = addFacility(action.payload, state);
            break;
        case Actions.UPDATE_LOFA_DETAIL:
            newState = updateLofaDetail(action.payload, state);
            break;
        case Actions.UPDATE_FACILITY_VALUE:
            newState = updateFacilityValue(action.payload, state);
            break;
        case Actions.UPDATE_FACILITY:
            newState = updateFacility(action.payload, state);
            break;
        case Actions.SET_INITIAL_DATA:
            newState = setInitialData(action.payload, state);
            break;
        case Actions.DELETE_FACILITY:
            newState = deleteFacility(action.payload, state);
            break;
        case Actions.RE_INITIALIZE:
            newState = setLofaScheduleToInitialState(action.payload, state);
            break;
        case Actions.SET_FORM_ENABLED_DISABLED:
            newState = setFormEnabledDisabled(action.payload, state);
            break;
    }

    return newState;
};

export { initialLofaScheduleEditState, lofaScheduleEditReducer };
