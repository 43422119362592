import React from 'react';
import './JobDetail.scss';
import 'material-design-icons/iconfont/material-icons.css';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent/TextContent';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';

interface JobDetailProps {
    addressLine1?: string | null;
    addressLine2?: string | null;
    city?: string | null;
    district?: {
        districtName: string | null;
        id: number | null;
        regionId: number | null;
        extSystemName: string | null;
        extSystemReference: string | null;
        districtAbbreviation: string | null;
    } | null;
    latitude?: number | null;
    longitude?: number | null;
    region?: {
        regionName: string | null;
        id: number | null;
        extSystemName: string | null;
        extSystemReference: string | null;
        regionCode: string | null;
    } | null;
    state?: {
        id: number | null;
        stateAcronym: string | null;
        stateName: string | null;
    } | null;
    zip?: string | null;
    directions?: string | null;
}

const defaultString = (text: string | null | undefined): string => text || '';

const JobDetail = ({
    addressLine1,
    addressLine2,
    city,
    district,
    latitude,
    longitude,
    region,
    state,
    zip,
    directions,
}: JobDetailProps): JSX.Element => {
    const cityStateZip = defaultString(city).concat(
        ', ',
        state ? defaultString(state.stateAcronym) : '',
        ' ',
        defaultString(zip),
    );

    const regionName = (region && region.regionName) || '';
    const districtName = (district && district.districtName) || '';
    return (
        <Col className='custom-column-p'>
            <Row className="JobDetail-Title">Job Details</Row>
            <Row>
                <TextContent hasTopMargin title={'Region:'} value={defaultString(regionName)}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'District:'} value={defaultString(districtName)}></TextContent>
            </Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Latitude:'}
                    value={(latitude && latitude.toString()) || ''}
                ></TextContent>
            </Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Longitude:'}
                    value={(longitude && longitude.toString()) || ''}
                ></TextContent>
            </Row>
            <Row className="JobDetail-Multiline">
                {directions !== '' ? (
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<div className="JobDetail-DirectionsTooltip">{directions}</div>}
                    >
                        <div className="JobDetail-GroupLink">
                            <TextContent
                                hasTopMargin
                                title={'Address:'}
                                value={defaultString(addressLine1)}
                                showInfoIcon={
                                    defaultString(addressLine1) !== '' &&
                                    defaultString(addressLine2) === '' &&
                                    cityStateZip === ''
                                }
                            ></TextContent>
                            <TextContent
                                title={''}
                                value={defaultString(addressLine2)}
                                showInfoIcon={defaultString(addressLine2) !== '' && cityStateZip === ''}
                            ></TextContent>
                            <TextContent
                                title={''}
                                value={cityStateZip}
                                showInfoIcon={cityStateZip !== ''}
                            ></TextContent>
                        </div>
                    </OverlayTrigger>
                ) : (
                    <div>
                        <TextContent hasTopMargin title={'Address:'} value={defaultString(addressLine1)}></TextContent>
                        <TextContent title={''} value={defaultString(addressLine2)}></TextContent>
                        <TextContent title={''} value={cityStateZip}></TextContent>
                    </div>
                )}
            </Row>
        </Col>
    );
};

export default JobDetail;
