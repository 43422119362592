import React, { FC } from 'react';
import moment from 'moment';
import 'material-design-icons/iconfont/material-icons.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ItemDetails from '../ItemDetails';
import './JobItem.scss';
import { GetAllJobsWithFiltersAndSearch_getAllJobsWithFiltersAndSearch as Job } from '../../../types/schemaTypes';
import { Routes } from '../../../utils';

interface JobItemProps {
    abortRequest: () => void;
    job: Job;
    isChecked: boolean;
    onChange: (checkedStatus: boolean) => void;
    detailsViewFrom?: string;
}

interface DetailOption {
    statusLabel: string;
    statusIcon: string;
    endDateLabel: string;
}

const jobDetailOptions: { [key: string]: DetailOption } = {
    CREATED: {
        statusLabel: 'Created',
        statusIcon: 'schedule',
        endDateLabel: 'Estimated End:',
    },
    INPROGRESS: {
        statusLabel: 'InProgress',
        statusIcon: 'update',
        endDateLabel: 'Estimated End:',
    },
    COMPLETE: {
        statusLabel: 'Complete',
        statusIcon: 'check_circle',
        endDateLabel: 'End Date:',
    },
    CLOSED: {
        statusLabel: 'Closed',
        statusIcon: 'cancel',
        endDateLabel: 'End Date:',
    },
    UNKNOWN: {
        statusLabel: '',
        statusIcon: '',
        endDateLabel: '',
    },
};

const DATE_FORMAT = 'MMM DD, YYYY';

const JobItem: FC<JobItemProps> = ({
    abortRequest,
    job,
    isChecked,
    onChange,
    detailsViewFrom,
}: JobItemProps): JSX.Element => {
    const { jobId, jobName, jdeNumber, customerName, region, district, status, endDate, estimatedEndDate } = job;
    const { statusLabel, statusIcon, endDateLabel } = jobDetailOptions[status];
    let jobEndDate = '';
    if (endDate !== '' || estimatedEndDate !== '') {
        jobEndDate = moment(endDate ? endDate : estimatedEndDate).format(DATE_FORMAT);
    }

    const openJobProfile = (jobId: string, jobName: string): void => {
        const URL = `${window.location.origin}${Routes.JOB_PROFILE.route}`;
        const newTab = window.open(URL + jobId, '_blank');
        localStorage.setItem('redirectbackto', detailsViewFrom ? detailsViewFrom : '');
        setTimeout(function(): void {
            if (newTab) {
                newTab.document.title = jobName;
            }
        }, 100);
    };

    return (
        <div className={'JobItem-Container ' + (isChecked ? 'JobItem-Selected' : '')}>
            <Col md="auto" className='custom-column'>
                <div className="mb-3">
                    <input
                        type="checkbox"
                        className="JobItem-Checkbox"
                        checked={isChecked}
                        onChange={(event): void => {
                            abortRequest();
                            onChange(event.currentTarget.checked);
                        }}
                    />
                </div>
            </Col>
            <Col md={6} className="d-flex flex-column custom-column-p justify-content-between my-flex-container">
                <div>
                    <Row>
                        <span className="JobItem-Name" onClick={(): void => openJobProfile(jobId, jobName)}>
                            {jobName}
                        </span>
                    </Row>
                    {/* <ItemDetails label="JDE #:" info={jdeNumber} /> */}
                </div>
                <div>
                    <ItemDetails label="Customer Name:" info={customerName} />
                </div>
            </Col>
            <Col md={3} className="d-flex flex-column custom-column-p justify-content-between my-flex-container">
                <ItemDetails label="Region:" info={region} />
                <ItemDetails label="District:" info={district} />
            </Col>
            <Col md="auto" className="d-flex flex-column custom-column-p justify-content-between my-flex-container">
                <Row>
                    <i className="material-icons JobItem-Icon">{statusIcon}</i>
                    <p className="ItemDetails-Label">{statusLabel}</p>
                </Row>
                <ItemDetails label={endDateLabel} info={jobEndDate} />
            </Col>
        </div>
    );
};

export default JobItem;
