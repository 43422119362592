import React from 'react';
import './TextContent.scss';
import { NumericFormat as NumberFormat,PatternFormat } from 'react-number-format';
import 'material-design-icons/iconfont/material-icons.css';

interface TextContentProps {
    hasTopMargin?: boolean;
    title: string;
    value: string;
    textFormat?: TextFormat;
    color?: string;
    showInfoIcon?: boolean;
}

export enum TextFormat {
    Text = 'TEXT',
    PhoneNumber = 'PHONE_NUMBER',
    Number = 'NUMBER',
}

const TextContent = ({
    title,
    value,
    hasTopMargin = false,
    textFormat = TextFormat.Text,
    color,
    showInfoIcon = false,
}: TextContentProps): JSX.Element => {
    let displayContent;
    if (textFormat === TextFormat.Number) {
        displayContent = <NumberFormat value={value || 0} displayType={'text'} thousandSeparator={true} />;
    } else if (textFormat === TextFormat.PhoneNumber) {
        displayContent = (
            <PatternFormat format="(###) ###-####" value={value} displayType={'text'}  />
        );
    } else {
        displayContent = value;
    }

    return (
        <div>
            {!color ? (
                <div className={`TextContent ${hasTopMargin ? 'TopMargin' : null}`}>
                    <div className="TextContent-Title">{`${title}    `}</div>
                    <div className="TextContent-Value">
                        {displayContent}
                        {showInfoIcon && <i className="material-icons TextContent-InfoIcon">info</i>}
                    </div>
                </div>
            ) : (
                <div>
                    <div
                        className={color === 'red' ? 'TextContent-TitleRed' : 'TextContent-TitleDarkGreen'}
                    >{`${title}    `}</div>
                    <div className={color === 'red' ? 'TextContent-ValueRed' : 'TextContent-ValueDarkGreen'}>
                        {displayContent}
                        {showInfoIcon && <i className="material-icons TextContent-InfoIcon">info</i>}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TextContent;
