import { Reducer } from 'react';
import { Actions } from '../../actions/userList';
import { Action, UserListState } from '../../types';

const initialUserListState: UserListState = {
    searchString: '',
    status: '',
    customer: 0,
    role: 0,
    carrier: 0,
};

const userListReducer: Reducer<UserListState, Action> = (state, action): UserListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.FILTER_SEARCHSTRING:
            newState = { ...state, searchString: action.payload };
            break;
        case Actions.FILTER_CUSTOMER_SELECT:
            newState = { ...state, customer: Number(action.payload) };
            break;
        case Actions.FILTER_ROLE_SELECT:
            newState = { ...state, role: Number(action.payload) };
            break;
        case Actions.FILTER_CARRIER_SELECT:
            newState = { ...state, carrier: Number(action.payload) };
            break;
        case Actions.FILTER_STATUS_SELECT:
            newState = { ...state, status: action.payload };
            break;
    }

    return newState;
};

export { initialUserListState, userListReducer };
