import { Action } from '../../types';

export enum Actions {
    UPDATE_SEARCH_PHRASE = 'CREW_LIST_UPDATE_SEARCH_PHRASE',
}

export const setSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};
