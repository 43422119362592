import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Button, Card } from 'react-bootstrap';
import { DateTimePicker, Loading } from '../_common';
import { Moment } from 'moment';
import { GET_TONNAGE_EXTRACT } from '../../graphql/client/queries';
import { GetTonnageExtract_getTonnageExtract as TonnageExtract } from '../../types/schemaTypes';
import { Parser } from '@json2csv/plainjs';
import './Filter.scss';
import '../JobProfile/DailySandAllocations/DailySandAllocations.scss';

interface DailySandAllocationsFilterProps {
    jobId: string;
    showDownloadFilter: boolean;
    showDownloadFilterHandler: () => void;
}

const DailySandAllocationsFilter = ({
    jobId,
    showDownloadFilter,
    showDownloadFilterHandler,
}: DailySandAllocationsFilterProps): JSX.Element => {
    const [fromDate, setFromDate] = useState<any>();
    const [toDate, setToDate] = useState<any>();

    const [fromDateError, setFromDateError] = useState('');
    const [toDateError, setToDateError] = useState('');

    const [csvCalled, setCsvCalled] = useState(false);
    const [csvErrorMessage, setCsvErrorMessage] = useState('');

    const clearDateRangeFilters = () => {
        setFromDate('');
        setToDate('');
        setFromDateError('');
        setToDateError('');
        setCsvErrorMessage('');
    };

    const onChangeDate = (isFromDate: boolean, date: string | Moment) => {
        const tempDate: Date = new Date(date.valueOf());
        tempDate.setUTCHours(0, 0, 0);
        if (isFromDate) {
            setFromDate(tempDate);
            fromDateError.length > 0 && setFromDateError('');
        } else {
            setToDate(tempDate);
            toDateError.length > 0 && setToDateError('');
        }
        csvErrorMessage.length !== 0 && setCsvErrorMessage('');
    };

    if (fromDate && toDate) {
        if (fromDate.getTime() > toDate.getTime()) {
            fromDateError.length === 0 && setFromDateError('From Date cannot be more than To Date');
        }
        if (fromDate.getTime() < toDate.getTime()) {
            fromDateError.length !== 0 && setFromDateError('');
        }
    }

    const getCurrentDate = (): string => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const strDay = day < 10 ? `0${day}` : `${day}`;
        const strMonth = month < 10 ? `0${month}` : `${month}`;
        return `${year}-${strMonth}-${strDay}`;
    };

    const { loading: csvLoading, data, error } = useQuery(GET_TONNAGE_EXTRACT, {
        onCompleted: () => {
            setCsvCalled(false);
            const tonnageDetails: TonnageExtract[] | null | undefined = data && data.getTonnageExtract;
            if (csvCalled && tonnageDetails && tonnageDetails.length !== 0) {
                const tonnageDetailsList = tonnageDetails.map(tonnageDetail => {
                    return {
                        ProppantName: tonnageDetail.proppantName ?? '',
                        LofaName: tonnageDetail.lofaName ?? '',
                        SandPO: tonnageDetail.sandPO ?? '',
                        LoadDate: tonnageDetail.loadDate ? tonnageDetail.loadDate.split('T')[0] : '',
                        PlannedWeight: tonnageDetail.plannedWeight ?? '',
                        ActualWeight: tonnageDetail.actualWeight ?? '',
                        UpdatedDate: tonnageDetail.updatedDate ?? '',
                        UpdatedBy: tonnageDetail.updatedBy ?? '',
                    };
                });

                const opts = {};
                const parser = new Parser(opts);
                const csvData = parser.parse(tonnageDetailsList);
                const file = new Blob([csvData], { type: 'application/csv' });
                const url = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = url;
                a.download = `List of Daily Tonnage ${jobId}_${getCurrentDate()}.csv`;
                a.click();
            }
        },
        onError: () => {
            setCsvCalled(false);
            setCsvErrorMessage(`Some error occured while downloading the CSV: ${error?.message}`);
        },
        variables: {
            input: {
                jobId: parseInt(jobId),
                fromDate: fromDate,
                toDate: toDate,
            },
        },
        skip: !csvCalled,
        fetchPolicy: 'no-cache',
    });

    const onClickDownloadCsv = () => {
        csvErrorMessage.length !== 0 && setCsvErrorMessage('');
        if (!fromDate || fromDate.toString() === 'Invalid Date') {
            setFromDateError('Enter a Valid Date');
        }
        if (!toDate || toDate.toString() === 'Invalid Date') {
            setToDateError('Enter a Valid Date');
        }

        if (
            fromDate &&
            toDate &&
            fromDate.toString() !== 'Invalid Date' &&
            toDate.toString() !== 'Invalid Date' &&
            fromDate.getTime() < toDate.getTime()
        ) {
            setCsvCalled(true);
        }
        return;
    };

    return (
        <div
            className={
                showDownloadFilter
                    ? 'DailySandAllocations-FiltersContainer'
                    : 'DailySandAllocations-FiltersContainer hide'
            }
        >
            <div className="Filter-Container" id="DailySandAllocations-FilterContainer">
                <div className="Filter-Header">
                    <div className="Filter-HeaderIconContainer">
                        <i className="material-icons">get_app</i>
                        <span className="Filter-HeaderTitle">Download CSV</span>
                    </div>
                    <i
                        className="material-icons DailySandAllocations-Download-Icon"
                        onClick={() => {
                            showDownloadFilterHandler();
                            clearDateRangeFilters();
                        }}
                    >
                        cancel
                    </i>
                </div>

                <div>
                    <Card style={{ overflow: 'visible' }}>
                        <Card.Body className="DailySandAllocations-DatePickerFilterItem-Body">
                            <Button
                                className="DatePickerFilterItem-SelectAll"
                                variant="link"
                                onClick={clearDateRangeFilters}
                            >
                                Clear Dates
                            </Button>

                            <div className="DailySandAllocations-DatePickerFilterItem-Date-Container">
                                <DateTimePicker
                                    dateOnly
                                    label="FROM"
                                    value={fromDate}
                                    onChange={value => onChangeDate(true, value)}
                                    error={fromDateError || ''}
                                />

                                <span className="DatePickerFilterItem-Divider">-</span>

                                <DateTimePicker
                                    dateOnly
                                    label="TO"
                                    value={toDate}
                                    onChange={value => onChangeDate(false, value)}
                                    error={toDateError || ''}
                                />
                            </div>

                            <div className="DailySandAllocations-Download-Container">
                                {csvLoading && <Loading />}
                                <i className="material-icons Filter-Download-Icon">get_app</i>
                                <Button className="Filter-SelectAll" variant="link" onClick={onClickDownloadCsv}>
                                    Download CSV
                                </Button>
                            </div>

                            {csvErrorMessage.length > 0 && (
                                <div className="Csv-Error-Box">
                                    <i
                                        className="material-icons Csv-Error-Box-Icon"
                                        onClick={() => setCsvErrorMessage('')}
                                    >
                                        cancel
                                    </i>
                                    {csvErrorMessage}
                                </div>
                            )}
                        </Card.Body>
                    </Card>
                </div>
            </div>
        </div>
    );
};

export default DailySandAllocationsFilter;
