import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './LOFAScheduleDisplayMode.css';
import LOFAScheduleDetailItem from '../LOFAScheduleDetailItem/LOFAScheduleDetailItem';
import { GetLofaSchedule_getLofaSchedule as LOFAScheduleSummaryData } from '../../../../types/schemaTypes';
import { PermissionEnum } from '../../../../types/PermissionEnum';
import { useAuthContext } from '../../../Auth/AuthProvider';

interface LOFAScheduleDisplayModeProps {
    lofaScheduleSummaryList: LOFAScheduleSummaryData[];
    isJobCompleted: boolean;
}

const LOFAScheduleDisplayMode = ({
    lofaScheduleSummaryList,
    isJobCompleted,
}: LOFAScheduleDisplayModeProps): JSX.Element => {
    return <LOFAScheduleDetailItem lofaScheduleSummaryList={lofaScheduleSummaryList} />;
};

export default LOFAScheduleDisplayMode;
