import React, { FC } from 'react';
import Row from 'react-bootstrap/Row';
import './ItemDetails.scss';

interface ItemDetailsProps {
    label: string;
    info: string | number | null;
}

const ItemDetails: FC<ItemDetailsProps> = ({ label, info }: ItemDetailsProps): JSX.Element => {
    return (
        <Row>
            <p className="ItemDetails-Label">{label}</p>
            <p className="ItemDetails-Info">{info}</p>
        </Row>
    );
};

export default ItemDetails;
