import React, { useEffect, useState } from 'react';
import LoadManagementHeader from '../Header/LoadManagementHeader';
import { LoadFilters } from '../Filter/LoadFilters';
import LoadList from '../TableList/LoadList';
import { GET_FILTERED_LOAD_MANAGEMENTS, GET_LOADMANAGEMENT_LOADLIST_CSV } from '../../graphql/client/queries';
import {
    GetFilteredLoadManagements,
    GetFilteredLoadManagements_getFilteredLoadManagements as Load,
    SortOrder,
} from '../../types/schemaTypes';
import { Error } from '../_common';
import { useStoreContext } from '../App/StoreProvider';
import { useQuery } from '@apollo/client';
import './LoadManagement.scss';
import { LoadStatusEnum } from '../../types/LoadStatusEnum';

import * as LoadManagementListActions from '../../store/actions/loadManagementList';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import ForbiddenError from '../_common/Error/ForbiddenError';
import { Base64 } from 'js-base64';
// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

interface SelectedLoad {
    loadId: number;
    loadNumber: number;
}

const LoadManagementLoadData = ({ match }: any): JSX.Element => {
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [state, dispatch] = useStoreContext();
    const {
        loadManagementList: { filters, searchPhrase },
        carrierList: { carrierId },
        loadList: {
            filters: { fromDate, toDate },
        },
    } = state;
    const abortController = React.useRef(new AbortController());
    const loadIds: any = [];
    const initialArray: SelectedLoad[] = [];
    // TODO: Move local state to jobListReducer
    const [selectedLoads, setSelectedLoads] = useState(initialArray);

    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const [isSearchOnLoadId, setIsSearchOnLoadId] = useState(true);
    const [csvCalled, setCsvCalled] = useState(false);
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCarrier = useAuthContext().userHasPermission(PermissionEnum.Carrier);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);

    const variables = React.useMemo(()=>{
        
        return {
        input: {
            regionIds: filters.regionIds,
            districtIds: filters.districtIds,
            customerIds: filters.customerIds,
            jobStatuses: filters.jobStatuses,
            proppants: filters.proppantIds,
            statuses: filters.statuses,
            carriers: filters.carrierIds,
            drivers: filters.driverIds,
            lofas: filters.lofaIds,
            jobIds: filters.jobIds,
            search: searchPhrase,
            sortOrder: sortOrder,
            atDestinationOutFrom: filters.fromDate,
            atDestinationOutTo: filters.toDate,
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
            isSearchOnLoadId: searchPhrase ? isSearchOnLoadId : true,
        },
    }},[filters.regionIds,filters.districtIds,filters.customerIds,filters.jobStatuses,filters.proppantIds,filters.statuses,filters.driverIds,filters.carrierIds,filters.lofaIds,filters.jobIds,searchPhrase,sortOrder,filters.fromDate,filters.toDate,carrierId]);

    const context = React.useMemo(()=>{
        return{
        fetchOptions: {
            signal: abortController.current.signal,
        },
    }},[abortController.current.signal]);

    

    const abortRequest = (): void => {
        abortController.current.abort();
    };

    const downloadCsv = (csvExport): Blob => {
        const result = csvExport.getLoadManagementLoadListCSV.csv;
        const decodedCsv = Base64.decode(result);
        const file = new Blob([decodedCsv], { type: 'application/csv' });
        return file;
    };

    const getCurrentDate = (): string => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const strDay = day < 10 ? `0${day}` : `${day}`;
        const strMonth = month < 10 ? `0${month}` : `${month}`;
        return `${year}${strMonth}${strDay}`;
    };

    const { loading: csvLoading, data: csvExport } = useQuery(GET_LOADMANAGEMENT_LOADLIST_CSV, {
        onCompleted: () => {
            if (csvCalled && csvExport) {
                const file: Blob = downloadCsv(csvExport);
                const url = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = url;
                a.download = `List of Loads_${getCurrentDate()}.csv`;
                a.click();
                setCsvCalled(false);
            }
        },
        variables: {
            input: {
                regionIds: filters.regionIds,
                districtIds: filters.districtIds,
                customerIds: filters.customerIds,
                jobStatuses: filters.jobStatuses,
                proppants: filters.proppantIds,
                statuses: filters.statuses,
                carriers: filters.carrierIds,
                drivers: filters.driverIds,
                lofas: filters.lofaIds,
                jobIds: filters.jobIds,
                search: searchPhrase,
                sortOrder: sortOrder,
                atDestinationOutFrom: filters.fromDate,
                atDestinationOutTo: filters.toDate,
                driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
                isSearchOnLoadId: searchPhrase ? isSearchOnLoadId : true,
            },
        },
        skip: !csvCalled,
        fetchPolicy: 'no-cache',
    });

    console.log('carrierId', carrierId);
    console.log("localStorage.getItem('carrierId')", localStorage.getItem('carrierId'));
    const { data, error, loading, fetchMore, refetch } = useQuery<GetFilteredLoadManagements>(
        GET_FILTERED_LOAD_MANAGEMENTS,
        {
            variables,
            pollInterval: 300000,
            context,
            fetchPolicy: 'network-only',
        },
    );

    if (error) {
        return <Error error={error} />;
    }

    const loadsList: Load[] = [];

    if (data && data.getFilteredLoadManagements && data.getFilteredLoadManagements.length !== 0) {
        data.getFilteredLoadManagements.forEach((element: any | null): void => {
            if (element !== null) {
                loadsList.push(element);
            }
        });
    }
    const offset = data && data.getFilteredLoadManagements ? data.getFilteredLoadManagements.length / PAGE_SIZE : 0;

    let isEndOfResults = false;
    if (
        offset % 1 !== 0 ||
        (!isEndOfResults &&
            data &&
            data.getFilteredLoadManagements &&
            data.getFilteredLoadManagements.length < PAGE_SIZE)
    ) {
        // If offset is not a whole number, the end of the results has been reached. (5/11/19) - KS
        isEndOfResults = true;
    }

    if (!isAdmin && !isOperationUser && !isCarrier) {
        return <ForbiddenError />;
    }

    return (
        <LoadList
            abortRequest={abortRequest}
            loadList={loadsList}
            isLoading={loading}
            isEndOfResults={isEndOfResults}
            entries={(data && data.getFilteredLoadManagements) || []}
            refetch={refetch}
            onLoadMore={() =>
                fetchMore({
                    variables: {
                        offset,
                    },
                    updateQuery: (prev: any, { fetchMoreResult }: any) => {
                        if (
                            prev &&
                            fetchMoreResult &&
                            prev.getFilteredLoadManagements &&
                            fetchMoreResult.getFilteredLoadManagements
                        ) {
                            return Object.assign({}, prev, {
                                getFilteredLoadManagements: [
                                    ...prev.getFilteredLoadManagements,
                                    ...fetchMoreResult.getFilteredLoadManagements,
                                ],
                            });
                        } else {
                            return prev;
                        }
                    },
                })
            }
        />
    );
};

export default LoadManagementLoadData;
