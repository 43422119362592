import { Reducer } from 'react';
import { Actions } from '../../actions/carrierList';
import { Action, CarrierListState } from '../../types';

const initialCarrierListState: CarrierListState = {
    searchPhrase: '',
    carrierId: 0,
};

const setSearchPhrase = (search: string, state: CarrierListState): CarrierListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const setCarrierSelection = (carrierId: number, state: CarrierListState): CarrierListState => {
    return {
        ...state,
        carrierId: carrierId,
    };
};

const carrierListReducer: Reducer<CarrierListState, Action> = (state, action): CarrierListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.CARRIER_LIST_SELECT:
            newState = setCarrierSelection(action.payload, state);
            break;
    }

    return newState;
};

export { initialCarrierListState, carrierListReducer };
