import './Filter.scss';
import React, { useState } from 'react';
import FilterItem from './FilterItem';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import {  QueryResult, useQuery } from '@apollo/client';
import {Query} from '@apollo/client/react/components';
import { GET_JOB_FILTERS_LIST } from '../../graphql/client/queries';
import SearchableDropdown from '../_common/SearchableDropdown/SearchableDropdown';
import { useStoreContext } from '../App/StoreProvider';
import * as DriversListActions from '../../store/actions/driversList';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import SearchBar from '../SearchBar';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';

import { GET_DRIVER_FILTERS_LIST } from '../../graphql/client/queries';
import {
    GetDriverFilters_getDriverFilters_regions as RegionFilter,
    GetDriverFilters_getDriverFilters_districts as DistrictsFilter,
    GetDriverFilters_getDriverFilters_statuses as StatusFilter,
} from '../../types/schemaTypes';
import { GetDriverFilters } from '../../types/schemaTypes';

interface DriverFiltersProps {
    cpOptions: any[];
    carrierOptions: any[];
    roles: any[];
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const DriverFilters = ({
    abortRequest,
    onFilterToggle,
    cpOptions,
    carrierOptions,
    roles,
}: DriverFiltersProps): JSX.Element => {
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);
    const rolesList = isOperationUser ? roles.filter(t => t.id === 5 || t.id === 6) : roles;
    const [state, dispatch] = useStoreContext();
    const {
        driverList: { searchString, status, districtIds, isComplaint, regionIds },
    } = state;
    const [activeKey, setActiveKey] = useState('0');
    let regionFilters: RegionFilter[] = [];
    let districtFilters: DistrictsFilter[] = [];
    const statusFilters = [
        { id: '1', name: 'Active' },
        { id: '2', name: 'Inactive' },
    ];

    const complaintFilters = [
        { id: '1', name: 'Compliant' },
        { id: '2', name: 'Not Compliant' },
    ];

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    const onSelectStatus = value => {
        dispatch(
            DriversListActions.setFilterValue(
                value === 1 ? 'Active' : value === 2 ? 'Inactive' : null,
                DriversListActions.Actions.FILTER_BY_STATUS,
            ),
        );
    };

    const onSelectComplaint = value => {
        console.log('onSelectComplaint', value);
        dispatch(
            DriversListActions.setFilterValue(
                value === 1 ? 'Compliant' : value === 2 ? 'NonCompliant' : null,
                DriversListActions.Actions.FILTER_BY_COMPLAINT,
            ),
        );
    };

    const variables = {
        input: {
            searchString,
            isOperationUserView: isOperationUser ? true : false,
            status,
        },
    };

    return (
        <Query query={GET_DRIVER_FILTERS_LIST}>
            {({ loading, error, data }: QueryResult<GetDriverFilters>): JSX.Element | null => {
                if (loading) {
                    return (
                        <div className="Filter-Loading">
                            <Loading />
                        </div>
                    );
                }
                if (error) {
                    return <Error error={error} />;
                }

                if (data && data.getDriverFilters) {
                    regionFilters = data.getDriverFilters.regions;
                    districtFilters = data.getDriverFilters.districts;
                }

                return (
                    <div className="Filter-Container">
                        <div className="Filter-Header">
                            <div className="Filter-HeaderIconContainer">
                                <i className="material-icons">filter_list</i>
                                <span className="Filter-HeaderTitle">Filters</span>
                            </div>
                            <Button className="Filter-SelectAll" variant="link" onClick={onSelectAll}>
                                {activeKey === '0' ? 'Collapse All' : 'Expand All'}
                            </Button>
                        </div>

                        <div
                            style={{
                                borderBottom: '1px solid lightgray',
                                marginLeft: '15px',
                                marginRight: '15px',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            }}
                        >
                            <SearchBar type={SearchTypeEnum.Driver}></SearchBar>
                        </div>

                        <FilterItem
                            abortRequest={abortRequest}
                            title="Status"
                            items={statusFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable={true}
                            type="selectDropDown"
                            onSelection={onSelectStatus}
                            placeHolder="Filter By Status"
                        />

                        <FilterItem
                            abortRequest={abortRequest}
                            title="Compliant"
                            items={complaintFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable={false}
                            type="selectDropDown"
                            onSelection={onSelectComplaint}
                            placeHolder="Filter By Compliant"
                            filterFrom={'DriverList'}
                        />

                        <FilterItem
                            abortRequest={abortRequest}
                            title="Districts"
                            items={districtFilters}
                            activeKey={activeKey}
                            isCollection={false}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                            filterFrom={'DriverList'}
                        />
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Regions"
                            items={regionFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                            filterFrom={'DriverList'}
                        />
                    </div>
                );
            }}
        </Query>
    );
};
