// import ApolloClient from 'apollo-client';
import { setContext } from '@apollo/client/link/context';
import { InMemoryCache } from '@apollo/client/cache';

import { ApolloClient } from '@apollo/client/core';
import { HttpLink, ApolloLink } from '@apollo/client';
import { onError } from '@apollo/client/link/error';

const cache = new InMemoryCache();

const errorLink = onError(({ graphQLErrors }) => {
    if (graphQLErrors) graphQLErrors.map(({ message }) => console.log(message));
});

const httpLink = new HttpLink({
    uri: process.env.REACT_APP_GRAPHQL + '/api/graphqlEndpoint',
});

const authLink = setContext((_, { headers }) => {
    const token = sessionStorage.getItem('accessToken');
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const client = new ApolloClient({
    link: ApolloLink.from([errorLink, authLink, httpLink]),
    cache,
});

export default client;
