import gql from 'graphql-tag';

export default gql`
    query GetFullJobProfile($jobId: Int) {
        getJobProfile(jobId: $jobId) {
            totalBoxesForWells
            customerDetails {
                customerId
                customerName
                customerPO
                epCompany
                crew
                contact
                phoneNumber
            }
            jobDetails {
                addressLine1
                addressLine2
                city
                contact
                directions
                district {
                    districtName
                    id
                    regionId
                    extSystemName
                    extSystemReference
                    districtAbbreviation
                }
                jdeNumber
                jobId
                jobName
                latitude
                longitude
                mobDate
                region {
                    regionName
                    id
                    extSystemName
                    extSystemReference
                    regionCode
                }
                state {
                    id
                    stateName
                    stateAcronym
                }
                status {
                    statusId
                    status
                }
                wellEndDate
                wellStartDate
                zip
                crewId
                epCompanyId
                accessorialId
                miles
                accessorialName
            }
            pumpScheduleSummary {
                id
                stages
                stageTime
                totalBoxes
                wellName
                proppants {
                    id
                    pumpScheduleId
                    proppantId
                    proppantName
                    totalVolume
                    volumePerBox
                    volumePerStage
                    boxesPerStage
                    boxesPerWell
                }
            }
        }
    }
`;
