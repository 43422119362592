import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import React from 'react';
import './NavBar.scss';

interface NavBarItemProps {
    children: JSX.Element;
}

const NavBarItemWithOverlay = ({ children }: NavBarItemProps): JSX.Element => {
    const tooltip = <div className="NavBar-Tooltip">Page not available</div>;
    return (
        <OverlayTrigger placement="bottom" delay={{ show: 250, hide: 400 }} overlay={tooltip}>
            <div className="NavBar-Tooltip-Container">{children}</div>
        </OverlayTrigger>
    );
};

export default NavBarItemWithOverlay;
