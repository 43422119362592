import React from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { GetLoadAllocation_getLoadAllocation as loadAllocationData } from '../../../types/schemaTypes';
import './DailySandAllocations.scss';

interface DailySandAllocationsDetailsTableProps {
    dateList: string[];
    loadAllocationDetails: any;
    loading: boolean;
}

const DailySandAllocationsDetailsTable = ({
    dateList,
    loadAllocationDetails,
    loading,
}: DailySandAllocationsDetailsTableProps): JSX.Element => {
    const finalTotalList = new Array(14).fill(0);

    return (
        <Col>
            <Row>
                <Table
                    bordered
                    className={
                        loading
                            ? 'DailySandAllocationsTable DailySandAllocationsTable-Loading'
                            : 'DailySandAllocationsTable'
                    }
                >
                    <tr className="DailySandAllocationsTable-Dates">
                        <th></th>
                        <th></th>
                        {dateList.map((date, index) => (
                            <th key={index} className={date.includes('|') ? 'DailySandAllocations-Weekend' : ''}>
                                {date.replace('|', '')}
                            </th>
                        ))}
                    </tr>

                    {Object.keys(loadAllocationDetails).map((proppant, index) => {
                        const totalList = new Array(14).fill(0);
                        return (
                            <>
                                <tr>
                                    <th className="DailySandAllocations-Header DailySandAllocations-ClmLg">
                                        {proppant}
                                    </th>
                                    <th className="DailySandAllocations-Header DailySandAllocations-ClmLg">Sand PO</th>
                                </tr>

                                {loadAllocationDetails[proppant].map((loadAllocationDetail, index) => {
                                    for (let i = 0; i < 14; i++) {
                                        totalList[i] =
                                            totalList[i] + (loadAllocationDetail[`weightonDate${i + 1}`] ?? 0);
                                    }

                                    return (
                                        <tr key={index}>
                                            <td className="DailySandAllocations-ClmLg">
                                                {loadAllocationDetail.lofaName}
                                            </td>
                                            <td className="DailySandAllocations-ClmLg">
                                                {loadAllocationDetail.sandPO}
                                            </td>
                                            <td>{loadAllocationDetail.weightonDate1?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate2?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate3?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate4?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate5?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate6?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate7?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate8?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate9?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate10?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate11?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate12?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate13?.toFixed(2) ?? '-'}</td>
                                            <td>{loadAllocationDetail.weightonDate14?.toFixed(2) ?? '-'}</td>
                                        </tr>
                                    );
                                })}

                                <tr className="DailySandAllocationsTable-Total-Row">
                                    <th
                                        colSpan={2}
                                        className="DailySandAllocationsTable-Total-Header"
                                    >{`Total ${proppant} Supply`}</th>
                                    {totalList.map((total, index) => {
                                        finalTotalList[index] = finalTotalList[index] + total;
                                        return <th key={index}>{total?.toFixed(2)}</th>;
                                    })}
                                </tr>
                                <td colSpan={16}>&nbsp;</td>
                            </>
                        );
                    })}

                    <tr className="DailySandAllocationsTable-Final-Total-Row">
                        <th colSpan={2} className="DailySandAllocationsTable-Total-Header">
                            Total
                        </th>
                        {finalTotalList.map((finalTotal, index) => (
                            <th key={index}>{finalTotal?.toFixed(2)}</th>
                        ))}
                    </tr>
                </Table>
            </Row>
        </Col>
    );
};

export default DailySandAllocationsDetailsTable;
