import React, { useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './LOFAScheduleSummary.scss';
import {
    GetLofaSchedule,
    GetLofaSchedule_getLofaSchedule as LOFAScheduleSummaryData,
} from '../../../types/schemaTypes';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import { Error, Loading } from '../../_common';
import { GET_LOFA_SCHEDULE } from '../../../graphql/client/queries';
import LOFAScheduleDisplayMode from './LOFAScheduleDisplayMode';

interface LOFAScheduleSummaryProps {
    jobId: number;
    isJobCompleted: boolean;
}

const LOFAScheduleSummary = ({ jobId, isJobCompleted }: LOFAScheduleSummaryProps): JSX.Element => {
    const variables = {
        jobId: jobId,
    };

    return (
        <Query query={GET_LOFA_SCHEDULE} variables={variables}>
            {({ error, data, loading }: QueryResult<GetLofaSchedule | undefined>): JSX.Element | null => {
                if (loading) {
                    return <Loading />;
                }

                if (error) {
                    return <Error error={error} />;
                }

                const lofaScheduleSummaryList: LOFAScheduleSummaryData[] = [];

                if (data && data.getLofaSchedule && data.getLofaSchedule.length !== 0) {
                    data.getLofaSchedule.forEach((element: LOFAScheduleSummaryData | null): void => {
                        if (element !== null) {
                            lofaScheduleSummaryList.push(element);
                        }
                    });
                }

                return (
                    <div className="LOFA-Container">
                        <LOFAScheduleDisplayMode
                            lofaScheduleSummaryList={lofaScheduleSummaryList}
                            isJobCompleted={isJobCompleted}
                        />
                    </div>
                );
            }}
        </Query>
    );
};

export default LOFAScheduleSummary;
