import React from 'react';
import './JobSummary.scss';
import { useQuery } from '@apollo/client';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent';
import { TextFormat } from '../../_common/TextContent/TextContent';
import { GET_JOB_SUMMARY } from '../../../graphql/client/queries';
import { GetJobProfileSummary } from '../../../types/schemaTypes';
import { Loading } from '../../_common';

interface JobSummaryProps {
    jobId: number;
    wellTotalBoxes?: number | null;
}

const defaultNumber = (value: number | null | undefined): string => {
    return value == undefined ? 'None' : value.toString();
};

const defaultDecimalNumber = (value: number | null | undefined): string => {
    return value == undefined ? 'None' : value.toFixed(2).toString();
};

const JobSummary = ({ jobId, wellTotalBoxes }: JobSummaryProps): JSX.Element => {
    const { data, loading } = useQuery<GetJobProfileSummary>(GET_JOB_SUMMARY, {
        variables: { jobId: jobId },
        fetchPolicy: 'network-only',
    });

    let totalBoxesRemaining;
    let totalBoxesLoaded;
    let averageLoadPerBox;
    let averageLoadPerBoxTons;

    if (data && data.getJobProfileSummary) {
        totalBoxesLoaded = data.getJobProfileSummary.totalBoxesLoaded;
        totalBoxesRemaining =
            wellTotalBoxes == null
                ? null
                : totalBoxesLoaded == null
                ? wellTotalBoxes
                : wellTotalBoxes - totalBoxesLoaded;
        averageLoadPerBox = data.getJobProfileSummary.averageLoadPerBox;
        if (averageLoadPerBox == null) averageLoadPerBoxTons = 0;
        else averageLoadPerBoxTons = averageLoadPerBox / 2000;
    }

    if (loading) {
        return (
            <Col>
                <Row className="JobSummary-Title">Job Summary</Row>
                <Loading />
            </Col>
        );
    }

    return (
        <Col className='custom-column-p'>
            <Row className="JobSummary-Title">Job Summary</Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Total Boxes for Wells:'}
                    textFormat={TextFormat.Number}
                    value={defaultNumber(wellTotalBoxes)}
                ></TextContent>
            </Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Total Boxes Remaining:'}
                    textFormat={TextFormat.Number}
                    value={defaultNumber(totalBoxesRemaining)}
                ></TextContent>
            </Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Total Boxes Loaded:'}
                    textFormat={TextFormat.Number}
                    value={defaultNumber(totalBoxesLoaded)}
                ></TextContent>
            </Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Average Pounds/Box:'}
                    textFormat={TextFormat.Number}
                    value={defaultNumber(averageLoadPerBox || 0)}
                ></TextContent>
            </Row>
            <Row>
                <TextContent
                    hasTopMargin
                    title={'Average Tons/Box:'}
                    textFormat={TextFormat.Number}
                    value={defaultDecimalNumber(averageLoadPerBoxTons || 0)}
                ></TextContent>
            </Row>
        </Col>
    );
};

export default JobSummary;
