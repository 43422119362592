import { useQuery, useLazyQuery } from '@apollo/client';
import 'material-design-icons/iconfont/material-icons.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { formatDateToUtc } from '../../../utils/helpers/datetimeHelper';
import {
    GET_CARRIERS,
    GET_ALL_REGIONS,
    GET_DRIVERCOMPANYREFERENCE,
    GET_DRIVER_BY_CELLPHONE,
    GET_ALL_DRIVERS_MASTER_DATA,
} from '../../../graphql/client/queries';
import {
    // GetAllDriversMasterData_getAllDriversMasterData as Driver,
    GetAllDriversListByFilter_getAllDriversListByFilter as Driver,
    GetAllDriversMasterData,
    GetCarrier_getCarriers as CarrierType,
    GetAllRegions_getAllRegions as RegionType,
    GetAllRegions_getAllRegions_districts as DistrictType,
    GetDriverCompanyReferenceByDriver_getDriverCompanyReferenceByDriver as DriverCompanyReferenceType,
} from '../../../types/schemaTypes';
import { CustomButton, DateTimePicker, Loading } from '../../_common';
import { Textfield } from '../../_common/Textfield/Textfield';
import { ModalForm } from '../interface';
import './AddEditDriverModal.scss';
import { MultiSelectDropdownV2 } from '../../_common/MultiSelectDropdown/MultiSelectDropdownV2';
import SearchableDropdown from '../../_common/SearchableDropdown/SearchableDropdown';
// import { ValueType } from 'react-select/src/types';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// import DriverCompanyReferenceItem from '../DriverCompanyReferenceItem';
import moment from 'moment';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import UpdateDriverConfirmationModal from '../UpdateDriverConfirmationModal/UpdateDriverConfirmationModal';

interface Option {
    id: number | string;
    name: string | number;
}

interface OptionType {
    label: string;
    value: string;
}

interface CarrierOption {
    id: number | string;
    companyName: string;
    companyType: string;
}

interface DriverCompanies {
    id: number;
    companyName: string;
    updatedDate: string;
    updatedBy: string;
}

interface AddEditDriverModalProps {
    modalForm: ModalForm;
    options: Option[];
    driverId?: number;
    selectedDriver?: Driver;
    onClickHide: () => void;
    onClickSave: (
        id: number,
        driverName: string,
        cellPhone: string,
        cdlNumber: string,
        isActive: boolean,
        extSystemName: string,
        extSystemIdentifier: string,
        activeInactiveDate: Date,
        inactiveReason: string,
        reactivationDate: Date,
        regionIds: string,
        districtIds: string,
        isComplaint: boolean,
    ) => void;
    show: boolean;
    errorMessage?: string;
}

const AddEditDriverModal = ({
    show,
    options,
    driverId,
    onClickHide,
    onClickSave,
    modalForm,
    errorMessage,
    selectedDriver,
}: AddEditDriverModalProps): JSX.Element => {
    const { data: regionsData } = useQuery(GET_ALL_REGIONS);
    const regions: OptionType[] = [];
    const initialSelectedRegions: OptionType[] = []///ValueType<OptionType>;
    const initialDistrictsnew: OptionType[] = [];
    const initialSelectedDistricts: OptionType[] = []//ValueType<OptionType>;

    const [selectedId, setSelectedId] = useState(0);
    const [selectedDriverName, setSelectedDriverName] = useState('');
    const [selectedCellPhone, setSelectedCellPhone] = useState('');
    const [selectedCDLNumber, setSelectedCDLNumber] = useState('');
    const [selectedCompanyName, setSelectedCompanyName] = useState('');
    const [selectedIsActive, setSelectedIsActive] = useState(true);
    const [selectedExtSystemName, setSelectedExtSystemName] = useState('');
    const [selectedExtSystemIdentifier, setSelectedExtSystemIdentifier] = useState('');
    const [selectedInactiveReason, setSelectedInactiveReason] = useState('');
    const [selectedReactivationDate, setSelectedReactivationDate] = useState(new Date(''));
    const [selectedActiveInactiveDate, setSelectedActiveInactiveDate] = useState(new Date(''));
    const [selectedRegionIds, setSelectedRegionIds] = useState('');
    const [selectedRegions, setSelectedRegions] = useState(initialSelectedRegions);
    const [selectedDistricts, setSelectedDistricts] = useState(initialSelectedDistricts);
    const [availableDistricts, setAvailableDistricts] = useState(initialDistrictsnew);
    const [selectedDistrictIds, setSelectedDistrictIds] = useState('');
    const [driverIsComplaint, setDriverIsComplaint] = useState(false);
    const [cellPhoneError, setCellPhoneError] = useState('');
    const [showAlertPopup, setShowAlertPopup] = useState(false);
    const [modalType, setModalType] = useState('');
    enum modalTypes {
        Update = 'update',
        Unassign = 'unassign',
        EndShift = 'endShift',
    }

    const [
        getDuplicateDriver,
        { data: countDuplicates, loading: countDuplicatesLoading },
    ] = useLazyQuery(GET_DRIVER_BY_CELLPHONE, { fetchPolicy: 'network-only' });

    const isEditForm = modalForm === ModalForm.EDIT_DRIVER;

    const variables = {
        driverId,
    };

    if (regionsData && regionsData.getAllRegions) {
        regionsData.getAllRegions.forEach((element: RegionType) => {
            if (element.regionName !== null) {
                regions.push({
                    value: element.id.toString(),
                    label: element.regionName,
                });
            }
        });

        if (selectedDriver && selectedDriver.districtIds && selectedDriver.districtIds !== '') {
            const regionIds = selectedDriver.districtIds.split(',');

            const districtsListNew: OptionType[] = [];

            regionsData.getAllRegions.forEach((element: RegionType) => {
                if (regionIds.find(x => parseInt(x) === element.id) && element.districts !== null) {
                    element.districts.forEach((element1: DistrictType | null) => {
                        if (element1!.districtName !== null) {
                            districtsListNew.push({
                                value: element1!.id.toString(),
                                label: element1!.districtName,
                            });
                        }
                    });
                }
            });
        }
    }

    useEffect(() => {
        if (!countDuplicatesLoading) {
            if (countDuplicates && countDuplicates.getDriverByCellPhone) {
                if (countDuplicates.getDriverByCellPhone.length == 0) {
                    setCellPhoneError('');
                } else if (
                    countDuplicates.getDriverByCellPhone.length > 0 &&
                    selectedDriver?.cellPhone &&
                    selectedCellPhone == selectedDriver.cellPhone
                ) {
                    setCellPhoneError('');
                } else {
                    setCellPhoneError('Cell phone number already exists.');
                }
            }
        }
    }, [countDuplicates]);

    useEffect(() => {
        if (!countDuplicatesLoading) {
            if (countDuplicates && countDuplicates.getDriverByCellPhone) {
                if (countDuplicates.getDriverByCellPhone.length == 0) {
                    setCellPhoneError('');
                } else if (
                    countDuplicates.getDriverByCellPhone.length > 0 &&
                    selectedDriver &&
                    selectedCellPhone == selectedDriver.cellPhone
                ) {
                    setCellPhoneError('');
                } else {
                    setCellPhoneError('Cell phone number already exists.');
                }
            }
        }
    }, [countDuplicates]);

    useEffect(() => {
        if (selectedDriver) {
            console.log('selectedDriver', selectedDriver);
            setSelectedId(selectedDriver.id ? selectedDriver.id : 0);
            setSelectedDriverName(selectedDriver.name ? selectedDriver.name : '');
            setSelectedCellPhone(selectedDriver.cellPhone ? selectedDriver.cellPhone : '');
            setSelectedCDLNumber(selectedDriver.cdlNumber ? selectedDriver.cdlNumber : '');
            setSelectedCompanyName(selectedDriver.companyName ? selectedDriver.companyName : '');
            setSelectedIsActive(selectedDriver.active ? selectedDriver.active : false);
            setSelectedExtSystemIdentifier(selectedDriver.isnetId ? selectedDriver.isnetId : '');
            setSelectedRegionIds(selectedDriver.regionIds ? selectedDriver.regionIds : '');
            setSelectedDistrictIds(selectedDriver.districtIds ? selectedDriver.districtIds : '');
            setDriverIsComplaint(selectedDriver.isCompliant === 'true' ? true : false);

            if (selectedDriver.regionIds && selectedDriver.regionIds !== '') {
                const regionlist = selectedDriver.regionIds.split(',');
                const tmpRegions: OptionType[] = [];
                const tmpDistricts: OptionType[] = [];
                for (let i = 0; i < regionlist.length; i++) {
                    if (regions.find(l => l.value === regionlist[i])) {
                        const tmpRegion = regions.find(l => l.value === regionlist[i]);

                        if (tmpRegion && tmpRegion.label !== null)
                            tmpRegions.push({
                                value: regionlist[i],
                                label: tmpRegion.label,
                            });
                    }
                    regionsData.getAllRegions.forEach((element: RegionType) => {
                        if (parseInt(regionlist[i]) === element.id && element.districts !== null) {
                            if (selectedDriver?.districtIds && selectedDriver?.districtIds !== '') {
                                const districtlist = selectedDriver?.districtIds.split(',');
                                for (let d = 0; d < districtlist.length; d++) {
                                    if (element.districts.find(ed => ed?.id === parseInt(districtlist[d]))) {
                                        const tmpDistrict = element.districts.find(
                                            ed => ed?.id === parseInt(districtlist[d]),
                                        );
                                        if (tmpDistrict && tmpDistrict.districtName)
                                            tmpDistricts.push({
                                                value: districtlist[d],
                                                label: tmpDistrict.districtName,
                                            });
                                    }
                                }
                            }
                        }
                    });
                }
                setSelectedRegions(tmpRegions);
                setSelectedDistricts(tmpDistricts);
            }
        }
    }, [selectedDriver]);

    const clearState = (): void => {
        setSelectedId(0);
        setSelectedDriverName('');
        setSelectedCellPhone('');
        setSelectedCDLNumber('');
        setSelectedCompanyName('');
        setSelectedIsActive(true);
        setSelectedRegions(initialSelectedRegions);
        setSelectedRegionIds('');
        setSelectedReactivationDate(new Date(''));
        setCellPhoneError('');
    };

    const checkForCellPhoneDuplicates = (): void => {
        getDuplicateDriver({ variables: { input: { cellPhone: selectedCellPhone } } });
    };

    const onSave = (): void => {
        onClickSave(
            selectedId,
            selectedDriverName,
            selectedCellPhone,
            selectedCDLNumber,
            selectedIsActive,
            selectedExtSystemName,
            selectedExtSystemIdentifier,
            selectedActiveInactiveDate,
            selectedInactiveReason,
            selectedReactivationDate,
            selectedRegionIds,
            selectedDistrictIds,
            driverIsComplaint,
        );
        // clearState();
    };

    const onCancel = (): void => {
        clearState();
        onClickHide();
    };

    const validateCellPhone = (phone: string): boolean => {
        const phoneRegex = /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/;
        const isCellPhoneValid = phoneRegex.test(phone.toString());

        return isCellPhoneValid;
    };

    const setSaveButtonStatus = (): boolean => {
        const isCarrierValid = selectedCompanyName.trim().length > 0 ? true : false;
        const isNameValid = selectedDriverName.trim().length > 2 ? true : false;
        const checkCellPhoneExists = selectedCellPhone.length == 0 ? false : true;
        const isCellPhoneValid = validateCellPhone(selectedCellPhone) && cellPhoneError == '';
        const isCDLNumberValid = selectedCDLNumber.trim().length > 0 ? true : false;

        let isActiveValid = true;
        if (selectedIsActive == false && selectedInactiveReason.length <= 0) isActiveValid = false;

        const isFormValid =
            isCarrierValid && isNameValid && checkCellPhoneExists && isCellPhoneValid && isCDLNumberValid; // &&
        // isActiveValid;

        return !isFormValid;
    };

    const changeExtSystemIdentifier = (event: any): void => {
        setSelectedExtSystemIdentifier(event.target.value);
    };

    const changeDriverName = (event: any): void => {
        setSelectedDriverName(event.target.value);
    };

    const changeCellPhone = (event: any): void => {
        setSelectedCellPhone(event.target.value);
    };

    const changeCDLNumber = (event: any): void => {
        setSelectedCDLNumber(event.target.value);
    };

    const changeReason = (event: any): void => {
        setSelectedInactiveReason(event.target.value);
    };

    const getAvailableDistricts = (regionId: number[]): void => {
        const districtsListNew: OptionType[] = [];
        const currentSelectedDistricts: OptionType[] = [];

        setAvailableDistricts(districtsListNew);
        regionsData.getAllRegions.forEach((element: RegionType) => {
            if (regionId.find(x => x === element.id) && element.districts !== null) {
                element.districts.forEach((element1: DistrictType | null) => {
                    if (element1!.districtName !== null) {
                        districtsListNew.push({
                            value: element1!.id.toString(),
                            label: element1!.districtName,
                        });
                    }
                });
            }
        });
        setAvailableDistricts(districtsListNew);
        const districtIds: number[] = [];
        if (selectedDistricts && selectedDistricts !== null && Array.isArray(selectedDistricts)) {
            for (let i = 0; i < selectedDistricts.length; i++) {
                if (selectedDistricts[i] && selectedDistricts[i].value !== null) {
                    const tmp = selectedDistricts[i].value;
                    if (districtsListNew.find(x => x.value === tmp)) {
                        currentSelectedDistricts.push(selectedDistricts[i]);
                        districtIds.push(parseInt(selectedDistricts[i].value));
                    }
                }
            }
        }
        setSelectedDistricts(currentSelectedDistricts);
        setSelectedDistrictIds(districtIds.toString());
    };

    const onRegionChange = (selectedRegion: OptionType): void => {
        const regionIds: number[] = [];
        if (selectedRegion && selectedRegion !== null && Array.isArray(selectedRegion)) {
            for (let i = 0; i < selectedRegion.length; i++) {
                regionIds.push(parseInt(selectedRegion[i].value));
            }

            setSelectedRegions(selectedRegion);
        }
        getAvailableDistricts(regionIds);
        setSelectedRegionIds(regionIds.toString());
    };

    const onDistrictChange = (selectedDistrict:OptionType): void => {
        const districtIds: number[] = [];
        if (selectedDistrict && selectedDistrict !== null && Array.isArray(selectedDistrict)) {
            setSelectedDistricts(selectedDistrict);
            for (let i = 0; i < selectedDistrict.length; i++) {
                districtIds.push(parseInt(selectedDistrict[i].value));
            }
        }
        setSelectedDistrictIds(districtIds.toString());
    };

    const searchIcon = <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'search' }} />;

    return (
        <Modal show={show} onHide={onCancel} size="lg">
            <Modal.Header className="AddEditDriverModal-Header">
                <Modal.Title className="AddEditDriverModal-Header-Container">
                    <button className="material-icons AddEditDriverModal-X-Button" onClick={onCancel}>
                        close
                    </button>
                    <div className="AddEditDriverModal-Title">{isEditForm ? 'Edit Driver' : 'Create New Driver'}</div>
                    <div className="AddEditDriverModal-ErrorMessage">{errorMessage}</div>
                </Modal.Title>
            </Modal.Header>
            <div className="AddEditDriverModal-Container">
                <Row className="AddEditDriverModal-Upper-TextField-Container">
                    <Col md={6} className="AddEditDriverModal-Textfield">
                        <Textfield
                            label="Driver Name"
                            value={selectedDriver !== undefined ? selectedDriver?.name : ''}
                            disabled={true}
                        />
                    </Col>
                    <Col md={6} className="AddEditDriverModal-Textfield">
                        <Textfield
                            label="Carrier Name"
                            value={selectedDriver !== undefined ? selectedDriver?.companyName : ''}
                            disabled={true}
                        />
                    </Col>
                </Row>
                <Row className="AddEditDriverModal-Upper-TextField-Container">
                    <Col md={12} className="AddEditDriverModal-Container">
                        <div>
                            <MultiSelectDropdownV2
                                dropdownOptions={regions}
                                label="Region"
                                onChange={(value: OptionType): void => {
                                    onRegionChange(value);
                                }}
                                key={`my_unique_select_key__${JSON.stringify(selectedRegions)}`}
                                value={selectedRegions}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="AddEditDriverModal-Upper-TextField-Container">
                    <Col md={12} className="AddEditDriverModal-Container">
                        <div>
                            <MultiSelectDropdownV2
                                dropdownOptions={availableDistricts}
                                label="Districts"
                                onChange={(value: OptionType): void => {
                                    onDistrictChange(value);
                                }}
                                key={`my_unique_select_key__${JSON.stringify(selectedRegions)}`}
                                value={selectedDistricts}
                            />
                        </div>
                    </Col>
                </Row>
                <Row className="AddEditDriverModal-Upper-TextField-Container">
                    <Col md={6} className="AddEditDriverModal-Textfield">
                        <Textfield
                            label="*Cell Phone"
                            value={selectedDriver !== undefined ? selectedDriver?.cellPhone : ''}
                            onChange={(event): void => changeCellPhone(event)}
                            onBlur={() => {
                                checkForCellPhoneDuplicates();
                            }}
                            mask={'999-999-9999'}
                            error={cellPhoneError !== ''}
                            helperText={cellPhoneError}
                        />
                    </Col>
                    <Col md={6} className="AddEditDriverModal-Textfield">
                        <Textfield
                            label="*CDL Number"
                            value={selectedDriver !== undefined ? selectedDriver?.cdlNumber : ''}
                            onChange={(event): void => changeCDLNumber(event)}
                        />
                    </Col>
                </Row>
                <Row className="AddEditDriverModal-Upper-TextField-Container">
                    <Col md={6} className="AddEditDriverModal-Textfield">
                        <Textfield
                            label="ISNET Id"
                            value={selectedDriver !== undefined ? selectedDriver?.isnetId : ''}
                            onChange={(event): void => changeExtSystemIdentifier(event)}
                        />
                    </Col>
                    <Col md={6} className="AddEditDriverModal-Textfield">
                        <Textfield
                            label="Status"
                            value={
                                selectedDriver !== undefined ? (selectedDriver?.active ? 'Active ' : 'Inactive') : ''
                            }
                            disabled={true}
                        />
                    </Col>
                </Row>
                {/* <Row>
                    <Col md={4} className="AddEditDriverModal-Radio">
                        <div className="AddEditDriver-InputTitle">Is Driver Active?</div>
                    </Col>
                    <Col md={2} className="AddEditDriverModal-Radio">
                        <input
                            className="AddEditDriverModal-Radio-Input"
                            type="radio"
                            value="true"
                            checked={selectedIsActive}
                            onChange={(event): void => changeActive(event)}
                            disabled={ReadOnly && !canEditDriverSchedule}
                        />
                        Active
                    </Col>
                    <Col md={2}>
                        <input
                            className="AddEditDriverModal-Radio-Input"
                            type="radio"
                            value="false"
                            checked={!selectedIsActive}
                            onChange={(event): void => changeActive(event)}
                            disabled={ReadOnly && !canEditDriverSchedule}
                        />
                        InActive
                    </Col>
                </Row> */}
                {/* <div style={{ display: !selectedIsActive ? 'block' : 'none' }}>
                    <Row>
                        <Col md={3} className="AddEditDriverModal-Radio">
                            <DateTimePicker
                                dateOnly
                                label="Reactivation Date"
                                value={selectedDriver !== undefined ? selectedDriver?.reactivationDate : ''}
                                onChange={(value: any) => {
                                    handleDateInputChange('reactivationDate', value);
                                }}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3} className="AddEditDriverModal-Radio">
                            <Textfield
                                label="*Reason"
                                value={selectedDriver !== undefined ? selectedDriver?.inactiveReason : ''}
                                onChange={(event): void => changeReason(event)}
                            />
                        </Col>
                    </Row>
                </div> */}
            </div>
            <Modal.Footer className="AddEditUserModal-Footer">
                <CustomButton title={'Cancel'} isBordered onClick={onCancel}></CustomButton>
                <CustomButton title="Save" isDisabled={setSaveButtonStatus()} onClick={onSave}></CustomButton>
            </Modal.Footer>
            {/* <UpdateDriverConfirmationModal
                show={showAlertPopup}
                loadId={selectedDriver?.loadId}
                modalType={modalType}
                onCancel={() => setShowAlertPopup(false)}
            /> */}
        </Modal>
    );
};

export default AddEditDriverModal;
