import React from 'react';
import { Routes as Switch, Route } from 'react-router-dom';
import JobActivity from '../../../JobProfile/JobActivity';
import JobManagement from '../../../JobManagement';
import JobMap from '../../../JobProfile/JobMap';
import LoadManagement from '../../../LoadManagement';
import JobProfile from '../../../JobProfile';
import Users from '../../../Users';
import { default as JobLoadsManagement } from '../../../JobProfile/LoadManagement';
import './Content.css';

import ProtectedRoute from '../../../Auth/ProtectedRoute/ProtectedRoute';
import { Routes } from '../../../../utils';
import LoadProfile from '../../../LoadProfile';
import LoadHistory from '../../../LoadProfile/LoadHistory';
import SilentRenew from '../../../Auth/SilentRenew';
import DailySandAllocations from '../../../JobProfile/DailySandAllocations';
import Dashboard from '../../../Dashboard';
import CarrierDashboard from '../../../CarrierDashboard';
import Drivers from '../../../Drivers';
import ChassisCheckout from '../../../ChassisCheckout';

export const Content = (): JSX.Element => {
    return (
        <Switch>
            {/* <PrivateRoute exact path={Routes.DASHBOARD.route} component={Dashboard} />
            <PrivateRoute exact path={Routes.JOB_MANAGEMENT.route} component={JobManagement} />
            <PrivateRoute exact path={Routes.JOB_PROFILE_WITH_ID.route} component={JobProfile} />
            <PrivateRoute exact path={Routes.JOB_LOADS_WITH_ID.route} component={JobLoadsManagement} />
            <PrivateRoute exact path={Routes.LOAD_PROFILE_WITH_ID.route} component={LoadProfile} />
            <PrivateRoute exact path={Routes.SYSTEM_ADMIN_USERS.route} component={Users} />
            <PrivateRoute exact path={Routes.DRIVERS.route} component={Drivers} />
            <PrivateRoute exact path={Routes.LOAD_HISTORY_WITH_ID.route} component={LoadHistory} />
            <PrivateRoute exact path={Routes.JOB_ACTIVITY_WITH_ID.route} component={JobActivity} />
            <PrivateRoute exact path={Routes.DAILY_SAND_ALLOCATIONS_WITH_ID.route} component={DailySandAllocations} />
            <PrivateRoute exact path={Routes.CARRIERDASHBOARD.route} component={CarrierDashboard} />
            <PrivateRoute exact path={Routes.LOAD_MANAGEMENT.route} component={LoadManagement} />
            <PrivateRoute exact path={Routes.CHASSIS_CHECKOUT.route} component={ChassisCheckout} /> */}
        <Route element={<ProtectedRoute />}>
            <Route  path={Routes.DASHBOARD.route} element={<Dashboard />} />
            <Route  path={Routes.JOB_MANAGEMENT.route} element={<JobManagement/>} />
            <Route  path={Routes.JOB_PROFILE_WITH_ID.route} element={<JobProfile/>} />
            <Route  path={Routes.JOB_LOADS_WITH_ID.route} element={<JobLoadsManagement/>} />
            <Route  path={Routes.LOAD_PROFILE_WITH_ID.route} element={<LoadProfile/>} />
            <Route  path={Routes.SYSTEM_ADMIN_USERS.route} element={<Users/>} />
            <Route  path={Routes.DRIVERS.route} element={<Drivers/>} />
            <Route  path={Routes.LOAD_HISTORY_WITH_ID.route} element={<LoadHistory/>} />
            <Route  path={Routes.JOB_ACTIVITY_WITH_ID.route} element={<JobActivity/>} />
            <Route  path={Routes.DAILY_SAND_ALLOCATIONS_WITH_ID.route} element={<DailySandAllocations/>} />
            <Route  path={Routes.CARRIERDASHBOARD.route} element={<CarrierDashboard/>} />
            <Route  path={Routes.LOAD_MANAGEMENT.route} element={<LoadManagement/>} />
            <Route  path={Routes.CHASSIS_CHECKOUT.route} element={<ChassisCheckout/>} />
            <Route path={Routes.SILENT_RENEW.route} Component={SilentRenew} />
            </Route>
        </Switch>
    );
};

export default Content;
