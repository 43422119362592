import './Filter.scss';
import React, { useState } from 'react';
import {
    GET_ALL_PROPPANTS,
    GET_ALL_STATUS_FILTER,
    GET_LOAD_FILTER_DRIVERLIST,
    GET_LOAD_FILTER_LOADFACILITYLIST,
} from '../../graphql/client/queries';
import { useStoreContext } from '../App/StoreProvider';
import LoadFilterItem from './FilterItem/LoadFilterItem';
import Button from 'react-bootstrap/Button';
import { useQuery } from '@apollo/client';
import DatePickerFilterItem from './DatePickerFilterItem/DatePickerFilterItem';
import SearchBar from '../SearchBar';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import {
    GetLoadFilterDriverList,
    GetLoadFilterDriverList_getLoadFilterDriverList as LoadFilterDriver,
    GetLoadFilterLoadFacilityList,
    GetLoadFilterLoadFacilityList_getLoadFilterLoadFacilityList as LoadFilterFacilityLoad,
} from '../../types/schemaTypes';
import FilterItem from './FilterItem';
import { Loading } from '../_common';

interface LoadFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
    isSearchOnLoadId: boolean;
    setIsSearchOnLoadId: React.Dispatch<React.SetStateAction<boolean>>;
    exportCsv: () => void;
    csvLoading;
}

export const LoadFilters = ({
    abortRequest,
    onFilterToggle,
    isSearchOnLoadId,
    setIsSearchOnLoadId,
    exportCsv,
    csvLoading,
}: LoadFiltersProps): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    const [state] = useStoreContext();

    const { data: proppantsData } = useQuery(GET_ALL_PROPPANTS);
    const { data: loadStatusesData } = useQuery(GET_ALL_STATUS_FILTER);

    const {
        loadManagementList: { filters, searchPhrase },
        carrierList: { carrierId },
    } = state;

    const proppants: any = [];
    let loadStatuses: any = [];

    const {
        loadManagementList: {
            searchPhraseSubset: { statusSubset, proppantSubset, lofaSubset, driverSubset },
            filters: { fromDate, toDate },
        },
    } = state;

    const onCloseDateRange = () => {
        //todo no action
    };

    if (proppantsData && proppantsData.getAllProppants) {
        proppantsData.getAllProppants.forEach((item: any) => {
            proppants.push({
                id: item.proppantId,
                name: item.proppantName,
            });
        });
    }

    if (loadStatusesData && loadStatusesData.getAllStatusFilter) {
        loadStatuses = loadStatusesData.getAllStatusFilter;
        // Remove the UNKNOWN status from the statuses list
        loadStatuses = loadStatuses.filter((stat: any) => {
           if (stat.name == 'Created') {const statMirror = {...stat} ; statMirror.name = 'Created/Rejected';}
            return stat.name !== 'Unknown';
        });
    }

    const variables = {
        input: {
            proppants: filters.proppantIds,
            statuses: filters.statuses,
            search: searchPhrase,
            atDestinationOutFrom: filters.fromDate,
            atDestinationOutTo: filters.toDate,
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
            isSearchOnLoadId: isSearchOnLoadId,
        },
    };

    return (
        <div className="Filter-Container">
            <div className="Filter-Header">
                <div className="Filter-HeaderIconContainer">
                    <i className="material-icons">filter_list</i>
                    <span className="Filter-HeaderTitle">Filters</span>
                </div>
                <Button
                    className="Filter-SelectAll"
                    variant="link"
                    onClick={(): void => setActiveKey(activeKey === '0' ? '1' : '0')}
                >
                    {activeKey === '0' ? 'Collapse All' : 'Expand All'}
                </Button>
            </div>

            <div
                style={{
                    borderBottom: '1px solid lightgray',
                    marginLeft: '15px',
                    marginRight: '15px',
                    paddingTop: '15px',
                    paddingBottom: '15px',
                }}
            >
                <div
                    className="row"
                    style={{
                        marginLeft: '15px',
                        paddingBottom: '10px',
                    }}
                >
                    <div className="col-md-4">
                        <div className="JobLoadManagementHeader-Search-Filter">
                            <input
                                type="radio"
                                id="loadId"
                                name="isLoadId"
                                checked={isSearchOnLoadId}
                                onChange={() => setIsSearchOnLoadId(true)}
                            />
                            <label htmlFor="loadId" style={{ marginLeft: '5px' }}>
                                {' '}
                                Load Id
                            </label>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="JobLoadManagementHeader-Search-Filter">
                            <input
                                type="radio"
                                id="searchAll"
                                name="isLoadId"
                                checked={!isSearchOnLoadId}
                                onChange={() => setIsSearchOnLoadId(false)}
                            />
                            <label htmlFor="searchAll" style={{ marginLeft: '5px' }}>
                                {' '}
                                Search All
                            </label>
                        </div>
                    </div>
                </div>
                <SearchBar type={SearchTypeEnum.LoadManagement}></SearchBar>
            </div>

            <LoadFilterItem
                abortRequest={abortRequest}
                title="Status"
                items={loadStatuses}
                itemsSubset={statusSubset.subset}
                searchPhrase={statusSubset.searchPhrase}
                activeKey={activeKey}
                isCollection={true}
                isSearchable={false}
                loadManagementMode={true}
                onFilterToggle={onFilterToggle}
            />
            <LoadFilterItem
                abortRequest={abortRequest}
                title="Proppant"
                items={proppants}
                itemsSubset={proppantSubset.subset}
                searchPhrase={proppantSubset.searchPhrase}
                activeKey={activeKey}
                isCollection={true}
                isSearchable={true}
                loadManagementMode={true}
                onFilterToggle={onFilterToggle}
            />

            {variables.input.driverCompanyId !== 0 && (
                <Query query={GET_LOAD_FILTER_LOADFACILITYLIST} variables={variables} fetchPolicy="cache-and-network">
                    {({ loading, error, data }: QueryResult<GetLoadFilterLoadFacilityList>): JSX.Element | null => {
                        const loadFacilityList: FilterItem[] = [];

                        if (
                            data &&
                            data.getLoadFilterLoadFacilityList &&
                            data.getLoadFilterLoadFacilityList.length !== 0
                        ) {
                            data.getLoadFilterLoadFacilityList.forEach(
                                (element: LoadFilterFacilityLoad | null): void => {
                                    if (element !== null) {
                                        loadFacilityList.push({ name: element.lofaName, id: element.lofaId });
                                    }
                                },
                            );
                        }

                        return (
                            <div>
                                <LoadFilterItem
                                    abortRequest={abortRequest}
                                    title="Loading Facility"
                                    items={loadFacilityList}
                                    itemsSubset={lofaSubset.subset}
                                    searchPhrase={lofaSubset.searchPhrase}
                                    activeKey={activeKey}
                                    isCollection={true}
                                    isSearchable={true}
                                    loadManagementMode={true}
                                    onFilterToggle={onFilterToggle}
                                />
                            </div>
                        );
                    }}
                </Query>
            )}
            {variables.input.driverCompanyId === 0 && (
                <LoadFilterItem
                    abortRequest={abortRequest}
                    title="Loading Facility"
                    items={[]}
                    itemsSubset={lofaSubset.subset}
                    searchPhrase={lofaSubset.searchPhrase}
                    activeKey={activeKey}
                    isCollection={true}
                    isSearchable={true}
                    loadManagementMode={true}
                    onFilterToggle={onFilterToggle}
                />
            )}

            <DatePickerFilterItem
                abortRequest={abortRequest}
                title="Delivered Date"
                activeKey={activeKey}
                fromDate={fromDate}
                toDate={toDate}
                filterType="Load"
                closeDateRange={onCloseDateRange}
            />

            {variables.input.driverCompanyId !== 0 && (
                <Query query={GET_LOAD_FILTER_DRIVERLIST} variables={variables} fetchPolicy="cache-and-network">
                    {({ loading, error, data }: QueryResult<GetLoadFilterDriverList>): JSX.Element | null => {
                        if (error) {
                        }

                        const driversList: FilterItem[] = [];

                        if (data && data.getLoadFilterDriverList && data.getLoadFilterDriverList.length !== 0) {
                            data.getLoadFilterDriverList.forEach((element: LoadFilterDriver | null): void => {
                                if (element !== null) {
                                    driversList.push({ name: element.driverName, id: element.driverId });
                                }
                            });
                        }

                        return (
                            <LoadFilterItem
                                abortRequest={abortRequest}
                                title="Driver"
                                items={driversList}
                                itemsSubset={driverSubset.subset}
                                searchPhrase={driverSubset.searchPhrase}
                                activeKey={activeKey}
                                isCollection={true}
                                isSearchable={true}
                                loadManagementMode={true}
                                onFilterToggle={onFilterToggle}
                            />
                        );
                    }}
                </Query>
            )}
            {variables.input.driverCompanyId === 0 && (
                <LoadFilterItem
                    abortRequest={abortRequest}
                    title="Driver"
                    items={[]}
                    itemsSubset={driverSubset.subset}
                    searchPhrase={driverSubset.searchPhrase}
                    activeKey={activeKey}
                    isCollection={true}
                    isSearchable={true}
                    loadManagementMode={true}
                    onFilterToggle={onFilterToggle}
                />
            )}

            {variables.input.driverCompanyId !== 0 && (
                <div className="Filter-Download-Container">
                    {csvLoading ? <Loading /> : ''}
                    <i className="material-icons Filter-Download-Icon">get_app</i>
                    <Button className="Filter-SelectAll" variant="link" onClick={exportCsv}>
                        Download CSV
                    </Button>
                </div>
            )}
        </div>
    );
};
