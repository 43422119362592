import React, { useEffect, useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './CarrierDashboardHeader.scss';
import CarrierDropDown from '../../CarrierDropdown/CarrierDropdown';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const CarrierDashboardHeader = (): JSX.Element => {
    return (
        <div className="Header-Container">
            <div className="Header-SearchBar">
                <CarrierDropDown></CarrierDropDown>
            </div>
            <div className="Header-ListHeader"></div>
        </div>
    );
};

export default CarrierDashboardHeader;
