import { Reducer } from 'react';
import { Actions } from '../../actions/auth';
import { Action, AuthState } from '../../types';
import { User } from 'oidc-client';

const initialAuthState: AuthState = {
    user: null,
    isUserLoading: true,
};

const setUserLoading = (payload: boolean, state: AuthState): AuthState => {
    return {
        ...state,
        isUserLoading: payload,
    };
};

const setUser = (payload: User): AuthState => {
    return {
        user: payload,
        isUserLoading: false,
    };
};

const removeUser = (): AuthState => {
    return {
        user: null,
        isUserLoading: false,
    };
};

const authReducer: Reducer<AuthState, Action> = (state, action): AuthState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.USER_LOADING:
            newState = setUserLoading(action.payload, state);
            break;
        case Actions.USER_LOADED:
            newState = setUser(action.payload);
            break;
        case Actions.USER_UNLOADED:
            newState = removeUser();
            break;
        case Actions.USER_SIGNED_OUT:
            newState = removeUser();
            break;
        case Actions.ACCESS_TOKEN_EXPIRED:
            newState = removeUser();
            break;
        case Actions.SILENT_RENEW_ERROR:
            // Silent Renew Errors occur when the tab is not focused.
            // We should keep the user around in this case, but we
            // could add logic to show an activity modal. - (12/2/19) BS
            // newState = removeUser();
            break;
    }

    return newState;
};

export { initialAuthState, authReducer };
