import React from 'react';
import './Summary.scss';
import { NumericFormat as NumberFormat } from 'react-number-format';

interface SummaryProps {
    proppantName: string | null;
    totalVolume: number | null;
    isLastItem: boolean;
}

const Summary = ({ proppantName, totalVolume, isLastItem }: SummaryProps): JSX.Element => {
    return (
        <div className={!isLastItem ? 'Summary-Row' : 'Summary-Last-Row'}>
            <span className="Summary-Container Row">
                <span className="Summary-Detail">{proppantName}</span>
                <span className="Summary-Detail">
                    <NumberFormat value={totalVolume || 0} displayType={'text'} thousandSeparator={true} />
                </span>
            </span>
        </div>
    );
};

export default Summary;
