import { Action } from '../../types';

export enum Actions {
    UPDATE_SEARCH_PHRASE = 'CARRIER_LIST_UPDATE_SEARCH_PHRASE',
    CARRIER_LIST_SELECT = 'CARRIER_LIST_SELECT',
}

export const setSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setCarrierIdSelection = (carrierId: number): Action => {
    return {
        type: Actions.CARRIER_LIST_SELECT,
        payload: carrierId,
        error: null,
    };
};
