import gql from 'graphql-tag';

export default gql`
    query GetDriverCompanyReferenceByDriver($driverId: Int) {
        getDriverCompanyReferenceByDriver(driverId: $driverId) {
            id
            driverId
            driverCompanyId
            updatedDate
            updatedBy
            companyName
        }
    }
`;
