import React, { FC, useEffect } from 'react';
import { useAuthContext } from '../AuthProvider';
import { Loading } from '../../_common';
import { Routes } from '../../../utils';
import './Callback.scss';
import { PermissionEnum } from '../../../types/PermissionEnum';

const Callback: FC = (): JSX.Element => {
    const { userManager } = useAuthContext();
    const authContext = useAuthContext();

    userManager.signinRedirectCallback().then(user => {
        // if (user) window.location.href = `${window.location.origin}${Routes.JOB_MANAGEMENT.route}`;
        // else window.location.href = window.location.origin;
        if (user) {
            const role = user.profile.role;
            if (
                role === PermissionEnum.Admin ||
                role === PermissionEnum.CustomerService ||
                role === PermissionEnum.Customer
            ) {
                window.location.href = `${window.location.origin}${Routes.DASHBOARD.route}`;
            } else if (role === PermissionEnum.Operation || role === PermissionEnum.Carrier) {
                window.location.href = `${window.location.origin}${Routes.CARRIERDASHBOARD.route}`;
            }
        } else {
            window.location.href = window.location.origin;
        }
    });

    return (
        <span className="Callback-Loading">
            <Loading />
        </span>
    );
};

export default Callback;
