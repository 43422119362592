import gql from 'graphql-tag';

export default gql`
    query GetLoadDetail($loadId: Int) {
        getLoadDetail(loadId: $loadId) {
            boxNumber
            carrierName
            driverName
            jobSummaryId
            jobName
            loadId
            loadingFacility
            loadNumber
            lofaId
            truckNumber
            chassisNumber
            transportationPo
            sandPO
            status
            customerName
            jdeNumber
            secondaryDriverId
            secondaryDriverName
            secondaryCarrierName
            secondaryTruckNumber
            secondaryChassisNumber
            regionId
            proppantName
            isSplit
            secondaryDriverLoadStatus
            tmsId
            driverLoadStatus
            driverId
            customerId
        }
    }
`;
