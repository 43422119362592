import gql from 'graphql-tag';

export default gql`
    query GetDriverCompanies {
        getDriverCompanies {
            id
            companyName
        }
    }
`;
