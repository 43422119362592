import { Action } from '../../types';
import {
    GetLofaSchedule_getLofaSchedule as LofaScheduleSummaryData,
    GetLofaSchedule_getLofaSchedule_jobSummaries as LoadFacilityDetailData,
} from '../../../types/schemaTypes';

export enum Actions {
    UPDATE_LOFA_DETAIL = 'LOFA_SCHEDULE_EDIT_UPDATE_LOFA_DETAIL',
    UPDATE_FACILITY_VALUE = 'LOFA_SCHEDULE_EDIT_UPDATE_FACILITY_VALUE',
    UPDATE_FACILITY = 'LOFA_SCHEDULE_EDIT_UPDATE_FACILITY',
    SET_INITIAL_DATA = 'LOFA_SCHEDULE_EDIT_SET_INITIAL_DATA',
    ADD_FACILITY = 'LOFA_SCHEDULE_ADD_FACILITY',
    DELETE_FACILITY = 'LOFA_SCHEDULE_EDIT_DELETE_FACILITY',
    RE_INITIALIZE = 'LOFA_SCHEDULE_RE_INITIALIZE',
    SET_FORM_ENABLED_DISABLED = 'SET_FORM_ENABLED_DISABLED',
}

export const updateLofaDetail = (
    lofaDetail: LofaScheduleSummaryData,
    inputTitle: string,
    value: string | number | null | undefined,
): Action => ({
    type: Actions.UPDATE_LOFA_DETAIL,
    payload: { lofaDetail, inputTitle, value },
    error: null,
});

export const updateFacilityValue = (
    lofaDetail: LofaScheduleSummaryData,
    lofaFacility: LoadFacilityDetailData,
    lofaFacilityValue: { id: number | null; value: string | number | undefined | null },
): Action => ({
    type: Actions.UPDATE_FACILITY_VALUE,
    payload: { lofaDetail, lofaFacility, lofaFacilityValue },
    error: null,
});

export const updateFacility = (
    lofaDetail: LofaScheduleSummaryData,
    lofaFacility: LoadFacilityDetailData,
    inputTitle: string,
    value: string | number | null | undefined,
): Action => ({
    type: Actions.UPDATE_FACILITY,
    payload: { lofaDetail, lofaFacility, inputTitle, value },
    error: null,
});

export const setInitialData = (data: LofaScheduleSummaryData[], newValues: any): Action => ({
    type: Actions.SET_INITIAL_DATA,
    payload: { data, newValues },
    error: null,
});

export const addFacility = (
    lofaDetail: LofaScheduleSummaryData,
    lofaSummaryDetail: LofaScheduleSummaryData,
): Action => ({
    type: Actions.ADD_FACILITY,
    payload: { lofaDetail, lofaSummaryDetail },
    error: null,
});

export const deleteFacility = (lofaDetail: LofaScheduleSummaryData, lofaFacility: LoadFacilityDetailData): Action => ({
    type: Actions.DELETE_FACILITY,
    payload: { lofaDetail, lofaFacility },
    error: null,
});

export const setLofaScheduleToInitialState = (): Action => ({
    type: Actions.RE_INITIALIZE,
    payload: [],
    error: null,
});

export const setFormEnabledDisabled = (isDisabled: boolean): Action => ({
    type: Actions.SET_FORM_ENABLED_DISABLED,
    payload: { isDisabled },
    error: null,
});
