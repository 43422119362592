import { Action } from '../../types';

export enum Actions {
    ASSIGN_DRIVER_TO_LOAD = 'ASSIGN_DRIVER_TO_LOAD',
    SET_CREATED_IDS_LIST = 'SET_CREATED_IDS_LIST',
    SET_TMS_IDS = 'SET_TMS_IDS',
    SET_CURRENT_LOAD_ID = 'SET_CURRENT_LOAD_ID',
    SET_NUMBER_OF_LOADS_TO_CREATE = 'SET_NUMBER_OF_LOADS_TO_CREATE',
    REMOVE_LOAD_IDS = 'REMOVE_LOAD_IDS',
    REMOVE_DRIVER_FROM_LOAD = 'REMOVE_DRIVER_FROM_LOAD',
    ADD_FAILED_LOAD_ID = 'ADD_FAILED_LOAD_ID',
    SET_ERROR_MESSAGE_FOR_A_LOAD = 'SET_ERROR_MESSAGE_FOR_A_LOAD',
    REMOVE_SUCCESSFULE_LOADS_FROM_LIST = 'REMOVE_SUCCESSFULE_LOADS_FROM_LIST',
    ADD_DRIVER_ID_TO_DRIVER_LIST = 'ADD_DRIVER_ID_TO_DRIVER_LIST',
    SET_DRIVER_IDS = 'SET_DRIVER_IDS',
    SET_FAILED_LOAD_IDS = 'SET_FAILED_LOAD_IDS',
}

export const setNumberOfLoadsToCreate = (numberToCreate: number): Action => {
    return {
        type: Actions.SET_NUMBER_OF_LOADS_TO_CREATE,
        payload: numberToCreate,
        error: null,
    };
};

export const setTmsIds = (tmsIds: number[]): Action => {
    return {
        type: Actions.SET_TMS_IDS,
        payload: tmsIds,
        error: null,
    };
};

export const setCreatedLoadIds = (loadIds: number[]): Action => {
    return {
        type: Actions.SET_CREATED_IDS_LIST,
        payload: loadIds,
        error: null,
    };
};

export const removeLoadIds = (loadIds: number[]): Action => {
    return {
        type: Actions.REMOVE_LOAD_IDS,
        payload: loadIds,
        error: null,
    };
};
export const setCurrentLoadId = (loadId: number): Action => {
    return {
        type: Actions.SET_CURRENT_LOAD_ID,
        payload: loadId,
        error: null,
    };
};

export const assignDriverToLoadId = (
    loadId: number,
    driverName: string,
    driverId: number,
    isPrimaryDriver: boolean,
    primaryDriverName: string,
): Action => {
    return {
        type: Actions.ASSIGN_DRIVER_TO_LOAD,
        payload: { loadId, driverName, driverId, isPrimaryDriver, primaryDriverName },
        error: null,
    };
};

export const removeDriverFromLoad = (loadId: number): Action => {
    return {
        type: Actions.REMOVE_DRIVER_FROM_LOAD,
        payload: { loadId },
        error: null,
    };
};

export const addFailedLoadId = (loadId: number): Action => {
    return {
        type: Actions.ADD_FAILED_LOAD_ID,
        payload: { loadId },
        error: null,
    };
};

export const setErrorMessageForLoad = (loadId: number, errorMessage: string): Action => {
    return {
        type: Actions.SET_ERROR_MESSAGE_FOR_A_LOAD,
        payload: { loadId, errorMessage },
        error: null,
    };
};

export const removeSuccessfulLoadsFromList = (): Action => {
    return {
        type: Actions.REMOVE_SUCCESSFULE_LOADS_FROM_LIST,
        payload: {},
        error: null,
    };
};

export const addDriverIdToDriverList = (driverId: number): Action => {
    return {
        type: Actions.ADD_DRIVER_ID_TO_DRIVER_LIST,
        payload: { driverId },
        error: null,
    };
};

export const setDriverIds = (driverIds: number[]): Action => {
    return {
        type: Actions.SET_DRIVER_IDS,
        payload: driverIds,
        error: null,
    };
};

export const setFailedLoadIds = (loadIds: number[]): Action => {
    return {
        type: Actions.SET_FAILED_LOAD_IDS,
        payload: loadIds,
        error: null,
    };
};
