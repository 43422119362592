import './Filter.scss';
import React, { useState } from 'react';
import FilterItem from './FilterItem';
import Button from 'react-bootstrap/Button';
import { Error, Loading } from '../_common';
import { Query } from '@apollo/client/react/components';
import {QueryResult} from '@apollo/client';
import { GET_DRIVER_FILTERS_LIST } from '../../graphql/client/queries';
import {
    GetDriverFilters_getDriverFilters_regions as RegionFilter,
    GetDriverFilters_getDriverFilters_districts as DistrictsFilter,
    GetDriverFilters_getDriverFilters_statuses as StatusFilter,
} from '../../types/schemaTypes';
import { GetDriverFilters } from '../../types/schemaTypes';
import SearchBar from '../SearchBar';
import { SearchTypeEnum } from '../../types/SearchTypeEnum';

interface CarrierDashboardFiltersProps {
    abortRequest: () => void;
    onFilterToggle: () => void;
}

export const CarrierDashboardFilters = ({
    abortRequest,
    onFilterToggle,
}: CarrierDashboardFiltersProps): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    let statusFilters: any[] = [];
    let regionFilters: RegionFilter[] = [];
    let districtFilters: DistrictsFilter[] = [];

    const onSelectAll = (): void => {
        setActiveKey(activeKey === '0' ? '1' : '0');
    };

    return (
        <Query query={GET_DRIVER_FILTERS_LIST}>
            {({ loading, error, data }: QueryResult<GetDriverFilters>): JSX.Element | null => {
                if (loading) {
                    return (
                        <div className="Filter-Loading">
                            <Loading />
                        </div>
                    );
                }
                if (error) {
                    return <Error error={error} />;
                }

                if (data && data.getDriverFilters) {
                    regionFilters = data.getDriverFilters.regions;
                    districtFilters = data.getDriverFilters.districts;
                    statusFilters = [];
                    statusFilters.push({ id: 1, name: 'Active', defaultChecked: true });
                    statusFilters.push({ id: 3, name: 'On Shift' });
                    statusFilters.push({ id: 5, name: 'On Load' });
                    statusFilters.push({ id: 2, name: 'Inactive' });
                    statusFilters.push({ id: 4, name: 'Off Shift' });
                    statusFilters.push({ id: 6, name: 'Waiting On Load' });
                }

                return (
                    <div className="Filter-Container">
                        <div className="Filter-Header">
                            <div className="Filter-HeaderIconContainer">
                                <i className="material-icons">filter_list</i>
                                <span className="Filter-HeaderTitle">Filters</span>
                            </div>
                            <Button className="Filter-SelectAll" variant="link" onClick={onSelectAll}>
                                {activeKey === '0' ? 'Collapse All' : 'Expand All'}
                            </Button>
                        </div>

                        <div
                            style={{
                                borderBottom: '1px solid lightgray',
                                marginLeft: '15px',
                                marginRight: '15px',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            }}
                        >
                            <SearchBar
                                type={SearchTypeEnum.Carrier}
                                placeHolderText="Search by Driver Name"
                            ></SearchBar>
                        </div>
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Status"
                            items={statusFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable={false}
                            onFilterToggle={onFilterToggle}
                        />
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Districts"
                            items={districtFilters}
                            activeKey={activeKey}
                            isCollection={false}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                        />
                        <FilterItem
                            abortRequest={abortRequest}
                            title="Regions"
                            items={regionFilters}
                            activeKey={activeKey}
                            isCollection={true}
                            isSearchable
                            onFilterToggle={onFilterToggle}
                        />
                    </div>
                );
            }}
        </Query>
    );
};
