import gql from 'graphql-tag';

export default gql`
    query GetStandbyDrivers($input: StandByDriverInput) {
        getStandbyDrivers(input: $input) {
            driverId
            driverName
            carrierName
            driverPoolNotes
            loadRequested
            loadRejected
            loadDelivered
            onShift
            assignedTime
        }
    }
`;
