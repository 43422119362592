import gql from 'graphql-tag';

export default gql`
    query GetAllDriversListByFilter($input: GetAllDriversListInput) {
        getAllDriversListByFilter(input: $input) {
            id
            name
            regionName
            districtName
            isnetId
            isCompliant
            driverActiveDate
            activeInactiveUser
            active
            companyName
            cellPhone
            status
            cdlNumber
            regionIds
            districtIds
        }
    }
`;
