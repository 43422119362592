import React, { useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './LoadProgress.scss';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import { Error, Loading } from '../../_common';
import GET_LOAD_PROGRESS from '../../../graphql/client/queries/getLoadProgress';
import {
    GetLoadProgress_getLoadProgress as LoadProgressData,
    GetLoadProgress,
    GetLoadDetail_getLoadDetail as LoadDetail,
} from '../../../types/schemaTypes';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { NumericFormat as NumberFormat } from 'react-number-format';
import { Option } from '../../Users/interface';
import { useAuthContext } from '../../Auth/AuthProvider';
import { DriverLoadStatusEnum } from '../../../types/DriverLoadStatusEnum';
import UploadLoadImage from './LoadImage/UploadLoadImage';

interface LoadProgressProps {
    loadId: number;
    loadDetail: LoadDetail;
}

const loadStatus: Option[] = [
    { id: 'Created', name: 'Created' },
    { id: 'Assigned', name: 'Assigned' },
    { id: 'Loading', name: 'Loading' },
    { id: 'Loaded', name: 'Loaded' },
    { id: 'On-site', name: 'On Site' },
    { id: 'Delivered', name: 'Delivered' },
];

const LoadProgress = ({ loadId, loadDetail }: LoadProgressProps): JSX.Element => {
    const [loadProgress, setLoadProgress] = useState<any>();

    const variables = {
        loadId: loadId,
    };

    const timeConverter = (minutes: string | null): string => {
        if (minutes != null) {
            const hours = parseInt(minutes) / 60;
            const rhours = Math.floor(hours);
            const min = (hours - rhours) * 60;
            const rmin = Math.round(min);
            return rhours + 'h' + ' ' + rmin + 'm';
        } else {
            return '';
        }
    };

    const isComplete = (complete: string | null): boolean => {
        if (complete != '' && complete != null) {
            return true;
        }
        return false;
    };
    const authContext = useAuthContext();

    return (
        <Query query={GET_LOAD_PROGRESS} variables={variables} fetchPolicy="cache-and-network">
            {({ loading, error, data }: QueryResult<GetLoadProgress>): JSX.Element | null => {
                if (loading) {
                    return <Loading />;
                }

                if (error) {
                    return <Error error={error} />;
                }

                let loadProgressData: LoadProgressData | null;

                if (data && data.getLoadProgress) {
                    loadProgressData = data.getLoadProgress;
                    setLoadProgress(loadProgressData);

                    const sandTicketComponent = loadProgressData ? (
                        <UploadLoadImage
                            ticketNumber={loadProgressData.sandTicketNumber}
                            ticketImage={loadProgressData.sandTicketImage}
                            ticketUploadedBy={loadProgressData.sandTicketUploadedBy}
                            imageText="SandTicket #: "
                            ticketUploadedAt={loadProgressData.sandTicketUploadedAt}
                            ticketName="stn"
                            loadId={loadId}
                            loadStatus={loadDetail.status}
                        />
                    ) : (
                        <UploadLoadImage />
                    );

                    const additionalTicketComponent = loadProgressData ? (
                        <UploadLoadImage
                            ticketNumber={loadProgressData.additionalTicketNumber}
                            ticketImage={loadProgressData.additionalTicketImage}
                            ticketUploadedBy={loadProgressData.additionalTicketUploadedBy}
                            imageText="Additional Sand Ticket/Other: "
                            ticketUploadedAt={loadProgressData.additionalTicketUploadedAt}
                            ticketName="bol2"
                            loadId={loadId}
                            loadStatus={loadDetail.status}
                        />
                    ) : (
                        <UploadLoadImage />
                    );

                    const bolTicketComponent = loadProgressData ? (
                        <UploadLoadImage
                            ticketNumber={loadProgressData.bolNumber}
                            ticketImage={loadProgressData.bolTicketImage}
                            ticketUploadedBy={loadProgressData.bolTicketUploadedBy}
                            imageText="Sandbox BOL #: "
                            ticketUploadedAt={loadProgressData.bolTicketUploadedAt}
                            ticketName="bol"
                            loadId={loadId}
                            loadStatus={loadDetail.status}
                        />
                    ) : (
                        <UploadLoadImage />
                    );

                    return (
                        <div className="LoadProgress-Container">
                            <Row>
                                <Col>
                                    <p className="LoadProgress-ElapsedTimeLabel"></p>
                                </Col>
                                <Col>
                                    <p className="LoadProgress-DeliveryTimeLabel"></p>
                                </Col>
                                <Col>
                                    <p className="LoadProgress-ElapsedTimeLabel">
                                        Wait Time: {timeConverter(loadProgressData.loadingElapsedTime)}{' '}
                                    </p>
                                </Col>
                                <Col>
                                    <p className="LoadProgress-MilesLabel">{loadProgressData.milesToWell} mile(s)</p>
                                </Col>
                                <Col>
                                    <p className="LoadProgress-DeliveryTimeLabel">
                                        Wait Time: {timeConverter(loadProgressData.deliveryElapsedTime)}{' '}
                                    </p>
                                </Col>
                                <Col>
                                    <p className="LoadProgress-DeliveryTimeLabel"></p>
                                </Col>
                            </Row>
                            <div className="step-wizard">
                                <div className="step-wizard-row setup-panel">
                                    <div className="step-wizard-step">
                                        <div className="icon-size">
                                            {loadDetail.driverId != 0 ? (
                                                loadDetail.driverLoadStatus === DriverLoadStatusEnum.ACCEPTED ? (
                                                    <i className="material-icons color LoadProgress-Icon">
                                                        check_circle_outline
                                                    </i>
                                                ) : (
                                                    <i className="material-icons color LoadProgress-OnIcon ">
                                                        brightness_1
                                                    </i>
                                                )
                                            ) : (
                                                <i className="material-icons color LoadProgress-NotCompletedIcon ">
                                                    brightness_1
                                                </i>
                                            )}
                                        </div>
                                        <p>Accepted</p>
                                        <p className="date"> {loadProgressData.acceptedDateTime}</p>
                                    </div>
                                    <div className="step-wizard-step">
                                        <div className="icon-size">
                                            {loadDetail.driverLoadStatus === DriverLoadStatusEnum.ACCEPTED ||
                                            loadProgressData.loadingDateTime !== '' ? (
                                                isComplete(loadProgressData.loadingDateTime) ? (
                                                    <i className="material-icons color LoadProgress-Icon">
                                                        check_circle_outline
                                                    </i>
                                                ) : (
                                                    <i className="material-icons color LoadProgress-OnIcon ">
                                                        brightness_1
                                                    </i>
                                                )
                                            ) : (
                                                <i className="material-icons color LoadProgress-NotCompletedIcon ">
                                                    brightness_1
                                                </i>
                                            )}
                                        </div>{' '}
                                        <p>Loading</p>
                                        <p className="date"> {loadProgressData.loadingDateTime}</p>
                                    </div>
                                    <div className="step-wizard-step">
                                        <div className="icon-size">
                                            {isComplete(loadProgressData.loadingDateTime) ? (
                                                isComplete(loadProgressData.loadedDateTime) ? (
                                                    <i className="material-icons color LoadProgress-Icon">
                                                        check_circle_outline
                                                    </i>
                                                ) : (
                                                    <i className="material-icons color LoadProgress-OnIcon ">
                                                        brightness_1
                                                    </i>
                                                )
                                            ) : (
                                                <i className="material-icons color LoadProgress-NotCompletedIcon ">
                                                    brightness_1
                                                </i>
                                            )}
                                        </div>{' '}
                                        <p>Loaded</p>
                                        <p className="date">{loadProgressData.loadedDateTime}</p>
                                        <p className="weight">
                                            Weight (lbs):{' '}
                                            {
                                                <NumberFormat
                                                    value={loadProgressData.weight || 0}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                            }
                                        </p>
                                        <p className="gross-weight">
                                            Gross Weight (lbs):{' '}
                                            {
                                                <NumberFormat
                                                    value={loadProgressData.grossWeight || 0}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                            }
                                        </p>
                                        <p className="tare-weight">
                                            Tare Weight (lbs):{' '}
                                            {
                                                <NumberFormat
                                                    value={loadProgressData.tareWeight || 0}
                                                    displayType={'text'}
                                                    thousandSeparator={true}
                                                />
                                            }
                                        </p>
                                    </div>
                                    <div className="step-wizard-step">
                                        <div className="icon-size">
                                            {isComplete(loadProgressData.loadedDateTime) ? (
                                                isComplete(loadProgressData.onSiteDateTime) ? (
                                                    <i className="material-icons color LoadProgress-Icon">
                                                        check_circle_outline
                                                    </i>
                                                ) : (
                                                    <i className="material-icons color LoadProgress-OnIcon ">
                                                        brightness_1
                                                    </i>
                                                )
                                            ) : (
                                                <i className="material-icons color LoadProgress-NotCompletedIcon ">
                                                    brightness_1
                                                </i>
                                            )}
                                        </div>{' '}
                                        <p>On-Site</p>
                                        <p className="date">{loadProgressData.onSiteDateTime}</p>
                                    </div>
                                    <div className="step-wizard-step">
                                        <div className="icon-size">
                                            {isComplete(loadProgressData.onSiteDateTime) ? (
                                                isComplete(loadProgressData.deliveredDateTime) ? (
                                                    <i className="material-icons color LoadProgress-Icon">
                                                        check_circle_outline
                                                    </i>
                                                ) : (
                                                    <i className="material-icons color LoadProgress-OnIcon ">
                                                        brightness_1
                                                    </i>
                                                )
                                            ) : (
                                                <i className="material-icons color LoadProgress-NotCompletedIcon ">
                                                    brightness_1
                                                </i>
                                            )}
                                        </div>{' '}
                                        <p>Delivered</p>
                                        <p className="date">{loadProgressData.deliveredDateTime}</p>
                                        {loadProgressData.emptyBox1 ? (
                                            <p className="EmptyBox-1">
                                                <span className="EmptyBox-Bold">Empty Box 1 #:</span>{' '}
                                                {loadProgressData.emptyBox1 || ''}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                        {loadProgressData.emptyBox2 ? (
                                            <p className="EmptyBox-2">
                                                <span className="EmptyBox-Bold">Empty Box 2 #:</span>{' '}
                                                {loadProgressData.emptyBox2 || ''}
                                            </p>
                                        ) : (
                                            ''
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className="LoadProgress-Images">
                                {sandTicketComponent}
                                {additionalTicketComponent}
                                {bolTicketComponent}
                            </div>
                        </div>
                    );
                } else {
                    return <div></div>;
                }
            }}
        </Query>
    );
};

export default LoadProgress;
