import { useState } from 'react';

export const useLazyLoading = (
    tableId: string,
    isLoading: boolean,
    isEndOfResults: boolean,
    onLoadMore: any,
    isScrollUpPagination = false,
): any => {
    const [scrollPos, setScrollPos] = useState(0);
    const handleScroll = (): void => {
        const doc: HTMLElement | null = document.getElementById(tableId);
        const scrollHeight = doc ? doc.scrollHeight : 0;
        if (isScrollUpPagination) {
            const scrollBottom = doc ? doc.clientHeight - doc.scrollTop : 0;
            const isScrolledToTop = Math.ceil(scrollBottom) >= scrollHeight;
            if (doc && isScrolledToTop && !isLoading && !isEndOfResults) {
                onLoadMore();
                setScrollPos(scrollBottom);
            }
        } else {
            const scrollTop = doc ? doc.scrollTop : 0;
            const clientHeight = doc ? doc.clientHeight : 0;
            const isScrolledToBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight;
            if (doc && isScrolledToBottom && !isLoading && !isEndOfResults) {
                onLoadMore();
                setScrollPos(scrollTop);
            }
        }
    };
    return {
        handleScroll,
        scrollPos,
        setScrollPos,
    };
};
