import { Action } from '../../types';

export enum Actions {
    SET_LOAD_SELECTED_TAB = 'LOAD_PROFILE_SET_SELECTED_TAB',
}

export const setLoadSelectedTab = (index: number): Action => {
    return {
        type: Actions.SET_LOAD_SELECTED_TAB,
        payload: index,
        error: null,
    };
};
