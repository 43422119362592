import React, { FC } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import { GetAllJobsWithFiltersAndSearch_getAllJobsWithFiltersAndSearch as Job } from '../../../types/schemaTypes';
import { NoResult, useLazyLoading, Loading } from '../../_common';
import JobItem from '../../ListItem/JobItem';
import './JobList.scss';

interface SelectedJob {
    jobId: string;
    jobName: string;
}

interface JobsListProps {
    abortRequest: () => void;
    jobsList: Job[];
    isLoading: boolean;
    isEndOfResults: boolean;
    entries: (Job | null)[];
    onLoadMore: () => void;
    selectedJobs: SelectedJob[];
    setSelectedJobs: (jobItems: SelectedJob[]) => void;
    detailsViewFrom?: string;
    refetch?: () => void;
}

const JobList: FC<JobsListProps> = ({
    abortRequest,
    jobsList,
    isLoading,
    isEndOfResults,
    onLoadMore,
    selectedJobs,
    setSelectedJobs,
    detailsViewFrom,
    refetch,
}: JobsListProps): JSX.Element => {
    const { handleScroll } = useLazyLoading('jobList', isLoading, isEndOfResults, onLoadMore);
    const onJobItemCheck = (checkedStatus: boolean, job: Job): void => {
        let result: SelectedJob[] = [];
        if (checkedStatus === true) {
            result = selectedJobs.concat({ jobId: job.jobId, jobName: job.jobName });
        } else if (checkedStatus === false) {
            result = selectedJobs.filter((item): boolean => item.jobId !== job.jobId);
        }

        setSelectedJobs(result);
    };

    const isItemChecked = (job: Job): boolean => {
        return selectedJobs.filter((item): boolean => item.jobId === job.jobId)[0] !== undefined;
    };

    if (isLoading) {
        return (
            <div className="JobList-Loading">
                <Loading />
            </div>
        );
    }

    return (
        <div className="JobList" id="jobList" onScroll={handleScroll}>
            {jobsList.length === 0 ? (
                <NoResult isModal={false} />
            ) : (
                jobsList.map(
                    (job): JSX.Element => {
                        return (
                            <JobItem
                                abortRequest={abortRequest}
                                job={job}
                                key={job.jobId}
                                isChecked={isItemChecked(job)}
                                onChange={(checkedStatus: boolean): void => onJobItemCheck(checkedStatus, job)}
                                detailsViewFrom={detailsViewFrom}
                            />
                        );
                    },
                )
            )}
        </div>
    );
};

export default JobList;
