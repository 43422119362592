import { Reducer } from 'react';
import { Actions } from '../../actions/loadProfile';
import { Action, LoadProfileState } from '../../types';
import loadNotes from '../../../assets/loadNotes.png';
import loadIcon from '../../../assets/loadIcon.png';

const initialLoadProfileState: LoadProfileState = {
    tabs: [
        {
            key: 0,
            displayName: 'Load Progress',
            icon: loadIcon,
            isSelected: true,
        },
        // {
        //     key: 1,
        //     displayName: 'Load Notes',
        //     icon: loadNotes,
        //     isSelected: false,
        // },
    ],
};

const setLoadSelectedTab = (payload: number, state: LoadProfileState): LoadProfileState => {
    return {
        ...state,
        tabs: state.tabs.map((tab): any => {
            const isSelected = tab.key === payload;
            return { ...tab, isSelected: isSelected };
        }),
    };
};

const loadProfileReducer: Reducer<LoadProfileState, Action> = (state, action): LoadProfileState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.SET_LOAD_SELECTED_TAB:
            newState = setLoadSelectedTab(action.payload, state);
            break;
    }

    return newState;
};

export { initialLoadProfileState, loadProfileReducer };
