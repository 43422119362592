//TODO: Remove the unused interfaces after hooking up with backend data

enum ModalForm {
    ADD_USER = 'addUser',
    EDIT_USER = 'editUser',
    EMPTY = '',
}

export interface Option {
    id: number | string;
    name: string | number;
    disabled?: boolean;
}

export interface CPOption {
    id: number | string;
    name: string | number;
    jdeId: string;
}

export interface CarrierOption {
    id: number;
    name: string;
}

export { ModalForm };
