import React, { useState } from 'react';
import { GetLoadSummary_getLoadSummary_loadSummaries as LoadSummaryDetails } from '../../../../types/schemaTypes';
import { NumericFormat as NumberFormat } from 'react-number-format';
import CustomLoadInformationModal from '../../../LoadProfile/LoadProgress/CustomLoadInformationModal';

interface LOFAScheduleItemProps {
    index: number;
    proppantName: string | null;
    item: LoadSummaryDetails;
    jobId: number;
    jobName: string | null;
    customerId: number;
    region?: {
        regionName: string | null;
        id: number | null;
        extSystemName: string | null;
        extSystemReference: string | null;
        regionCode: string | null;
    } | null;
    selectedTab: string;
    isJobCompleted: boolean;
}

const LoadSummaryItem = ({
    item,
    index,
    proppantName,
    jobId,
    jobName,
    customerId,
    region,
    selectedTab,
    isJobCompleted,
}: LOFAScheduleItemProps): JSX.Element => {
    const [showModal, setShowModal] = useState(false);

    const handleOnClickHide = (): void => {
        setShowModal(false);
        window.location = window.location;
    };
    return (
        <div className="LoadSummary-Detail-Row" key={index}>
            <div className="LoadSummary-Detail-Item justify-content-start">{index === 0 ? proppantName : ''}</div>
            <div className="LoadSummary-Detail-Item justify-content-start">
                <input type="checkbox" className="FilterCheckBox-Checkbox" checked={item.isActive ?? false} disabled />
            </div>
            <div className="LoadSummary-Detail-Item LoadSummary-Name justify-content-start">{item.lofaName}</div>
            <div className="LoadSummary-Detail-Item justify-content-start">{item.sandPo}</div>
            <div className="LoadSummary-Detail-Item justify-content-end">{item.transportationPo}</div>
            <div className="LoadSummary-Detail-Item justify-content-end">
                <NumberFormat
                    value={selectedTab === 'Loads' ? item.loadsCreated || 0 : item.loadsCreatedVolume || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                />
            </div>
            <div className="LoadSummary-Detail-Item justify-content-end">
                <NumberFormat
                    value={selectedTab === 'Loads' ? item.loadsInProgress || 0 : item.loadsInProgressVolume || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                />
            </div>
            <div className="LoadSummary-Detail-Item justify-content-end">
                <NumberFormat
                    value={selectedTab === 'Loads' ? item.loadsCompleted || 0 : item.loadsCompletedVolume || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                />
            </div>
            <div className="LoadSummary-Detail-Item justify-content-end">
                <NumberFormat
                    value={selectedTab === 'Loads' ? item.loadsRemaining || 0 : item.loadsRemainingVolume || 0}
                    displayType={'text'}
                    thousandSeparator={true}
                />
            </div>
            <div className="LoadSummary-Detail-Item justify-content-end"></div>
            <CustomLoadInformationModal
                show={showModal}
                onCancel={handleOnClickHide}
                text="The loads are in the process of being created and will be assigned shortly."
            />
        </div>
    );
};

export default LoadSummaryItem;
