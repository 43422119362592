import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './PumpScheduleDisplayMode.scss';
import WellheadItem from '../WellheadItem/WellheadItem';
import {
    GetFullJobProfile_getJobProfile_pumpScheduleSummary as PumpScheduleSummaryData,
    GetFullJobProfile_getJobProfile_pumpScheduleSummary_proppants as ProppantData,
    GetAllProppants,
} from '../../../../types/schemaTypes';
import { useQuery } from '@apollo/client';
import { GET_ALL_PROPPANTS } from '../../../../graphql/client/queries';
import * as uuid from 'uuid';
import * as PumpScheduleEditActions from '../../../../store/actions/pumpScheduleEdit';
import { useStoreContext } from '../../../App/StoreProvider';
import { useAuthContext } from '../../../Auth/AuthProvider';
import { PermissionEnum } from '../../../../types/PermissionEnum';

interface PumpScheduleDisplayModeProps {
    pumpScheduleSummaryList: PumpScheduleSummaryData[];
    isJobCompleted: boolean;
}

const PumpScheduleDisplayMode = ({
    pumpScheduleSummaryList,
    isJobCompleted,
}: PumpScheduleDisplayModeProps): JSX.Element => {
    const [, dispatch] = useStoreContext();
    const { data } = useQuery<GetAllProppants>(GET_ALL_PROPPANTS);

    return (
        <div>
            <WellheadItem pumpScheduleSummaryList={pumpScheduleSummaryList} />
        </div>
    );
};

export default PumpScheduleDisplayMode;
