import React from 'react';
import './LOFAScheduleDetailItem.scss';
import {
    GetLofaSchedule_getLofaSchedule as LOFAScheduleSummaryData,
    GetLofaSchedule_getLofaSchedule_jobSummaries as FacilityData,
} from '../../../../types/schemaTypes';
import Summary from '../Summary';
import FacilityItem from '../FacilityItem/FacilityItem';

interface LOFAScheduleDetailItemProps {
    lofaScheduleSummaryList: LOFAScheduleSummaryData[];
}

const LOFAScheduleDetailItem = ({ lofaScheduleSummaryList }: LOFAScheduleDetailItemProps): JSX.Element => {
    return (
        <div className="LOFASchedule-ListContainer">
            <div className="LOFASchedule-Header Row">
                <span className="LOFASchedule-Header-Columns">
                    <div className="LOFASchedule-Header-Item">Proppant Type</div>
                    <div className="LOFASchedule-Header-Item">Volume</div>
                </span>

                <div className="LOFASchedule-Items-Container Row">
                    <div className="LOFASchedule-Header-Item justify-content-start">Loading Facility</div>
                    <div className="LOFASchedule-Header-Item justify-content-end">LOFA Volume</div>
                    <div className="LOFASchedule-Header-Item justify-content-end">Miles from Well</div>
                    <div className="LOFASchedule-Header-Item justify-content-end">Max Vol/Box</div>
                    <div className="LOFASchedule-Header-Item justify-content-end">Sand PO</div>
                    <div className="LOFASchedule-Header-Item justify-content-end">Transportation PO</div>
                </div>
            </div>
            <div className="Column">
                {lofaScheduleSummaryList.map((lofaDetail: LOFAScheduleSummaryData | null, index: number):
                    | JSX.Element
                    | undefined => {
                    if (lofaDetail !== null) {
                        // TODO: Make total volume come down from the API at the proppant name level rather than as a job summary
                        // so the duplicate value can be removed from the job summaries.
                        const totalVolume = lofaDetail!.jobSummaries!.find(e => e !== undefined)!.totalVolume || 0;

                        return (
                            <div
                                key={index}
                                className={
                                    index % 2 === 0
                                        ? 'LOFASchedule-Detail-Even-Row '
                                        : 'LOFASchedule-Detail-Odd-Row justify-content-start'
                                }
                            >
                                <Summary
                                    proppantName={lofaDetail.proppantName}
                                    totalVolume={totalVolume}
                                    isLastItem={lofaDetail ? index === lofaScheduleSummaryList.length - 1 : false}
                                />
                                <div className="LOFASchedule-Row-Container">
                                    {lofaDetail &&
                                        lofaDetail.jobSummaries &&
                                        lofaDetail.jobSummaries.length !== 0 &&
                                        lofaDetail.jobSummaries.map((facilityItem: FacilityData | null, index: number):
                                            | JSX.Element
                                            | undefined => {
                                            if (facilityItem !== null) {
                                                return (
                                                    <FacilityItem
                                                        facility={facilityItem}
                                                        key={index}
                                                        isLastItem={
                                                            lofaDetail && lofaDetail.jobSummaries
                                                                ? index === lofaDetail.jobSummaries.length - 1
                                                                : false
                                                        }
                                                    />
                                                );
                                            }

                                            return undefined;
                                        })}
                                </div>
                            </div>
                        );
                    }

                    return undefined;
                })}
            </div>
        </div>
    );
};

export default LOFAScheduleDetailItem;
