import React, { FC } from 'react';
import * as AuthActions from '../../../store/actions/auth';
import { useAuthContext } from '../AuthProvider';
import { useStoreContext } from '../../App/StoreProvider';

const SilentRenew: FC = () => {
    const { userManager } = useAuthContext();
    const [, dispatch] = useStoreContext();
    userManager
        .signinSilentCallback()
        .then(user => {
            if (user) dispatch(AuthActions.userLoaded(user));
        })
        .catch((err): void => {
            console.log(err);
        });
    return <React.Fragment />;
};

export default SilentRenew;
