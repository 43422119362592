import { Reducer } from 'react';
import { Actions } from '../../actions/loadManagementList';
import { Action, LoadManagementListState } from '../../types';
import FilterItem from '../../../components/Filter/FilterItem';
import { LoadManagementFilterTypeEnum } from '../../../types/LoadManagementFilterType';

const initialLoadManagementListState: LoadManagementListState = {
    filters: {
        jobIds: [],
        regionIds: [],
        districtIds: [],
        customerIds: [],
        jobStatuses: [],
        statuses: [],
        proppantIds: [],
        lofaIds: [],
        driverIds: [],
        carrierIds: [],
        fromDate: '',
        toDate: '',
    },
    searchPhraseSubset: {
        jobSubset: { subset: [], searchPhrase: '' },
        statusSubset: { subset: [], searchPhrase: '' },
        driverSubset: { subset: [], searchPhrase: '' },
        proppantSubset: { subset: [], searchPhrase: '' },
        lofaSubset: { subset: [], searchPhrase: '' },
        carrierSubset: { subset: [], searchPhrase: '' },
        regionSubset: { subset: [], searchPhrase: '' },
        districtSubset: { subset: [], searchPhrase: '' },
        customerSubset: { subset: [], searchPhrase: '' },
        jobStatusSubset: { subset: [], searchPhrase: '' },
    },
    searchPhrase: '',
};

interface FilterObject {
    filterType: string;
    id: number;
    isSelected: boolean;
}

const setDateRangeFilters = (
    payload: { fromDate: string; toDate: string },
    state: LoadManagementListState,
): LoadManagementListState => {
    return {
        ...state,
        filters: {
            ...state.filters,
            fromDate: payload.fromDate,
            toDate: payload.toDate,
        },
    };
};

const addOrRemoveFilters = (payload: FilterObject, filters: number[]): number[] => {
    let massagedFilter: number[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.id);
    } else {
        massagedFilter = filters.filter((id): any => {
            if (id !== payload.id) return id;

            return undefined;
        });
    }

    return massagedFilter;
};

const addOrRemoveSelectAllFilters = (itemIds: number[], filters: number[], selected: boolean): number[] => {
    const massagedFilter: number[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(itemIds);
    }
};

const updateFilterListForSearchPhrase = (items: { id: number; name: string }[], searchString: string): FilterItem[] => {
    return items.filter(item => item.name.toLowerCase().includes(searchString.toLowerCase()));
};

const setSelectedFilters = (payload: FilterObject, state: LoadManagementListState): LoadManagementListState => {
    const { filters } = state;

    if (payload.filterType === LoadManagementFilterTypeEnum.PROPPANT) {
        filters.proppantIds = addOrRemoveFilters(payload, filters.proppantIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.LOADING_FACILITY) {
        filters.lofaIds = addOrRemoveFilters(payload, filters.lofaIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.DRIVER) {
        filters.driverIds = addOrRemoveFilters(payload, filters.driverIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.CARRIER) {
        filters.carrierIds = addOrRemoveFilters(payload, filters.carrierIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.LOAD_STATUS) {
        filters.statuses = addOrRemoveFilters(payload, filters.statuses);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.STATUS) {
        filters.statuses = addOrRemoveFilters(payload, filters.statuses);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.REGION) {
        filters.regionIds = addOrRemoveFilters(payload, filters.regionIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.DISTRICT) {
        filters.districtIds = addOrRemoveFilters(payload, filters.districtIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.CUSTOMER) {
        filters.customerIds = addOrRemoveFilters(payload, filters.customerIds);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.JOB_STATUS) {
        filters.jobStatuses = addOrRemoveFilters(payload, filters.jobStatuses);
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.JOB) {
        filters.jobIds = addOrRemoveFilters(payload, filters.jobIds);
    }

    return {
        ...state,
        filters: { ...filters },
    };
};

// TODO: Generisize these two methods to take in the action type and reduce use of chained if statements
const setSelectAllFilters = (
    payload: { itemIds: number[]; filterType: string },
    state: LoadManagementListState,
): LoadManagementListState => {
    const { filters } = state;
    const filtersCopy = {...filters};

    if (payload.filterType === LoadManagementFilterTypeEnum.PROPPANT) {
        filtersCopy.proppantIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.proppantIds,
            !(filtersCopy.proppantIds.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.LOADING_FACILITY) {
        filtersCopy.lofaIds = addOrRemoveSelectAllFilters(payload.itemIds, filtersCopy.lofaIds, !(filtersCopy.lofaIds.length > 0));
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.DRIVER) {
        filtersCopy.driverIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.driverIds,
            !(filtersCopy.driverIds.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.CARRIER) {
        filtersCopy.carrierIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.carrierIds,
            !(filtersCopy.carrierIds.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.LOAD_STATUS) {
        filtersCopy.statuses = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.statuses,
            !(filtersCopy.statuses.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.REGION) {
        filtersCopy.regionIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.regionIds,
            !(filtersCopy.regionIds.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.DISTRICT) {
        filtersCopy.districtIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.districtIds,
            !(filtersCopy.districtIds.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.CUSTOMER) {
        filtersCopy.customerIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.customerIds,
            !(filtersCopy.customerIds.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.JOB_STATUS) {
        filtersCopy.jobStatuses = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.jobStatuses,
            !(filtersCopy.jobStatuses.length > 0),
        );
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.JOB) {
        filtersCopy.jobIds = addOrRemoveSelectAllFilters(payload.itemIds, filtersCopy.jobIds, !(filtersCopy.jobIds.length > 0));
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.STATUS) {
        filtersCopy.statuses = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filtersCopy.statuses,
            !(filtersCopy.statuses.length > 0),
        );
    }
    return {
        ...state,
        filters: { ...filtersCopy },
    };
};

const setFilterSearchPhrase = (
    payload: { items: FilterItem[]; filterType: string; searchPhrase: string },
    state: LoadManagementListState,
): LoadManagementListState => {
    const { searchPhraseSubset } = state;
    if (payload.filterType === LoadManagementFilterTypeEnum.PROPPANT) {
        searchPhraseSubset.proppantSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.LOADING_FACILITY) {
        searchPhraseSubset.lofaSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.DRIVER) {
        searchPhraseSubset.driverSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.CARRIER) {
        searchPhraseSubset.carrierSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.LOAD_STATUS) {
        searchPhraseSubset.statusSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.REGION) {
        searchPhraseSubset.regionSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.DISTRICT) {
        searchPhraseSubset.districtSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.CUSTOMER) {
        searchPhraseSubset.customerSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.JOB_STATUS) {
        searchPhraseSubset.jobStatusSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }
    if (payload.filterType === LoadManagementFilterTypeEnum.JOB) {
        searchPhraseSubset.jobSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }

    return {
        ...state,
        searchPhraseSubset: { ...searchPhraseSubset },
    };
};

const setSearchPhrase = (search: string, state: LoadManagementListState): LoadManagementListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const resetFilterState = (state: LoadManagementListState) => {
    return {
        ...state,
        filters: {
            jobIds: [],
            regionIds: [],
            districtIds: [],
            customerIds: [],
            jobStatuses: [],
            statuses: [],
            proppantIds: [],
            lofaIds: [],
            driverIds: [],
            carrierIds: [],
            fromDate: '',
            toDate: '',
        },
        searchPhraseSubset: {
            jobSubset: { subset: [], searchPhrase: '' },
            statusSubset: { subset: [], searchPhrase: '' },
            driverSubset: { subset: [], searchPhrase: '' },
            proppantSubset: { subset: [], searchPhrase: '' },
            lofaSubset: { subset: [], searchPhrase: '' },
            carrierSubset: { subset: [], searchPhrase: '' },
            regionSubset: { subset: [], searchPhrase: '' },
            districtSubset: { subset: [], searchPhrase: '' },
            customerSubset: { subset: [], searchPhrase: '' },
            jobStatusSubset: { subset: [], searchPhrase: '' },
        },
        searchPhrase: '',
    };
};

const loadManagementListReducer: Reducer<LoadManagementListState, Action> = (
    state,
    action,
): LoadManagementListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_FILTERS:
            newState = setSelectedFilters(action.payload, state);
            break;
        case Actions.UPDATE_FILTER_SEARCH_PHRASE:
            newState = setFilterSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_FILTERS_SELECT_ALL:
            newState = setSelectAllFilters(action.payload, state);
            break;
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_DATE_RANGE_FILTERS:
            newState = setDateRangeFilters(action.payload, state);
            break;
        case Actions.REFRESH_LOADMANAGEMENT_FILTERS:
            newState = resetFilterState(state);
    }

    return newState;
};

export { initialLoadManagementListState, loadManagementListReducer };
