import React, { FC, createElement } from 'react';
import { useAuthContext } from '../AuthProvider';
import { Route,  useParams} from 'react-router-dom';
import {Navigate , Outlet} from 'react-router'
import { Loading } from '../../_common';
import { useStoreContext } from '../../App/StoreProvider';


export const ProtectedRoute = (rest:any) => {

    const params = useParams();
    var { userManager } = useAuthContext();
    const [{ auth }] = useStoreContext();

    if(!auth.isUserLoading)
    {
        if (auth.user && !auth.user.expired) {
          
            return  <Outlet {...params}/> ;  
        } else {
            userManager.removeUser();
            userManager.clearStaleState();

            return (
              <Navigate to="/" />
            );
        }
      } else {
        <Loading />;
      }
    

  //  return !auth.isUserLoading ? <Route {...rest} /> : <Loading />;
};

export default ProtectedRoute;
