import { Action } from '../../types';
import FilterItem from '../../../components/Filter/FilterItem';

export enum Actions {
    UPDATE_FILTERS = 'LOAD_LIST_UPDATE_FILTERS',
    UPDATE_FILTERS_SELECT_ALL = 'LOAD_LIST_UPDATE_FILTERS_TO_SELECT_ALL',
    UPDATE_FILTER_SEARCH_PHRASE = 'LOAD_LIST_FILTERS_UPDATE_SEARCH_PHRASE',
    UPDATE_SEARCH_PHRASE = 'LOAD_LIST_UPDATE_SEARCH_PHRASE',
    UPDATE_DATE_RANGE_FILTERS = 'LOAD_LIST_UPDATE_DATE_RANGE_FILTERS',
}

export const setFiltersForLoads = (filterType: string, id: number, isSelected: boolean): Action => {
    return {
        type: Actions.UPDATE_FILTERS,
        payload: {
            filterType,
            id,
            isSelected,
        },
        error: null,
    };
};

export const setSelectAllFilters = (itemIds: number[], filterType: string): Action => {
    return {
        type: Actions.UPDATE_FILTERS_SELECT_ALL,
        payload: {
            filterType,
            itemIds,
        },
        error: null,
    };
};

export const setFilterSearchPhrase = (items: FilterItem[], filterType: string, searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_FILTER_SEARCH_PHRASE,
        payload: {
            filterType,
            items,
            searchPhrase,
        },
        error: null,
    };
};

export const setSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setDateRangeFilters = (fromDate: string, toDate: string): Action => {
    return {
        type: Actions.UPDATE_DATE_RANGE_FILTERS,
        payload: {
            fromDate,
            toDate,
        },
        error: null,
    };
};
