import gql from 'graphql-tag';

export default gql`
    query GetFilteredLoadManagements($input: GetFilteredLoadListInput, $offset: Int) {
        getFilteredLoadManagements(input: $input, offset: $offset) {
            atLofaIn
            atLofaOut
            atDestinationIn
            atDestinationOut
            truckNumber
            chassisNumber
            sandTicket
            transportationPo
            jobSummaryId
            loadId
            boxNumber
            carrierName
            driverCompanyId
            driverId
            driverName
            driverLoadStatus
            loadingFacility
            loadNumber
            proppantName
            status
            weight
            regionName
            isSplit
            jobId
            districtName
            customerName
            regionId
            sandPO
            secondaryDriverId
            secondaryDriverLoadStatus
            jobName
            rejectReason
            assignedDateTime
            rejectedDateTime
            userName
            rejectedDriverName
        }
    }
`;
