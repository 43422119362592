import gql from 'graphql-tag';

export default gql`
    query GetDriverByCellPhone($input: CellPhoneInput) {
        getDriverByCellPhone(input: $input) {
            driverId
            driverName
        }
    }
`;
