import { Reducer } from 'react';
import { Actions } from '../../actions/currentActivity';
import { Action, CurrentActivityState } from '../../types';
import moment from 'moment';
import FilterItem from '../../../components/Filter/FilterItem';

const initialCurrentActivityState: CurrentActivityState = {
    filters: {
        carrierIds: [],
    },
    searchPhrase: '',
    searchPhraseSubset: {
        carrierSubset: { subset: [], searchPhrase: '' },
    },
};

interface FilterObject {
    filterType: string;
    id: number;
    isSelected: boolean;
}

const addOrRemoveFilters = (payload: FilterObject, filters: number[]): number[] => {
    let massagedFilter: number[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.id);
    } else {
        massagedFilter = filters.filter((id): any => {
            if (id !== payload.id) return id;

            return undefined;
        });
    }

    return massagedFilter;
};

const addOrRemoveSelectAllFilters = (itemIds: number[], filters: number[], selected: boolean): number[] => {
    const massagedFilter: number[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(itemIds);
    }
};

const setSelectedFilters = (payload: FilterObject, state: CurrentActivityState): CurrentActivityState => {
    const { filters } = state;

    if (payload.filterType === 'CARRIER') {
        filters.carrierIds = addOrRemoveFilters(payload, filters.carrierIds);
    }

    return {
        ...state,
        filters: { ...filters },
    };
};

// TODO: Generisize these two methods to take in the action type and reduce use of chained if statements
const setSelectAllFilters = (
    payload: { itemIds: number[]; filterType: string },
    state: CurrentActivityState,
): CurrentActivityState => {
    const { filters } = state;

    if (payload.filterType === 'CARRIER') {
        filters.carrierIds = addOrRemoveSelectAllFilters(
            payload.itemIds,
            filters.carrierIds,
            !(filters.carrierIds.length > 0),
        );
    }
    return {
        ...state,
        filters: { ...filters },
    };
};

const setSearchPhrase = (search: string, state: CurrentActivityState): CurrentActivityState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const updateFilterListForSearchPhrase = (items: { id: number; name: string }[], searchString: string): FilterItem[] => {
    return items.filter(item => item.name.toLowerCase().includes(searchString.toLowerCase()));
};

const setFilterSearchPhrase = (
    payload: { items: FilterItem[]; filterType: string; searchPhrase: string },
    state: CurrentActivityState,
): CurrentActivityState => {
    const { searchPhraseSubset } = state;

    if (payload.filterType === 'CARRIER') {
        searchPhraseSubset.carrierSubset = {
            subset: updateFilterListForSearchPhrase(payload.items, payload.searchPhrase),
            searchPhrase: payload.searchPhrase,
        };
    }

    return {
        ...state,
        searchPhraseSubset: { ...searchPhraseSubset },
    };
};

const resetFilterState = (state: CurrentActivityState) => {
    return {
        ...state,
        filters: {
            carrierIds: [],
        },
        searchPhrase: '',
        searchPhraseSubset: {
            carrierSubset: { subset: [], searchPhrase: '' },
        },
    };
};

const currentActivityReducer: Reducer<CurrentActivityState, Action> = (state, action): CurrentActivityState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_FILTERS:
            newState = setSelectedFilters(action.payload, state);
            break;
        case Actions.UPDATE_FILTERS_SELECT_ALL:
            newState = setSelectAllFilters(action.payload, state);
            break;
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_FILTER_SEARCH_PHRASE:
            newState = setFilterSearchPhrase(action.payload, state);
            break;
    }

    return newState;
};

export { initialCurrentActivityState, currentActivityReducer };
