import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SearchBar from '../../SearchBar/SearchBar';
import './JobLoadsManagementHeader.scss';
import { Routes } from '../../../utils';
import { SearchTypeEnum } from '../../../types/SearchTypeEnum';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { useAuthContext } from '../../Auth/AuthProvider';

interface HeaderProps {
    jobId: string;
    jobName: string;
    isSearchOnLoadId: boolean;
    setIsSearchOnLoadId: React.Dispatch<React.SetStateAction<boolean>>;
}

const JobLoadsManagementHeader = ({
    jobId,
    jobName,
    isSearchOnLoadId,
    setIsSearchOnLoadId,
}: HeaderProps): JSX.Element => {
    const authContext = useAuthContext();
    const opsCarrierUser = authContext.userHasPermission(PermissionEnum.Carrier);
    const [isCustomerView, setIsCustomerView] = useState(true);

    useEffect(() => {
        if (opsCarrierUser) {
            setIsCustomerView(false);
        } else {
            const isRedirectToCustomer =
                localStorage.getItem('redirectbackto') && localStorage.getItem('redirectbackto') === 'carrier'
                    ? false
                    : true;
            setIsCustomerView(isRedirectToCustomer);
        }
    }, [opsCarrierUser]);

    return (
        <div className="Header-Container">
            <div className="Header-SearchBar">
                <SearchBar type={SearchTypeEnum.Load}></SearchBar>
            </div>
            <div className="Header-ListHeader">
                <div className="JobLoadManagementHeader-SearchBar">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="JobLoadManagementHeader-Search-Filter">
                                <input
                                    type="radio"
                                    id="loadId"
                                    name="isLoadId"
                                    checked={isSearchOnLoadId}
                                    onChange={() => setIsSearchOnLoadId(true)}
                                />
                                <label htmlFor="loadId" style={{ marginLeft: '5px' }}>
                                    Load Id
                                </label>
                            </div>
                        </div>
                        <div className="col-md-12">
                            <div className="JobLoadManagementHeader-Search-Filter">
                                <input
                                    type="radio"
                                    id="searchAll"
                                    name="isLoadId"
                                    checked={!isSearchOnLoadId}
                                    onChange={() => setIsSearchOnLoadId(false)}
                                />
                                <label htmlFor="searchAll" style={{ marginLeft: '5px' }}>
                                    Search All
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="JobLoadManagementHeader-Search-Container">
                    <div className="Header-JobNameTitle">{jobName}</div>
                </div>
                <div className="Header-RightOptions">
                    <div className="Row JobProfile-ToggleButtonsContainer">
                        <Link
                            className="JobProfile-LeftToggleButton JobProfile-ButtonText"
                            to={{ pathname: `${Routes.JOB_PROFILE.route}${jobId}` }}
                        >
                            Job Details
                        </Link>
                        <Link
                            to={{
                                pathname: `${Routes.JOB_ACTIVITY.route}${jobId}`,
                            }}
                            className={`JobProfile-CenterToggleButton JobProfile-ButtonText`}
                        >
                            Current Activity
                        </Link>
                        {!opsCarrierUser && isCustomerView && (
                            <Link
                                to={{
                                    pathname: `${Routes.DAILY_SAND_ALLOCATIONS.route}${jobId}`,
                                }}
                                className={`JobProfile-CenterToggleButton JobProfile-ButtonText`}
                            >
                                Sand Allocations
                            </Link>
                        )}
                        <div className="JobProfile-RightToggleButton JobProfile-ActiveButton">
                            <p className="JobProfile-ButtonText">Loads</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobLoadsManagementHeader;
