export enum LoadStatusEnum {
    ASSIGNED = 'Assigned',
    COMPLETED = 'Completed',
    CREATED = 'Created',
    CREATED_NOT_SAVED = 'CreatedNotSaved',
    DELIVERED = 'Delivered',
    LOADED = 'Loaded',
    LOADING = 'Loading',
    ON_SITE = 'On-site',
    CANCELED = 'Canceled',
}
