import React from 'react';
import './JobTotals.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent, { TextFormat } from '../../_common/TextContent/TextContent';

interface JobTotalsProps {
    loadsByWell: {
        wellName: string | null;
        loadCount: number | null;
    }[];
}

const defaultNumber = (value: number | null | undefined): string => value!.toString() || '0';
const defaultString = (text: string | null | undefined): string => text || '';

const JobTotals = ({ loadsByWell }: JobTotalsProps): JSX.Element => {
    const totalLoadsByWell: JSX.Element[] = [];
    for (let i = 0; i < loadsByWell.length; i++) {
        totalLoadsByWell.push(
            <Row key={i}>
                <TextContent
                    key={i}
                    hasTopMargin
                    title={defaultString(loadsByWell[i].wellName).concat(':')}
                    textFormat={TextFormat.Number}
                    value={defaultNumber(loadsByWell[i].loadCount)}
                ></TextContent>
            </Row>,
        );
    }
    return (
        <Col className='custom-column-p'>
            <Row className="JobTotals-Title">Total Loads/Well</Row>
            {totalLoadsByWell}
        </Col>
    );
};

export default JobTotals;
