import React from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import { Modal } from 'react-bootstrap';
import { CustomButton } from '../../_common/CustomButton/CustomButton';
import './DeleteModal.scss';

interface ModalProps {
    show: boolean;
    onCancel: () => void;
    onConfirm: () => void;
    name: string;
}

const DeleteModal = ({ show, onCancel, onConfirm, name }: ModalProps): JSX.Element => {
    return (
        <Modal show={show} centered className="DeleteModal-Container">
            <Modal.Header className="DeleteModal-Header">
                <i className="material-icons DeleteModal-CloseIcon" onClick={onCancel}>
                    clear
                </i>
            </Modal.Header>
            <Modal.Body>
                <div className="DeleteModal-HeaderText">Are you sure you want to delete this {name}?</div>
                <div className="DeleteModal-BodyText">
                    This action cannot be undone and you will be unable to recover any data.
                </div>
            </Modal.Body>
            <Modal.Footer className="DeleteModal-Footer">
                <div className="DeleteModal-ButtonContainer">
                    <CustomButton title="Cancel" onClick={onCancel} isBordered />
                </div>
                <div className="DeleteModal-ButtonContainer">
                    <CustomButton title="Delete" onClick={onConfirm} isBordered={false} />
                </div>
            </Modal.Footer>
        </Modal>
    );
};

export default DeleteModal;
