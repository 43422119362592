import React, { useState, useRef, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../../Header/JobLoadsManagementHeader/JobLoadsManagementHeader.scss';

import { Routes } from '../../../utils';
import { useAuthContext } from '../../Auth/AuthProvider';
import { PermissionEnum } from '../../../types/PermissionEnum';
import { useStoreContext } from '../../App/StoreProvider';
import './JobActivity.scss';

import { Table } from 'react-bootstrap';
import { GET_ACTIVITY_SUMMARY, GET_DRIVER_ACTIVITY, GET_STANDBY_DRIVERS } from '../../../graphql/client/queries';
import { useQuery } from '@apollo/client';
import Row from 'react-bootstrap/Row';
// import BootstrapTable from 'react-bootstrap-table-next';
import { Error, Loading } from '../../_common';
import {
    GetActivitySymmary_getActivitySummary as ActSummary,
    GetDriverActivity_getDriverActivity as DriverAct,
    GetStandbyDrivers_getStandbyDrivers as standByDrivers,
} from '../../../types/schemaTypes';
import { formatDateUtcToLocal } from '../../../utils/helpers/datetimeHelper';
import ForbiddenError from '../../_common/Error/ForbiddenError';
import NotFoundError from '../../_common/Error/NotFoundError';
import DriverLoadNotes from './DriverLoadNotes/DriverLoadNotes';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import ContentCopyRoundedIcon from '@mui/icons-material/ContentCopyRounded';
import { ToastContainer, toast } from 'react-toastify';
import { toastOpt } from '../../../utils/helpers/toastHelper'
const JobActivity = ({ match }: any): JSX.Element => {
    const authContext = useAuthContext();
    const [state, dispatch] = useStoreContext();
    const [tabTitle, setTabTitle] = useState(document.title);
    document.title = localStorage.getItem('Jobdetails-TabName') + '';
    const abortController = new AbortController();
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [isCustomerView, setIsCustomerView] = useState(true);
    const opsCarrierUser = authContext.userHasPermission(PermissionEnum.Carrier);
    const gridRef = React.useRef<AgGridReact<any>>(null);
    let loadingTotal1 = 0;
    let loadedTotal1 = 0;
    let onsiteTotal1 = 0;
    let assignedTotal1 = 0;
    let createdTotal1 = 0;
    let acceptedTotal1 = 0;
    let activetrucksTotal1 = 0;
    const {jobId} = useParams()
    const jobIdVal:any = jobId;
    const {
        auth: { user },
        currentActivity: { filters, searchPhrase, searchPhraseSubset },
        carrierList: { carrierId },
    } = state;

    const userProfile = user.profile;

    const isDisplayLoadsEnabled =
        authContext.userHasPermission(PermissionEnum.CustomerService) ||
        authContext.userHasPermission(PermissionEnum.Customer) ||
        authContext.userHasPermission(PermissionEnum.Admin) ||
        authContext.userHasPermission(PermissionEnum.Carrier) ||
        authContext.userHasPermission(PermissionEnum.Operation);
    const selectedCarrierId = opsCarrierUser
        ? Number(userProfile?.CarrierId)
        : parseInt(localStorage.getItem('carrierId') || '0');

    const openLoadProfile = (loadId: number | null, boxNumber: string | null): void => {
        const URL = `${window.location.origin}${Routes.LOAD_PROFILE.route}`;
        const newTab = window.open(URL + loadId, '_blank');
        setTimeout(function(): void {
            if (newTab) {
                newTab.document.title = boxNumber!;
            }
        }, 100);
    };
    const getClassName = (overDueFlag: string | null): string => {
        let names = 'JobActivity-Alert';
        if (overDueFlag === 'Y') names = names + 'Disabled';
        if (overDueFlag === 'N') names = names + '';
        if (overDueFlag === 'E') names = names + 'GrayedOut';
        return names;
    };
    const evaluateStatus = (status: string | null): string => {
        let newstatus;
        if (status == 'Assigned') newstatus = 'Accepted';
        else newstatus = status;
        return newstatus;
    };
    let jobName: string | null = '';
  
    const columns = React.useMemo(() => {
        return [
        {
            field: 'driverName',
            headerName: 'Driver Name',
        //    sort: true,
        //    headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
            /* eslint-disable react/display-name */
            cellRenderer: ({ value, data}) => {
                return (
                    <div style={{ display: 'flex' }}>
                        <div>{data.driverName}</div>
                        {data.notes && data.notes.length > 0 && <DriverLoadNotes data={data}></DriverLoadNotes>}
                    </div>
                );
            },
        },
        {
            field: 'proppantName',
            headerName: 'Proppant Name',
           // sort: true,
          //  headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'lofaName',
            headerName: 'Lofa Name',
           // sort: true,
           // headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'status',
            headerName: 'Status',
           // sort: true,
           cellRenderer: ({ value, data}) => {
                return evaluateStatus(value);
            },
          //  headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'loadId',
            headerName: 'Load Number',
           // sort: true,
          //  classes: 'JobActivityItem-Name',
          cellRenderer: ({value, data}) => {

            const openLoad = () => {
                if (data.loadId !== 0) {
                    openLoadProfile(data['loadId'], jobName);
                }
            }
            return <div>{data.loadId !== 0 && <a onClick={openLoad}>{data.loadId}</a>}</div>;
          },
            // events: {
            //     onClick: (e, column, columnIndex, row, rowIndex) => {
            //         openLoadProfile(row['loadId'], jobName);
            //     },
            // },
           // headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'acceptTime',
            headerName: 'Accepted',
           // sort: true,
           cellRenderer: ({ value, data}) => {
                return formatDateUtcToLocal(value);
            },
          //  headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'etaLoading',
            headerName: 'Loading',
         //   sort: true,
           
            cellRenderer: ({ value, data}) => {
                return <span className={getClassName(data['overdueAtLofaIn'])}>{formatDateUtcToLocal(value)}</span>; 
            },
           // headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'etaLoaded',
            headerName: 'Loaded',
          //  sort: true,
            
            cellRenderer: ({ value, data}) => {
                return <span className={getClassName(data['overdueAtLofaOut'])}>{formatDateUtcToLocal(value)}</span>; 
            },
          //  headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'etaOnsite',
            headerName: 'Onsite',
           // sort: true,
            
            cellRenderer: ({ value, data}) => {
                return <span className={getClassName(data['overdueAtDestinationIn'])}>{formatDateUtcToLocal(value)}</span>; 
            },
         //   headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
        {
            field: 'etaDelivered',
            headerName: 'Delivered',
          //  sort: true,
           
            cellRenderer: ({ value, data}) => {
                return <span className={getClassName(data['overdueAtDestinationOut'])}>{formatDateUtcToLocal(value)}</span>; 
            },
          //  headerSortingClasses: 'SortingHeaderStyle',
            //headerClasses: 'DriverActivityHeader',
        },
    ] }, []);

    const columnsStandyDrivers = [
        {
            field: 'driverName',
            headerName: 'Driver Name',
           // sort: true,
           // headerSortingClasses: 'SortingHeaderStyle',
        },
        {
            field: 'carrierName',
            headerName: 'Carrier Name',
           // sort: true,
           // headerSortingClasses: 'SortingHeaderStyle',
        },
        {
            field: 'loadRequested',
            headerName: 'Minutes since Load Requested',
           // sort: true,
          //  headerSortingClasses: 'SortingHeaderStyle',
        },
        {
            field: 'loadRejected',
            headerName: 'Minutes since Load Rejected',
          //  sort: true,
           // headerSortingClasses: 'SortingHeaderStyle',
        },
        {
            field: 'loadDelivered',
            headerName: 'Minutes since Load Delivered',
          //  sort: true,
           // headerSortingClasses: 'SortingHeaderStyle',
        },
        {
            field: 'onShift',
            headerName: 'Minutes on shift',
          //  sort: true,
          //  headerSortingClasses: 'SortingHeaderStyle',
        },
        {
            field: 'assignedTime',
            headerName: 'Assigned Time',
           // sort: true,
          //  headerSortingClasses: 'SortingHeaderStyle',
          cellRenderer: ({ value, data}) => {
                return formatDateUtcToLocal(value);
            },
        },
    ];

    useEffect(() => {
        if (opsCarrierUser) {
            setIsCustomerView(false);
        } else {
            const isRedirectToCustomer =
                localStorage.getItem('redirectbackto') && localStorage.getItem('redirectbackto') === 'carrier'
                    ? false
                    : true;
            setIsCustomerView(isRedirectToCustomer);
        }
    }, [opsCarrierUser]);

    const { loading: activitySummaryLoading, error, data: summaryData } = useQuery(GET_ACTIVITY_SUMMARY, {
        variables: {
            input: {
                jobId: parseInt(jobIdVal),
                carrierIds:
                    filters.carrierIds.length > 0
                        ? filters.carrierIds
                        : selectedCarrierId && selectedCarrierId != 0
                        ? [selectedCarrierId]
                        : [],
            },
        },
        fetchPolicy: 'network-only',
        // pollInterval: 120000,
    });

    const { loading: standbyDriversLoading, data: standbyDriverData } = useQuery(GET_STANDBY_DRIVERS, {
        variables: {
            input: {
                jobId: parseInt(jobIdVal),
                carrierIds:
                    filters.carrierIds.length > 0
                        ? filters.carrierIds
                        : selectedCarrierId && selectedCarrierId != 0
                        ? [selectedCarrierId]
                        : [],
            },
        },
        fetchPolicy: 'network-only',
        // pollInterval: 120000,
    });

    const componentRef = useRef(null);

    const { loading: driverActivityLoading, error: driverActivityError, data: driverData } = useQuery(
        GET_DRIVER_ACTIVITY,
        {
            variables: {
                input: {
                    jobId: parseInt(jobIdVal),
                    carrierIds:
                        filters.carrierIds.length > 0
                            ? filters.carrierIds
                            : selectedCarrierId && selectedCarrierId != 0
                            ? [selectedCarrierId]
                            : [],
                },
            },
            fetchPolicy: 'network-only',
            // pollInterval: 120000,
        },
    );

    if (error) {
        if (error.message.includes('NoAccess')) {
            return <ForbiddenError />;
        }
        if (error.message.includes('NoResults')) {
            return <NotFoundError />;
        }
        return <Error error={error} />;
    }

    if (driverActivityError) {
        if (driverActivityError.message.includes('NoAccess')) {
            return <ForbiddenError />;
        }
        if (driverActivityError.message.includes('NoResults')) {
            return <NotFoundError />;
        }
        return <Error error={driverActivityError} />;
    }

    const summaries: ActSummary[] = [];
    if (summaryData && summaryData.getActivitySummary && summaryData.getActivitySummary.length !== 0) {
        summaryData.getActivitySummary.forEach((element: ActSummary | null): void => {
            if (element !== null) {
                summaries.push(element);
            }
        });
        if (summaries.length > 0) {
            jobName = summaries[0].wellName;
            document.title = jobName ? jobName : tabTitle;
            for (let idx = 0; idx < summaries.length; idx++) {
                createdTotal1 += summaries[idx].created!;
                acceptedTotal1 += summaries[idx].accepted!;
                loadingTotal1 += summaries[idx].loading!;
                loadedTotal1 += summaries[idx].loaded!;
                onsiteTotal1 += summaries[idx].unloading!;
                assignedTotal1 += summaries[idx].assigned!;
                activetrucksTotal1 += summaries[idx].totalActiveTrucks!;
            }
        }
    }

    const standbyDrivers: any = React.useMemo(() => {
    const standbyDrivers: any = [];
    if (standbyDriverData && standbyDriverData.getStandbyDrivers && standbyDriverData.getStandbyDrivers.length !== 0) {
        standbyDriverData.getStandbyDrivers.forEach((element: standByDrivers | null): void => {
            if (element !== null) {
                standbyDrivers.push(element);
            }
        });
    }
    return standbyDrivers
}, [standbyDriverData]);


  //  console.log("standbyDrivers",standbyDrivers)
    const driversummaries: any = React.useMemo(() => {
        const driversummaries: DriverAct[] = [];
    
    if (driverData && driverData.getDriverActivity) {
        driverData.getDriverActivity.forEach((element: DriverAct | null): void => {
            if (element !== null) {
                driversummaries.push(element);
            }
        });
    }
    return driversummaries;
    }, [driverData]);

    // const currentActivityRefreshHandler = (event: any) => {
    //     setShowFilter(prevShowFilter => !prevShowFilter);
    // };
    const onGridReadyCallback = (params) => {

        gridRef!.current!.api.sizeColumnsToFit();
    }
    const defaultColDef = React.useMemo<any>(() => {
        return {
            flex: 1,
            resizable: false,
            filter: false,
            minWidth: 75,
            wrapText: true,
            autoHeight: true,
            suppressMovable: true,
            cellStyle: (params) => ({
                display: "flex",
                alignItems: "center",
                wordBreak: "normal"
              })
        };
    }, []);

    const generateDynamicHtml = () => {

        //  const table = copyToClipboardMap.get(id);
          let html = "";
          
          console.log("getDriversByFiltersData",driversummaries)
          if (driversummaries
              .length > 0) {
                html += `<div style="font-family: Avenir-Book; font-size: 15px">Driver Details</div><table style="border: 1px solid black;
                border-collapse: collapse;">
                <thead>
                    <tr>
                        <!-- Add more columns as needed -->
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Driver Name</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Proppant Name</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Lofa Name</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Status</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Load Number</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Accepted</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Loading</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Loaded</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Onsite</th>
                                            <th style="font-size: 11px;    font-family: Avenir-Book, sans-serif;  font-weight: 700;   border: 1px solid black;
                                            border-collapse: collapse;">Delivered</th>
                    </tr>
                </thead>
                <tbody>
                    `
        
                        driversummaries.forEach((item) => {
                            html += `<tr><td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${item['driverName']}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${item['proppantName']}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${item['lofaName']}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${evaluateStatus(item['status'])}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${item['loadId']}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${formatDateUtcToLocal(item['acceptTime'])}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${formatDateUtcToLocal(item['etaLoading'])}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${formatDateUtcToLocal(item['etaLoaded'])}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${formatDateUtcToLocal(item['etaOnsite'])}</td>
                        <td style="font-size: 11px; border: 1px solid black;
                        border-collapse: collapse;">${formatDateUtcToLocal(item['etaDelivered'])}</td>
                        </tr>`
                        });
        
                        html += `
                    </tbody>
                    </table>`
        
                    } else {
                        html += `<div style="font-family: Avenir-Book; font-size: 11px">No Driver Details available.</div><br><br>`
                    }
          const parser = new DOMParser();
          const htmlDoc = parser.parseFromString(html, 'text/html');
          return htmlDoc.documentElement.outerHTML;
          
      }
  
      const copyToClipboard = async (id) => {
  
          const html = generateDynamicHtml();
        
          const clipboardItem = new
              ClipboardItem({
                  'text/html': new Blob([html],
                      { type: 'text/html' }),
              });
  
          try {
              await navigator.clipboard.write([clipboardItem]);
              toast.success(`Driver Details table data copied successfully`, toastOpt);
  
          } catch (error) {
              console.log("error -", error);
              toast.error('Something went wrong!', toastOpt);
          }
  
      }

    return (
        <div className="JobActivity-Container">
            <div id="loadsListContainer" className="JobActivity-List" style={{ height: listHeight }}>
                <div className="JobProfile-Header">
                    <div className="JobProfile-HeaderTitle">Current Activity: {jobName}</div>
                    <div className="Row JobProfile-ToggleButtonsContainer">
                        <Link
                            to={{
                                pathname: `${Routes.JOB_PROFILE.route}${jobIdVal}`,
                            }}
                            className={`JobProfile-LeftToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                'JobProfile-RightToggleButton-Disabled'}`}
                        >
                            Job Details
                        </Link>
                        <div className="JobProfile-CenterToggleButton JobProfile-ActiveButton">
                            <p className="JobProfile-ButtonText">Current Activity</p>
                        </div>
                        {!opsCarrierUser && isCustomerView && (
                            <Link
                                to={{
                                    pathname: `${Routes.DAILY_SAND_ALLOCATIONS.route}${jobIdVal}`,
                                }}
                                className={`JobProfile-CenterToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                    'JobProfile-RightToggleButton-Disabled'}`}
                            >
                                Sand Allocations
                            </Link>
                        )}
                        <Link
                            to={{
                                pathname: `${Routes.JOB_LOADS.route}${jobIdVal}`,
                            }}
                            className={`JobProfile-RightToggleButton JobProfile-ButtonText ${!isDisplayLoadsEnabled &&
                                'JobProfile-RightToggleButton-Disabled'}`}
                        >
                            Loads
                        </Link>
                    </div>
                </div>
                <Row>
                    <span className="JobActivity-LabelText">Activity Summary</span>
                </Row>
                <Row className="JobActivity-SummaryData pl-0">
                    {activitySummaryLoading ? (
                        <div className="JobActivity-Spinner-Container">
                            <Loading />
                        </div>
                    ) : summaries.length > 0 ? (
                        <div className="react-bootstrap-table " >
                            <Table striped bordered hover ref={componentRef} className="tabletest">
                                <thead>
                                    <tr>
                                        <th>Proppant Name</th>
                                        <th>Lofa Name</th>
                                        <th>Sand PO</th>
                                        <th>Created</th>
                                        <th>Assigned</th>
                                        <th>Accepted</th>
                                        <th>Loading</th>
                                        <th>Loaded</th>
                                        <th>Onsite</th>
                                        <th>Total Active Trucks</th>
                                    </tr>
                                </thead>
                                <tbody data-bind="foreach: teams">
                                    {summaries.map(i => (
                                        <tr key={i['proppantName']}>
                                            <td>{i['proppantName']}</td>
                                            <td>{i['lofaName']}</td>
                                            <td>{i['sandPO']}</td>
                                            <td>{i['created']}</td>
                                            <td>{i['assigned']}</td>
                                            <td>{i['accepted']}</td>
                                            <td>{i['loading']}</td>
                                            <td>{i['loaded']}</td>
                                            <td>{i['unloading']}</td>
                                            <td>{i['totalActiveTrucks']}</td>
                                        </tr>
                                    ))}
                                </tbody>
                                <tfoot>
                                    <tr>
                                        <th>Total</th>
                                        <th></th>
                                        <th></th>
                                        <th>{createdTotal1}</th>
                                        <th>{assignedTotal1}</th>
                                        <th>{acceptedTotal1}</th>
                                        <th>{loadingTotal1}</th>
                                        <th>{loadedTotal1}</th>
                                        <th>{onsiteTotal1}</th>
                                        <th>{activetrucksTotal1}</th>
                                    </tr>
                                </tfoot>
                            </Table>
                        </div>
                    ) : (
                        <div className="NoResult-Container JobActivity-NoResult-Container">
                            <div className="NoResult-Second-Title-Primary JobActivity-NoResult-Title">
                                No Activity Summary Available.
                            </div>
                        </div>
                    )}
                </Row>
                {!isCustomerView && (
                    <Row>
                        <span className="JobActivity-LabelText">Standby Details</span>
                    </Row>
                )}
                {!isCustomerView && (
                    <Row className="JobActivity-StandByDriversData pl-0" >
                       
                        {standbyDriversLoading ? (
                            <div className="JobActivity-Spinner-Container">
                                <Loading />
                            </div>
                        ) : standbyDrivers.length > 0 ? (
                        

                            <>

                            <div
                            className={
                                "ag-theme-quartz"
                            }
                            style={{ width: "98%", height: '100%' }}
                        >
                            <AgGridReact
                            ref={gridRef}
                            enableCellTextSelection={true}
                            rowData={standbyDrivers}
                            columnDefs={columnsStandyDrivers}
                            suppressMenuHide={true}
                            defaultColDef={defaultColDef}
                            animateRows = {false}
                            onGridReady={onGridReadyCallback}
                            />


                            </div>
                          </>
                            // <BootstrapTable
                            //     keyField="id"
                            //     data={standbyDrivers}
                            //     columns={columnsStandyDrivers}
                            //     striped
                            //     bordered
                            //     hover
                            //     classes="DriverActivityTable"
                            //     bootstrap4
                            //     table-responsive
                            // />
                          
                            
                        ) : (
                            <div className="NoResult-Container JobActivity-NoResult-Container">
                                <div className="NoResult-Second-Title-Primary JobActivity-NoResult-Title">
                                    No Standby Drivers Available.
                                </div>
                            </div>
                        )}
                    </Row>
                )}
                <Row>

                    <span className="JobActivity-LabelText" style={{width:'auto'}}>Driver Details</span>
                    <ContentCopyRoundedIcon
                                                    className='JobActivity-Copy'
                                                    onClick={() => copyToClipboard(1)}
                                                />
                </Row>
                <Row className="JobActivity-Data pl-0" >
                    {driverActivityLoading ? (
                        <div className="JobActivity-Spinner-Container">
                            <Loading />
                        </div>
                    ) : driversummaries.length > 0 ? (
                        // <BootstrapTable
                        //     keyField="id"
                        //     data={driversummaries}
                        //     columns={columns}
                        //     //defaultSorted={defaultSorted}
                        //     striped
                        //     bordered
                        //     hover
                        //     classes="DriverActivityTable"
                        //     bootstrap4
                        //     //headerClasses="DriverActivityHeader"
                        //     table-responsive
                        // />
                        <>
                        
                        <ToastContainer/>

                        <div
                        className={
                            "ag-theme-quartz"
                        }
                        style={{ width: "98%", height: '100%'}}
                    >
                        <AgGridReact
                            ref={gridRef}
                            enableCellTextSelection={true}
                            rowData={driversummaries}
                            columnDefs={columns}
                            suppressMenuHide={true}
                            defaultColDef={defaultColDef}
                            animateRows = {false}
                            />
                            </div>
                            </>
                    ) : (
                        <div className="NoResult-Container JobActivity-NoResult-Container">
                            <div className="NoResult-Second-Title-Primary JobActivity-NoResult-Title">
                                No Driver Details Available.
                            </div>
                        </div>
                    )}
                </Row>
            </div>
        </div>
    );
};

export default JobActivity;
