import { Action } from '../../types';

interface FilterItem {
    id: number;
    name: string;
}

export enum Actions {
    UPDATE_FILTERS = 'MESSAGE_LIST_UPDATE_FILTERS',
    UPDATE_MESSAGE_TYPE_FILTERS = 'MESSAGE_TYPE_UPDATE_FILTERS',
    UPDATE_FILTERS_SELECT_ALL = 'MESSAGE_LIST_UPDATE_FILTERS_TO_SELECT_ALL',
    UPDATE_MESSAGE_TYPE_FILTERS_SELECT_ALL = 'MESSAGE_TYPE_UPDATE_FILTERS_TO_SELECT_ALL',
    UPDATE_FILTER_SEARCH_PHRASE = 'MESSAGE_LIST_FILTERS_UPDATE_SEARCH_PHRASE',
    UPDATE_SEARCH_PHRASE = 'MESSAGE_LIST_UPDATE_SEARCH_PHRASE',
    UPDATE_DRIVER_SEARCH_PHRASE = 'DRIVER_LIST_UPDATE_DRIVER_SEARCH_PHRASE',
    UPDATE_DATE_FILTER = 'MESSAGE_LIST_UPDATE_DATE_FILTER',
    UPDATE_DATE_RANGE_FILTERS = 'MESSAGE_LIST_UPDATE_DATE_RANGE_FILTERS',
    REFRESH_MESSAGE_FILTERS = 'MESSAGE_FILTER_SET_TO_DEFAULT',
}

export const setFiltersForMessages = (filterType: string, id: number, isSelected: boolean): Action => {
    return {
        type: Actions.UPDATE_FILTERS,
        payload: {
            filterType,
            id,
            isSelected,
        },
        error: null,
    };
};

export const setFiltersForMessagesType = (filterType: string, value: string, isSelected: boolean): Action => {
    return {
        type: Actions.UPDATE_MESSAGE_TYPE_FILTERS,
        payload: {
            filterType,
            value,
            isSelected,
        },
        error: null,
    };
};

export const setSelectAllFilters = (itemIds: number[], filterType: string): Action => {
    return {
        type: Actions.UPDATE_FILTERS_SELECT_ALL,
        payload: {
            filterType,
            itemIds,
        },
        error: null,
    };
};

export const setSelectAllMessageTypeFilters = (messageTypeValues: string[], filterType: string): Action => {
    return {
        type: Actions.UPDATE_MESSAGE_TYPE_FILTERS_SELECT_ALL,
        payload: {
            filterType,
            messageTypeValues,
        },
        error: null,
    };
};

export const setFilterSearchPhrase = (items: FilterItem[], filterType: string, searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_FILTER_SEARCH_PHRASE,
        payload: {
            filterType,
            items,
            searchPhrase,
        },
        error: null,
    };
};

export const setSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setDriverSearchPhrase = (searchPhrase: string): Action => {
    return {
        type: Actions.UPDATE_DRIVER_SEARCH_PHRASE,
        payload: searchPhrase,
        error: null,
    };
};

export const setDateFilter = (dateFilter: string): Action => {
    return {
        type: Actions.UPDATE_DATE_FILTER,
        payload: dateFilter,
        error: null,
    };
};

export const setDateRangeFilters = (fromDate: string, toDate: string): Action => {
    return {
        type: Actions.UPDATE_DATE_RANGE_FILTERS,
        payload: {
            fromDate,
            toDate,
        },
        error: null,
    };
};

export const resetFilterState = (): Action => {
    return {
        type: Actions.REFRESH_MESSAGE_FILTERS,
        payload: null,
        error: null,
    };
};
