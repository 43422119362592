import React, { useState } from 'react';
import './UploadLoadImage.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../../../_common/TextContent/TextContent';
import FullLoadImage from '../FullLoadImage';

interface UploadLoadImageProps {
    ticketNumber?: string | null;
    ticketImage?: string | null;
    ticketUploadedBy?: string | null;
    imageText?: string;
    ticketUploadedAt?: string | null;
    loadId?: number;
    ticketName?: string;
    loadStatus?: string;
}

const UploadLoadImage = ({
    ticketNumber,
    ticketImage,
    ticketUploadedBy,
    imageText,
    ticketUploadedAt,
    ticketName,
    loadId,
    loadStatus,
}: UploadLoadImageProps): JSX.Element => {
    const [showModal, setShowModal] = useState(false);

    const handleOnClick = (): void => {
        setShowModal(!showModal);
    };

    return (
        <Col>
            <Row className="LoadDetail-Title">
                <TextContent hasTopMargin title={imageText || ''} value={ticketNumber || ''}></TextContent>
            </Row>
            <Row>
                {ticketImage != null && ticketImage != '' ? (
                    <div>
                        <img className="image" src={ticketImage} onClick={handleOnClick} />
                        {showModal && (
                            <FullLoadImage
                                onClickShow={() => setShowModal(true)}
                                show={showModal}
                                onClickHide={handleOnClick}
                                image={ticketImage}
                            />
                        )}
                    </div>
                ) : (
                    <div className="LoadDetail-Image LoadDetail-Image-Disabled">
                        <div className="No-Image-Text">
                            <span className="UploadImage-Span">Image Not Available</span>
                        </div>
                    </div>
                )}
            </Row>
            <Row>
                {ticketUploadedBy != null && ticketUploadedBy != '' ? (
                    <TextContent hasTopMargin title={'Uploaded by: '} value={ticketUploadedBy || ''}></TextContent>
                ) : (
                    <div></div>
                )}
            </Row>
            <Row>
                <TextContent hasTopMargin title={''} value={ticketUploadedAt || ''}></TextContent>
            </Row>
        </Col>
    );
};

export default UploadLoadImage;
