import gql from 'graphql-tag';

export default gql`
    query GetAllRegions {
        getAllRegions {
            id
            regionName
            regionCode
            districts {
                id
                districtName
                regionId
            }
        }
    }
`;
