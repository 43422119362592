import gql from 'graphql-tag';

export default gql`
    query GetDriverLocations($input: DriverLocationsInput) {
        getDriverLocations(input: $input) {
            latitude
            longitude
            locationTimestamp
        }
    }
`;
