import React, { useEffect, useState } from 'react';
import LoadDetail from './LoadDetail';
import { GET_LOAD_DETAIL, CHECK_ISLOADACCESSIBLE } from '../../graphql/client/queries';
import {
    GetLoadDetail_getLoadDetail as LoadDetailData,
    GetLoadDetail,
    CheckIsLoadAccessible,
} from '../../types/schemaTypes';
import { Query } from '@apollo/client/react/components';
import {  QueryResult } from '@apollo/client';
import { Error, Loading } from '../_common';
import './LoadProfile.scss';
import DriverDetail from './DriverDetail';
import SplitLoadDriverDetail from './SplitLoadDriverDetail';
import AccessorialForLoadDetail from './AccessorialForLoadDetail';
import LoadProfileNav from './LoadProfileNav';
import { LoadStatusEnum } from '../../types/LoadStatusEnum';
import { Routes } from '../../utils';
import { Option } from '../Users/interface';
import { Link, useParams } from 'react-router-dom';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import ForbiddenError from '../_common/Error/ForbiddenError';
import NotFoundError from '../_common/Error/NotFoundError';
import { useStoreContext } from '../App/StoreProvider';
import * as CarrierListActions from '../../store/actions/carrierList';

const LoadProfile = ({ match }: any): JSX.Element => {
    const authContext = useAuthContext();
    const [state, dispatch] = useStoreContext();
    const {loadId:loadVal} = useParams()
    const loadIdVal:any = loadVal;
    const variables = {
        loadId: parseInt(loadIdVal),
    };

    const [tabTitle, setTabTitle] = useState(document.title);
    const {
        auth: { user },
    } = state;
    const userProfile = user.profile;
    const opsCarrierUser = authContext.userHasPermission(PermissionEnum.Carrier);
    const canViewLogs =
        authContext.userHasPermission(PermissionEnum.CustomerService) ||
        authContext.userHasPermission(PermissionEnum.Customer) ||
        authContext.userHasPermission(PermissionEnum.Admin);
    // const canEditLoadDetails = authContext.userHasPermission(PermissionEnum.EditLoadDetails);

    useEffect(() => {
        if (opsCarrierUser) {
            localStorage.setItem('carrierId', userProfile?.CarrierId);
            if (userProfile.CarrierId) {
                dispatch(CarrierListActions.setCarrierIdSelection(Number(userProfile?.CarrierId)));
            }
        }
    }, [opsCarrierUser]);

    const checkIsLoadAccessvariables = {
        input: {
            loadId: parseInt(loadIdVal),
            driverCompanyId: opsCarrierUser ? Number(userProfile?.CarrierId) : 0,
        },
    };

    const isRedirectToCustomer =
        localStorage.getItem('redirectbackto') && localStorage.getItem('redirectbackto') === 'carrier' ? false : true;

    if (opsCarrierUser) {
        return (
            <Query
                query={CHECK_ISLOADACCESSIBLE}
                variables={checkIsLoadAccessvariables}
                fetchPolicy="cache-and-network"
            >
                {({ loading, error, data }: QueryResult<CheckIsLoadAccessible>): JSX.Element | null => {
                    if (loading) {
                        return <Loading />;
                    }
                    if (data && data?.checkIsLoadAccessible?.isAccessible) {
                        return (
                            <Query query={GET_LOAD_DETAIL} variables={variables} fetchPolicy="cache-and-network">
                                {({
                                    loading,
                                    error,
                                    data,
                                    refetch,
                                }: QueryResult<GetLoadDetail>): JSX.Element | null => {
                                    if (loading) {
                                        return <Loading />;
                                    }

                                    if (error) {
                                        if (error.message.includes('NoAccess')) {
                                            return <ForbiddenError />;
                                        }
                                        if (error.message.includes('NullReferenceException')) {
                                            return (
                                                <div className="NoResult-Container">
                                                    <div className="NoResult-First-Title-Secondary">
                                                        Some internal error occurred
                                                    </div>
                                                </div>
                                            );
                                        }
                                        if (error.message.includes('NoResults')) {
                                            return <NotFoundError />;
                                        }
                                        return <Error error={error} />;
                                    }

                                    let loadDetailData: LoadDetailData | null;

                                    if (data && data.getLoadDetail) {
                                        loadDetailData = data.getLoadDetail;

                                        const loadJobName =
                                            loadDetailData && loadDetailData.jobName ? loadDetailData.jobName : '';
                                        const loadLoadId =
                                            loadDetailData && loadDetailData.loadId
                                                ? loadDetailData.loadId.toString()
                                                : tabTitle;
                                        document.title = loadJobName + ':' + loadLoadId;
                                        localStorage.setItem('loadHistory-JobName', loadJobName);
                                        setTabTitle(document.title);

                                        const loadDetailComponent = loadDetailData ? (
                                            <LoadDetail
                                                jobName={loadDetailData.jobName}
                                                customerName={loadDetailData.customerName}
                                                jdeNumber={loadDetailData.jdeNumber}
                                                sandPo={loadDetailData.sandPO}
                                                loadingFacility={loadDetailData.loadingFacility}
                                                proppantType={loadDetailData.proppantName}
                                                transportationPO={loadDetailData.transportationPo}
                                                tmsId={loadDetailData.tmsId}
                                            />
                                        ) : (
                                            <LoadDetail />
                                        );

                                        const driverDetailComponent = loadDetailData ? (
                                            <DriverDetail
                                                driver={loadDetailData.driverName}
                                                carrier={loadDetailData.carrierName}
                                                box={loadDetailData.boxNumber}
                                                truck={loadDetailData.truckNumber}
                                                chassis={loadDetailData.chassisNumber}
                                                driverStatus={loadDetailData.driverLoadStatus || ''}
                                                driverId={loadDetailData.driverId || 0}
                                                secondaryDriverId={loadDetailData.secondaryDriverId || 0}
                                            />
                                        ) : (
                                            <DriverDetail />
                                        );

                                        const splitLoadDriverDetailComponent = (
                                            <SplitLoadDriverDetail
                                                box={loadDetailData.boxNumber}
                                                secondaryTruck={loadDetailData.secondaryTruckNumber}
                                                secondaryChassis={loadDetailData.secondaryChassisNumber}
                                                secondaryDriverStatus={loadDetailData.secondaryDriverLoadStatus || ''}
                                            />
                                        );

                                        return (
                                            <div className="LoadProfile-Container">
                                                <div className="LoadProfile-Header">
                                                    <div className="LoadProfile-HeaderTitle">
                                                        Load #{loadDetailData && loadDetailData.loadId}
                                                        {(() => {
                                                            if (loadDetailData.status === LoadStatusEnum.COMPLETED) {
                                                                return (
                                                                    <div className="LoadProfile-CompleteLabel">
                                                                        <i className="material-icons complete-icon-size">
                                                                            check_circle_outline
                                                                        </i>
                                                                        <span className="complete-label">
                                                                            COMPLETED
                                                                        </span>
                                                                    </div>
                                                                );
                                                            } else if (
                                                                loadDetailData.status === LoadStatusEnum.CANCELED
                                                            ) {
                                                                return (
                                                                    <div className="LoadProfile-CompleteLabel">
                                                                        <i className="material-icons complete-icon-size">
                                                                            cancel
                                                                        </i>
                                                                        <span className="complete-label">CANCELED</span>
                                                                    </div>
                                                                );
                                                            } else {
                                                                return <div></div>;
                                                            }
                                                        })()}
                                                    </div>
                                                </div>

                                                <div className="LoadProfile-LoadDetailsContainer">
                                                    <div className="LoadProfile-LoadDetails">
                                                        {loadDetailComponent}
                                                        {driverDetailComponent}
                                                        {loadDetailData.isSplit === false
                                                            ? ''
                                                            : splitLoadDriverDetailComponent}
                                                        {/* <AccessorialForLoadDetail loadId={loadDetailData.loadId} />{' '} */}
                                                    </div>
                                                </div>

                                                <div className="LoadProfile-Nav">
                                                    <LoadProfileNav
                                                        loadId={parseInt(loadIdVal)}
                                                        loadDetail={loadDetailData}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    } else {
                                        return <div></div>;
                                    }
                                }}
                            </Query>
                        );
                    } else {
                        return <ForbiddenError />;
                    }
                }}
            </Query>
        );
    } else {
        return (
            <Query query={GET_LOAD_DETAIL} variables={variables} fetchPolicy="cache-and-network">
                {({ loading, error, data, refetch }: QueryResult<GetLoadDetail>): JSX.Element | null => {
                    if (loading) {
                        return <Loading />;
                    }

                    if (error) {
                        if (error.message.includes('NoAccess')) {
                            return <ForbiddenError />;
                        }
                        if (error.message.includes('NullReferenceException')) {
                            return (
                                <div className="NoResult-Container">
                                    <div className="NoResult-First-Title-Secondary">Some internal error occurred</div>
                                </div>
                            );
                        }
                        if (error.message.includes('NoResults')) {
                            return <NotFoundError />;
                        }
                        return <Error error={error} />;
                    }

                    let loadDetailData: LoadDetailData | null;

                    if (data && data.getLoadDetail) {
                        loadDetailData = data.getLoadDetail;
                        const loadJobName = loadDetailData && loadDetailData.jobName ? loadDetailData.jobName : '';
                        const loadLoadId =
                            loadDetailData && loadDetailData.loadId ? loadDetailData.loadId.toString() : tabTitle;

                        document.title = loadJobName + ':' + loadLoadId;
                        localStorage.setItem('loadHistory-JobName', loadJobName);
                        setTabTitle(document.title);

                        const loadDetailComponent = loadDetailData ? (
                            <LoadDetail
                                jobName={loadDetailData.jobName}
                                customerName={loadDetailData.customerName}
                                jdeNumber={loadDetailData.jdeNumber}
                                sandPo={loadDetailData.sandPO}
                                loadingFacility={loadDetailData.loadingFacility}
                                proppantType={loadDetailData.proppantName}
                                transportationPO={loadDetailData.transportationPo}
                                tmsId={loadDetailData.tmsId}
                            />
                        ) : (
                            <LoadDetail />
                        );

                        const driverDetailComponent = loadDetailData ? (
                            <DriverDetail
                                driver={loadDetailData.driverName}
                                carrier={loadDetailData.carrierName}
                                box={loadDetailData.boxNumber}
                                truck={loadDetailData.truckNumber}
                                chassis={loadDetailData.chassisNumber}
                                driverStatus={loadDetailData.driverLoadStatus || ''}
                                driverId={loadDetailData.driverId || 0}
                                secondaryDriverId={loadDetailData.secondaryDriverId || 0}
                            />
                        ) : (
                            <DriverDetail />
                        );

                        const splitLoadDriverDetailComponent = (
                            <SplitLoadDriverDetail
                                box={loadDetailData.boxNumber}
                                secondaryTruck={loadDetailData.secondaryTruckNumber}
                                secondaryChassis={loadDetailData.secondaryChassisNumber}
                                secondaryDriverStatus={loadDetailData.secondaryDriverLoadStatus || ''}
                            />
                        );

                        return (
                            <div className="LoadProfile-Container">
                                <div className="LoadProfile-Header">
                                    <div className="LoadProfile-HeaderTitle">
                                        Load #{loadDetailData && loadDetailData.loadId}
                                        {(() => {
                                            if (loadDetailData.status === LoadStatusEnum.COMPLETED) {
                                                return (
                                                    <div className="LoadProfile-CompleteLabel">
                                                        <i className="material-icons complete-icon-size">
                                                            check_circle_outline
                                                        </i>
                                                        <span className="complete-label">COMPLETED</span>
                                                    </div>
                                                );
                                            } else if (loadDetailData.status === LoadStatusEnum.CANCELED) {
                                                return (
                                                    <div className="LoadProfile-CompleteLabel">
                                                        <i className="material-icons complete-icon-size">cancel</i>
                                                        <span className="complete-label">CANCELED</span>
                                                    </div>
                                                );
                                            } else {
                                                return <div></div>;
                                            }
                                        })()}
                                    </div>
                                </div>

                                <div className="LoadProfile-LoadDetailsContainer">
                                    <div className="LoadProfile-LoadDetails">
                                        {loadDetailComponent}
                                        {driverDetailComponent}
                                        {loadDetailData.isSplit === false ? '' : splitLoadDriverDetailComponent}
                                        {isRedirectToCustomer && (
                                            <AccessorialForLoadDetail loadId={loadDetailData.loadId} />
                                        )}{' '}
                                    </div>
                                </div>

                                <div className="LoadProfile-Nav">
                                    <LoadProfileNav
                                        loadId={parseInt(loadIdVal)}
                                        loadDetail={loadDetailData}
                                    />
                                </div>
                            </div>
                        );
                    } else {
                        return <div></div>;
                    }
                }}
            </Query>
        );
    }
};

export default LoadProfile;
