import { Reducer } from 'react';
import { Actions } from '../../actions/jobList';
import { Action, JobListState } from '../../types';

const initialJobListState: JobListState = {
    filters: { statuses: [], districtIds: [], regionIds: [], customerIds: [] },
    searchPhrase: '',
};

interface FilterObject {
    filterType: string;
    id: number;
    isSelected: boolean;
}

const addOrRemoveFilters = (payload: FilterObject, filters: number[]): number[] => {
    let massagedFilter: number[] = [];
    massagedFilter = massagedFilter.concat(filters);
    if (payload.isSelected) {
        massagedFilter.push(payload.id);
    } else {
        massagedFilter = filters.filter((id): any => {
            if (id !== payload.id) return id;

            return undefined;
        });
    }

    return massagedFilter;
};

const addOrRemoveSelectAllFilters = (itemIds: number[], filters: number[], selected: boolean): number[] => {
    const massagedFilter: number[] = [];
    if (!selected) {
        return [];
    } else {
        return massagedFilter.concat(itemIds);
    }
};

const setSelectedFilters = (payload: FilterObject, state: JobListState): JobListState => {
    const { filters } = state;

    if (payload.filterType === 'Regions') {
        filters.regionIds = addOrRemoveFilters(payload, filters.regionIds);
    }
    if (payload.filterType === 'Districts') {
        filters.districtIds = addOrRemoveFilters(payload, filters.districtIds);
    }
    if (payload.filterType === 'Customers') {
        filters.customerIds = addOrRemoveFilters(payload, filters.customerIds);
    }
    if (payload.filterType === 'Status') {
        filters.statuses = addOrRemoveFilters(payload, filters.statuses);
    }

    return {
        ...state,
        filters: { ...filters },
    };
};

const setSelectAllFilters = (
    payload: { itemIds: number[]; filterType: string; isSelected: boolean },
    state: JobListState,
): JobListState => {
    const { filters } = state;
    const filterCopy = {...filters};

    if (payload.filterType === 'Regions') {
        const selectAll = filterCopy.regionIds.length > 0 ? false : true;
        filterCopy.regionIds = addOrRemoveSelectAllFilters(payload.itemIds, filterCopy.regionIds, selectAll);
    }
    if (payload.filterType === 'Districts') {
        const selectAll = filterCopy.districtIds.length > 0 ? false : true;
        filterCopy.districtIds = addOrRemoveSelectAllFilters(payload.itemIds, filterCopy.districtIds, selectAll);
    }
    if (payload.filterType === 'Customers') {
        const selectAll = filterCopy.customerIds.length > 0 ? false : true;
        filterCopy.customerIds = addOrRemoveSelectAllFilters(payload.itemIds, filterCopy.customerIds, selectAll);
    }
    if (payload.filterType === 'Status') {
        const selectAll = filterCopy.statuses.length > 0 ? false : true;
        filterCopy.statuses = addOrRemoveSelectAllFilters(payload.itemIds, filterCopy.statuses, selectAll);
    }
    return {
        ...state,
        filters: { ...filterCopy },
    };
};

const setSearchPhrase = (payload: string, state: JobListState): JobListState => {
    return {
        ...state,
        searchPhrase: payload,
    };
};

const resetFilterState = (state: JobListState) => {
    return {
        ...state,
        filters: { statuses: [], districtIds: [], regionIds: [], customerIds: [] },
        searchPhrase: '',
    };
};

const jobListReducer: Reducer<JobListState, Action> = (state, action): JobListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_FILTERS:
            newState = setSelectedFilters(action.payload, state);
            break;
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
        case Actions.UPDATE_FILTERS_SELECT_ALL:
            newState = setSelectAllFilters(action.payload, state);
            break;
        case Actions.REFRESH_JOBMANAGEMENT_FILTERS:
            newState = resetFilterState(state);
    }

    return newState;
};

export { initialJobListState, jobListReducer };
