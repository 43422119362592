import React from 'react';
import './Error.css';
import { Alert } from 'react-bootstrap';

import {  ToastContainer, toast } from 'react-toastify';
import {toastOpt} from '../../../utils/helpers/toastHelper'


interface ErrorProps {
    error: {
        message: string;
    };
}

export const Error = (props: ErrorProps): JSX.Element => {
    const { message } = props.error;
    const toasterOptions = { duration: 2500 };
    // const test : MessageOptionalOptions
    const renderToast = (message, type = 'info') => {
        interface ToastProps {
            onClose?: () => void;
        }
        const Comp = (props: ToastProps) => {
            return (
                <Alert variant={type} onClose={props.onClose} id="1">
                    {message}
                </Alert>
            );
        };

        Comp.displayName = 'toast';
        return Comp;
    };

   // toaster.notify(renderToast('There is an error while loading the data, please try again', 'danger'), toasterOptions);
     toast.error('There is an error while loading the data, please try again',toastOpt);
    
    return <ToastContainer />;
    // return <div>Error: {message}</div>;
};
