import gql from 'graphql-tag';

export default gql`
    query GetAllJobsFilter {
        getAllJobsFilter {
            id
            name
        }
    }
`;
