import gql from 'graphql-tag';

export default gql`
    query GetAllCrewFiltered($input: String) {
        getAllCrewFiltered(input: $input) {
            id
            crewNumber
            customerId
            customerName
            isDeleted
        }
    }
`;
