import gql from 'graphql-tag';

export default gql`
    query GetAllLofasByJob($jobId: Int) {
        getAllLofasByJob(jobId: $jobId) {
            lofaId
            lofaName
        }
    }
`;
