import React from 'react';
import './SplitLoadDriverDetail.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent';

interface SplitLoadDriverDetailProps {
    box?: string | null;
    secondaryTruck?: string | null;
    secondaryChassis?: string | null;
    secondaryDriverStatus: string;
}

const SplitLoadDriverDetail = ({
    box,
    secondaryTruck,
    secondaryChassis,
    secondaryDriverStatus,
}: SplitLoadDriverDetailProps): JSX.Element => {
    return (
        <Col>
            <Row className="SplitLoadDriverDetail-Title">Split Load Driver Details</Row>
            <Row>
                <TextContent hasTopMargin title={'Box: '} value={box || ''}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'Truck: '} value={secondaryTruck || ''}></TextContent>
            </Row>
            <Row>
                <TextContent hasTopMargin title={'Chassis: '} value={secondaryChassis || ''}></TextContent>
            </Row>
            <Row>
                {secondaryDriverStatus === 'Accepted' ? (
                    <div className="DriverDetail-StatusLabel">
                        <i className="material-icons complete-icon">check_circle_outline</i>
                        <span className="status-label">Accepted</span>
                    </div>
                ) : (
                    ''
                )}
                {secondaryDriverStatus === 'Rejected' ? (
                    <div className="DriverDetail-StatusLabel">
                        <i className="material-icons rejected-icon">highlight_off</i>
                        <span className="status-label">Rejected</span>
                    </div>
                ) : (
                    ''
                )}
                {secondaryDriverStatus === 'Pending' ? (
                    <div className="DriverDetail-StatusLabel">
                        <i className="material-icons pending-icon">restore</i>
                        <span className="status-label">Pending</span>
                    </div>
                ) : (
                    ''
                )}
            </Row>
        </Col>
    );
};

export default SplitLoadDriverDetail;
