import gql from 'graphql-tag';

export default gql`
    query GetAllCarriersFilter {
        getAllCarriersFilter {
            id
            name
        }
    }
`;
