import gql from 'graphql-tag';

export default gql`
    query GetAllDrivers($regionId: Int) {
        getAllDrivers(regionId: $regionId) {
            driverId
            driverName
            driverCompany
            regionId
        }
    }
`;
