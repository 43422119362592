import React, { FC } from 'react';
import { useStoreContext } from '../../App/StoreProvider';
import * as LoadProfileActions from '../../../store/actions/loadProfile';
import './LoadProfileNav.scss';
import LoadProgress from '../LoadProgress';
// import LoadNotes from '../LoadNotes';
import { GetLoadDetail_getLoadDetail as LoadDetail } from '../../../types/schemaTypes';
import { LoadStatusEnum } from '../../../types/LoadStatusEnum';

interface LoadProfileNavProps {
    loadId: number;
    loadDetail: LoadDetail;
}

const getNavigationComponent = (tabs: any, loadId: number, loadDetail: LoadDetail): JSX.Element => {
    const selectedTab = tabs.find((tab: any): boolean => {
        return tab.isSelected;
    });

    if (!selectedTab) {
        return <div>No tab selected</div>;
    }

    switch (selectedTab.displayName) {
        case 'Load Progress':
            return <LoadProgress loadId={loadId} loadDetail={loadDetail} />;
        // case 'Load Notes':
        //     return <LoadNotes loadId={loadId} />;
        default:
            return <div>No content provided for {selectedTab.displayName}</div>;
    }
};

const LoadProfileNav: FC<LoadProfileNavProps> = ({ loadId, loadDetail }: LoadProfileNavProps): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const { loadProfile } = state;

    return (
        <React.Fragment>
            <span className="LoadProfileNav-TabContainer">
                {loadProfile.tabs.map(
                    (tab: any): JSX.Element => {
                        const tabClass = `LoadProfileNav-Tab ${
                            tab.isSelected ? 'LoadProfileNav-Selected' : 'LoadProfileNav-Unselected'
                        }`;

                        return (
                            <div
                                key={tab.key}
                                className={tabClass}
                                onClick={(): void => dispatch(LoadProfileActions.setLoadSelectedTab(tab.key))}
                            >
                                <span>
                                    <img
                                        className="LoadProfileNav-TabIcon"
                                        src={tab.icon}
                                        alt={`${tab.displayName} Icon`}
                                    />
                                    {tab.displayName}
                                </span>
                            </div>
                        );
                    },
                )}
            </span>
            <div
                className={`LoadProfileNav-Content ${loadDetail.status === LoadStatusEnum.CANCELED &&
                    'LoadProfileNav-Content-Disabled'}`}
            >
                {getNavigationComponent(loadProfile.tabs, loadId, loadDetail)}
            </div>
        </React.Fragment>
    );
};

export default LoadProfileNav;
