import gql from 'graphql-tag';

export default gql`
    query GetLoadLoggers($input: LoggerSearchInput) {
        getLoadLoggers(input: $input) {
            id
            message
            creationDate
            createdBy
            creatorName
            loadId
            systemType
        }
    }
`;
