import gql from 'graphql-tag';

export default gql`
    query GetJobImages($jobId: Int) {
        getJobImages(jobId: $jobId) {
            id
            name
            imageName
            uploadedBy
            uploadDate
            downloadLink
        }
    }
`;
