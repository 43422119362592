import React from 'react';
import './Tags.css';
import 'material-design-icons/iconfont/material-icons.css';

interface TagItemProps {
    title: string;
}

const TagItem = ({ title }: TagItemProps): JSX.Element => {
    return (
        <div className="TagItem-Container">
            <i className="material-icons TagItem-X">close</i>
            <div className="TagItem-Title">{title}</div>
        </div>
    );
};

interface TagsProps {
    items: string[];
}

const Tags = ({ items }: TagsProps): JSX.Element => {
    const tags: JSX.Element[] = [];
    for (let i = 0; i < items.length; i++) {
        tags.push(<TagItem title={items[i]}></TagItem>);
    }
    return <div className="Tags-Container">{tags}</div>;
};

export default Tags;
