import gql from 'graphql-tag';

export default gql`
    query GetAccessorialForLoadDetail {
        getAccessorialForLoadDetail {
            accessorialId
            accessorialName
            isDeleted
            extSystemName
            extSystemReference
        }
    }
`;
