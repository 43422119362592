import React from 'react';
import './NoResult.scss';
import 'material-design-icons/iconfont/material-icons.css';

interface NoResultProps {
    isModal: boolean;
}

export const NoResult = ({ isModal = false }: NoResultProps): JSX.Element => {
    return (
        <>
            {isModal ? (
                <div className="NoResult-Container">
                    <div className="NoResult-First-Title-Primary">No matching items found.</div>
                </div>
            ) : (
                <div className="NoResult-Container">
                    <i className="material-icons NoResult-Icon">filter_list</i>
                    <div className="NoResult-First-Title-Secondary">No matching items found.</div>
                    <div className="NoResult-Second-Title-Secondary">Please adjust your filter selection</div>
                </div>
            )}
        </>
    );
};
