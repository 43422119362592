import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import * as LoadListActions from '../../../store/actions/loadList';
import * as LoadManagementListActions from '../../../store/actions/loadManagementList';
import { useStoreContext } from '../../App/StoreProvider';
import FilterItem from '../FilterItem';
import './FilterList.css';
import 'material-design-icons/iconfont/material-icons.css';

interface ListProps {
    abortRequest: () => void;
    filterType: string;
    items: FilterItem[];
    loadManagementMode?: boolean;
}

const Collection = ({ abortRequest, filterType, items, loadManagementMode = false }: ListProps): JSX.Element => {
    const halfLength = Math.ceil(items.length / 2);
    const leftSideItems = items.slice(0, halfLength);
    const rightSideItems = items.slice(halfLength, items.length);

    const leftSideRows: JSX.Element[] = [];
    for (let i = 0; i < leftSideItems.length; i++) {
        leftSideRows.push(
            <FilterCheckBox
                filterType={filterType}
                id={leftSideItems[i].id}
                key={i}
                title={leftSideItems[i].name}
                loadManagementMode={loadManagementMode}
                abortRequest={abortRequest}
            />,
        );
    }

    const rightSideRows: JSX.Element[] = [];
    for (let i = 0; i < rightSideItems.length; i++) {
        rightSideRows.push(
            <FilterCheckBox
                filterType={filterType}
                id={rightSideItems[i].id}
                key={i}
                title={rightSideItems[i].name}
                loadManagementMode={loadManagementMode}
                abortRequest={abortRequest}
            />,
        );
    }

    return (
        <Container className="FilterList-Container">
            <Row className="FilterList-Row">
                <Col>{leftSideRows}</Col>
                <Col>{rightSideRows}</Col>
            </Row>
        </Container>
    );
};

const List = ({ abortRequest, filterType, items, loadManagementMode = false }: ListProps): JSX.Element => {
    const rows: JSX.Element[] = [];
    for (let i = 0; i < items.length; i++) {
        rows.push(
            <FilterCheckBox
                filterType={filterType}
                id={items[i].id}
                key={i}
                title={items[i].name}
                loadManagementMode={loadManagementMode}
                abortRequest={abortRequest}
            />,
        );
    }

    return (
        <Container className="FilterList-Container">
            <Col className="FilterList-Col">{rows}</Col>
        </Container>
    );
};

interface FilterCheckBoxProp {
    abortRequest: () => void;
    id: number;
    title: string;
    filterType: string;
    loadManagementMode?: boolean;
}

const FilterCheckBox = ({
    abortRequest,
    id,
    title,
    filterType,
    loadManagementMode = false,
}: FilterCheckBoxProp): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const checkbox: React.Ref<HTMLInputElement> = React.createRef();

    // TODO: Extract this method's logic as not to violate DRY (8/6/19) - BS
    const isJobLoadsFilterChecked = (): boolean => {
        const {
            loadList: { filters },
        } = state;

        if (filterType === 'Proppant') {
            const found = filters.proppantIds.find((proppantId): number | undefined => {
                if (proppantId === id) return proppantId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Loading Facility') {
            const found = filters.lofaIds.find((lofaId): number | undefined => {
                if (lofaId === id) return lofaId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Driver') {
            const found = filters.driverIds.find((driverId): number | undefined => {
                if (driverId === id) return driverId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Status') {
            const found = filters.statuses.find((status): number | undefined => {
                if (status === id) return status;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Carrier') {
            const found = filters.carrierIds.find((carrier): number | undefined => {
                if (carrier === id) return carrier;

                return undefined;
            });

            return !!found;
        }

        return false;
    };

    const isLoadManagementFilterChecked = (): boolean => {
        const {
            loadManagementList: { filters },
        } = state;

        if (filterType === 'Proppant') {
            const found = filters.proppantIds.find((proppantId): number | undefined => {
                if (proppantId === id) return proppantId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Loading Facility') {
            const found = filters.lofaIds.find((lofaId): number | undefined => {
                if (lofaId === id) return lofaId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Driver') {
            const found = filters.driverIds.find((driverId): number | undefined => {
                if (driverId === id) return driverId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Load Status') {
            const found = filters.statuses.find((status): number | undefined => {
                if (status === id) return status;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Status') {
            const found = filters.statuses.find((status): number | undefined => {
                if (status === id) return status;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Carrier') {
            const found = filters.carrierIds.find((carrier): number | undefined => {
                if (carrier === id) return carrier;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Region') {
            const found = filters.regionIds.find((regionId): number | undefined => {
                if (regionId === id) return regionId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'District') {
            const found = filters.districtIds.find((disrictId): number | undefined => {
                if (disrictId === id) return disrictId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Customer') {
            const found = filters.customerIds.find((customerId): number | undefined => {
                if (customerId === id) return customerId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Job Status') {
            const found = filters.jobStatuses.find((jobStatusId): number | undefined => {
                if (jobStatusId === id) return jobStatusId;

                return undefined;
            });

            return !!found;
        }
        if (filterType === 'Job') {
            const found = filters.jobIds.find((jobId): number | undefined => {
                if (jobId === id) return jobId;

                return undefined;
            });

            return !!found;
        }

        return false;
    };

    const isFilterChecked = (): boolean => {
        if (loadManagementMode) {
            return isLoadManagementFilterChecked();
        } else {
            return isJobLoadsFilterChecked();
        }
    };

    const updateFilterFromCheckbox = (): void => {
        const isSelected = checkbox && checkbox.current ? checkbox.current.checked : false;
        if (loadManagementMode) {
            dispatch(LoadManagementListActions.setFiltersForLoads(filterType, id, isSelected));
        } else {
            dispatch(LoadListActions.setFiltersForLoads(filterType, id, isSelected));
        }
    };

    return (
        <Row key={id} className="FilterCheckBox-Container">
            <input
                key={filterType + '_' + id}
                value={id}
                type="checkbox"
                className="FilterCheckBox-Checkbox"
                ref={checkbox}
                checked={isFilterChecked()}
                onChange={(): void => {
                    abortRequest();
                    updateFilterFromCheckbox();
                }}
            />
            <span className="FilterCheckBox-Title">{title}</span>
        </Row>
    );
};

export { Collection, List };
