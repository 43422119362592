import gql from 'graphql-tag';

export default gql`
    query GetLoadAllocation($input: GetLoadAllocationInput) {
        getLoadAllocation(input: $input) {
            id
            jobName
            lofaId
            lofaName
            proppantId
            proppantName
            sandPO
            type
            weightonDate1
            weightonDate2
            weightonDate3
            weightonDate4
            weightonDate5
            weightonDate6
            weightonDate7
            weightonDate8
            weightonDate9
            weightonDate10
            weightonDate11
            weightonDate12
            weightonDate13
            weightonDate14
        }
    }
`;
