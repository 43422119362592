import gql from 'graphql-tag';

export default gql`
    query GetAllJobsWithFiltersAndSearch($input: GetAllJobsInput, $offset: Int) {
        getAllJobsWithFiltersAndSearch(input: $input, offset: $offset) {
            jobId
            jobName
            jdeNumber
            region
            district
            customerName
            startDate
            endDate
            estimatedEndDate
            status
        }
    }
`;
