import React from 'react';
// import { GetLoadList_getLoadListByJobId as LoadType } from '../../../types/schemaTypes';
import { NoResult, useLazyLoading, Loading } from '../../_common';
import LoadItem from '../../ListItem/LoadItem';
import './LoadList.scss';

interface LoadListProps {
    abortRequest: () => void;
    loadList: any[];
    isLoading: boolean;
    isEndOfResults: boolean;
    entries: (any | null)[];
    onLoadMore: () => void;
    refetch: () => void;
}

const LoadList = ({
    abortRequest,
    loadList,
    isLoading,
    isEndOfResults,
    onLoadMore,
    refetch,
}: LoadListProps): JSX.Element => {
    const { handleScroll } = useLazyLoading('loadList', isLoading, isEndOfResults, onLoadMore);

    if (isLoading) {
        return (
            <div className="LoadList-Loading">
                <Loading />
            </div>
        );
    }

    return (
        <div className="JobLoads-LoadList" id="loadList" onScroll={handleScroll}>
            {loadList && loadList.length === 0 ? (
                <NoResult isModal={false} />
            ) : (
                loadList &&
                loadList.map(
                    (load): JSX.Element => {
                        return <LoadItem abortRequest={abortRequest} load={load} key={load.loadId} refetch={refetch} />;
                    },
                )
            )}
        </div>
    );
};

export default LoadList;
