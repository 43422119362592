import gql from 'graphql-tag';

export default gql`
    query GetAllDriversByCarriers($input: CarrierListInput) {
        getAllDriversByCarriers(input: $input) {
            id
            name
        }
    }
`;
