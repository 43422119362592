import { useQuery } from '@apollo/client';
import 'material-design-icons/iconfont/material-icons.css';
import React, { useEffect, useState } from 'react';
import Modal from 'react-bootstrap/Modal';
import { GET_USERS_CHECK_EMAIL, GET_USERAUDIT, GET_B2C_USER_FILTERED } from '../../../graphql/client/queries';
import { useMutation } from '@apollo/client';
import { SAVE_B2C_USER } from '../../../graphql/client/mutations';
import {
    GetUsersCheckEmail,
    GetB2CUsers_getB2CUsers as B2CUser,
    GetUsersAudit_getUsersAudit as UserAuditType,
    SaveB2CUser,
} from '../../../types/schemaTypes';
import { CustomButton, Loading } from '../../_common';
import SearchableDropdown from '../../_common/SearchableDropdown/SearchableDropdown';
import { Textfield } from '../../_common/Textfield/Textfield';
import { CarrierOption, ModalForm } from '../interface';
import './AddEditUserModal.scss';
import moment from 'moment';
import { Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface Option {
    id: number | string;
    name: string | number;
}

interface UserAudits {
    id: number;
    userId: number | null;
    auditDate: string | null;
    auditUserName: string | null;
    nameBefore: string | null | undefined;
    emailAddressBefore: string | null | undefined;
    isActiveBefore: boolean | null | undefined;
    roleIdBefore: number | null | undefined;
    nameAfter: string | null | undefined;
    emailAddressAfter: string | null | undefined;
    isActiveAfter: boolean | null | undefined;
    roleIdAfter: number | null | undefined;
}
// interface UserData {
//     name: string | null;
//     emailAddress: string | null;
//     isActive: boolean | null;
//     isDeleted: boolean | null;
//     roleId: number | null;
// }
interface AddEditUserModalProps {
    modalForm: ModalForm;
    cpOptions: Option[];
    options: Option[];
    carrierOptions: Option[];
    selectedUser?: B2CUser;
    isOperationUser: boolean;
    onClickHide: () => void;
    onUserSaved: () => void;
    checkIfPassword: () => void;
    setIsResetPassword: React.Dispatch<React.SetStateAction<boolean>>;
    show: boolean;
    userId: number;
    errorMessage?: string;
    setErrorMessage: React.Dispatch<React.SetStateAction<string>>;
}

const AddEditUserModal = ({
    show,
    options,
    cpOptions,
    carrierOptions,
    onClickHide,
    onUserSaved,
    checkIfPassword,
    setIsResetPassword,
    modalForm,
    selectedUser,
    userId,
    errorMessage,
    setErrorMessage,
    isOperationUser,
}: AddEditUserModalProps): JSX.Element => {
    const [selectedUserId, setSelectedUserId] = useState(0);
    const [selectedFirstName, setSelectedFirstName] = useState('');
    const [selectedLastName, setSelectedLastName] = useState('');
    const [selectedEmailAddress, setSelectedEmailAddress] = useState('');
    const [selectedCustomerParent, setSelectedCustomerParent] = useState(0);
    const [selectedCarrier, setSelectedCarrier] = useState(0);
    const [selectedRole, setSelectedRole] = useState(0);
    const [isSelectedRoleCustomer, setIsSelectedRoleCustomer] = useState(false);
    const [selectedStatus, setSelectedStatus] = useState(true);
    const [enableResetPassword, setEnableResetPassword] = useState(false);
    const [isUssilicaUser, setIsUssilicaUser] = useState(false);
    const [isSelectedRoleCarrier, setIsSelectedRoleCarrier] = useState(false);
    const isEditForm = modalForm === ModalForm.EDIT_USER;

    const [emailExists, setEmailExists] = useState(false);
    const [emailErrorMessage, setEmailErrorMessage] = useState('');
    const userAuditHistory: UserAudits[] = [];
    const intUserOptions = options.filter(t => t.id === 1 || t.id === 2 || t.id === 6);
    const extUserOptions = options.filter(t => t.id === 3 || t.id === 5);
    const operationIntUserOptions = options.filter(t => t.id === 6);
    const operationExtUserOptions = options.filter(t => t.id === 5);
    const searchIcon = <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'search' }} />;
    const [isCarrierActive, setIsCarrierActive] = useState(true);

    useEffect(() => {
        if (selectedUser) {
            setSelectedUserId(selectedUser.userId ? selectedUser.userId : 0);
            setSelectedFirstName(selectedUser.firstName ? selectedUser.firstName : ' ');
            setSelectedLastName(selectedUser.lastName ? selectedUser.lastName : ' ');
            setSelectedEmailAddress(selectedUser.emailAddress ? selectedUser.emailAddress : ' ');
            setSelectedRole(selectedUser.roleId ? selectedUser.roleId : 0);
            setIsSelectedRoleCustomer(selectedUser.roleName?.trim().toLowerCase() === 'customer' ? true : false);
            setIsSelectedRoleCarrier(selectedUser.roleName?.trim().toLowerCase() === 'carrier' ? true : false);
            setSelectedCustomerParent(selectedUser.customerParentId ? selectedUser.customerParentId : 0);
            setSelectedCarrier(selectedUser.driverCompanyId ? selectedUser.driverCompanyId : 0);
            setSelectedStatus(selectedUser.isActive ? true : false);
            setEnableResetPassword(selectedUser.emailAddress?.includes('@ussilica.com') ? false : true);
            setIsUssilicaUser(selectedUser.emailAddress?.includes('@ussilica.com') ? true : false);

            const id = selectedUser && selectedUser.driverCompanyId ? selectedUser.driverCompanyId.toString() : '';
            if (id !== '') {
                const numId = parseInt(id);
                const filteredCarrierName = carrierOptions.filter((item: Option) => item.id === numId);
                if (filteredCarrierName.length === 0) {
                    setIsCarrierActive(false);
                }
            }
        }
    }, [selectedUser]);

    useQuery<GetUsersCheckEmail>(GET_USERS_CHECK_EMAIL, {
        variables: { input: { emailAddress: selectedEmailAddress } },
        fetchPolicy: 'no-cache',
        onCompleted(data) {
            const emailExists =
                data!.getUsersCheckEmail!.emailExists == null ? false : data!.getUsersCheckEmail!.emailExists;

            const checkEmailExists =
                (isEditForm && selectedEmailAddress == selectedUser?.emailAddress) || selectedEmailAddress.length == 0
                    ? false
                    : emailExists;

            setEmailExists(checkEmailExists);

            if (checkEmailExists) setEmailErrorMessage('This email address is already in use');
            else setEmailErrorMessage('');
        },
    });
    const variables = {
        userId,
    };

    const { data: userAuditReferenceData, loading: userAuditLoading, error: userAuditError } = useQuery(GET_USERAUDIT, {
        variables,
        fetchPolicy: 'network-only',
    });

    if (userAuditReferenceData && userAuditReferenceData.getUsersAudit) {
        userAuditReferenceData.getUsersAudit.forEach((element: UserAuditType) => {
            if (element.userId !== null) {
                userAuditHistory.push({
                    id: element.id,
                    userId: element.userId,
                    auditUserName: element.auditUserName,
                    auditDate: element.auditDate,
                    nameBefore: element.valuesBefore?.name,
                    nameAfter: element.valuesAfter?.name,
                    isActiveBefore: element.valuesBefore?.isActive,
                    isActiveAfter: element.valuesAfter?.isActive,
                    emailAddressBefore: element.valuesBefore?.emailAddress,
                    emailAddressAfter: element.valuesAfter?.emailAddress,
                    roleIdBefore: element.valuesBefore?.roleId,
                    roleIdAfter: element.valuesAfter?.roleId,
                });
            }
        });
    }
    const clearState = (): void => {
        setSelectedUserId(0);
        setSelectedFirstName('');
        setSelectedLastName('');
        setSelectedEmailAddress('');
        setSelectedRole(0);
        setIsSelectedRoleCustomer(false);
        setSelectedCustomerParent(0);
        setSelectedCarrier(0);
        setSelectedStatus(true);
        setEmailExists(false);
        setIsUssilicaUser(false);
        setEnableResetPassword(false);
    };

    const onCompletedSaveUser = (data: SaveB2CUser): void => {
        checkIfPassword();
        if (data.saveB2CUser === null) return;
        const saveUserErrors = data.saveB2CUser.errors;
        if (saveUserErrors && saveUserErrors.length > 0) {
            if (
                saveUserErrors[0]?.includes(
                    'Another object with the same value for property userPrincipalName already exists',
                )
            ) {
                setEmailExists(true);
                setEmailErrorMessage('This email address is already in use');
            } else if (
                saveUserErrors[0]?.includes(
                    'does not exist or one of its queried reference-property objects are not present',
                )
            ) {
                setEmailExists(true);
                setEmailErrorMessage('This account does not exist');
            } else {
                setErrorMessage(saveUserErrors[0] || '');
            }
        } else {
            clearState();
            onUserSaved();
        }
    };

    const variablesGetUser = {
        input: {
            searchString: '',
            isOperationUserView: isOperationUser ? true : false,
        },
    };

    const [saveB2CUser, { loading: saveB2CUserLoading }] = useMutation(SAVE_B2C_USER, {
        onCompleted: onCompletedSaveUser,
        refetchQueries: () => {
            return [
                {
                    query: GET_B2C_USER_FILTERED,
                    variables: variablesGetUser,
                },
            ];
        },
    });

    const handleOnSave = async (
        userId: number,
        firstName: string,
        lastName: string,
        email: string,
        roleId: number,
        customerParentId: number,
        status: boolean,
        resetPassword: boolean,
        driverCompanyId: number,
    ): Promise<void> => {
        if (selectedUser) {

            const selectedUserMirror  = {...selectedUser}
            selectedUserMirror.userId = userId;
            selectedUserMirror.firstName = firstName;
            selectedUserMirror.lastName = lastName;
            selectedUserMirror.emailAddress = email;
            selectedUserMirror.roleId = roleId;
            selectedUserMirror.roleName = '';
            selectedUserMirror.customerParentId = customerParentId;
            selectedUserMirror.driverCompanyId = driverCompanyId;
            selectedUserMirror.isActive = status;
            
            selectedUser = {...selectedUserMirror}
        }

        const variables = {
            input: {
                userId,
                firstName,
                lastName,
                emailAddress: email,
                roleId,
                customerParentId,
                isActive: status,
                resetPassword,
                driverCompanyId,
            },
        };
        if (variables.input.resetPassword == true) {
            setIsResetPassword(true);
        }
        await saveB2CUser({ variables });
    };

    const onClickSave = (): void => {
        if (!emailExists) {
            handleOnSave(
                selectedUserId,
                selectedFirstName,
                selectedLastName,
                selectedEmailAddress,
                selectedRole,
                selectedCustomerParent,
                selectedStatus,
                false,
                selectedCarrier,
            );
        }
    };
    const onReset = (): void => {
        if (!emailExists) {
            handleOnSave(
                selectedUserId,
                selectedFirstName,
                selectedLastName,
                selectedEmailAddress,
                selectedRole,
                selectedCustomerParent,
                selectedStatus,
                true,
                selectedCarrier,
            );
        }
    };

    const onCancel = (): void => {
        clearState();
        onClickHide();
    };

    const validateEmail = (email: string): boolean => {
        const emailRegex = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+\.[A-Za-z]+$/;
        //const emailRegex = /\S+@\ussilica|sandboxlogistics+\.\S+/;
        const isEmailValid = emailRegex.test(email.toString());
        return isEmailValid;
    };

    const setSaveButtonStatus = (): boolean => {
        if (emailExists) return true;

        const isNameValid = selectedFirstName.trim().length > 0 ? true : false;
        const isLastNameValid = selectedLastName.trim().length > 0 ? true : false;
        const isEmailValid = validateEmail(selectedEmailAddress);
        const isRoleValid = selectedRole != 0 ? true : false;
        const isCustomerParentValid = selectedCustomerParent != 0 ? true : !isSelectedRoleCustomer ? true : false;
        const isCarrierValid = selectedCarrier != 0 ? true : !isSelectedRoleCarrier ? true : false;
        const isStatusValid = true;
        const isFormValid =
            isNameValid &&
            isLastNameValid &&
            isEmailValid &&
            isRoleValid &&
            isCustomerParentValid &&
            isCarrierValid &&
            isStatusValid;

        return !isFormValid;
    };

    const changeFirstName = (event: any): void => {
        setSelectedFirstName(event.target.value);
    };

    const changeLastName = (event: any): void => {
        setSelectedLastName(event.target.value);
    };

    const changeEmail = (value: any): void => {
        if (errorMessage) {
            setErrorMessage('');
        }
        const emailAddress = value ? value : '';
        setSelectedEmailAddress(emailAddress.trim());
    };

    const checkExternalUser = (value: any): void => {
        const emailRegex = /\S+@\ussilica+\.\S+/;
        const isUssilicaMail = emailRegex.test(value.toLowerCase().toString());
        setIsUssilicaUser(isUssilicaMail);
    };

    const getCustomerParentName = (): string | number | null => {
        const id = selectedUser && selectedUser.customerParentId ? selectedUser.customerParentId.toString() : '';
        if (id == '') return null;
        const numId = parseInt(id);
        const filteredCustomerName = cpOptions.filter((item: Option) => item.id === numId);
        const role = filteredCustomerName[0].name ? filteredCustomerName[0].name : '';
        return role;
    };

    const getCarrierName = (): string | number | null => {
        const id = selectedUser && selectedUser.driverCompanyId ? selectedUser.driverCompanyId.toString() : '';
        if (id == '') return null;
        const numId = parseInt(id);
        const filteredCarrierName = carrierOptions.filter((item: Option) => item.id === numId);
        let carrier: any = '';
        if (filteredCarrierName.length > 0) {
            carrier = filteredCarrierName[0].name ? filteredCarrierName[0].name : '';
        } else {
            carrier = selectedUser?.driverCompanyName;
        }
        return carrier;
    };

    const getAuditRoleName = (roleId: number): string | number | null => {
        const filteredRole = options.filter((item: Option) => item.id === roleId);
        const role = filteredRole[0].name;
        return role;
    };

    const changeCustomerParent = (value: any): void => {
        const id = parseInt(value);
        setSelectedCustomerParent(id);
    };

    const changeCarrier = (value: any): void => {
        const id = parseInt(value);
        setSelectedCarrier(id);
    };

    const changeRole = (value: any): void => {
        const id = parseInt(value);
        setSelectedRole(id);
        const roleName = getAuditRoleName(id);
        if (
            roleName
                ?.toString()
                .trim()
                .toLowerCase() == 'customer'
        ) {
            setIsSelectedRoleCustomer(true);
        } else {
            setSelectedCustomerParent(0);
            setIsSelectedRoleCustomer(false);
        }

        if (
            roleName
                ?.toString()
                .trim()
                .toLowerCase() == 'carrier'
        ) {
            setIsSelectedRoleCarrier(true);
        } else {
            setSelectedCarrier(0);
            setIsSelectedRoleCarrier(false);
        }
    };

    const changeStatus = (event: any): void => {
        const boolValue = JSON.parse(event.target.value);
        setSelectedStatus(boolValue);
    };

    const getRoleName = (): string | number | null => {
        const roleId = selectedRole;
        if (roleId === 0) return null;

        let roles: any = [];
        if (isOperationUser) {
            roles = isUssilicaUser ? operationIntUserOptions : operationExtUserOptions;
        } else {
            roles = isUssilicaUser ? intUserOptions : extUserOptions;
        }

        const filteredRole = roles.filter((item: Option) => item.id === roleId);
        if (filteredRole.length > 0) {
            const role = filteredRole[0].name ? filteredRole[0].name : '';
            return role;
        } else {
            setSelectedRole(0);
            return '';
        }
    };

    return (
        <Modal show={show} onHide={onCancel} size="lg">
            <Modal.Header className="AddEditUserModal-Header">
                <Modal.Title className="AddEditUserModal-Header-Container">
                    <button className="material-icons AddEditUserModal-X-Button" onClick={onCancel}>
                        close
                    </button>
                    <div className="AddEditUserModal-Title">{isEditForm ? 'Edit User' : 'Create New User'}</div>
                    <div className="AddEditUserModal-ErrorMessage">{errorMessage}</div>
                </Modal.Title>
            </Modal.Header>

            <Modal.Body>
                {saveB2CUserLoading && (
                    <div className="AddEditUserModal-SpinnerContainer">
                        <Loading />
                    </div>
                )}
                <div className="AddEditUserModal-Upper-TextField-Container">
                    <div className="AddEditUserModal-Textfield">
                        <Textfield
                            label="FirstName"
                            value={selectedUser !== undefined ? selectedUser.firstName : ''}
                            onChange={(event): void => changeFirstName(event)}
                            maxLength={64}
                        />
                    </div>
                    <div className="AddEditUserModal-Textfield">
                        <Textfield
                            label="LastName"
                            value={selectedUser !== undefined ? selectedUser.lastName : ''}
                            onChange={(event): void => changeLastName(event)}
                            maxLength={64}
                        />
                    </div>
                </div>
                <div className="AddEditUserModal-Down-TextField-Container">
                    <div className="AddEditUserModal-Textfield">
                        <Textfield
                            label="Email"
                            error={emailExists == null ? false : emailExists}
                            helperText={emailErrorMessage}
                            value={selectedUser !== undefined ? selectedUser.emailAddress : ''}
                            disabled={isEditForm}
                            onChange={event => {
                                const value = event.currentTarget.value;
                                if (value) checkExternalUser(value);
                                changeEmail(value);
                            }}
                            maxLength={64}
                        />
                    </div>
                    <div className="AddEditUserModal-Textfield">
                        <SearchableDropdown
                            dropdownOptions={
                                isOperationUser
                                    ? isUssilicaUser
                                        ? operationIntUserOptions
                                        : operationExtUserOptions
                                    : isUssilicaUser
                                    ? intUserOptions
                                    : extUserOptions
                            }
                            id="role"
                            label="Role"
                            onSelect={(eventKey: string | null) => {
                                changeRole(eventKey);
                            }}
                            value={getRoleName()}
                            disabled={isCarrierActive ? false : true}
                        />
                    </div>
                </div>
                <Col md={11} className="AddEditUserModal-Dropdownfield custom-css">
                    {isSelectedRoleCustomer && (
                        <SearchableDropdown
                            autoCompleteEnabled={true}
                            dropdownOptions={cpOptions}
                            id="customerparent"
                            label="*Customer Parent"
                            onSelect={(eventKey: string | null) => {
                                changeCustomerParent(eventKey);
                            }}
                            value={getCustomerParentName()}
                            endIcon={searchIcon}
                        />
                    )}

                    {isSelectedRoleCarrier && (
                        <SearchableDropdown
                            disabled={isCarrierActive ? false : true}
                            autoCompleteEnabled={true}
                            dropdownOptions={carrierOptions}
                            id="carrier"
                            label="*Carrier"
                            onSelect={(eventKey: string | null) => {
                                changeCarrier(eventKey);
                            }}
                            value={getCarrierName()}
                            endIcon={searchIcon}
                        />
                    )}
                </Col>

                <div className="AddEditUserModal-HasAccess-Container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="AddEditUserModal-HasAccess-Title">Has access to Operations Portal?</div>
                            <div className="AddEditUserModal-HasAccess-Radio-Container">
                                <div className="AddEditUserModal-Radio">
                                    <input
                                        className="AddEditUserModal-Radio-Input"
                                        type="radio"
                                        value="true"
                                        disabled={isCarrierActive ? false : true}
                                        checked={selectedStatus}
                                        onChange={(event): void => changeStatus(event)}
                                    />
                                    Yes
                                </div>
                                <div>
                                    <input
                                        className="AddEditUserModal-Radio-Input"
                                        type="radio"
                                        value="false"
                                        disabled={isCarrierActive ? false : true}
                                        checked={!selectedStatus}
                                        onChange={(event): void => changeStatus(event)}
                                    />
                                    No
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            {selectedUser && (
                                <div>
                                    <div className="DriverCompany-Header-Item justify-content-start">
                                        User Audit History
                                    </div>
                                    <div className="User-Audit-Grid-Container">
                                        {userAuditLoading && (
                                            <div className="spinnerContainer mt-4">
                                                <Loading />
                                            </div>
                                        )}
                                        {userAuditHistory && userAuditHistory.length > 0 ? (
                                            <div className="User-Audit-Grid User-Audit-Grid-Header">
                                                <div className="User-Audit-Grid-Header-Item justify-content-start">
                                                    Audit Date
                                                </div>
                                                <div className="User-Audit-Grid-Header-Item justify-content-start">
                                                    Audit User
                                                </div>
                                                <div className="User-Audit-Grid-Header-Item justify-content-start">
                                                    Name
                                                </div>
                                                <div className="User-Audit-Grid-Header-Item-Lg justify-content-start">
                                                    Email
                                                </div>
                                                <div className="User-Audit-Grid-Header-Item justify-content-start">
                                                    HasAccess
                                                </div>
                                                <div className="User-Audit-Grid-Header-Item justify-content-start">
                                                    Role
                                                </div>
                                            </div>
                                        ) : (
                                            !userAuditLoading && (
                                                <div className="NotResults-Container">
                                                    <div className="NoResult-Second-Title-Primary">
                                                        No Audit History Found
                                                    </div>
                                                </div>
                                            )
                                        )}

                                        {userAuditHistory &&
                                            userAuditHistory.map((userAudit: UserAudits): JSX.Element | undefined => {
                                                if (
                                                    userAudit !== null &&
                                                    (userAudit.nameBefore !== userAudit.nameAfter ||
                                                        userAudit.emailAddressBefore !== userAudit.emailAddressAfter ||
                                                        userAudit.isActiveBefore !== userAudit.isActiveAfter ||
                                                        userAudit.roleIdBefore !== userAudit.roleIdAfter)
                                                ) {
                                                    return (
                                                        <div
                                                            className="User-Audit-Grid User-Audit-Grid-Data"
                                                            key={userAudit.id}
                                                        >
                                                            <div className="User-Audit-Grid-Item justify-content-start">
                                                                {moment(userAudit.auditDate!).format('MM-DD-YYYY')}
                                                            </div>
                                                            <div className="User-Audit-Grid-Item justify-content-start">
                                                                {userAudit.auditUserName}
                                                            </div>
                                                            {userAudit.nameBefore !== userAudit.nameAfter && (
                                                                <div className="User-Audit-Grid-Item justify-content-start">
                                                                    {userAudit.nameBefore}
                                                                </div>
                                                            )}
                                                            {userAudit.nameBefore === userAudit.nameAfter && (
                                                                <div className="User-Audit-Grid-Item justify-content-start">
                                                                    -
                                                                </div>
                                                            )}
                                                            {userAudit.emailAddressBefore !==
                                                                userAudit.emailAddressAfter && (
                                                                <div className="User-Audit-Grid-Item-Lg justify-content-start">
                                                                    {userAudit.emailAddressBefore}
                                                                </div>
                                                            )}
                                                            {userAudit.emailAddressBefore ===
                                                                userAudit.emailAddressAfter && (
                                                                <div className="User-Audit-Grid-Item-Lg justify-content-start">
                                                                    -
                                                                </div>
                                                            )}
                                                            {userAudit.isActiveBefore !== userAudit.isActiveAfter && (
                                                                <div className="User-Audit-Grid-Item justify-content-start">
                                                                    {userAudit.isActiveBefore ? 'Active' : 'Inactive'}
                                                                </div>
                                                            )}
                                                            {userAudit.isActiveBefore === userAudit.isActiveAfter && (
                                                                <div className="User-Audit-Grid-Item justify-content-start">
                                                                    -
                                                                </div>
                                                            )}
                                                            {userAudit.roleIdBefore !== userAudit.roleIdAfter && (
                                                                <div className="User-Audit-Grid-Item justify-content-start">
                                                                    {getAuditRoleName(userAudit.roleIdBefore!)}
                                                                </div>
                                                            )}
                                                            {userAudit.roleIdBefore === userAudit.roleIdAfter && (
                                                                <div className="User-Audit-Grid-Item justify-content-start">
                                                                    -
                                                                </div>
                                                            )}
                                                        </div>
                                                    );
                                                }
                                                return undefined;
                                            })}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Modal.Body>

            <Modal.Footer className="AddEditUserModal-Footer">
                <CustomButton title="Cancel" isBordered onClick={onCancel}></CustomButton>
                <CustomButton
                    title="Save"
                    isDisabled={(errorMessage || saveB2CUserLoading ? true : setSaveButtonStatus()) || !isCarrierActive}
                    onClick={onClickSave}
                ></CustomButton>
                {enableResetPassword && (
                    <CustomButton
                        title="Reset Password"
                        isDisabled={
                            (errorMessage || saveB2CUserLoading ? true : setSaveButtonStatus()) || !isCarrierActive
                        }
                        onClick={onReset}
                    ></CustomButton>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default AddEditUserModal;
