import gql from 'graphql-tag';

export default gql`
    query GetUsersAudit($userId: Int) {
        getUsersAudit(userId: $userId) {
            id
            userId
            auditDate
            auditUserName
            valuesBefore {
                name
                emailAddress
                isActive
                isDeleted
                roleId
            }
            valuesAfter {
                name
                emailAddress
                isActive
                isDeleted
                roleId
            }
        }
    }
`;
