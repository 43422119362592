import './LoadHistory.scss';
import 'material-design-icons/iconfont/material-icons.css';
import React, { useState } from 'react';
import { Query } from '@apollo/client/react/components';
import { GET_LOAD_LOGGERS } from '../../../graphql/client/queries';
import { Error, Loading } from '../../_common';
import { GetLoadLoggers_getLoadLoggers as LoadLogger } from '../../../types/schemaTypes';
import LoadHistoryList from './LoadHistoryList';
import { useParams } from 'react-router-dom';

const LoadHistory = ({ match }: any): JSX.Element => {
    const [tabTitle, setTabTitle] = useState(document.title);
    const {loadId} = useParams()
    const loadIdVal:any = loadId;
    const loadIdParam = loadIdVal;
    const [searchPhrase, setSearchPhrase] = useState('');
    const variables = {
        input: {
            loadId: +loadIdParam,
            search: '',
        },
    };

    const goBackToLoadProfile = (): void => {
        window.history.back();
    };

    return (
        <Query query={GET_LOAD_LOGGERS} variables={variables} fetchPolicy="network-only" pollInterval={60000}>
            {({ error, data, loading }: any): JSX.Element | null => {
                if (loading) {
                    return <Loading />;
                }

                if (error) {
                    return <Error error={error} />;
                }

                let loadLoggers: LoadLogger[] = [];

                if (data && data.getLoadLoggers) {
                    loadLoggers = data.getLoadLoggers;
                }

                const onSearch = (event: any): void => {
                    const searchPhrase = event.target.value;
                    setSearchPhrase(searchPhrase);
                };
                document.title = localStorage.getItem('loadHistory-JobName') + ':' + loadIdParam;
                setTabTitle(document.title);
                return (
                    <div className="LoadHistory-Container">
                        <div className="LoadHistory-Header">
                            <div className="LoadHistory-SearchBarContainer">
                                <i className="material-icons LoadHistory-SearchBarIcon">search</i>
                                <input
                                    id="textSearch"
                                    type="text"
                                    placeholder="Search"
                                    className="LoadHistory-SearchBarInput"
                                    onChange={onSearch}
                                />
                            </div>
                            <div className="LoadHistory-HeaderTitle">
                                {`Load #: `}
                                <span className="LoadHistory-LoadNumber">{loadIdParam}</span>
                            </div>
                            <div className="LoadHistory-ToggleButtonsContainer">
                                <div className="LoadHistory-LeftToggleButton" onClick={goBackToLoadProfile}>
                                    <p className="LoadHistory-ButtonText">Load Details</p>
                                </div>
                                <div className="LoadHistory-RightToggleButton LoadHistory-ActiveButton">
                                    <p className="LoadHistory-ButtonText">Load History</p>
                                </div>
                            </div>
                        </div>
                        <LoadHistoryList loadLoggers={loadLoggers} searchPhrase={searchPhrase} />
                    </div>
                );
            }}
        </Query>
    );
};

export default LoadHistory;
