import React from 'react';
import Modal from 'react-bootstrap/Modal';
import 'material-design-icons/iconfont/material-icons.css';
import './FullLoadImage.scss';

interface FullLoadImageProps {
    onClickShow: () => void;
    onClickHide: () => void;
    show: boolean;
    image: string;
}

const FullLoadImage = ({ onClickHide, show, image }: FullLoadImageProps): JSX.Element => {
    return (
        <Modal size="lg" show={show} onHide={onClickHide}>
            <Modal.Header closeButton />
            <img className="LoadImage-Full" src={image} onClick={onClickHide} alt="" />
        </Modal>
    );
};

export default FullLoadImage;
