export enum LoadManagementFilterTypeEnum {
    PROPPANT = 'Proppant',
    LOADING_FACILITY = 'Loading Facility',
    DRIVER = 'Driver',
    CARRIER = 'Carrier',
    LOAD_STATUS = 'Load Status',
    STATUS = 'Status',
    REGION = 'Region',
    DISTRICT = 'District',
    CUSTOMER = 'Customer',
    JOB_STATUS = 'Job Status',
    JOB = 'Job',
}
