import { Reducer } from 'react';
import { Actions } from '../../actions/jobProfile';
import { Action, JobProfileState } from '../../types';
import loadIcon from '../../../assets/loadIcon.png';
import lofaIcon from '../../../assets/lofaIcon.png';
import pumpIcon from '../../../assets/pumpIcon.png';
import notesIcon from '../../../assets/loadNotes.png';

const initialJobProfileState: JobProfileState = {
    tabs: [
        {
            key: 1,
            displayName: 'Load Summary',
            icon: loadIcon,
            isSelected: true,
        },
        {
            key: 2,
            displayName: 'LOFA Schedule',
            icon: lofaIcon,
            isSelected: false,
        },
        {
            key: 3,
            displayName: 'Pump Schedule Summary',
            icon: pumpIcon,
            isSelected: false,
        },
    ],
};

const setSelectedTab = (payload: number, state: JobProfileState): JobProfileState => {
    return {
        ...state,
        tabs: state.tabs.map((tab): any => {
            const isSelected = tab.key === payload;
            return { ...tab, isSelected: isSelected };
        }),
    };
};

const jobProfileReducer: Reducer<JobProfileState, Action> = (state, action): JobProfileState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.SET_SELECTED_TAB:
            newState = setSelectedTab(action.payload, state);
            break;
    }

    return newState;
};

export { initialJobProfileState, jobProfileReducer };
