import { Action } from '../../types';

export enum Actions {
    FILTER_BY_SEARCHSTRING = 'DRIVERLIST_FILTER_SEARCHSTRING',
    FILTER_BY_COMPLAINT = 'DRIVERLIST_FILTER_BY_COMPLAINT',
    FILTER_BY_STATUS = 'DRIVERLIST_FILTER_BY_STATUS',
    FILTER_BY_CARRIER = 'DRIVERLIST_FILTER_BY_CARRIER',
    FILTER_BY_REGION = 'DRIVERLIST_FILTER_BY_REGION',
    FILTER_BY_DISTRICT = 'DRIVERLIST_FILTER_BY_DISTRICT',
    UPDATE_FILTERS_SELECT_ALL = 'UPDATE_FILTERS_SELECT_ALL',
    UPDATE_FILTERS = 'UPDATE_FILTERS',
}

export const setFilterValue = (value: any, actionName: any): Action => {
    return {
        type: actionName,
        payload: value,
        error: null,
    };
};

export const setSelectedFilters = (filterType: string, id: number, isSelected: boolean): Action => {
    return {
        type: Actions.UPDATE_FILTERS,
        payload: {
            filterType,
            id,
            isSelected,
        },
        error: null,
    };
};

export const setSelectAllFilters = (itemIds: number[], filterType: string): Action => {
    return {
        type: Actions.UPDATE_FILTERS_SELECT_ALL,
        payload: {
            filterType,
            itemIds,
        },
        error: null,
    };
};
