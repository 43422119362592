import gql from 'graphql-tag';

export default gql`
    query GetMapActiveData {
        getMapActiveData {
            maps {
                wellName
                loadId
                wellLat
                wellLong
                lofaLat
                lofaLong
                loadStatus
                regionName
                milesFromWell
                jobId
                driverLat
                driverLong
                lofaName
                proppantName
                driverName
                companyName
                lofaId
                driverId
                driverLastUpdated
                averageLoadTime
                regionId
                carrierId
                proppantId
                averageUnloadTime
                cellPhone
                customerId
                atLofaIn
                atLofaOut
                avgDriveTimeLofaToWell
                driveTimeToWellSoFar
            }
            onshiftdrivers {
                driverId
                driverName
                companyName
                cellPhone
                driverLat
                driverLong
                driverLastUpdated
                carrierId
            }
        }
    }
`;
