import './LoadHistoryList.scss';
import 'material-design-icons/iconfont/material-icons.css';
import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import LoadLogItem from '../LoadLogItem';
import { GetLoadLoggers_getLoadLoggers as LoadLogger } from '../../../../types/schemaTypes';

interface LoadHistoryListProps {
    loadLoggers: LoadLogger[];
    searchPhrase: string;
}

const LoadHistoryList = ({ loadLoggers, searchPhrase }: LoadHistoryListProps): JSX.Element => {
    const [filteredLoadLoggers, setFilteredLoadLoggers] = useState(loadLoggers);

    useEffect(() => {
        const result = loadLoggers.filter((load: LoadLogger) => {
            return (
                load.message.toLowerCase().includes(searchPhrase.toLowerCase()) ||
                (load.creatorName && load.creatorName.toLowerCase().includes(searchPhrase.toLowerCase())) ||
                (load.systemType && load.systemType.toLowerCase().includes(searchPhrase.toLowerCase()))
            );
        });
        setFilteredLoadLoggers(result);
    }, [searchPhrase]);

    return (
        <div className="LoadHistoryList-ListContainer">
            <div className="LoadHistoryList-RowContainer LoadHistory-LoadHistoryList">
                <Col md={3} className="LoadHistoryList-HeaderColumn">
                    Date
                </Col>
                <Col md={5} className="LoadHistoryList-HeaderColumn">
                    Log
                </Col>
                <Col md={2} className="LoadHistoryList-HeaderColumn">
                    User/Driver
                </Col>
                <Col md={2} className="LoadHistoryList-HeaderColumn">
                    System Type
                </Col>
            </div>
            {filteredLoadLoggers.map((item, index) => (
                <LoadLogItem key={item.id} loadLogItem={item} index={index} />
            ))}
        </div>
    );
};

export default LoadHistoryList;
