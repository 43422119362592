import gql from 'graphql-tag';

export default gql`
    query GetJobProfileSummary($jobId: Int) {
        getJobProfileSummary(jobId: $jobId) {
            totalBoxesRemaining
            totalBoxesLoaded
            averageLoadPerBox
        }
    }
`;
