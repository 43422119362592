export interface RoutesObject {
    title: string;
    route: string;
    type?: string;
}

export interface RoutesInterface {
    HOME: RoutesObject;
    JOB_MANAGEMENT: RoutesObject;
    JOB_PROFILE_WITH_ID: RoutesObject;
    REPORTING: RoutesObject;
    DRIVER_REPORT: RoutesObject;
    POWER_BI_REPORT: RoutesObject;
    SYSTEM_ADMIN_USERS: RoutesObject;
    SYSTEM_ADMIN_PERMISSIONS: RoutesObject;
    SYSTEM_ADMIN_LOFAS: RoutesObject;
    SYSTEM_ADMIN_CREWS: RoutesObject;
    SYSTEM_ADMIN_CUSTOMERS: RoutesObject;
    SYSTEM_ADMIN_CUSTOMER_PARENTS: RoutesObject;
    SYSTEM_ADMIN_PROPANTS: RoutesObject;
    SYSTEM_ADMIN_DRIVERS: RoutesObject;
    SYSTEM_ADMIN_CARRIERS: RoutesObject;
    SYSTEM_ADMIN: RoutesObject;
    SYSTEM_ADMIN_SETTINGS: RoutesObject;
    EMAIL: RoutesObject;
    PROFILE: RoutesObject;
    CALLBACK: RoutesObject;
    SILENT_RENEW: RoutesObject;
    JOB_PROFILE: RoutesObject;
    JOB_LOADS: RoutesObject;
    JOB_LOADS_WITH_ID: RoutesObject;
    LOAD_PROFILE: RoutesObject;
    LOAD_PROFILE_WITH_ID: RoutesObject;
    CHANGE_PASSWORD: RoutesObject;
    LOAD_HISTORY: RoutesObject;
    LOAD_HISTORY_WITH_ID: RoutesObject;
    JOB_ACTIVITY: RoutesObject;
    JOB_ACTIVITY_WITH_ID: RoutesObject;
    DAILY_SAND_ALLOCATIONS: RoutesObject;
    DAILY_SAND_ALLOCATIONS_WITH_ID: RoutesObject;
    DASHBOARD: RoutesObject;
    LOAD_MANAGEMENT: RoutesObject;
    CARRIERDASHBOARD: RoutesObject;
    DRIVERS: RoutesObject;
    CHASSIS_CHECKOUT: RoutesObject;
}

export const Routes: RoutesInterface = {
    HOME: {
        title: 'Home',
        route: '/',
    },
    DASHBOARD: {
        title: 'Dashboard',
        route: '/dashboard',
    },
    CARRIERDASHBOARD: {
        title: 'Carrier',
        route: '/carrier-dashboard',
    },
    DRIVERS: {
        title: 'Drivers',
        route: '/drivers',
    },
    JOB_MANAGEMENT: {
        title: 'Job Management',
        route: '/job',
    },
    JOB_PROFILE_WITH_ID: {
        title: 'Job Profile',
        route: '/job_profile/:jobId',
    },
    REPORTING: {
        title: 'Reporting',
        route: '/reporting',
    },
    DRIVER_REPORT: {
        title: 'Driver Report',
        route: '/driver_report',
    },
    POWER_BI_REPORT: {
        title: 'Load Report',
        route: '/sandbox_bi',
    },
    SYSTEM_ADMIN: {
        title: 'System Admin',
        route: '/system_admin',
    },
    SYSTEM_ADMIN_USERS: {
        title: 'Users',
        route: '/system_admin_users',
    },
    SYSTEM_ADMIN_PERMISSIONS: {
        title: 'Permissions',
        route: '/system_admin_permissions',
    },
    SYSTEM_ADMIN_LOFAS: {
        title: 'Lofas',
        route: '/system_admin_lofas',
    },
    SYSTEM_ADMIN_CREWS: {
        title: 'Crews',
        route: '/system_admin_crews',
    },
    SYSTEM_ADMIN_CUSTOMERS: {
        title: 'Customers',
        route: '/system_admin_customers',
    },
    SYSTEM_ADMIN_CUSTOMER_PARENTS: {
        title: 'Customer Parents',
        route: '/system_admin_customerparents',
    },
    SYSTEM_ADMIN_PROPANTS: {
        title: 'Proppants',
        route: '/system_admin_proppants',
    },
    SYSTEM_ADMIN_DRIVERS: {
        title: 'Drivers',
        route: '/system_admin_drivers',
    },
    SYSTEM_ADMIN_CARRIERS: {
        title: 'Carriers',
        route: '/system_admin_carriers',
    },
    SYSTEM_ADMIN_SETTINGS: {
        title: 'Settings',
        route: '/system_admin_settings',
    },
    EMAIL: {
        title: 'Email',
        route: '/email',
    },
    PROFILE: {
        title: 'View Profile',
        route: '/profile',
    },
    CALLBACK: {
        title: 'Callback',
        route: '/callback',
    },
    SILENT_RENEW: {
        title: 'Silent Renew',
        route: '/silent-renew',
    },
    JOB_PROFILE: {
        title: 'Job Profile',
        route: '/job_profile/',
    },
    LOAD_MANAGEMENT: {
        title: 'Load Management',
        route: '/loads/',
    },
    // LOAD_MANAGEMENT_WITH_ID: {
    //     title: '',
    //     route: '/loads/:jobId',
    // },
    // EDIT_JOB: {
    //     title: 'Edit Job',
    //     route: '/edit_job/',
    // },
    // EDIT_JOB_WITH_ID: {
    //     title: '',
    //     route: '/edit_job/:jobId',
    // },
    JOB_LOADS: {
        title: 'Job Loads',
        route: '/job_loads/',
    },
    JOB_LOADS_WITH_ID: {
        title: '',
        route: '/job_loads/:jobId',
    },
    LOAD_PROFILE: {
        title: 'Load Profile',
        route: '/load_profile/',
    },
    LOAD_PROFILE_WITH_ID: {
        title: '',
        route: '/load_profile/:loadId',
    },
    CHANGE_PASSWORD: {
        title: 'Change Password',
        route: '/change_password',
    },
    LOAD_HISTORY: {
        title: 'Load History',
        route: '/load_history/',
    },
    LOAD_HISTORY_WITH_ID: {
        title: '',
        route: '/load_history/:loadId',
    },
    JOB_ACTIVITY: {
        title: '',
        route: '/job_activity/',
    },
    JOB_ACTIVITY_WITH_ID: {
        title: '',
        route: '/job_activity/:jobId',
    },
    DAILY_SAND_ALLOCATIONS: {
        title: '',
        route: '/daily_sand_allocation/',
    },
    DAILY_SAND_ALLOCATIONS_WITH_ID: {
        title: '',
        route: '/daily_sand_allocation/:jobId',
    },
    CHASSIS_CHECKOUT: {
        title: '',
        route: '/chassischeckout',
    },
};
