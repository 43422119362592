import './Filter.scss';
import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { useStoreContext } from '../App/StoreProvider';
import {
    GET_ALL_STATUS_FILTER,
    GET_ALL_PROPPANTS_BY_JOB,
    GET_ALL_LOFAS_BY_JOB,
    // GET_ALL_DRIVERS_FILTER,
    // GET_ALL_CARRIERS_FILTER,
    GET_LOADS_CSV,
} from '../../graphql/client/queries';
import { Error, Loading } from '../_common';
import LoadFilterItem from './FilterItem/LoadFilterItem';
import Button from 'react-bootstrap/Button';
import DatePickerFilterItem from './DatePickerFilterItem/DatePickerFilterItem';

interface JobLoadsFiltersProps {
    abortRequest: () => void;
    jobId: number;
    onFilterToggle: () => void;
    exportCsv: () => void;
    csvLoading;
}

export const JobLoadsFilters = ({
    abortRequest,
    jobId,
    onFilterToggle,
    exportCsv,
    csvLoading,
}: JobLoadsFiltersProps): JSX.Element => {
    const [activeKey, setActiveKey] = useState('0');
    const [state] = useStoreContext();
    let statuses: any[] = [];
    const proppants: any[] = [];
    const lofaFilters: any[] = [];
    // var driverFilters: any[] = [];
    // var carrierFilters: any[] =  [];
    const variables = {
        jobId,
    };

    const {
        loadList: {
            searchPhraseSubset: { statusSubset, proppantSubset, lofaSubset, driverSubset, carrierSubset },
            filters: { fromDate, toDate },
        },
    } = state;

    const { data: proppantsData, loading: proppantLoading, error: proppantError } = useQuery(GET_ALL_PROPPANTS_BY_JOB, {
        variables,
    });
    const { data: lofasData } = useQuery(GET_ALL_LOFAS_BY_JOB, { variables });
    const { data: loadStatusesData } = useQuery(GET_ALL_STATUS_FILTER);
    // const { data: driversData } = useQu ery(GET_ALL_DRIVERS_FILTER);
    // const { data: carriersData } = useQuery(GET_ALL_CARRIERS_FILTER);

    if (proppantLoading) {
        return (
            <div className="Filter-Loading">
                <Loading />
            </div>
        );
    }

    if (proppantError) {
        return <Error error={proppantError} />;
    }

    if (lofasData && lofasData.getAllLofasByJob) {
        lofasData.getAllLofasByJob.forEach((item: any) => {
            lofaFilters.push({
                id: item.lofaId,
                name: item.lofaName,
            });
        });
    }

    if (proppantsData && proppantsData.getAllProppantsByJob) {
        proppantsData.getAllProppantsByJob.forEach((item: any) => {
            proppants.push({
                id: item.proppantId,
                name: item.proppantName,
            });
        });
    }

    if (loadStatusesData && loadStatusesData.getAllStatusFilter) {
        statuses = loadStatusesData.getAllStatusFilter;
        // Remove the UNKNOWN status from the statuses list
        statuses = statuses.filter(stat => {
            if (stat.name == 'Created') {const statMirror = {...stat} ; statMirror.name = 'Created/Rejected';}
            return stat.name !== 'Unknown';
           
        });
    }

    // if (driversData && driversData.getAllDriversFilter) {
    //     driverFilters = driversData.getAllDriversFilter;
    // }

    // if (carriersData && carriersData.getAllCarriersFilter) {
    //     carrierFilters = carriersData.getAllCarriersFilter;
    // }

    const onCloseDateRange = () => {
        //todo no action
    };

    return (
        <div className="Filter-Container">
            <div className="Filter-Header">
                <div className="Filter-HeaderIconContainer">
                    <i className="material-icons">filter_list</i>
                    <span className="Filter-HeaderTitle">Filters</span>
                </div>
                <Button
                    className="Filter-SelectAll"
                    variant="link"
                    onClick={(): void => setActiveKey(activeKey === '0' ? '1' : '0')}
                >
                    {activeKey === '0' ? 'Collapse All' : 'Expand All'}
                </Button>
            </div>
            <LoadFilterItem
                abortRequest={abortRequest}
                title="Status"
                items={statuses}
                itemsSubset={statusSubset.subset}
                searchPhrase={statusSubset.searchPhrase}
                activeKey={activeKey}
                isCollection={true}
                isSearchable={false}
                onFilterToggle={onFilterToggle}
            />
            <LoadFilterItem
                abortRequest={abortRequest}
                title="Proppant"
                items={proppants}
                itemsSubset={proppantSubset.subset}
                searchPhrase={proppantSubset.searchPhrase}
                activeKey={activeKey}
                isCollection={true}
                isSearchable={true}
                onFilterToggle={onFilterToggle}
            />
            <LoadFilterItem
                abortRequest={abortRequest}
                title="Loading Facility"
                items={lofaFilters}
                itemsSubset={lofaSubset.subset}
                searchPhrase={lofaSubset.searchPhrase}
                activeKey={activeKey}
                isCollection={true}
                isSearchable={true}
                onFilterToggle={onFilterToggle}
            />
            <DatePickerFilterItem
                abortRequest={abortRequest}
                title="Delivered Date"
                activeKey={activeKey}
                fromDate={fromDate}
                toDate={toDate}
                filterType="jobLoads"
                closeDateRange={onCloseDateRange}
            />
            <div className="Filter-Download-Container">
                {csvLoading ? <Loading /> : ''}
                <i className="material-icons Filter-Download-Icon">get_app</i>
                <Button className="Filter-SelectAll" variant="link" onClick={exportCsv}>
                    Download CSV
                </Button>
            </div>
        </div>
    );
};
