import React, { useEffect, useState } from 'react';
import LoadManagementHeader from '../Header/LoadManagementHeader';
import { LoadFilters } from '../Filter/LoadFilters';
import LoadList from '../TableList/LoadList';
import { GET_FILTERED_LOAD_MANAGEMENTS, GET_LOADMANAGEMENT_LOADLIST_CSV } from '../../graphql/client/queries';
import {
    GetFilteredLoadManagements,
    GetFilteredLoadManagements_getFilteredLoadManagements as Load,
    SortOrder,
} from '../../types/schemaTypes';
import { Error, NoResult } from '../_common';
import { useStoreContext } from '../App/StoreProvider';
import { useQuery } from '@apollo/client';
import './LoadManagement.scss';
import { LoadStatusEnum } from '../../types/LoadStatusEnum';

import * as LoadManagementListActions from '../../store/actions/loadManagementList';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import ForbiddenError from '../_common/Error/ForbiddenError';
import LoadManagementLoadData from './LoadManagementLoadData';
import { Base64 } from 'js-base64';
// Do not change this value unless the associate backend value has been updated - (8/22/19) BS
const PAGE_SIZE = 50;

interface SelectedLoad {
    loadId: number;
    loadNumber: number;
}

const LoadManagement = ({ match }: any): JSX.Element => {
    const [listHeight, setListHeight] = useState(window.screen.height);
    const [state, dispatch] = useStoreContext();
    const {
        loadManagementList: { filters, searchPhrase },
        carrierList: { carrierId },
        loadList: {
            filters: { fromDate, toDate },
        },
    } = state;
    const abortController =new AbortController();
    const loadIds: any = [];
    useEffect(() => {
        dispatch(LoadManagementListActions.resetFilterState());
    }, []);
    const initialArray: SelectedLoad[] = [];
    // TODO: Move local state to jobListReducer
    const [selectedLoads, setSelectedLoads] = useState(initialArray);

    const [sortOrder, setSortOrder] = useState<SortOrder | null>(null);
    const [isSearchOnLoadId, setIsSearchOnLoadId] = useState(true);
    const [csvCalled, setCsvCalled] = useState(false);
    const isAdmin = useAuthContext().userHasPermission(PermissionEnum.Admin);
    const isCarrier = useAuthContext().userHasPermission(PermissionEnum.Carrier);
    const isOperationUser = useAuthContext().userHasPermission(PermissionEnum.Operation);

    const variables =  React.useMemo(()=>{
        
        return {
        input: {
            regionIds: filters.regionIds,
            districtIds: filters.districtIds,
            customerIds: filters.customerIds,
            jobStatuses: filters.jobStatuses,
            proppants: filters.proppantIds,
            statuses: filters.statuses,
            carriers: filters.carrierIds,
            drivers: filters.driverIds,
            lofas: filters.lofaIds,
            jobIds: filters.jobIds,
            search: searchPhrase,
            sortOrder: sortOrder,
            atDestinationOutFrom: filters.fromDate,
            atDestinationOutTo: filters.toDate,
            driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
            isSearchOnLoadId: searchPhrase ? isSearchOnLoadId : true,
        },
    }},[filters.regionIds,filters.districtIds,filters.customerIds,filters.jobStatuses,filters.statuses,filters.carrierIds,filters.driverIds,filters.lofaIds,filters.jobIds,searchPhrase,sortOrder,filters.fromDate,filters.toDate,carrierId,isSearchOnLoadId]);

    const context = {
       
        fetchOptions: {
            signal: abortController.signal,
        },
    
     }



    const abortRequest = (): void => {
        abortController.abort();
    };


    const downloadCsv = (csvExport): Blob => {
        const result = csvExport.getLoadManagementLoadListCSV.csv;
        const decodedCsv = Base64.decode(result);
        const file = new Blob([decodedCsv], { type: 'application/csv' });
        return file;
    };

    const getCurrentDate = (): string => {
        const today = new Date();
        const day = today.getDate();
        const month = today.getMonth() + 1;
        const year = today.getFullYear();
        const strDay = day < 10 ? `0${day}` : `${day}`;
        const strMonth = month < 10 ? `0${month}` : `${month}`;
        return `${year}${strMonth}${strDay}`;
    };

    const { loading: csvLoading, data: csvExport } = useQuery(GET_LOADMANAGEMENT_LOADLIST_CSV, {
        onCompleted: () => {
            if (csvCalled && csvExport) {
                const file: Blob = downloadCsv(csvExport);
                const url = window.URL.createObjectURL(file);
                const a = document.createElement('a');
                a.href = url;
                a.download = `List of Loads_${getCurrentDate()}.csv`;
                a.click();
                setCsvCalled(false);
            }
        },
        variables: {
            input: {
                regionIds: filters.regionIds,
                districtIds: filters.districtIds,
                customerIds: filters.customerIds,
                jobStatuses: filters.jobStatuses,
                proppants: filters.proppantIds,
                statuses: filters.statuses,
                carriers: filters.carrierIds,
                drivers: filters.driverIds,
                lofas: filters.lofaIds,
                jobIds: filters.jobIds,
                search: searchPhrase,
                sortOrder: sortOrder,
                atDestinationOutFrom: filters.fromDate,
                atDestinationOutTo: filters.toDate,
                driverCompanyId: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
                isSearchOnLoadId: searchPhrase ? isSearchOnLoadId : true,
            },
        },
        skip: !csvCalled,
        fetchPolicy: 'no-cache',
    });

    const setListContainerHeight = (): void => {
        const filterDiv = document.getElementById('loadsFilterContainer');
        const listDiv = document.getElementById('loadsListContainer');

        let filterHeight = 0;
        // setTimeout in place to avoid animation miscalculations
        setTimeout(() => {
            if (filterDiv) {
                filterHeight = filterDiv.scrollHeight;
            }
            if (listDiv) {
                setListHeight(Math.min(filterHeight));
            }
        }, 100);
    };

    const onCheckAll = (checkedStatus: boolean, loadsList: Load[]): void => {
        const result: SelectedLoad[] = [];
        if (checkedStatus === true) {
            loadsList.forEach((el: Load): void => {
                result.push({ loadId: el.loadId, loadNumber: el.loadNumber });
            });
        }
        setSelectedLoads(result);
    };

    const toggleSortOrder = (): void => {
        if (sortOrder === null) {
            setSortOrder(SortOrder.ASCENDING);
        } else if (sortOrder === SortOrder.ASCENDING) {
            setSortOrder(SortOrder.DESCENDING);
        } else {
            setSortOrder(null);
        }
    };

    document.title = 'Load Management';

    if (!isAdmin && !isOperationUser && !isCarrier) {
        return <ForbiddenError />;
    }

    return (
        <div className="JobLoadsManagement-Container">
            <LoadManagementHeader
                onCheckAll={checked => onCheckAll(checked, [])}
                sortOrder={sortOrder}
                toggleSortOrder={toggleSortOrder}
                selectedJobs={[]}
                isSearchOnLoadId={isSearchOnLoadId}
                setIsSearchOnLoadId={setIsSearchOnLoadId}
            />

            <div className="JobLoadsManagement-BodyContainer">
                <div id="loadsFilterContainer" className="JobLoadsManagement-FiltersContainer">
                    
                    <LoadFilters
                        abortRequest={abortRequest}
                        onFilterToggle={setListContainerHeight}
                        isSearchOnLoadId={isSearchOnLoadId}
                        setIsSearchOnLoadId={setIsSearchOnLoadId}
                        exportCsv={() => {
                            setCsvCalled(true);
                        }}
                        csvLoading={csvLoading}
                    />
                </div>

                {variables.input.driverCompanyId != 0 && (
                    <div
                        id="loadsListContainer"
                        className="JobLoadsManagement-ListContainer"
                        style={{ height: listHeight }}
                    >
                        <LoadManagementLoadData></LoadManagementLoadData>
                    </div>
                )}
                {variables.input.driverCompanyId == 0 && (
                    <div id="loadsListContainer" className="JobLoadsManagement-ListContainer">
                        <NoResult isModal={false} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default LoadManagement;
