import gql from 'graphql-tag';

export default gql`
    query GetAllEpCompanies {
        getAllEpCompanies {
            id
            companyName
        }
    }
`;
