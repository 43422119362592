/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * A size-optimized refactor of Redux's combineReducers.
 * All safeguards removed. Use at your own risk.
 * https://github.com/reduxjs/redux/blob/master/src/combineReducers.js
 */
export const combineReducers = (reducers: any): any => (state: any, action: any): any => {
    let hasChanged = false;
    const initialState = {};

    const newState = Object.keys(reducers).reduce((result: any, key: any): any => {
        result[key] = reducers[key](state[key], action);
        hasChanged = hasChanged || result[key] !== state[key];
        return result;
    }, initialState);

    return hasChanged ? newState : state;
};
