import React, { useState, useRef } from 'react';
import { ButtonToolbar, Overlay, OverlayTrigger, Popover, Button } from 'react-bootstrap';
import './DriverLoadNotes.scss';
import 'material-design-icons/iconfont/material-icons.css';
import moment from 'moment';
import { formatDateUtcToLocal } from '../../../../utils/helpers/datetimeHelper';

interface ToolTipProps {
    data?: any;
}

const DriverLoadNotes = ({ data }: ToolTipProps): JSX.Element => {
    const popoverRight = t => {
        if (t) {
            return (
                <Popover id="popover-positioned-scrolling-right" title="Popover right">
                    <div style={{ padding: '10px', width: '290px', maxHeight: '515px', overflowY: 'auto' }}>
                        <div>
                            {t.notes.map(function(object, i) {
                                return (
                                    <div key={object.loadId}>
                                        <div>
                                            <div>
                                                <span style={{ fontSize: '16px', fontWeight: 'bolder' }}>
                                                    {object.name}
                                                </span>
                                            </div>
                                            <div>
                                                <span style={{ fontSize: '12px' }}>
                                                    {formatDateUtcToLocal(object.createDate)}
                                                </span>
                                            </div>
                                        </div>
                                        <div style={{ marginTop: '10px' }}>
                                            <p>{object.notes}</p>
                                        </div>
                                        {t.notes.length > i + 1 && (
                                            <div
                                                style={{
                                                    borderBottom: '1px solid lightgray',
                                                    marginBottom: '10px',
                                                    marginRight: '20px',
                                                }}
                                            ></div>
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Popover>
            );
        } else {
            return <div></div>;
        }
    };

    return (
        <OverlayTrigger trigger="click" placement="right" overlay={popoverRight(data)} rootClose={true}>
            <i
                style={{ marginLeft: '7px', fontSize: '18px', marginTop: '12px' }}
                className="material-icons Info-Icon"
                onClick={() => document.body.click()}
            >
                info
            </i>
        </OverlayTrigger>
    );
};
DriverLoadNotes.displayName = 'DriverLoadNotes';
export default DriverLoadNotes;
