import React, { useRef, useState } from 'react';
import { GetAllDriversListByFilter_getAllDriversListByFilter as Driver } from '../../../types/schemaTypes';
import './DriverItem.scss';
import 'material-design-icons/iconfont/material-icons.css';
import DeleteModal from '../../JobProfile/DeleteModal';
import { CarrierOption, CPOption, Option } from '../interface';
import { formatDateUtcToLocal } from '../../../utils/helpers/datetimeHelper';

interface DriverItemProps {
    driver: Driver;
    options: Option[];
    cpOptions: CPOption[];
    carrierOptions: CarrierOption[];
    index: number;
    canEditUsers: boolean;
    isOperationUser: boolean;
    onEditClick: (user: Driver) => void;
    onDelete?: (record: any) => void;
}

const DriverItem = ({
    driver,
    options,
    cpOptions,
    carrierOptions,
    index,
    onEditClick,
    onDelete,
    canEditUsers,
    isOperationUser,
}: DriverItemProps): JSX.Element => {
    const {
        id,
        name,
        regionName,
        districtName,
        isnetId,
        isCompliant,
        driverActiveDate,
        activeInactiveUser,
        active,
        companyName,
        cellPhone,
        status,
    } = driver;
    const [deleteConfirm, setDeleteConfirm] = useState(false);
    const isCarrierActive = useRef(true);
    const handleDelete = () => {
        onDelete && onDelete(driver);
        setDeleteConfirm(false);
    };

    const isOdd = index % 2 == 1;
    const containerStyle = `DriversItem-Bottom-Container  ${!isOdd && 'DriversItem-Bottom-Container-With-Background'}`;

    return (
        <div className={containerStyle}>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-2">
                <div className="DriversItem-List-Header">
                    {isCompliant === '3' ? (
                        <div className="compliant"></div>
                    ) : isCompliant === '1' ? (
                        <div className="notcompliant"></div>
                    ) : (
                        <div className="expired"></div>
                    )}
                    {name}
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">{regionName}</div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">{districtName}</div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">{isnetId}</div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">
                    {isCompliant === '3' || isCompliant === '2' ? 'Compliant' : 'Not Compliant'}
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">
                    {driverActiveDate ? formatDateUtcToLocal(driverActiveDate) : ''}
                </div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">{activeInactiveUser}</div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-2">
                <div className="DriversItem-List-Header">{companyName}</div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">{cellPhone}</div>
            </div>
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">{active ? 'Active' : 'Inactive'}</div>
            </div>

            {!canEditUsers && <div className="DriversItem-List-Header"></div>}
            <div className="d-flex flex-column justify-content-between my-flex-container col-md-1">
                <div className="DriversItem-List-Header">
                    {canEditUsers && (
                        <div
                            className={`
                            ${canEditUsers ? 'UsersItem-Edit-Container' : 'UsersItem-Edit-Container-Disable'}`}
                            onClick={() => onEditClick(driver)}
                        >
                            <i className="material-icons UsersItem-Icon">edit</i>
                            <span className="UsersItem-Link">Edit</span>
                        </div>
                    )}
                    {/* &nbsp;|&nbsp;
                    {canEditUsers && onDelete && (
                        <div onClick={() => setDeleteConfirm(true)} className={` ${'GridViewItem-Edit-Container'}`}>
                            <i className="material-icons UsersItem-Icon">delete</i>
                            <span className="UsersItem-Link">Delete</span>
                        </div>
                    )} */}
                </div>
            </div>

            <DeleteModal
                show={deleteConfirm}
                name={'user'}
                onConfirm={handleDelete}
                onCancel={() => setDeleteConfirm(false)}
            />
        </div>
    );
};

export default DriverItem;
