export enum InputTitleEnum {
    PROPPANT_ID = 'proppantId',
    PROPPANT_NAME = 'proppantName',
    VOLUME = 'totalVolume',
    LOFA_VOLUME = 'lofaVolume',
    MILES_FROM_WELL = 'milesFromWell',
    VOLUME_PER_BOX = 'volumePerBox',
    SAND_PO = 'sandPo',
    TRANSPORTATION_PO = 'transportationPo',
    WELL_NAME = 'wellName',
    STAGES = 'stages',
    STAGE_TIME = 'stageTime',
    TOTAL_VOLUME = 'totalVolume',
    MAX_VOL_BOX = 'volumePerBox', // TODO jsiegel -- revisit GQL to fix naming.
}
