import gql from 'graphql-tag';

export default gql`
    query GetLoadProgress($loadId: Int) {
        getLoadProgress(loadId: $loadId) {
            isAssigned
            assignedDateTime
            acceptedDateTime
            isLoading
            loadingDateTime
            isLoaded
            loadedDateTime
            loadingElapsedTime
            milesToWell
            isOnSite
            onSiteDateTime
            isDelivered
            deliveredDateTime
            deliveryElapsedTime
            sandTicketNumber
            bolNumber
            additionalTicketNumber
            weight
            grossWeight
            tareWeight
            sandTicketImage
            bolTicketImage
            additionalTicketImage
            sandTicketUploadedBy
            bolTicketUploadedBy
            additionalTicketUploadedBy
            sandTicketUploadedAt
            bolTicketUploadedAt
            additionalTicketUploadedAt
            emptyBox1
            emptyBox2
        }
    }
`;
