import React from 'react';
import './CustomerDetail.scss';
import { Row, Col } from 'react-bootstrap';
import TextContent from '../../_common/TextContent';
import { TextFormat } from '../../_common/TextContent/TextContent';

interface CustomerDetailProps {
    contact?: string | null;
    crew?: string | null;
    customerName?: string | null;
    customerPo?: string | null;
    epCompany?: string | null;
    phoneNumber?: string | null;
}

const defaultString = (text: string | null | undefined): string => text || 'None';

const CustomerDetail = ({
    contact,
    crew,
    customerName,
    customerPo,
    epCompany,
    phoneNumber,
}: CustomerDetailProps): JSX.Element => {
    return (
        <Col>
            <Row className="CustomerDetail-Title">Customer Details</Row>
            <Row className='custom-row'>
                <TextContent hasTopMargin title={'Customer Name:'} value={defaultString(customerName)}></TextContent>
            </Row>
            <Row className='custom-row'>
                <TextContent hasTopMargin title={'Customer PO:'} value={defaultString(customerPo)}></TextContent>
            </Row>
            <Row className='custom-row'>
                <TextContent hasTopMargin title={'E&P Company:'} value={defaultString(epCompany)}></TextContent>
            </Row>
            <Row className='custom-row'>
                <TextContent hasTopMargin title={'Crew:'} value={defaultString(crew)}></TextContent>
            </Row>
            <Row className='custom-row'>
                <TextContent hasTopMargin title={'Contact:'} value={defaultString(contact)}></TextContent>
            </Row>
            <Row className='custom-row'>
                <TextContent
                    hasTopMargin
                    title={'Phone Number:'}
                    textFormat={TextFormat.PhoneNumber}
                    value={defaultString(phoneNumber)}
                ></TextContent>
            </Row>
        </Col>
    );
};

export default CustomerDetail;
