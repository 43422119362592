import { Action } from '../../types';

export enum Actions {
    FILTER_SEARCHSTRING = 'USERLIST_FILTER_SEARCHSTRING',
    FILTER_STATUS_SELECT = 'USERLIST_FILTER_STATUS_SELECT',
    FILTER_ROLE_SELECT = 'USERLIST_FILTER_ROLE_SELECT',
    FILTER_CUSTOMER_SELECT = 'USERLIST_FILTER_CUSTOMER_SELECT',
    FILTER_CARRIER_SELECT = 'USERLIST_FILTER_CARRIER_SELECT',
}

export const setFilterValue = (value: any, actionName: any): Action => {
    return {
        type: actionName,
        payload: value,
        error: null,
    };
};
