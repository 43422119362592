import { Action } from '../../types';

export enum Actions {
    UPDATE_FILTERS = 'JOB_LIST_UPDATE_FILTERS',
    UPDATE_SEARCH_PHRASE = 'JOB_LIST_UPDATE_SEARCH_PHRASE',
    UPDATE_FILTERS_SELECT_ALL = 'UPDATE_FILTERS_SELECT_ALL',
    REFRESH_JOBMANAGEMENT_FILTERS = 'jOBMANAGEMENT_FILTER_SET_TO_DEFAULT',
}

export const setFiltersForJobs = (filterType: string, id: number, isSelected: boolean): Action => {
    return {
        type: Actions.UPDATE_FILTERS,
        payload: {
            filterType,
            id,
            isSelected,
        },
        error: null,
    };
};

export const setSelectAllFilters = (itemIds: number[], filterType: string): Action => {
    return {
        type: Actions.UPDATE_FILTERS_SELECT_ALL,
        payload: {
            filterType,
            itemIds,
        },
        error: null,
    };
};

export const setSearchPhrase = (phrase: string): Action => {
    return {
        type: Actions.UPDATE_SEARCH_PHRASE,
        payload: phrase,
        error: null,
    };
};

export const resetFilterState = (): Action => {
    return {
        type: Actions.REFRESH_JOBMANAGEMENT_FILTERS,
        payload: null,
        error: null,
    };
};
