import './LoadLogItem.scss';
import React from 'react';
import Col from 'react-bootstrap/Col';
import moment from 'moment';
import { GetLoadLoggers_getLoadLoggers as LoadLogger } from '../../../../types/schemaTypes';

interface LoadLogProps {
    loadLogItem: LoadLogger;
    index: number;
}

const LoadLogItem = ({ loadLogItem, index }: LoadLogProps): JSX.Element => {
    const datePart = moment(loadLogItem.creationDate.toString()).format('MMM DD, YYYY');
    const timePart = moment(loadLogItem.creationDate.toString()).format('H:mm:ss');

    return (
        <div
            className={`LoadLogItem-RowContainer ${index % 2 === 0 ? 'LoadLogItem-OddRow' : 'LoadLogItem-EvenRow'}`}
            key={loadLogItem.id}
        >
            <Col md={3} className="LoadLogItem-ItemColumn">
                {`${datePart} at ${timePart}`}
            </Col>
            <Col md={5} className="LoadLogItem-ItemColumn">
                {loadLogItem.message}
            </Col>
            <Col md={2} className="LoadLogItem-ItemColumn">
                {loadLogItem.creatorName}
            </Col>
            <Col md={2} className="LoadLogItem-ItemColumn">
                {loadLogItem.systemType}
            </Col>
        </div>
    );
};

export default LoadLogItem;
