import React, { useEffect, useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import './DriverCompanyGrade.scss';
import { GET_DRIVERCOMPANY_GRADE } from '../../../graphql/client/queries';
import {
    GetDriverCompanyGrade_getDriverCompanyGrade as DriverCompanyGradeObj,
    GetDriverCompanyGrade,
} from '../../../types/schemaTypes';
import { Query } from '@apollo/client/react/components';
import {QueryResult} from '@apollo/client'
import { Error } from '../../_common';
import { useStoreContext } from '../../App/StoreProvider';

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

const DriverCompanyGrade = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const {
        carrierList: { carrierId },
    } = state;

    const abortController = new AbortController();
    const context = {
        fetchOptions: {
            signal: abortController.signal,
        },
    };

    const variables = {
        id: carrierId ? carrierId : parseInt(localStorage.getItem('carrierId') || '0'),
    };

    return (
        <Query query={GET_DRIVERCOMPANY_GRADE} variables={variables} fetchPolicy="network-only" context={context}>
            {({
                error,
                data,
                loading,
                refetch,
                fetchMore,
            }: QueryResult<GetDriverCompanyGrade | undefined>): JSX.Element | null => {
                if (error) {
                    return <Error error={error} />;
                }
                if (
                    data != null &&
                    data.getDriverCompanyGrade != null &&
                    data.getDriverCompanyGrade.grade != null &&
                    data.getDriverCompanyGrade.grade !== 'Satisfactory'
                ) {
                    return (
                        <div>
                            <div className="Grade-Container">Action Required | ISNET Company Profile Grade</div>
                            <div className="Grade-Container-FontRed">
                                ISNET Company Grade is Not &quot;Satisfactory&quot;. Please take immediate action
                            </div>
                        </div>
                    );
                } else {
                    return <div></div>;
                }
            }}
        </Query>
    );
};

export default DriverCompanyGrade;
