import gql from 'graphql-tag';

export default gql`
    query GetAllAccessorials {
        getAllAccessorials {
            accessorialId
            accessorialName
            isDeleted
        }
    }
`;
