import React, { useEffect, useState } from 'react';
import 'material-design-icons/iconfont/material-icons.css';
import Image from 'react-bootstrap/Image';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import sortAsc from '../../../assets/sort_az.svg';
import sortDesc from '../../../assets/sort_za.svg';
import sortDefault from '../../../assets/sort_default.svg';
import multipleEnabled from '../../../assets/multiple_enabled.svg';
import multipleDissabled from '../../../assets/multiple_dissabled.svg';
import './JobManagementHeader.css';
import CarrierDropDown from '../../CarrierDropdown/CarrierDropdown';

interface SelectedJob {
    jobId: string;
    jobName: string;
}

export enum SortOrder {
    ASCENDING = 'ASCENDING',
    DESCENDING = 'DESCENDING',
}

interface HeaderProps {
    onCheckAll: (checkedStatus: boolean) => void;
    selectedJobs: SelectedJob[];
    toggleSortOrder: () => void;
    sortOrder: SortOrder | null;
    openSelectedJobs: () => void;
    defaultChecked: boolean;
}

const sortIconSrc = (sortOrder: SortOrder | null): string => {
    if (sortOrder === null) return sortDefault;
    else return sortOrder === SortOrder.ASCENDING ? sortAsc : sortDesc;
};

const JobManagementHeader = ({
    onCheckAll,
    selectedJobs,
    toggleSortOrder,
    sortOrder,
    openSelectedJobs,
    defaultChecked,
}: HeaderProps): JSX.Element => {
    return (
        <div className="Header-Container">
            <div className="Header-SearchBar">
                <CarrierDropDown></CarrierDropDown>
            </div>
            <div className="Header-ListHeader">
                <div className="Header-LeftOptions">
                    <input
                        type="checkbox"
                        className="Header-Checkbox"
                        checked={defaultChecked}
                        onChange={(event): void => onCheckAll(event.currentTarget.checked)}
                    />
                    <div className="Row">
                        <span className="TableListHeader-Link">{`Select All (${selectedJobs.length} selected)`}</span>
                    </div>
                    <span className="Header-Divider">|</span>
                    <OverlayTrigger
                        placement="bottom"
                        delay={{ show: 250, hide: 400 }}
                        overlay={<div className="Header-Tooltip">Sort by Job Name</div>}
                    >
                        <div className="Row Header-GroupLink" onClick={(): void => toggleSortOrder()}>
                            <Image src={sortIconSrc(sortOrder)} className="Header-Sorting-Icon" />
                            <span className="TableListHeader-Link">Sort</span>
                        </div>
                    </OverlayTrigger>
                </div>
                <div className="Header-RightOptions">
                    <div className="Row Header-GroupLink" onClick={(): void => openSelectedJobs()}>
                        <Image
                            src={selectedJobs.length !== 0 ? multipleEnabled : multipleDissabled}
                            className="Header-Sorting-Icon"
                        />
                        <span
                            className={
                                selectedJobs.length !== 0
                                    ? 'TableListHeader-Link'
                                    : 'TableListHeader-Link Header-Inactive'
                            }
                        >
                            Open Jobs
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default JobManagementHeader;
