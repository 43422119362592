import { Reducer } from 'react';
import { Actions } from '../../actions/crewList';
import { Action, CrewListState } from '../../types';

const initialCrewListState: CrewListState = {
    searchPhrase: '',
};

const setSearchPhrase = (search: string, state: CrewListState): CrewListState => {
    return {
        ...state,
        searchPhrase: search,
    };
};

const crewListReducer: Reducer<CrewListState, Action> = (state, action): CrewListState => {
    let newState = { ...state };

    switch (action.type) {
        case Actions.UPDATE_SEARCH_PHRASE:
            newState = setSearchPhrase(action.payload, state);
            break;
    }

    return newState;
};

export { initialCrewListState, crewListReducer };
