import React from 'react';
import './Loading.scss';

export const Loading = (): JSX.Element => {
    return (
        <div className="loader-container">
            <div className="spinner-border Loading-Spinner" role="status">
                <span className="sr-only"></span>
            </div>
        </div>
    );
};
