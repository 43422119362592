import 'material-design-icons/iconfont/material-icons.css';
import React, { useEffect, useState } from 'react';
import { useStoreContext } from '../App/StoreProvider';
import * as CarrierListActions from '../../store/actions/carrierList';
import { Actions } from '../../store/actions/userList';
import { useAuthContext } from '../Auth/AuthProvider';
import { PermissionEnum } from '../../types/PermissionEnum';
import { useQuery } from '@apollo/client';
import { GET_ALL_DRIVER_COMPANY } from '../../graphql/client/queries';
import { GetDriverCompanies_getDriverCompanies as DriverCompany } from '../../types/schemaTypes';
import { CarrierOption } from '../Users/interface';
import * as LoadManagementListActions from '../../store/actions/loadManagementList';

const CarrierDropDown = (): JSX.Element => {
    const [state, dispatch] = useStoreContext();
    const [selectedCarrierId, setSelectedCarrierId] = useState(0);
    const authContext = useAuthContext();
    const {
        auth: { user },
        carrierList: { carrierId },
    } = state;
    const userProfile = user.profile;
    const opsCarrierUser = authContext.userHasPermission(PermissionEnum.Carrier);
    const { data: driverComapniesData } = useQuery(GET_ALL_DRIVER_COMPANY);
    const carrierOptions: CarrierOption[] = [];
    if (driverComapniesData && driverComapniesData.getDriverCompanies) {
        driverComapniesData.getDriverCompanies.forEach((element: DriverCompany) => {
            if (element.id !== null) {
                const id = element.id;
                const displayName = element && element.companyName ? element.companyName : '<None>';
                carrierOptions.push({
                    id: id,
                    name: displayName,
                });
            }
        });
    }

    useEffect(() => {
        const selectedCarrierId = parseInt(localStorage.getItem('carrierId') || '0');
        setSelectedCarrierId(selectedCarrierId);
        // dispatch(CarrierListActions.setCarrierIdSelection(selectedCarrierId));
    }, []);

    useEffect(() => {
        if (opsCarrierUser) {
            localStorage.setItem('carrierId', userProfile?.CarrierId);
            setSelectedCarrierId(userProfile?.CarrierId);
            if (userProfile.CarrierId) {
                dispatch(CarrierListActions.setCarrierIdSelection(Number(userProfile?.CarrierId)));
            }
        }
    }, [opsCarrierUser]);

    const onSelectCarrier = (e): any => {
        localStorage.setItem('carrierId', e.target.value);
        setSelectedCarrierId(Number(e.target.value));
        dispatch(CarrierListActions.setCarrierIdSelection(Number(e.target.value)));
        dispatch(LoadManagementListActions.resetFilterState());
    };

    return (
        <select
            className="form-control m-3"
            disabled={opsCarrierUser}
            value={selectedCarrierId}
            onChange={e => onSelectCarrier(e)}
        >
            <option key="0" value="0">
                -- Select Carrier --
            </option>
            {carrierOptions.map((item: any) => (
                <option key={item.id} value={item.id}>
                    {item.name}
                </option>
            ))}
        </select>
    );
};

export default CarrierDropDown;
